import { Button, Col, Row, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InView } from 'react-intersection-observer';
import { MagicPenBlue, ReportsIcon } from '../../assets/svg';
import TableComponent from '../../components/CommonTable';
import { CREATE_REPORT } from './graphql/Mutations';
import {
  LIMIT,
  PROJECT_ROLES,
  REPORTS_STATUS,
  ROAD_ANALYSIS,
} from '../../common/constants';
import {
  GET_REPORT,
  CHECK_PROJECT_HAS_DETECTIONS,
  ANALYSIS,
  GET_PROJECT_VIDEOS,
} from './graphql/Queries';
import { AppContext } from '../../AppContext';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';

const Reports = () => {
  const {
    state: { projectRole },
  } = useContext(AppContext);
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [reportCount, setReportCount] = useState('');
  const [reportList, setReportList] = useState([]);
  const [reportCreated, setReportCreated] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [generateReportContinue, setGenerateReportContinue] = useState(false);
  const [roadTrafficAnalysisData, setRoadTrafficAnalysisData] = useState(null);

  const [getProjectVideo, { data: { getProjectVideos } = {} }] = useLazyQuery(
    GET_PROJECT_VIDEOS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [accidentAnalysis] = useLazyQuery(ANALYSIS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setRoadTrafficAnalysisData(res?.analysis?.data);
    },
    onError() {},
  });

  const [trafficAnalysis] = useLazyQuery(ANALYSIS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setRoadTrafficAnalysisData(res?.analysis?.data);
    },
  });
  const [getReport, { loading: reportLoading }] = useLazyQuery(GET_REPORT, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setReportCount(res?.reports?.count);
      if (!reportCreated) {
        setReportList([...reportList, ...res?.reports?.data]);
      } else {
        setReportList(res?.reports?.data);
      }
      if (res?.reports?.data?.length < LIMIT) {
        setHasMore(false);
      }
      if (
        res?.reports?.data?.[0]?.status === REPORTS_STATUS?.SUCCESS ||
        res?.reports?.data?.[0]?.status === REPORTS_STATUS?.FAILED
      ) {
        setReportCreated(false);
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [
    checkProjectHasDetections,
    {
      data: {
        checkProjectHasAIDetections: { hasAIDetections = false } = {},
      } = {},
    },
  ] = useLazyQuery(CHECK_PROJECT_HAS_DETECTIONS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [createReport] = useMutation(CREATE_REPORT, {
    onCompleted() {
      setReportCreated(true);
      setReportList([]);
      setLoading(true);
      setHasMore(true);
      getReport({
        variables: {
          where: {
            projectId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    },
  });

  const handleCreateReport = () => {
    if (hasAIDetections) {
      setGenerateReportContinue(true);
    } else {
      createReport({
        variables: {
          data: {
            projectId,
          },
        },
      });
    }
  };

  useEffect(() => {
    getProjectVideo({
      variables: {
        where: {
          projectId,
        },
      },
    });
  }, []);

  useEffect(() => {
    accidentAnalysis({
      variables: {
        where: {
          type: ROAD_ANALYSIS?.ACCIDENT,
          projectId,
        },
      },
    });
    trafficAnalysis({
      variables: {
        where: {
          type: ROAD_ANALYSIS?.TRAFFIC,
          projectId,
        },
      },
    });
  }, []);

  useEffect(() => {
    checkProjectHasDetections({
      variables: {
        where: { projectId },
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getReport({
      variables: {
        where: {
          projectId,
        },
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, []);

  const handleScroll = () => {
    setLoading(true);
    getReport({
      variables: {
        where: {
          projectId,
        },
        filter: {
          skip: reportList?.length,
          limit: LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    if (
      reportCreated &&
      reportList &&
      reportList?.[0]?.status !== REPORTS_STATUS.SUCCESS
    ) {
      // Start polling for report status every 10 seconds.
      const intervalId = setInterval(() => {
        getReport({
          variables: {
            where: {
              projectId,
            },
          },
        });
      }, 5000);

      setPollingInterval(intervalId);
    } else {
      // Clear the polling interval when the report status is 'SUCCESS'.
      clearInterval(pollingInterval);
    }
  }, [reportCreated]);

  const columns = [
    {
      title: ' ',
      dataIndex: 'status',
      render: (_, val) =>
        val?.aiProcessed && (
          <Tooltip title="AI Processed">
            <MagicPenBlue />
          </Tooltip>
        ),
    },
    {
      title: '#',
      dataIndex: '',
      render: (_, _1, index) => index + 1,
    },
    {
      title: 'Report Name',
      dataIndex: 'name',
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      render: (_, record) => {
        const date = new Date(record?.createdAt);
        const formattedDate = date?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        });
        const formattedTime = date?.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
        return `${formattedDate} | ${formattedTime}`;
      },
    },
    {
      title: ' ',
      dataIndex: '',
      render: (_, record) => {
        if (record?.status === REPORTS_STATUS?.FAILED) {
          return (
            <Button
              className="secondary-btn border-btn failed"
              size="small"
              disabled
            >
              Failed
            </Button>
          );
        }
        if (record?.status === REPORTS_STATUS?.IN_PROGRESS) {
          return (
            <div className="generating-report">
              <div className="dot-pulse" />
            </div>
          );
        }
        return (
          <Button
            className="secondary-btn border-btn success"
            size="small"
            href={record?.downloadUrl}
          >
            Download
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row className="mt-20 mb-24 align-center">
        <Col span={12} className="reports-title">
          All Reports {reportCount > 0 && `(${reportCount})`}
        </Col>
        <Col span={12} className="d-flex flex-horizontal-reverse">
          <ProjectAccessRole
            allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
            currentRole={projectRole}
          >
            <Button
              type="primary"
              size="small"
              icon={<ReportsIcon />}
              onClick={handleCreateReport}
              disabled={
                reportCreated ||
                (roadTrafficAnalysisData === null &&
                  !getProjectVideos?.count > 0)
              }
            >
              Generate Report
            </Button>
          </ProjectAccessRole>
        </Col>
        <Col span={24} className="reports-description">
          <p>
            We suggest that you open the report in Microsoft Word to ensure the
            correct formatting.
          </p>
        </Col>
      </Row>
      <div className="reportsInview">
        <TableComponent
          columns={columns}
          data={reportList}
          loading={reportLoading}
          tableClassName="custom-table report-list"
        />
        {!loading && !reportLoading && !reportCreated && hasMore && (
          <InView
            as="div"
            onChange={(inView) => {
              if (inView) {
                handleScroll();
              }
            }}
          />
        )}
      </div>

      <CommonConfirmationModal
        visible={generateReportContinue}
        onOk={() => {
          setLoading(true);
          setGenerateReportContinue(false);
          createReport({
            variables: {
              data: {
                projectId,
              },
            },
          });
        }}
        onCancel={() => {
          setGenerateReportContinue(false);
        }}
        title="Generate Report"
        content="Since certain detections are incorporated through AI we suggest you to kindly verify all of them manually before generating the report. Are you sure you want to generate report?"
        okText="Confirm"
        cancelText="Discard"
      />
    </>
  );
};

export default Reports;
