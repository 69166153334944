import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INVITE_MEMBERS_FOR_WORKSPACE = gql`
  mutation inviteWorkspaceMembers($data: InviteWorkspaceMembersInput!) {
    inviteWorkspaceMembers(data: $data) {
      message
    }
  }
`;

export const DELETE_MEMBER_FROM_WORKSPACE = gql`
  mutation deleteWorkspaceMember($where: DeleteWorkspaceMemberUniqueInput!) {
    deleteWorkspaceMember(where: $where) {
      message
    }
  }
`;

export const DELETE_DETECTION = gql`
  mutation deleteDetection($where: DetectionUniqueInput!) {
    deleteDetection(where: $where) {
      message
    }
  }
`;