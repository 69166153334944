import { Button, Dropdown, Form, Select, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InView } from 'react-intersection-observer';
import { AddUserIcon, CateGory } from '../../assets/svg';
import ModalComponents from '../../components/CommonModel';
import { formValidatorRules } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import { AppContext } from '../../AppContext';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { GET_WORKSPACE_MEMBERS } from './graphql/Queries';
import {
  DELETE_MEMBER_FROM_WORKSPACE,
  INVITE_MEMBERS_FOR_WORKSPACE,
} from './graphql/Mutations';
import { LIMIT, ROLES } from '../../common/constants';

const Member = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorText, setErrorText] = useState(null);
  const [removeMemberModal, setRemoveMemberModal] = useState(false);
  const [removeMemberId, setRemoveMemberId] = useState();
  const [workSpaceMembersData, setWorkSpaceMembersData] = useState([]);
  const [workSpaceMembersCount, setWorkSpaceMembersCount] = useState(null);
  const { required, checkEmailArray } = formValidatorRules;
  const { getCurrentUser } = useContext(AppContext);
  const { workspaceRole, workspaceId } = getCurrentUser() || null;

  const [
    workspaceMembers,
    { loading: workspaceMemberDataLoading },
  ] = useLazyQuery(GET_WORKSPACE_MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.workspaceMembers?.data < LIMIT) {
        setHasMore(false);
      }
      setLoading(false);
      setWorkSpaceMembersData([
        ...workSpaceMembersData,
        ...res?.workspaceMembers?.data,
      ]);
      if (res?.workspaceMembers?.data?.length > 0) {
        setWorkSpaceMembersCount(res?.workspaceMembers?.count);
      }
    },
    onError() {},
  });

  const [
    inviteWorkSpaceMembers,
    { loading: inviteMemberLoading },
  ] = useMutation(INVITE_MEMBERS_FOR_WORKSPACE, {
    onCompleted() {
      if (workspaceRole === ROLES?.OWNER) {
        setWorkSpaceMembersData([]);
        setLoading(true);
        workspaceMembers({
          variables: {
            where: {
              workspaceId,
            },
            filter: {
              search: '',
            },
          },
        });
      }
    },
    onError() {},
  });

  const [deleteMemberFromWorkspace] = useMutation(
    DELETE_MEMBER_FROM_WORKSPACE,
    {
      onCompleted() {
        workspaceMembers({
          variables: {
            where: {
              workspaceId,
            },
          },
        });
        setRemoveMemberModal(false);
      },
      onError() {},
    },
  );

  const onSearch = async (value) => {
    const onSearchValue = value.trim();
    setLoading(true);
    setWorkSpaceMembersData([]);
    await workspaceMembers({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          search: onSearchValue,
        },
      },
    });
  };

  const handleModel = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const onFinish = async (values) => {
    if (values?.inviteByEmail) {
      try {
        const res = await inviteWorkSpaceMembers({
          variables: {
            data: {
              emails: values?.inviteByEmail,
              workspaceId,
            },
          },
        });

        if (res?.errors) {
          if (
            res.errors.message.includes('already member of another workspace!')
          ) {
            setErrorText('MEMBER');
          }
          if (res.errors.message.includes('already invited to the workspace')) {
            setErrorText('INVITED');
          }
          if (res.errors.message.includes('cannot invite itself')) {
            setErrorText('ITSELF');
          }
          setErrorEmail(values?.inviteByEmail);
          setOpenPop(true);
        }
      } catch (error) {
        return error;
      }
    }
    setVisible(false);
  };

  const handleRemoveMember = async (record) => {
    setLoading(true);
    setWorkSpaceMembersData([]);
    await deleteMemberFromWorkspace({
      variables: {
        where: {
          workspaceId,
          id: record,
        },
      },
    });
  };

  useEffect(() => {
    if (workspaceRole === ROLES?.OWNER) {
      workspaceMembers({
        variables: {
          where: {
            workspaceId,
          },
          filter: {
            search: '',
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, []);

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (rule, value, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => (
        <>
          {record?.role === ROLES?.OWNER && record?.fullName
            ? `${record?.fullName} (You)`
            : record?.fullName}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        if (record?.status === 'ACCEPTED') {
          return (
            <Tag color="#D8F3DC" className="member-tags accepted">
              Accepted
            </Tag>
          );
        }
        if (record?.status === 'CANCELLED') {
          return (
            <Tag color="#E64E2C47" className="member-tags cancelled">
              Cancelled
            </Tag>
          );
        }
        return (
          <Tag color="#E6E6E6" className="member-tags invited">
            Invited
          </Tag>
        );
      },
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => (
        <>
          {record?.role !== ROLES?.OWNER && (
            <Dropdown
              className="pointer"
              trigger={['click']}
              i
              menu={{
                items: [
                  {
                    key: 1,
                    label: 'Remove',
                    onClick: () => {
                      setRemoveMemberModal(true);
                      setRemoveMemberId(record?.id);
                    },
                  },
                ],
              }}
              disabled={record?.role === 'OWNER'}
              placement="bottom"
            >
              <CateGory />
            </Dropdown>
          )}
        </>
      ),
    },
  ];

  const handleScroll = () => {
    setLoading(true);
    workspaceMembers({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          search: '',
          skip: workSpaceMembersData?.length,
          limit: LIMIT,
        },
      },
    });
  };

  return (
    <div>
      <div className="content-top d-flex flex-horizontal justify-between align-center">
        <div className="content-top-heading">
          Members {workSpaceMembersCount && `(${workSpaceMembersCount})`}{' '}
        </div>
        <div className="content-top-input d-flex flex-horizontal align-center">
          <div className="searchBox d-flex flex-horizontal justify-start align-center">
            <Search
              placeholder="Search"
              allowClear
              onSearch={onSearch}
              size="small"
            />
          </div>

          <Button
            className="secondary-btn border-btn ml-24"
            size="small"
            icon={<AddUserIcon />}
            onClick={handleModel}
          >
            Invite Users
          </Button>
        </div>
      </div>
      <div className="mt-40">
        <TableComponent
          data={workSpaceMembersData}
          columns={columns}
          tableClassName="custom-table"
          loading={workspaceMemberDataLoading}
        />
      </div>

      <ModalComponents
        title="Invite Users"
        visible={visible}
        className="modal-components-design"
        centered
      >
        <Form layout="vertical" onFinish={onFinish} className="custom-label">
          <Form.Item
            rules={[required, checkEmailArray]}
            label="Email Id(s)"
            className="custom-label"
            name="inviteByEmail"
          >
            <Select
              placeholder="Email, Comma Separated"
              mode="tags"
              tokenSeparators={[',']}
              defaultOpen={false}
              notFoundContent=""
            />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={inviteMemberLoading}
              block
            >
              Invite
            </Button>
          </Form.Item>
        </Form>
      </ModalComponents>
      <CommonConfirmationModal
        visible={openPop}
        onOk={() => setOpenPop(false)}
        title="Error"
        okText="Okay"
        contentBlock={
          // eslint-disable-next-line no-nested-ternary
          errorText === 'INVITED' ? (
            <p>
              User “<span className="error-color">{errorEmail}</span>” is
              already invited to the workspace.
            </p>
          ) : errorText === 'MEMBER' ? (
            <p>
              User “<span className="error-color">{errorEmail}</span>” is
              already a member of another workspace!
            </p>
          ) : (
            <p>
              User “<span className="error-color">{errorEmail}</span>” cannot
              invite itself
            </p>
          )
        }
        cancelButtonProps={{ style: { display: 'none' } }}
      />
      <CommonConfirmationModal
        visible={removeMemberModal}
        onCancel={() => {
          setRemoveMemberModal(false);
        }}
        onOk={() => handleRemoveMember(removeMemberId)}
        title="Remove Member"
        content="Are you sure you want to remove this member from the workspace?"
        cancelText="Discard"
        okText="Confirm"
      />
      {!loading && (
        <InView
          as="div"
          onChange={(inView) => (inView && hasMore ? handleScroll() : null)}
        />
      )}
    </div>
  );
};

export default Member;
