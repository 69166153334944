import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT,
  DETECTION_SOURCE_IMAGE_DATA_URL,
  DETECTION_SOURCE_IMAGE_OBJECT,
  DETECTION_VIDEO_CHAINAGE_DETAIL,
  PROJECT_ROLE,
  REFRESH_TOKEN,
  TOKEN,
  USER,
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  // eslint-disable-next-line no-undef
  projectRole: localStorage?.getItem(PROJECT_ROLE),
  // eslint-disable-next-line no-undef
  currentVideoDetectionImageDataUrl: localStorage?.getItem(
    DETECTION_SOURCE_IMAGE_DATA_URL,
  ),
  currentVideoChainageDetail: JSON?.parse(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(DETECTION_VIDEO_CHAINAGE_DETAIL),
  ),
  currentVideoDetectionSourceImageObj: JSON?.parse(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(DETECTION_SOURCE_IMAGE_OBJECT),
  ),
  currentVideoDetectionRecommendationSourceImageObj: JSON?.parse(
    // eslint-disable-next-line no-undef
    localStorage?.getItem(DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT),
  ),
  tourClose: false,
  showPrompt: false,
  processedWithAI: false,
  videoChainage: null,
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'DETECTION_SOURCE_IMAGE_DATA_URL':
      // eslint-disable-next-line no-unused-expressions
      action?.data !== null
        ? // eslint-disable-next-line no-undef
          localStorage.setItem(DETECTION_SOURCE_IMAGE_DATA_URL, action?.data)
        : // eslint-disable-next-line no-undef
          localStorage.removeItem(DETECTION_SOURCE_IMAGE_DATA_URL);
      return {
        ...state,
        currentVideoDetectionImageDataUrl: action?.data,
      };
    case 'SET_VIDEO_DETECTION_SOURCE_IMAGE_OBJ':
      // eslint-disable-next-line no-unused-expressions
      action?.data !== null
        ? // eslint-disable-next-line no-undef
          localStorage.setItem(
            DETECTION_SOURCE_IMAGE_OBJECT,
            action?.data && Object?.keys(action?.data)?.length
              ? JSON?.stringify(action?.data)
              : null,
          )
        : // eslint-disable-next-line no-undef
          localStorage.removeItem(DETECTION_SOURCE_IMAGE_OBJECT);

      return { ...state, currentVideoDetectionSourceImageObj: action?.data };
    case 'SET_VIDEO_DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJ':
      // eslint-disable-next-line no-unused-expressions
      action?.data !== null
        ? // eslint-disable-next-line no-undef
          localStorage.setItem(
            DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT,
            action?.data && Object?.keys(action?.data)?.length
              ? JSON?.stringify(action?.data)
              : null,
          )
        : // eslint-disable-next-line no-undef
          localStorage.removeItem(DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT);

      return {
        ...state,
        currentVideoDetectionRecommendationSourceImageObj: action?.data,
      };
    case 'SET_VIDEO_CHAINAGE_DETAIL':
      // eslint-disable-next-line no-unused-expressions
      action?.data !== null
        ? // eslint-disable-next-line no-undef
          localStorage.setItem(
            DETECTION_VIDEO_CHAINAGE_DETAIL,
            action?.data && Object?.keys(action?.data)?.length
              ? JSON?.stringify(action?.data)
              : null,
          ) // eslint-disable-next-line no-undef
        : localStorage.removeItem(DETECTION_VIDEO_CHAINAGE_DETAIL);

      return { ...state, currentVideoChainageDetail: action?.data };
    case 'SET_PROJECT_ROLE':
      // eslint-disable-next-line no-undef
      localStorage.setItem(PROJECT_ROLE, action?.data);
      return { ...state, projectRole: action?.data };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'SET_SHOW_PROMPT':
      return { ...state, showPrompt: action?.data };
    case 'SET_TOUR_CLOSE':
      return { ...state, tourClose: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'PROCESSED_WITH_AI':
      return { ...state, processedWithAI: action?.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action?.data };
    case 'SET_VIDEO_CHAINAGE':
      return { ...state, videoChainage: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action?.data);
      return {
        ...state,
        refreshToken: action?.data,
      };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'UPDATE_USER': {
      const updateUser = { ...state?.currentUser, ...action?.data };
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        updateUser && Object?.keys(updateUser)?.length
          ? JSON?.stringify(updateUser)
          : null,
      );
      return { ...state, currentUser: updateUser };
    }
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.workspaceRole || '';
  };

  const isAuthenticated = () => state?.authenticated;

  const initializeAuth = (authToken, userData, refreshToken) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refresh });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      if (user?.role) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user?.role });
      }
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    getRefreshToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
