import React from 'react';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import BreadCrumb from '../../components/BreadCrumb';
import AddDetection from './AddDetection';
import Synchronization from '../sync/Components/Synchronization';
import DetectionDetail from './DetectionDetail';
import InsideProject from './InsideProject';
import ConsolidatedView from '../consolidated/ConsolidatedView';
import Reports from './Reports';
import TrafficAnalysis from './TrafficAnalysis';
import AccidentAnalysis from './AccidentAnalysis';
import SeeAll from './SeeAll';

const ProjectWrapper = () => {
  const { projectId, videoId } = useParams();
  return (
    <>
      <BreadCrumb />
      <Switch>
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.ACCIDENT_ANALYSIS}`}
          component={AccidentAnalysis}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.TRAFFIC_ANALYSIS}`}
          component={TrafficAnalysis}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.REPORTS}`}
          component={Reports}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.CONSOLIDATED_VIEW}`}
          component={ConsolidatedView}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.SEE_ALL}`}
          component={SeeAll}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/:detectionId`}
          component={DetectionDetail}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.CREATE}`}
          component={DetectionDetail}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.SYNCHRONIZATION}`}
          component={Synchronization}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}`}
          component={AddDetection}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}`}
          render={() => (
            <Redirect
              to={`${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}`}
            />
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}`}
          render={() => (
            <Redirect
              to={`${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}`}
            />
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.CREATE}`}
          render={() => (
            <Redirect
              to={`${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}`}
            />
          )}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/:detectionId`}
          component={DetectionDetail}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}${ROUTES?.CREATE}`}
          component={DetectionDetail}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.SYNCHRONIZATION}`}
          component={Synchronization}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}`}
          component={AddDetection}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId`}
          render={() => <Redirect to={`${ROUTES?.PROJECTS}/${projectId}`} />}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.SYNCHRONIZATION}`}
          component={Synchronization}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}`}
          component={AddDetection}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId${ROUTES?.ADD_DETECTION}`}
          component={DetectionDetail}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId/:videoId`}
          render={() => <Redirect to={`${ROUTES?.PROJECTS}/${projectId}`} />}
        />
        <Route
          exact
          path={`${ROUTES?.PROJECTS}/:projectId`}
          component={InsideProject}
        />
      </Switch>
    </>
  );
};

export default ProjectWrapper;
