import { Slider } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import captureVideoFrame from 'capture-video-frame';
import ReactPlayer from 'react-player';
import { ForwardOutlined, BackwardOutlined } from '@ant-design/icons';
import { SKIP_INTERVAL } from '../../../common/constants';

const VideoPlayer = ({
  url,
  handleOnSeek,
  handleOnProgress,
  timeToStart,
  setVideoDuration,
  customSlider,
  videoSliderValue,
  handleVideoSliderChange,
  handleOnAfterVideoSliderChange,
  handleSlider = true,
  playVideo,
  config,
  setCaptureDetectionImage,
  setDetectionImage = null,
  captureDetectionImage,
  videoControls,
  onEnded,
  setPlay,
  setPlayVideo,
}) => {
  const playerRef = useRef();

  useEffect(() => {
    if (playerRef && timeToStart) {
      playerRef.current.seekTo(timeToStart, 'seconds');
    }
  }, [playerRef, timeToStart]);

  const handleCaptureFrame = () => {
    const frame = captureVideoFrame(playerRef?.current?.getInternalPlayer());
    setDetectionImage(frame?.dataUri);
    setCaptureDetectionImage(false);
  };

  useEffect(() => {
    if (captureDetectionImage) {
      handleCaptureFrame();
    }
  }, [captureDetectionImage]);

  const videoLoaded = (e) => {
    setVideoDuration(e?.getDuration());
  };

  const handleForward = useCallback(() => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + SKIP_INTERVAL, 'seconds');
    }
  }, []);

  const handleBackward = useCallback(() => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime - SKIP_INTERVAL, 'seconds');
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key.toLowerCase()) {
        case 'arrowleft':
          handleBackward();
          break;
        case 'arrowright':
          handleForward();
          break;
        default:
          break;
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('keydown', handleKeyDown);

    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleForward, handleBackward]);

  return (
    <div className="video-player">
      <div className="react-player">
        <ReactPlayer
          url={url}
          ref={playerRef}
          width="100%"
          height="350px"
          controls={!customSlider}
          onSeek={handleOnSeek}
          onProgress={handleOnProgress}
          onReady={videoLoaded}
          playing={playVideo}
          config={config}
          onEnded={onEnded}
          onPause={() => {
            if (setPlay) {
              setPlay(false);
              setPlayVideo(false);
            }
          }}
          onPlay={() => {
            if (setPlay) {
              setPlay(true);
            }
          }}
        />
        <div className="seek-play-pause">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className="pointer" type="button" onClick={handleBackward}>
            <BackwardOutlined />
          </button>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className="pointer" type="button" onClick={handleForward}>
            <ForwardOutlined />
          </button>
        </div>
        {handleSlider && customSlider && !videoControls && (
          <div>
            <Slider
              step={1}
              defaultValue={1}
              value={videoSliderValue || 0}
              onChange={handleVideoSliderChange}
              onAfterChange={handleOnAfterVideoSliderChange}
              className="video-slider"
              max={playerRef?.current?.getDuration()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
