/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  MOBILE_LOGIN: '/mobile-login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  COMPONENT: '/component',
  PROFILE: '/profile',
  MEMBERS: '/members',
  SETTINGS: '/settings',
  TERMS: '/terms',
  PRIVACY_POLICY: '/privacy-policy',
  PROJECTS: '/projects',
  SYNCHRONIZATION: '/synchronization',
  FORGET_EMAIL_SENT: '/forget-email-sent',
  SIGNUP_EMAIL_SENT: '/signup-email-sent',
  RESENT_EMAIL_VERIFICATION: '/resent-email-verification',
  UPDATE_PASSWORD: '/update-password',
  VERIFY_EMAIL: '/verify-email',
  PROJECT_DETAILS: '/video',
  WORKSPACE_INVITATION: '/workspace-invitation',
  PERSONAL: '/personal',
  AGENCY: '/agency',
  SYNC: '/sync',
  PASSWORD_CHANGED: '/password-changed',
  ADD_DETECTION: '/add-detection',
  DETECTION_DETAIL: '/detection-detail',
  CONSOLIDATED_VIEW: '/consolidated-view',
  REPORTS: '/reports',
  TRAFFIC_ANALYSIS: '/traffic-analysis',
  ACCIDENT_ANALYSIS: '/accident-analysis',
  CREATE: '/create',
  EDIT: '/edit',
  SEE_ALL: `/see-all`,
  WORKSPACE_MANAGEMENT: '/workspace-management',
};

/*  Modules */
export const MODULES = {
  PROJECTS: 'Projects',
  MEMBERS: 'Members',
  SETTINGS: 'Settings',
  PERSONAL: 'Personal',
  AGENCY: 'Agency',
  WORKSPACES: 'Workspaces',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const DETECTION_IMAGE_NANOID = 'DETECTION_IMAGE_NANOID';

export const ROLES = {
  OWNER: 'OWNER',
  MEMBER: 'MEMBER',
};

export const PROJECT_ROLES = {
  OWNER: 'OWNER',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

export const PROJECT_NAME = 'PROJECT_NAME';
export const VIDEO_NAME = 'VIDEO_NAME';
export const DETECTION_SOURCE_IMAGE_OBJECT = 'DETECTION_SOURCE_IMAGE_OBJECT';
export const DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT =
  'DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJECT';

export const DETECTION_VIDEO_CHAINAGE_DETAIL =
  'DETECTION_VIDEO_CHAINAGE_DETAIL';
export const DETECTION_SOURCE_IMAGE_DATA_URL =
  'DETECTION_SOURCE_IMAGE_DATA_URL';
export const PROJECT_ROLE = 'PROJECT_ROLE';

export const CODE = { PASSWORD_INCORRECT: 'PASSWORD_INCORRECT' };

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const DATE_TIME_FORMET = 'DD-MMM-YYYY | h:mma';

export const ALL_IMAGE_UPLOAD = ['jpeg', 'jpg', 'png'];

export const ALL_CSV_UPLOAD = ['csv'];

export const ALL_VIDEO_UPLOAD = ['mp4', 'mov'];

export const VIDEO_UPLOAD_TYPE = 'video/mp4';

export const ALL_KML_UPLOAD = ['kml'];

export const LAT_LANG_UPLOAD = ['json'];

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  CHAINAGE: /^\d+\s*\+\s*\d+$/,
};

export const DISABLED_ERROR = ['checkVideoName', 'checkProjectName'];

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const MOVIES_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Updated At', value: 'updatedAt' },
  { name: 'Release Date', value: 'releaseDate' },
  { name: 'Popularity', value: 'popularity' },
  { name: 'Rate', value: 'voteAverage' },
];
export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' },
];

export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' },
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const MOVIE_STATUS = {
  PENDING: 'Pending',
  RELEASED: 'Released',
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const SLIDER_MARK = 1;
export const MARKER_TYPE = {
  SURFACE: { label: 'Surface', key: 'SURFACE' },
  ISSUES: { label: 'Issues', key: 'ISSUES' },
};

export const STATUS_SYNCED = 'SYNCED';
export const STATUS_PENDING = 'PENDING';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';

export const riskLevelOptions = [
  {
    value: 'VERY_HIGH',
    label: 'Very High',
  },
  {
    value: 'HIGH',
    label: 'High',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
];

export const priorityOptions = [
  {
    value: 'ESSENTIAL',
    label: 'Essential',
  },
  {
    value: 'HIGHLY_DESIRABLE',
    label: 'Highly Desirable',
  },
  {
    value: 'DESIRABLE',
    label: 'Desirable',
  },
];

export const ROAD_ANALYSIS = {
  TRAFFIC: 'TRAFFIC',
  ACCIDENT: 'ACCIDENT',
};

export const VIDEO_ID_CHECK = [
  'consolidated-view',
  'reports',
  'traffic-analysis',
  'accident-analysis',
  'see-all',
];

export const libraries = ['places'];

export const containerStyle = {
  width: '100%',
  height: '100%',
};

export const center = {
  lat: 21.1419136,
  lng: 72.7678976,
};

export const REPORTS_STATUS = {
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
};
export const AI_PROCESSING = {
  AI: 'AI',
  WITHOUTAI: 'WITHOUTAI',
  TERMINATING: 'TERMINATING',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  TERMINATED: 'TERMINATED',
  FAILED: 'FAILED',
};
export const PROCESSED_WITH_AI = 'PROCESSED_WITH_AI';
export const AI_PROCESS = 'AI';
export const UPLOADED_FROM_MOBILE = 'MOBILE';
export const BI0_LIMIT = 1000;

export const ATTACH_KML_FILE = 'attach_kml_file';
export const ATTACH_LATITUDE_LONGITUDE_FILE = 'attach_latitude_longitude_file';
export const IMAGE_TYPE = {
  ANNOTATED_IMAGE: 'ANNOTATED_IMAGE',
  REPLACED_IMAGE: 'REPLACED_IMAGE',
  RECOMMENDATION_REPLACED_IMAGE: 'RECOMMENDATION_REPLACED_IMAGE',
  RECOMMENDATION_ANNOTATED_IMAGE: 'RECOMMENDATION_ANNOTATED_IMAGE',
};
export const DIRECTION = {
  UPWARD: 'UPWARD',
  DOWNWARD: 'DOWNWARD',
};

export const SKIP_INTERVAL = 10;
export const KML_FORMAT = 'kml';
export const JSON_FORMAT = 'json';
