import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CONSOLIDATED_VIDEOS = gql`
  query consolidatedVideos($where: ProjectUniqueInput!) {
    consolidatedVideos(where: $where) {
      distanceCovered
      count
      data {
        totalDistance
        id
        name
        endingChainage
        duration
        locations
        startingChainage
      }
      count
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query getProject($where: ProjectUniqueInput!) {
    getProject(where: $where) {
      data {
        id
        createdAt
        name
        logoUrl
        startAddress
        endAddress
        startPoint
        endPoint
        roadType {
          id
          key
          name
        }
        roadWidth
        description
        uid
        userRole
        consolidatedImageUrl
        reportHeaderName
      }
    }
  }
`;
