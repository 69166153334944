import { gql } from '@apollo/client';
// eslint-disable-next-line import/prefer-default-export
export const UPSERT_PROJECT_CONSOLIDATED_IMAGE = gql`
  mutation UpsertProjectConsolidatedImage(
    $data: UpsertProjectConsolidatedImageInput!
  ) {
    upsertProjectConsolidatedImage(data: $data) {
      message
    }
  }
`;
