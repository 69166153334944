import { Avatar, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { getInitials } from '../../../common/utils';
import UserProfileModal from './UserProfileModal';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const [profileModal, setProfileModal] = useState(false);
  const userData = getCurrentUser() || {};
  const email = userData?.email?.toUpperCase();
  const name = userData?.fullName?.split(' ');
  const firstName = name && name?.[0]?.toUpperCase();
  const lastName = name && name?.[1]?.toUpperCase();

  return (
    <>
      <Space className="pointer d-flex align-center gap-4" size="small">
        {userData?.profileImageUrl ? (
          <Avatar
            onClick={() => setProfileModal(true)}
            src={userData?.profileImageUrl}
            size={48}
          />
        ) : (
          <Avatar
            onClick={() => setProfileModal(true)}
            className="user-avatar"
            alt="Avatar"
            size={48}
          >
            {getInitials(firstName, lastName) || getInitials(email)}
          </Avatar>
        )}
      </Space>
      {profileModal && (
        <UserProfileModal visible={profileModal} setVisible={setProfileModal} />
      )}
    </>
  );
};

export default UserProfile;
