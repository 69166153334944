import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Upload } from 'antd';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DownloadExcel, UploadCsvFileIcon } from '../../assets/svg';
import csvLogo from '../../assets/svg/csvLogo.svg';
import { fileUpload } from '../../common/utils';
import { ANALYSIS, ANALYSIS_CSV_SIGNED_PUT_URL } from './graphql/Queries';
import { CREATE_ANALYSIS, UPDATE_ANALYSIS } from './graphql/Mutations';
import {
  ALL_CSV_UPLOAD,
  PROJECT_ROLES,
  ROAD_ANALYSIS,
  USER,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import { AppContext } from '../../AppContext';

const sampleAccidentAnalysis = process.env.REACT_APP_SAMPLE_ACCIDENT_ANALYSIS;

const AccidentAnalysis = () => {
  const { projectId } = useParams();
  const [graphsData, setGraphsData] = useState(null);
  const [csvUrl, setCsvUrl] = useState(null);
  const [csvMeta, setCsvMeta] = useState(null);
  const [deleteCsvModel, setDeleteCsvModel] = useState(false);
  const [accidentAnalysisData, setAccidentAnalysisData] = useState(null);
  const [accidentAnalysisUid, setAccidentAnalysisUid] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [csvUploadError, setCsvUploadError] = useState(false);

  const {
    state: { projectRole },
  } = useContext(AppContext);

  // eslint-disable-next-line no-undef
  const createdBy = JSON?.parse(localStorage?.getItem(USER));

  const [analysisCsvSignedPutUrl] = useLazyQuery(ANALYSIS_CSV_SIGNED_PUT_URL, {
    fetchPolicy: 'no-cache',
  });
  const [
    analysis,
    { refetch: analysisRefetch, loading: analysisLoading },
  ] = useLazyQuery(ANALYSIS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setAccidentAnalysisUid(res?.analysis?.data?.uid);
      setCsvUrl(res?.analysis?.data?.csvUrl);
      setCsvMeta(res?.analysis?.data?.csvMeta);
      setDataId(res?.analysis?.data?.id);
      setGraphsData(res?.analysis?.data?.graphs);
      setAccidentAnalysisData(res?.analysis?.sourceData);
    },
    onError() {},
  });

  const [createAnalysis, { loading: createAnalysisLoading }] = useMutation(
    CREATE_ANALYSIS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        analysisRefetch();
      },
    },
  );

  const [updateAnalysis, { loading: updateLoading }] = useMutation(
    UPDATE_ANALYSIS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        analysisRefetch();
      },
    },
  );

  const handleChange = async (value) => {
    if (value?.fileList?.[0]?.originFileObj) {
      const file = value?.fileList?.[0]?.originFileObj;
      const extension = file?.name?.split('.');
      const createNanoId = nanoid(8);
      const { data } = await analysisCsvSignedPutUrl({
        variables: {
          data: {
            fileName: file?.name,
            // eslint-disable-next-line no-unneeded-ternary
            uid: accidentAnalysisUid ? accidentAnalysisUid : createNanoId,
            type: ROAD_ANALYSIS?.ACCIDENT,
          },
        },
      });
      const csvKey = data?.analysisCsvSignedPutUrl?.fileName;
      await fileUpload(data?.analysisCsvSignedPutUrl?.signedUrl, file);
      if (ALL_CSV_UPLOAD?.includes(...extension?.slice(1, 2))) {
        setCsvUploadError(false);
        try {
          if (dataId) {
            await updateAnalysis({
              variables: {
                where: {
                  type: ROAD_ANALYSIS?.ACCIDENT,
                  projectId,
                },
                data: {
                  csvKey,
                  csvMeta: {
                    extension: extension?.[extension?.length - 1],
                    fileName: file?.name,
                    contentType: file?.type,
                    size: file?.size,
                  },
                },
              },
            });
          } else {
            await createAnalysis({
              variables: {
                data: {
                  uid: createNanoId,
                  type: ROAD_ANALYSIS?.ACCIDENT,
                  projectId,
                  csvKey,
                  createdBy: createdBy?.id,
                  csvMeta: {
                    extension: extension?.[extension?.length - 1],
                    fileName: file?.name,
                    contentType: file?.type,
                    size: file?.size,
                  },
                },
              },
            });
          }
        } catch (errors) {
          return errors;
        }
      } else if (!ALL_CSV_UPLOAD?.includes(...extension?.slice(1, 2))) {
        setCsvUploadError(true);
      }
    }
  };

  useEffect(() => {
    analysis({
      variables: {
        where: {
          type: ROAD_ANALYSIS?.ACCIDENT,
          projectId,
        },
      },
    });
  }, []);

  const accidentAnalysisTableCol = [
    {
      title: 'Sr.No.',
      dataIndex: 'Serial No.',
      key: 'Serial No.',
      createAnalysisLoading,
    },
    {
      title: 'Chainage',
      dataIndex: 'Chainage',
      key: 'Chainage',
    },
    {
      title: 'Accidents Between',
      children: [
        {
          dataIndex: ['Accidents Between', 'Vehicle 1'],
          key: 'Vehicle 1',
        },
        {
          dataIndex: ['Accidents Between', 'Vehicle 2'],
          key: 'Vehicle 2',
        },
      ],
    },
    {
      title: 'Type of Accident',
      dataIndex: 'Type of Accident',
      key: 'Type of Accident',
    },
    {
      title: 'Major Cause of Accident',
      dataIndex: 'Major Cause of Accident',
      key: 'Average Speed (KMPH)',
    },
  ];

  return (
    <>
      <Typography className="analysis-title mb-24 mt-38">
        Accident Analysis
      </Typography>
      <div className="traffic-analysis mt-20">
        <Row gutter={32}>
          <Col span={12}>
            <div className="steps-block">
              <div className="steps-head">
                <Typography className="step">Step 1</Typography>
                <Typography className="description">
                  Download excel file with existing format
                </Typography>
              </div>
              <div className="steps-footer">
                <Button icon={<DownloadExcel />} href={sampleAccidentAnalysis}>
                  Download Excel Sheet
                </Button>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="steps-block">
              <div className="steps-head">
                <Typography className="step">Step 1</Typography>
                <Typography className="description">
                  Upload CSV file with updated data
                </Typography>
              </div>
              <div className="steps-footer">
                <ProjectAccessRole
                  allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                  currentRole={projectRole}
                >
                  <Upload
                    maxCount={1}
                    onChange={handleChange}
                    beforeUpload={() => false}
                    showUploadList={false}
                  >
                    {csvUrl ? (
                      <Row gutter={[12, 12]}>
                        <Col onClick={(e) => e?.stopPropagation()}>
                          <img src={csvLogo} alt="upload csv file" />
                        </Col>
                        <Col>
                          <Typography className="csv-file-name mb-6">
                            {csvMeta?.fileName}
                          </Typography>
                          <Button
                            className="secondary-btn border-btn"
                            size="small"
                            onClick={(e) => {
                              setDeleteCsvModel(true);
                              e?.stopPropagation();
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {csvUploadError && (
                          <p className="uploaded_csv_error">
                            The uploaded file does not match with the CSV
                            format. Please check the file and try again.
                          </p>
                        )}
                        <Button
                          type="dashed"
                          className="upload-csv"
                          icon={<UploadCsvFileIcon />}
                          loading={createAnalysisLoading || updateLoading}
                        >
                          Upload CSV File
                        </Button>
                      </>
                    )}
                  </Upload>
                </ProjectAccessRole>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        {accidentAnalysisData?.length > 0 && (
          <TableComponent
            columns={accidentAnalysisTableCol}
            data={accidentAnalysisData}
            tableClassName="mt-24 custom-table-analysis"
            loadingData={analysisLoading || updateLoading}
            scroll={{ x: true }}
          />
        )}
        {(createAnalysisLoading || updateLoading) && (
          <LoaderComponent
            setHeight="450"
            spinning={createAnalysisLoading || updateLoading}
          />
        )}
        {graphsData &&
          graphsData?.map((elem) => (
            <div className="graph-container mt-32" key={elem.type}>
              <img alt="graph" src={elem?.graphUrl} />
            </div>
          ))}

        <CommonConfirmationModal
          visible={deleteCsvModel}
          title="Delete CSV"
          content="Are you sure you want to delete the file"
          cancelText="Discard"
          okText="Delete"
          onOk={() => {
            updateAnalysis({
              variables: {
                where: {
                  type: ROAD_ANALYSIS?.ACCIDENT,
                  projectId,
                },
                data: {
                  csvKey: null,
                  csvMeta: null,
                },
              },
            });
            setDeleteCsvModel(false);
          }}
          onCancel={() => setDeleteCsvModel(false)}
        />
      </div>
    </>
  );
};

export default AccidentAnalysis;
