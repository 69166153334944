import { EllipsisOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tour,
} from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { uniqBy } from 'lodash';
import { InView } from 'react-intersection-observer';
import { Link, useHistory } from 'react-router-dom';
import ModalComponents from '../../components/CommonModel';
import { fileUpload, formValidatorRules } from '../../common/utils';
import { AddUser, Plus, ReportColumn } from '../../assets/svg';
import { LIMIT, PROJECT_ROLES, ROLES, ROUTES } from '../../common/constants';
import AddNewProject from './AddNewProject';
import CommonUploadFile from '../../components/CommonUploadFile';
import projectBackground from '../../assets/images/projectBackground.png';
import { AppContext } from '../../AppContext';
import AccessRole from '../../components/AccessRole';
import LoaderComponent from '../../components/LoaderComponent';
import TimeAgo from '../../components/TimeAgo';
import { DELETE_PROJECT, UPDATE_WORKSPACE } from './graphql/Mutations';
import {
  GET_PROJECTS,
  GET_SIGNED_URL_WORKSPACE_LOGO,
  GET_WORKSPACE_DETAIL,
} from './graphql/Queries';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import AssignMembersModal from './AssignMembersModal';
import ProjectAccessRole from '../../components/ProjectAccessRole';

const { Meta } = Card;
const { Search } = Input;
const {
  required,
  checkImageSizeAndDimensions,
  number,
  email: emailRegEx,
} = formValidatorRules;
const { Option } = Select;
const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 75,
      }}
      defaultValue="+91"
    >
      <Option value="+91">+91</Option>
    </Select>
  </Form.Item>
);
const Project = () => {
  const {
    getCurrentUser,
    getCurrentUserRole,
    dispatch,
    state: { projectRole, tourClose },
  } = useContext(AppContext);
  const { workspaceId, lastActiveOn } = getCurrentUser() || null;
  const history = useHistory();
  const [form] = Form?.useForm();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [assignMembersModel, setAssignMembersModel] = useState(false);
  const [assignMembersProjectId, setAssignMembersProjectId] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsCount, setProjectsCounts] = useState(null);
  const [visible, setVisible] = useState(false);
  const [workSpace, setWorkSpace] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [projectRefetch, setProjectRefetch] = useState(false);
  const [deleteProjectVisible, setDeleteProjectVisible] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const workspaceRole = getCurrentUserRole() || null;
  const [closeTourVisible, setCloseTourVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const ref = useRef(null);

  const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL_WORKSPACE_LOGO, {
    onError() {},
    fetchPolicy: 'no-cache',
  });

  const [getProjects] = useLazyQuery(GET_PROJECTS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setProjectsCounts(response?.getProjects?.count);
      if (projectRefetch) {
        setProjects(uniqBy(response?.getProjects?.data, 'id'));
      } else {
        setProjects(
          uniqBy([...projects, ...response?.getProjects?.data], 'id'),
        );
      }
      if (response?.getProjects?.data?.length < LIMIT) {
        setHasMore(false);
      }
      setLoading(false);
      setScrollLoading(false);
      setProjectRefetch(false);
      if (workspaceRole !== ROLES?.OWNER && !lastActiveOn && !tourClose) {
        setOpen(true);
      }
    },
    onError() {
      setLoading(false);
      setScrollLoading(false);
    },
  });

  const [workspace] = useLazyQuery(GET_WORKSPACE_DETAIL, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (!res?.workspace?.agency) {
        setWorkSpace(true);
      } else {
        setWorkSpace(false);
      }
    },
    onError() {},
  });

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted() {
      setDeleteProjectId(null);
      getProjects({
        variables: {
          where: {
            workspaceId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
            search: null,
          },
        },
      });
    },
    onError() {},
  });

  const [updateWorkSpace, { loading: workspaceLoader }] = useMutation(
    UPDATE_WORKSPACE,
    {
      onCompleted() {
        if (!lastActiveOn) {
          setOpen(true);
        }
      },
      onError() {},
    },
  );

  const changeOnAddNewProject = () => {
    setProjectRefetch(true);
    setLoading(true);
    getProjects({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          skip: 0,
          limit: LIMIT,
          search: null,
        },
      },
    });
  };

  const onFinish = async (values) => {
    if (currentFile) {
      const res = await getSignedUrl({
        variables: {
          data: {
            fileName: currentFile?.name,
            workspaceId,
          },
        },
      });

      await fileUpload(
        res?.data?.getWorkspaceLogoUploadSignedPutUrl?.signedUrl,
        currentFile,
      );

      try {
        const updateWorkSpaceData = {
          agency: values?.agencyName,
          logoKey:
            res?.data?.getWorkspaceLogoUploadSignedPutUrl?.fileName || null,
          name: values?.workspaceName,
          bio: values?.bio || null,
          contactDetails: {
            email: values?.email || null,
            addressLine2: values?.addressLine1 || null,
            addressLine1: values?.addressLine2 || null,
            fax: values?.fax || null,
            phoneNumber: values?.phoneNumber || null,
            website: values?.website || null,
          },
        };
        await updateWorkSpace({
          variables: {
            data: updateWorkSpaceData,
            where: {
              workspaceId,
            },
          },
        });
      } catch (err) {
        return err;
      }
      setWorkSpace(false);
    } else {
      const updateWorkSpaceData = {
        agency: values?.agencyName,
        logoKey: null,
        name: values?.workspaceName,
        bio: values?.bio || null,
        contactDetails: {
          email: values?.email || null,
          addressLine2: values?.addressLine1 || null,
          addressLine1: values?.addressLine2 || null,
          fax: values?.fax || null,
          phoneNumber: values?.phoneNumber || null,
          website: values?.website || null,
        },
      };
      await updateWorkSpace({
        variables: {
          data: updateWorkSpaceData,
          where: {
            workspaceId,
          },
        },
      });
      setWorkSpace(false);
    }
  };

  const steps = [
    {
      title: 'Welcome to Terramatic!!',
      description: `Let's start with a product tour to help you get a better understanding of the features.`,
      target: null,
    },
    {
      title: 'Demo Project',
      description:
        'Click here to view additional information related to this project.',
      target: () => ref?.current,
    },
    {
      target: () =>
        history?.push({
          pathname: `${ROUTES?.PROJECTS}/${projects?.[0]?.id}`,
          state: { tourStart: true },
        }),
    },
  ];

  const onSearch = async (values) => {
    const onSearchValue = values?.trim();
    setLoading(true);
    setProjectRefetch(true);
    await getProjects({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          skip: 0,
          limit: LIMIT,
          search: onSearchValue,
        },
      },
    });
  };

  const handleModel = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const handleScroll = () => {
    setScrollLoading(true);
    getProjects({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          skip: projects?.length,
          limit: LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    if (workspaceRole === ROLES?.OWNER) {
      workspace();
    }
    getProjects({
      variables: {
        where: {
          workspaceId,
        },
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
    // eslint-disable-next-line no-undef
    localStorage?.removeItem('storedTimeMinutes');
    // eslint-disable-next-line no-undef
    localStorage?.removeItem('storedTimeSeconds');
  }, []);

  const formattedCount = projectsCount?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const handleDeleteProject = () => {
    setLoading(true);
    setProjectRefetch(true);
    deleteProject({
      variables: {
        where: {
          projectId: deleteProjectId,
          workspaceId,
        },
      },
    });
    setDeleteProjectVisible(false);
  };

  const handleCloseTour = () => {
    setCloseTourVisible(true);
    setOpen(false);
    dispatch({
      type: 'SET_TOUR_CLOSE',
      data: true,
    });
  };

  const handleNextPrev = (e) => {
    if (currentStep < e) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <div>
        <div className="content-top d-flex flex-horizontal justify-between align-center">
          <div className="content-top-heading">
            Projects {formattedCount > 0 && `(${formattedCount})`}
          </div>
          <div className="content-top-input d-flex flex-horizontal align-center">
            <div className="searchBox">
              <Search
                placeholder="Search"
                allowClear
                onSearch={onSearch}
                size="small"
              />
            </div>
            {AccessRole({ allowedRoles: ROLES?.OWNER }) && (
              <Button
                className="secondary-btn ml-24"
                size="small"
                onClick={handleModel}
                icon={<Plus />}
              >
                Add New Project
              </Button>
            )}
          </div>
        </div>
        <div className="project-card-container">
          <Tour
            open={open}
            onClose={() => handleCloseTour()}
            steps={steps}
            current={currentStep}
            onChange={handleNextPrev}
          />
          {loading ? (
            <LoaderComponent setHeight="200" spinning={loading} />
          ) : (
            <Row gutter={[32, 32]}>
              {projects?.map((item, index) => (
                <Col xs={24} sm={12} lg={8} xl={6} key={item?.id}>
                  <Card
                    className="project-card"
                    ref={index === 0 ? ref : null}
                    cover={
                      <Link to={`${ROUTES?.PROJECTS}/${item?.id}`}>
                        <div className="card-cover">
                          {item?.logoUrl ? (
                            <img src={item?.logoUrl} alt="project-logo-img" />
                          ) : (
                            <img
                              src={projectBackground}
                              alt="project-logo-img"
                            />
                          )}
                        </div>
                      </Link>
                    }
                    actions={[
                      <span
                        className="card-redirect-click"
                        key="add-user"
                        onClick={(e) => {
                          if (workspaceRole === ROLES?.OWNER) {
                            setAssignMembersProjectId(item?.id);
                            setAssignMembersModel(true);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <AddUser />
                      </span>,
                      <span
                        className="card-redirect-click"
                        key="edit"
                        onClick={() => {
                          history?.push(
                            `${ROUTES?.PROJECTS}/${item?.id}${ROUTES?.CONSOLIDATED_VIEW}`,
                          );
                        }}
                      >
                        <ReportColumn />
                      </span>,
                      // eslint-disable-next-line react/jsx-key
                      <div className="dropdown" key="dropdown">
                        <Dropdown
                          overlayClassName="project-card-dropdown"
                          placement="bottom"
                          trigger={['click']}
                          menu={{
                            items: [
                              {
                                key: 1,
                                label: 'Traffic Analysis',
                                onClick: () => {
                                  history?.push(
                                    `${ROUTES?.PROJECTS}/${item?.id}${ROUTES?.TRAFFIC_ANALYSIS}`,
                                  );
                                },
                              },
                              {
                                type: 'divider',
                              },
                              {
                                key: 2,
                                label: 'Accident Analysis',
                                onClick: () => {
                                  history?.push(
                                    `${ROUTES?.PROJECTS}/${item?.id}${ROUTES?.ACCIDENT_ANALYSIS}`,
                                  );
                                },
                              },
                              {
                                type: 'divider',
                              },
                              {
                                key: 3,
                                label: 'See All Detections',
                                onClick: () => {
                                  history?.push(
                                    `${ROUTES?.PROJECTS}/${item?.id}${ROUTES?.SEE_ALL}`,
                                  );
                                },
                              },
                              {
                                type: 'divider',
                              },
                              {
                                key: 4,
                                label: 'Generate Report',
                                onClick: () => {
                                  history?.push(
                                    `${ROUTES?.PROJECTS}/${item?.id}${ROUTES?.REPORTS}`,
                                  );
                                },
                              },
                              ...(ProjectAccessRole({
                                allowedRoles: [PROJECT_ROLES?.OWNER],
                                currentRole: projectRole,
                              }) && !item?.isDemo
                                ? [
                                    { type: 'divider' },
                                    {
                                      key: 5,
                                      label: 'Delete Project',
                                      onClick: () => {
                                        setDeleteProjectId(item?.id);
                                        setDeleteProjectVisible(true);
                                      },
                                      className: 'error-color',
                                    },
                                  ]
                                : []),
                            ],
                          }}
                        >
                          <EllipsisOutlined key="ellipsis" />
                        </Dropdown>
                      </div>,
                    ]}
                    onClick={() => {
                      dispatch({
                        type: 'SET_PROJECT_ROLE',
                        data: item?.userRole,
                      });
                    }}
                  >
                    <Link to={`${ROUTES?.PROJECTS}/${item?.id}`}>
                      <Meta
                        title={item?.name}
                        description={<TimeAgo timestamp={item?.updatedAt} />}
                      />
                    </Link>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </div>
        <div>
          <ModalComponents
            centered
            maskClosable={false}
            closable={false}
            title="Workspace Details"
            className="modal-components-design modals property-modal"
            visible={workSpace}
          >
            <div className="workspace-details-form">
              <Spin spinning={false}>
                <div className="form-overflow">
                  <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                    name="workspace-details"
                    className="custom-label"
                  >
                    <Form.Item
                      name="projectLogo"
                      valuePropName="fileList"
                      getValueFromEvent={(event) => event?.fileList}
                      rules={[
                        { required, message: 'Please Upload the file' },
                        checkImageSizeAndDimensions,
                      ]}
                    >
                      <CommonUploadFile
                        title="Agency Logo"
                        previewImage={previewImage}
                        setPreviewImage={setPreviewImage}
                        currentFile={currentFile}
                        setCurrentFile={setCurrentFile}
                        deletePopConfirmDescription="Are you sure you want to delete agency logo?"
                        deletePopConfirmTitle="Delete Logo"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Agency Name"
                      name="agencyName"
                      rules={[
                        { required, message: 'Please enter agency name!' },
                      ]}
                      onChange={() => {
                        form.setFields([
                          { name: 'projectLogo', touched: true },
                        ]);
                      }}
                    >
                      <Input
                        placeholder="Enter your agency name here"
                        maxLength={50}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Workspace Name"
                      name="workspaceName"
                      rules={[
                        { required, message: 'Please enter workspace name!' },
                      ]}
                    >
                      <Input
                        placeholder="Enter your workspace name here"
                        maxLength={50}
                      />
                    </Form.Item>
                    <Form.Item label="Bio" name="bio">
                      <Input
                        placeholder="Enter your bio here"
                        maxLength={250}
                        type="text"
                      />
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[emailRegEx]}>
                      <Input
                        placeholder="Enter your email here"
                        maxLength={64}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[number]}
                    >
                      <Input
                        placeholder="Enter your phone number here"
                        maxLength={10}
                        addonBefore={prefixSelector}
                      />
                    </Form.Item>
                    <Form.Item label="Website" name="website">
                      <Input
                        placeholder="Enter your website link here"
                        maxLength={25}
                      />
                    </Form.Item>
                    <Form.Item label="Fax" name="fax" rules={[number]}>
                      <Input
                        placeholder="Enter your fax number"
                        maxLength={25}
                      />
                    </Form.Item>
                    <Form.Item label="Address Line 1" name="addressLine1">
                      <Input
                        showCount
                        placeholder="enter address here"
                        maxLength={50}
                      />
                    </Form.Item>
                    <Form.Item label="Address Line 2" name="addressLine2">
                      <Input
                        showCount
                        placeholder="enter address here"
                        maxLength={50}
                      />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Button
                          loading={workspaceLoader}
                          htmlType="submit"
                          size="large"
                          type="primary"
                          disabled={
                            !form.isFieldTouched('agencyName') ||
                            !form.isFieldTouched('workspaceName') ||
                            !!form
                              ?.getFieldsError()
                              ?.filter(({ errors }) => errors?.length)?.length
                          }
                          block
                        >
                          Save
                        </Button>
                      )}
                    </Form.Item>
                  </Form>
                </div>
              </Spin>
            </div>
          </ModalComponents>
        </div>
        {visible && (
          <AddNewProject
            visible={visible}
            setVisible={setVisible}
            onSubmit={() => {
              changeOnAddNewProject();
            }}
          />
        )}
        {deleteProjectVisible && (
          <CommonConfirmationModal
            visible={deleteProjectVisible}
            onCancel={() => {
              setDeleteProjectVisible(false);
            }}
            onOk={() => {
              handleDeleteProject();
            }}
            title="Delete Project"
            content="Are you sure you want to delete project?"
            okText="Delete"
            cancelText="Cancel"
          />
        )}
        {closeTourVisible && (
          <CommonConfirmationModal
            visible={closeTourVisible}
            onCancel={() => {
              setCloseTourVisible(false);
              setOpen(true);
            }}
            onOk={() => {
              setCloseTourVisible(false);
              setOpen(false);
            }}
            title="Demo Project Tour"
            content="Are you sure you want to close the tour for demo project?"
            okText="Close"
            cancelText="Cancel"
          />
        )}
        {assignMembersModel && (
          <AssignMembersModal
            visible={assignMembersModel}
            setVisible={setAssignMembersModel}
            projectId={assignMembersProjectId}
          />
        )}
      </div>
      {!loading && (
        <InView
          as="div"
          onChange={(inView) => (inView && hasMore ? handleScroll() : null)}
        />
      )}
      {scrollLoading && !loading ? <Spin /> : null}
    </>
  );
};

export default Project;
