import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import CommonUploadFile from '../../components/CommonUploadFile';
import { fileUpload, formValidatorRules } from '../../common/utils';
import { AppContext } from '../../AppContext';
import ChangePassword from '../../components/changePassword/ChangePassword';
import DeleteAccountModel from '../../components/deleteAccountModel/DeleteAccountModel';
import { GET_PROFILE_IMAGE_URL_LOGO } from './graphql/Queries';
import { UPDATE_PROFILE } from './graphql/Mutations';
import LoaderComponent from '../../components/LoaderComponent';

const initialValue = {
  projectLogo: '',
  projectName: '',
};
const Personal = () => {
  const {
    state: { currentUser = {} },
    dispatch,
  } = useContext(AppContext);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form?.useForm();
  const { required, checkImageSizeAndDimensions } = formValidatorRules;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const [GetProfileImageUploadSignedPutUrl] = useLazyQuery(
    GET_PROFILE_IMAGE_URL_LOGO,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      setSaveDisabled(false);
      setLoading(false);
    },
  });

  const onFinish = async ({ projectLogo, fullName }) => {
    setLoading(true);
    const payload = {
      fullName,
    };
    try {
      if (projectLogo?.[0]?.originFileObj) {
        const {
          data: {
            getProfileImageUploadSignedPutUrl: { fileName, signedUrl } = {},
          } = {},
        } = await GetProfileImageUploadSignedPutUrl({
          variables: {
            data: {
              fileName: projectLogo?.[0]?.name,
            },
          },
        });
        await fileUpload(signedUrl, projectLogo?.[0]?.originFileObj);
        payload.profileImageKey = fileName;
      } else if (previewImage === null) {
        payload.profileImageKey = null;
      }
      const {
        data: { updateUser: { data: user } = {} } = {},
      } = await updateProfile({
        variables: {
          data: payload,
        },
      });
      dispatch({ type: 'UPDATE_USER', data: user });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        projectLogo: null,
        fullName: currentUser?.fullName,
        emailID: currentUser?.email,
      });
      setPreviewImage(currentUser?.profileImageUrl);
      setLoading(false);
    }
  }, [currentUser]);

  return (
    <div className="height-calc-60">
      <div className="content-top mb-32">
        <div className="content-top-heading">My Settings</div>
      </div>
      {loading ? (
        <LoaderComponent spinning={loading} setHeight="200" />
      ) : (
        <>
          <div className="modal-components-design height-100">
            <Form
              name="personalDetails"
              initialValues={initialValue}
              className="custom-label setting height-100 d-flex justify-between flex-vertical"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              id="upload-profile-form"
            >
              <div>
                <Form.Item
                  className="project-logo"
                  name="projectLogo"
                  getValueFromEvent={(event) => event?.fileList}
                  rules={[
                    { required, message: 'Please upload the file' },
                    checkImageSizeAndDimensions,
                  ]}
                >
                  <CommonUploadFile
                    title="Profile Picture"
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    currentFile={currentFile}
                    setCurrentFile={setCurrentFile}
                    deleteStyles="popconfirm-buttons"
                    deletePopConfirmTitle="Delete Profile Picture"
                    deletePopConfirmDescription="Are you sure you want to delete profile picture?"
                    setSaveDisabled={setSaveDisabled}
                  />
                </Form.Item>
                <Row gutter={32}>
                  <Col span={12}>
                    <Form.Item
                      label="Full Name"
                      name="fullName"
                      rules={[
                        {
                          required,
                          message: 'Please enter full name here!',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter name here"
                        maxLength={50}
                        onChange={() => setSaveDisabled(true)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Email ID" name="emailID">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                {currentUser?.provider === 'GOOGLE' ? (
                  <span className="google-text">
                    Please note that you have Signed-up with "Google"
                  </span>
                ) : (
                  <>
                    <Typography className="change-pw-title">
                      Password
                    </Typography>
                    <Typography
                      className="mt-14 change-pw"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Change Password
                    </Typography>
                  </>
                )}
              </div>
              <div className="d-flex align-center justify-between">
                <Typography
                  className="delete-account-button"
                  onClick={() => setVisible(true)}
                >
                  Delete Account
                </Typography>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                  disabled={!saveDisabled}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
      <ChangePassword
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <DeleteAccountModel visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default Personal;
