import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  useLoadScript,
  GoogleMap,
  Marker,
  Polyline,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Slider,
  Space,
  Typography,
  message,
  Tooltip,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep, maxBy } from 'lodash';
import { AppContext } from '../../../AppContext';
import VideoPlayer from './VideoPlayer';
import CommonTable from '../../../components/CommonTable';
import ModalComponents from '../../../components/CommonModel';
import editMarkerIcon from '../../../assets/svg/map-pin-blue.svg';
import CommonConfirmationModal from '../../../components/CommonConfirmationModal';
import LoaderComponent from '../../../components/LoaderComponent';
import {
  CREATE_DETECTION_AI_JOB,
  CREATE_SYNC_DATA,
  UPDATE_PROJECT_VIDEO,
  UPDATE_SOURCE_SYNC_LOCATION,
  UPDATE_SOURCE_SYNC_VIDEO_TIMESTAMP,
} from '../../projects/graphql/Mutations';
import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_KML_SIGNED_URL,
  GET_PROJECT_VIDEO,
  GET_PROJECT_VIDEO_SOURCE_DATA_SIGNED_PUT_URL,
  SOURCE_SYNC_DATA_LIST,
} from '../../projects/graphql/Queries';
import {
  AI_PROCESS,
  JSON_FORMAT,
  KML_FORMAT,
  PROJECT_ROLES,
  ROUTES,
  SLIDER_MARK,
  STATUS_SYNCED,
  UPLOADED_FROM_MOBILE,
  containerStyle,
  libraries,
} from '../../../common/constants';
import { fileUpload } from '../../../common/utils';
import KmlUploader from '../../../components/uploader/KmlUploader';
import ProjectAccessRole from '../../../components/ProjectAccessRole';
import LatLongUploader from '../../../components/uploader/LatLongUploader';

const Synchronization = () => {
  const ref = useRef();
  const searchInput = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const { videoId, projectId } = useParams();
  const {
    dispatch,
    state: { projectRole },
    getCurrentUser,
  } = useContext(AppContext);
  const { workspaceFeatures } = getCurrentUser() || {};
  const continueWithAI = workspaceFeatures?.includes(AI_PROCESS);
  const fileList = [];
  const history = useHistory();
  const [mergingSlider, setMergingSlider] = useState(false);
  const [initialLatLngCoordinates, setInitialLatLngCoordinates] = useState(
    null,
  );
  const [center, setCenter] = useState();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [directions, setDirections] = useState(null);
  const [mapZoom, setMapZoom] = useState(15);
  const [mapRef, setMapRef] = useState(null);
  const [mobUploadLiveMarker, setMobUploadLiveMarker] = useState(null);
  const [mobileUploadData, setMobileUploadData] = useState(null);
  const [mobileUploadLatLngData, setMobileUploadLatLngData] = useState([]);
  const [uploadedFromMobile, setUploadedFromMobile] = useState(false);
  const [updateKmlModel, setUpdateKmlModel] = useState(false);
  const [updateKmlData, setUpdateKmlData] = useState(null);
  const [initialVideoTimeStamps, setInitialVideoTimeStamps] = useState(null);
  const [discardEditLatLng, setDiscardEditLatLng] = useState(null);
  const [kmlLatLngEdit, setKmlLatLngEdit] = useState(false);
  const [latLngDiscardModal, setLatLngDiscardModal] = useState(false);
  const [videoDiscardModal, setVideoDiscardModal] = useState(false);
  const [sliderValueAfterChange, setSliderValueAfterChange] = useState(null);
  const [videoTimeStampsEdit, setVideoTimeStampsEdit] = useState(false);
  const [endPointMarker, setEndPointLiveMarker] = useState(null);
  const [kmlStartPointMarker, setKmlStartPointMarker] = useState(null);
  const [startPointMarker, setStartPointMarker] = useState(null);
  const [seekTo, setSeekTo] = useState(null);
  const [mainSliderValue, setMainSliderValue] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const [initialTableData, setInitialTableData] = useState();
  const [editVideoData, setEditVideoData] = useState(null);
  const [videoSliderValue, setVideoSliderValue] = useState(null);
  const [editMarkerValue, setEditMarkerValue] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [openWithoutKmlModel, setOpenWithoutKmlModel] = useState(false);
  const [openLatLngErrorModel, setOpenLatLngErrorModel] = useState(false);
  const [LatLngErrorText, setLatLngErrorText] = useState(null);
  const [openAiModel, setOpenAiModel] = useState(false);
  const [withAI, setWithAI] = useState(false);
  const [latLngWeb, setLatLngWeb] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);

  const initialValue = {
    previousTimeStamps: initialVideoTimeStamps,
    updatedTimeStamps: sliderValueAfterChange,
    videoStartTimeStamps: initialVideoTimeStamps,
    kmlTime: !initialLatLngCoordinates?.kmlTimeStamp
      ? 'N/A'
      : initialLatLngCoordinates?.kmlTimeStamp,
    latitudeCoordinates: !initialLatLngCoordinates?.lat
      ? 'N/A'
      : initialLatLngCoordinates?.lat,
    longitudeCoordinates: !initialLatLngCoordinates?.lng
      ? 'N/A'
      : initialLatLngCoordinates?.lng,
    previousLatitude: initialLatLngCoordinates?.lat,
    updateLatitude: editMarkerValue?.lat,
    previousLongitude: initialLatLngCoordinates?.lng,
    updateLongitude: editMarkerValue?.lng,
  };

  const [
    sourceSyncData,
    {
      data: { sourceSyncDataList = {} } = {},
      loading: sourceSyncDataListLoading,
      refetch: refetchSourceSyncData,
    },
  ] = useLazyQuery(SOURCE_SYNC_DATA_LIST, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const array = [];
      const latLangData = [];
      const mobUploadData = [];
      const webUploadData = [];
      if (Array.isArray(res?.sourceSyncDataList?.data)) {
        res?.sourceSyncDataList?.data?.map((elem) => {
          array.push({
            lat: elem?.location?.coordinates?.[1],
            lng: elem?.location?.coordinates?.[0],
            kmlTimeStamp: elem?.kmlTimestamp,
            videoTimeStamp: elem?.videoTimestamp,
            syncDataId: elem?.id,
          });
          if (
            elem?.location?.coordinates?.[1] !== 0 &&
            elem?.location?.coordinates?.[1] !== undefined
          ) {
            if (
              uploadedFromMobile &&
              elem?.location?.coordinates?.[1] !== 0 &&
              elem?.location?.coordinates?.[1] !== undefined
            ) {
              latLangData.push({
                lat: elem?.location?.coordinates?.[1],
                lng: elem?.location?.coordinates?.[0],
              });
              mobUploadData.push({
                lat: elem?.location?.coordinates?.[1],
                lng: elem?.location?.coordinates?.[0],
                videoTimeStamp: elem?.videoTimestamp,
              });
            } else {
              webUploadData.push({
                lat: elem?.location?.coordinates?.[1],
                lng: elem?.location?.coordinates?.[0],
              });
            }
          }
          return null;
        });
      }
      setLatLngWeb(webUploadData);
      setMobileUploadData(mobUploadData);
      setMobUploadLiveMarker({
        lat: mobUploadData?.[0]?.lat,
        lng: mobUploadData?.[0]?.lng,
      });
      setMobileUploadLatLngData(latLangData);
      setKmlStartPointMarker({
        lat: Number(
          res?.sourceSyncDataList?.data?.[0]?.location?.coordinates?.[1],
        ),
        lng: Number(
          res?.sourceSyncDataList?.data?.[0]?.location?.coordinates?.[0],
        ),
      });
      setInitialTableData(array);
    },
    onError() {},
  });

  const [projectDetails] = useLazyQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setStartPointMarker({
        lat: Number(res?.getProject?.data?.startPoint?.coordinates?.[1]),
        lng: Number(res?.getProject?.data?.startPoint?.coordinates?.[0]),
      });
      setEndPointLiveMarker({
        lat: Number(res?.getProject?.data?.endPoint?.coordinates?.[1]),
        lng: Number(res?.getProject?.data?.endPoint?.coordinates?.[0]),
      });
    },
    onError() {},
  });

  const [getProjectVideo] = useLazyQuery(GET_PROJECT_VIDEO, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      dispatch({
        type: 'SET_CURRENT_VIDEO_NAME',
        data: res?.getProjectVideo?.data?.name,
      });
      if (res?.getProjectVideo?.data?.status === 'SYNCED') {
        history?.push(
          `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES.ADD_DETECTION}`,
        );
      }

      if (!res?.getProjectVideo?.data?.kmlUrl) {
        projectDetails({
          variables: {
            where: {
              projectId,
            },
          },
        });
      }
      if (res?.getProjectVideo?.data?.uploadedFrom === UPLOADED_FROM_MOBILE) {
        setUploadedFromMobile(true);
      }

      setUpdateKmlData(res?.getProjectVideo?.data);
    },
    onError() {},
  });

  const [updateProjectVideo] = useMutation(UPDATE_PROJECT_VIDEO, {
    fetchPolicy: 'network-only',
    onCompleted() {
      getProjectVideo({
        variables: {
          where: {
            videoId,
          },
        },
      });
      refetchSourceSyncData();
    },
  });
  const [updateSourceSyncLocation] = useMutation(UPDATE_SOURCE_SYNC_LOCATION, {
    onCompleted: () => {
      refetchSourceSyncData();
    },
  });

  const [updateSourceSyncVideoTimestamp] = useMutation(
    UPDATE_SOURCE_SYNC_VIDEO_TIMESTAMP,
    {
      onCompleted: () => {
        refetchSourceSyncData();
      },
    },
  );

  const [getKmlSignedPutUrl] = useLazyQuery(GET_PROJECT_KML_SIGNED_URL, {
    fetchPolicy: 'network-only',
  });
  const [getProjectVideoSourceDataSignedPutUrl] = useLazyQuery(
    GET_PROJECT_VIDEO_SOURCE_DATA_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [
    createDetectionAIJob,
    { loading: createDetectionAIJobLoading },
  ] = useMutation(CREATE_DETECTION_AI_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      history?.push(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES.ADD_DETECTION}`,
      );
    },
  });

  const [createSyncData, { loading: createSyncDataLoading }] = useMutation(
    CREATE_SYNC_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        if (withAI) {
          dispatch({
            type: 'PROCESSED_WITH_AI',
            data: true,
          });
          createDetectionAIJob({
            variables: {
              data: {
                type: 'VIDEO',
                instanceId: videoId,
              },
            },
          });
        } else {
          history?.push(
            `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES.ADD_DETECTION}`,
          );
        }
      },
    },
  );

  const handleWithAi = async () => {
    setOpenAiModel(false);
    setWithAI(true);
    await createSyncData({
      variables: {
        data: {
          videoId,
        },
      },
    });
  };

  const handleWithOutAi = async () => {
    setOpenAiModel(false);
    await createSyncData({
      variables: {
        data: {
          videoId,
        },
      },
    });
  };

  const handleOnSeek = () => {};

  const handleVideoSliderChange = (e) => {
    setSeekTo(e);
    if (e <= initialTableData[editVideoData?.index - 1]?.videoTimeStamp) {
      message.destroy();
      message.error('Time should be greater than above timestamp');
      setVideoSliderValue(
        initialTableData[editVideoData?.index - 1]?.videoTimeStamp,
      );
      return;
    }
    setVideoSliderValue(e);
  };

  const updateTimeStampValues = (sliderTime) => {
    if (editVideoData) {
      const currentTableValues = cloneDeep(initialTableData);
      const actualTime = editVideoData?.wholeData?.videoTimeStamp;
      const timeDiff = sliderTime - actualTime;
      const maxTimeObj = maxBy(currentTableValues, 'videoTimeStamp');
      const maxTime = maxTimeObj.videoTimeStamp;

      let newArr = [];

      let flag = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of currentTableValues) {
        const currentTimeStamp = item?.videoTimeStamp;
        if (currentTimeStamp === null) {
          const lastTimeOfNewArr = newArr[newArr.length - 1]?.videoTimeStamp;
          if (lastTimeOfNewArr === null || lastTimeOfNewArr >= maxTime) {
            newArr.push(item);
          } else {
            newArr.push({
              ...item,
              videoTimeStamp: lastTimeOfNewArr - timeDiff,
            });
          }
        } else if (currentTimeStamp < actualTime) {
          newArr.push(item);
        } else if (currentTimeStamp === actualTime) {
          newArr.push({ ...item, videoTimeStamp: currentTimeStamp + timeDiff });
        } else if (currentTimeStamp > actualTime) {
          if (item?.kmlTimeStamp) {
            if (!flag && currentTimeStamp + timeDiff > maxTime) {
              flag = true;
              newArr.push({ ...item, videoTimeStamp: maxTime });
            } else if (flag) {
              newArr.push({ ...item, videoTimeStamp: null });
            } else {
              newArr.push({
                ...item,
                videoTimeStamp: currentTimeStamp + timeDiff,
              });
            }
          }
        }
      }
      if (
        newArr[newArr.length - 1]?.videoTimeStamp &&
        newArr[newArr.length - 1]?.videoTimeStamp < maxTime
      ) {
        newArr.push({
          kmlTimeStamp: null,
          videoTimeStamp: maxTime,
          lat: null,
          lng: null,
        });
      }

      const newArray = [];
      newArr.map((elem) =>
        newArray.push({
          setVideoDuration,
          videoTimestamp: elem?.videoTimeStamp,
          location: {
            lng: elem?.lng,
            lat: elem?.lat,
          },
          kmlTimestamp: elem?.kmlTimeStamp,
        }),
      );
      updateSourceSyncVideoTimestamp({
        variables: {
          where: { videoId },
          data: newArray,
        },
      });
      flag = false;
      newArr = [];
      setEditVideoData(null);
      setVideoSliderValue(null);
    }
  };

  const handleOnEditVideoTimeStampsOnSave = () => {
    updateTimeStampValues(sliderValueAfterChange);
    setVideoTimeStampsEdit(false);
    setOpenModel(false);
  };
  const handleOnAfterVideoSliderChange = (e) => {
    setVideoTimeStampsEdit(true);
    setSliderValueAfterChange(e);
  };

  const handleOnProgress = (value) => {
    const playedSeconds = Math.round(value?.playedSeconds);
    setMainSliderValue(playedSeconds);
    const tableData = initialTableData;

    const latlng = tableData.find((e) => e?.videoTimeStamp === playedSeconds);
    const mobLatLng = mobileUploadData.find(
      (e) => e?.videoTimeStamp === playedSeconds,
    );
    if (latlng?.lat && latlng?.lng && updateKmlData?.kmlUrl) {
      setKmlStartPointMarker({
        lat: Number(latlng?.lat),
        lng: Number(latlng?.lng),
      });
    }
    if (latlng?.lat && latlng?.lng && updateKmlData?.sourceDataUrl) {
      setKmlStartPointMarker({
        lat: Number(latlng?.lat),
        lng: Number(latlng?.lng),
      });
    }
    if (mobLatLng?.lat) {
      setMobUploadLiveMarker({
        lat: Number(mobLatLng?.lat),
        lng: Number(mobLatLng?.lng),
      });
    }
  };

  const editTime = (e) => {
    // eslint-disable-next-line no-undef
    document
      ?.querySelector('#wrapper-to-scroll')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
    setKmlStartPointMarker({
      lat: Number(e?.wholeData?.lat),
      lng: Number(e?.wholeData?.lng),
    });
    setEditMarkerValue(null);
    setInitialLatLngCoordinates(e?.wholeData);
    setEditVideoData(e);
    setInitialVideoTimeStamps(e?.wholeData?.videoTimeStamp);
    setVideoSliderValue(e?.wholeData?.videoTimeStamp);
  };

  const editLatLng = (e) => {
    // eslint-disable-next-line no-undef
    document
      ?.querySelector('#wrapper-to-scroll')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
    setSeekTo(e?.videoTimeStamp);
    if (e?.lat) {
      setInitialLatLngCoordinates(e);
      setEditMarkerValue(e);
      setDiscardEditLatLng(e);
      setInitialVideoTimeStamps(e?.videoTimeStamp);
    } else if (initialTableData[0]?.lat && initialTableData[0]?.lng) {
      setInitialVideoTimeStamps(e?.videoTimeStamp);
      setEditMarkerValue({
        ...e,
        lat: initialTableData[0]?.lat,
        lng: initialTableData[0]?.lng,
      });
    } else {
      setInitialVideoTimeStamps(e?.videoTimeStamp);
      setEditMarkerValue({
        ...e,
        lat: startPointMarker?.lat,
        lng: startPointMarker?.lng,
      });
      if (!startPointMarker?.lat && !startPointMarker?.lng) {
        setEditMarkerValue({
          ...e,
          ...center,
        });
      }
    }
  };

  const editMarker = (e) => {
    setKmlLatLngEdit(true);
    const currentEditData = editMarkerValue;
    const initialData = cloneDeep(initialTableData);
    const ind = initialData.findIndex(
      (val) =>
        val?.videoTimeStamp === currentEditData?.videoTimeStamp &&
        val?.kmlTimeStamp === currentEditData?.kmlTimeStamp,
    );

    if (ind >= 0) {
      initialData[ind].lat = e?.latLng.lat();
      initialData[ind].lng = e?.latLng.lng();
    }

    setEditMarkerValue({
      ...editMarkerValue,
      lat: e?.latLng.lat(),
      lng: e?.latLng.lng(),
    });
  };

  const handleLatLngOnKmlEdit = () => {
    updateSourceSyncLocation({
      variables: {
        where: {
          sourceSyncDataId: editMarkerValue?.syncDataId,
        },
        data: {
          location: {
            lat: editMarkerValue?.lat,
            lng: editMarkerValue?.lng,
          },
        },
      },
    });
    setEditMarkerValue(null);
    setKmlLatLngEdit(false);
    setOpenModel(false);
    setOpenWithoutKmlModel(false);
  };

  const handleVideoEditDiscard = () => {
    setVideoSliderValue(initialTableData[editVideoData?.index]?.videoTimeStamp);
    setVideoTimeStampsEdit(false);
    setOpenModel(false);
  };

  const handleKmlLatLngEditDiscard = () => {
    setEditMarkerValue(discardEditLatLng);
    setKmlLatLngEdit(false);
    setOpenModel(false);
  };

  const handleSliderChange = (val) => {
    setMainSliderValue(val);
    setSeekTo(val);
  };

  const onFinish = () => {
    if (videoTimeStampsEdit) {
      handleOnEditVideoTimeStampsOnSave();
    } else if (kmlLatLngEdit) {
      handleLatLngOnKmlEdit();
    }
  };

  const onHandleEntireVideoAsSynced = async () => {
    try {
      if (
        !initialTableData?.[0]?.lat &&
        !initialTableData?.[initialTableData.length - 1]?.lng
      ) {
        setOpenLatLngErrorModel(true);
        setLatLngErrorText(
          'Please add lat long information for the first & last synced point',
        );
      } else if (!initialTableData?.[initialTableData.length - 1]?.lng) {
        setOpenLatLngErrorModel(true);
        setLatLngErrorText(
          'Please add lat long information for the last synced point',
        );
      } else if (!initialTableData?.[0]?.lat) {
        setOpenLatLngErrorModel(true);
        setLatLngErrorText(
          'Please add lat long information for the first synced point',
        );
      } else {
        setOpenAiModel(true);
        setMergingSlider(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error', error);
    }
  };
  const handleChange = async (value, format) => {
    const extension = value?.fileList?.[0]?.type?.substring(
      value?.fileList?.[0]?.type?.lastIndexOf('.') + 1,
    );
    const payload = {
      name: updateKmlData?.name,
      videoMeta: {
        fileName: updateKmlData?.videoMeta?.fileName,
        size: updateKmlData?.videoMeta?.size,
        duration: updateKmlData?.videoMeta?.duration,
        contentType: updateKmlData?.videoMeta?.contentType || undefined,
        extension: updateKmlData?.videoMeta?.extension || undefined,
      },
      uid: updateKmlData?.data?.uid,
      startingChainage: {
        kilometer: +updateKmlData?.startingChainage?.kilometer,
        meter: +updateKmlData?.startingChainage?.meter,
      },
      endingChainage: updateKmlData?.endingChainage
        ? {
            kilometer: +updateKmlData?.endingChainage?.kilometer,
            meter: +updateKmlData?.endingChainage?.meter,
          }
        : null,
    };
    const promises = [];
    if (value?.fileList?.[0]?.originFileObj && format?.format === KML_FORMAT) {
      payload.kmlMeta = {
        fileName: value?.fileList?.[0]?.name,
        size: value?.fileList?.[0]?.size,
        contentType: value?.fileList?.[0]?.type,
        extension,
      };
      payload.kmlKey = '';
      const kmlFile = value?.fileList?.[0]?.originFileObj;
      const { data, error } = await getKmlSignedPutUrl({
        variables: {
          data: {
            fileName: kmlFile?.name,
            uid: updateKmlData?.uid,
          },
        },
      });
      if (!error && data) {
        payload.kmlKey = data?.getProjectKmlSignedPutUrl?.fileName;
        promises.push(
          fileUpload(data?.getProjectKmlSignedPutUrl?.signedUrl, kmlFile),
        );
      }
    }
    if (value?.fileList?.[0]?.originFileObj && format?.format === JSON_FORMAT) {
      payload.sourceDataKey = null;
      payload.sourceDataMeta = {
        fileName: value?.fileList?.[0]?.name,
        size: value?.fileList?.[0]?.size,
        contentType: value?.fileList?.[0]?.type,
        extension,
      };
      const latLong = value?.fileList?.[0]?.originFileObj;
      const { data, error } = await getProjectVideoSourceDataSignedPutUrl({
        variables: {
          data: {
            fileName: latLong?.name,
            uid: updateKmlData?.uid,
          },
        },
      });
      if (!error && data) {
        payload.sourceDataKey = data?.getProjectKmlSignedPutUrl?.fileName;
        promises.push(
          fileUpload(
            data?.getProjectVideoSourceDataSignedPutUrl?.signedUrl,
            latLong,
          ),
        );
      }
    }
    if (promises.length) await Promise.all(promises);
    setStartPointMarker(null);
    setEndPointLiveMarker(null);
    await updateProjectVideo({
      variables: {
        data: payload,
        where: {
          videoId: updateKmlData?.id,
        },
      },
    });
  };

  const handleSearch = async (selectedKeys, confirm) => {
    confirm();

    if (selectedKeys.length > 0) {
      await refetchSourceSyncData({
        filter: {
          search: selectedKeys || null,
        },
      });
    }
    return null;
  };

  const handleReset = async (clearFilters, confirm) => {
    clearFilters();
    confirm();
    refetchSourceSyncData({
      filter: {
        search: '',
      },
    });
  };

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        className="video-name-search"
        onKeyDown={(e) => e?.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder="Search timestamp"
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e?.target?.value)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            className="secondary-btn border-btn"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined />,
  });

  const centerMapFunc = async () => {
    // eslint-disable-next-line no-undef
    const userLocation = navigator?.geolocation;
    if (userLocation) {
      userLocation?.getCurrentPosition((data) => {
        setCurrentPosition({
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        });
        return {
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        };
      });
    }
    return center;
  };

  useEffect(() => {
    setCenter(currentPosition);
  }, [currentPosition]);

  const initialTableCol = [
    {
      title: '#',
      dataIndex: '#',
      render: (_, _1, index) => index + 1,
    },
    {
      title: updateKmlData?.sourceDataUrl
        ? 'Lat-Long TimeStamp'
        : 'KML TimeStamp',
      dataIndex: 'kmlTimeStamp',
      render: (val) => val || '-',
    },
    {
      title: 'Video TimeStamp',
      dataIndex: 'videoTimeStamp',
      ...getColumnSearchProps('videoTimestamp'),
    },
    {
      title: 'Latitude',
      dataIndex: 'lat',
      render: (val) => val || '-',
    },
    {
      title: 'Longitude',
      dataIndex: 'lng',
      render: (val) => val || '-',
    },
    (projectRole === PROJECT_ROLES?.OWNER ||
      projectRole === PROJECT_ROLES?.EDITOR) &&
      updateKmlData?.status !== STATUS_SYNCED && {
        title: 'Manage',
        dataIndex: 'manage',
        render: (val, wholeData, index) => (
          <Dropdown
            menu={{
              items: [
                updateKmlData?.kmlUrl && {
                  key: 'editTimeStamp',
                  label: (
                    <Typography
                      onClick={() => editTime({ wholeData, index, val })}
                    >
                      Edit TimeStamp
                    </Typography>
                  ),
                },
                {
                  key: 'editLatLong',
                  label: (
                    <Typography onClick={() => editLatLng(wholeData, index)}>
                      Edit Lat,Long
                    </Typography>
                  ),
                },
              ],
            }}
            placement="bottomRight"
            trigger={['click']}
            disabled={
              videoTimeStampsEdit ||
              kmlLatLngEdit ||
              updateKmlData?.status === STATUS_SYNCED
            }
          >
            <Typography className="edit-dropdown">Edit</Typography>
          </Dropdown>
        ),
      },
  ].filter(Boolean);

  useEffect(() => {
    getProjectVideo({
      variables: {
        where: {
          videoId,
        },
      },
    });
    sourceSyncData({
      variables: {
        where: {
          videoId,
        },
      },
    });
  }, [uploadedFromMobile]);

  useEffect(() => {
    if (isLoaded && startPointMarker && endPointMarker) {
      const directionsOptions = {
        origin: startPointMarker,
        destination: endPointMarker,
        travelMode: 'DRIVING',
      };
      // eslint-disable-next-line no-undef
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(directionsOptions, (result, status) => {
        // eslint-disable-next-line no-undef
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      });
    }
  }, [isLoaded, startPointMarker, endPointMarker]);

  useEffect(() => {
    if (isLoaded) {
      centerMapFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleMouseMove = (e) => {
    const latLng = {
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    };

    setInfoWindowPosition(latLng);
  };
  if (loadError) return 'Error Loading Maps..!';

  return (
    <>
      {!isLoaded ? (
        <LoaderComponent spinning={!isLoaded} setHeight="200" />
      ) : (
        <div className="sync-container" id="sync-container1">
          <ProjectAccessRole
            allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
            currentRole={projectRole}
          >
            {updateKmlData?.status !== STATUS_SYNCED && (
              <>
                <div className="d-flex flex-horizontal-reverse mt-12">
                  <Button
                    className="secondary-btn"
                    size="small"
                    onClick={onHandleEntireVideoAsSynced}
                    loading={
                      createSyncDataLoading || createDetectionAIJobLoading
                    }
                    disabled={updateKmlData?.status === STATUS_SYNCED}
                  >
                    Mark Entire Video as Synced
                  </Button>
                </div>
                <div className="d-flex upload-kml">
                  <div
                    className={
                      videoTimeStampsEdit
                        ? 'upload-kml-div'
                        : 'upload-kml-div save-details'
                    }
                  >
                    <Typography
                      className="save pointer"
                      onClick={() => {
                        setOpenModel(true);
                      }}
                    >
                      Save
                    </Typography>
                    <CommonConfirmationModal
                      visible={videoDiscardModal}
                      onCancel={() => {
                        handleVideoEditDiscard();
                        setVideoDiscardModal(false);
                      }}
                      onOk={() => {
                        setVideoDiscardModal(false);
                      }}
                      title="Unsaved Changes"
                      content="If you go back, you will lose any changes that you have made."
                      cancelText="Discard"
                      okText="Keep editing"
                    />
                    <Typography
                      className="discard mr-32 pointer"
                      onClick={() => setVideoDiscardModal(true)}
                    >
                      Discard
                    </Typography>
                  </div>
                  <div className="upload-kml-div">
                    {!kmlLatLngEdit ? (
                      <>
                        {!updateKmlData?.sourceDataUrl ? (
                          <KmlUploader
                            uploadKml
                            mergingSlider={mergingSlider}
                            fileList={fileList}
                            onChange={handleChange}
                            updateKmlModel={updateKmlModel}
                            id="updateKmlFileInSynchronization"
                            setUpdateKmlModel={setUpdateKmlModel}
                          />
                        ) : (
                          <LatLongUploader
                            uploadKml
                            updateKmlModel={updateKmlModel}
                            setUpdateKmlModel={setUpdateKmlModel}
                            onChange={handleChange}
                            fileList={fileList}
                            mergingSlider={mergingSlider}
                            id="updateKmlFileInSynchronization"
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex">
                        <CommonConfirmationModal
                          visible={latLngDiscardModal}
                          onCancel={() => {
                            handleKmlLatLngEditDiscard();
                            setLatLngDiscardModal(false);
                          }}
                          onOk={() => {
                            setLatLngDiscardModal(false);
                          }}
                          title="Unsaved Changes"
                          content="If you go back, you will lose any changes that you have made."
                          cancelText="Discard"
                          okText="Keep editing"
                        />
                        <Typography
                          className="discard mr-32 pointer"
                          onClick={() => setLatLngDiscardModal(true)}
                        >
                          Discard
                        </Typography>
                        <Typography
                          className="save pointer"
                          onClick={() => {
                            if (!initialTableData?.[0]?.kmlTimeStamp) {
                              setOpenWithoutKmlModel(true);
                            } else {
                              setOpenModel(true);
                            }
                          }}
                        >
                          Save
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </ProjectAccessRole>
          <div className="d-flex main-wrapper">
            <div className="width-percent-50 video-wrapper text-center">
              <div>
                <div className="fill-width d-flex justify-center flex-vertical">
                  <VideoPlayer
                    url={updateKmlData?.videoUrl}
                    handleOnSeek={handleOnSeek}
                    handleOnProgress={handleOnProgress}
                    timeToStart={seekTo}
                    setVideoDuration={setVideoDuration}
                    customSlider={(editVideoData || editMarkerValue) ?? false}
                    videoControls={editMarkerValue}
                    videoSliderValue={videoSliderValue}
                    handleVideoSliderChange={handleVideoSliderChange}
                    handleOnAfterVideoSliderChange={
                      handleOnAfterVideoSliderChange
                    }
                    config={{
                      file: { attributes: { crossOrigin: 'anonymous' } },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="width-percent-50 map-wrapper text-center">
              <div>
                <div
                  ref={ref}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  className="fill-width d-flex justify-center flex-vertical map-container"
                >
                  {!uploadedFromMobile ? (
                    <GoogleMap
                      center={
                        latLngWeb?.[0] || startPointMarker?.lat !== 0 || center
                      }
                      mapContainerStyle={containerStyle}
                      zoom={mapZoom}
                      onLoad={(mapRefCopy) => {
                        setMapRef(mapRefCopy);
                      }}
                      onZoomChanged={() => {
                        if (mapRef) {
                          setMapZoom(mapRef?.getZoom());
                        }
                      }}
                    >
                      {currentPosition && <Marker position={currentPosition} />}
                      {latLngWeb?.length > 0 && !startPointMarker ? (
                        <>
                          <Polyline
                            path={latLngWeb}
                            options={{
                              strokeColor: 'blue',
                              strokeOpacity: 1.0,
                              strokeWeight: 4,
                            }}
                            onClick={(e) => handleMouseMove(e)}
                          />
                          {editMarkerValue ? (
                            <Marker
                              position={editMarkerValue}
                              draggable
                              icon={{
                                url: editMarkerIcon,
                              }}
                              onDragEnd={editMarker}
                            />
                          ) : (
                            <Marker position={kmlStartPointMarker} />
                          )}
                        </>
                      ) : (
                        <>
                          {directions && latLngWeb?.length >= 2 ? (
                            <>
                              <Polyline
                                path={latLngWeb}
                                options={{
                                  strokeColor: 'blue',
                                  strokeOpacity: 1.0,
                                  strokeWeight: 4,
                                }}
                                onClick={(e) => handleMouseMove(e)}
                              />
                              {editMarkerValue ? (
                                <Marker
                                  position={editMarkerValue}
                                  draggable
                                  icon={{
                                    url: editMarkerIcon,
                                  }}
                                  onDragEnd={editMarker}
                                />
                              ) : (
                                <Marker position={kmlStartPointMarker} />
                              )}
                            </>
                          ) : (
                            <DirectionsRenderer
                              directions={directions}
                              styles={{ zIndex: 1 }}
                              options={{ suppressMarkers: true }}
                            />
                          )}
                          {editMarkerValue && (
                            <Marker
                              position={editMarkerValue}
                              draggable
                              icon={{
                                url: editMarkerIcon,
                              }}
                              onDragEnd={editMarker}
                            />
                          )}
                        </>
                      )}
                      {infoWindowPosition && (
                        <div className="info-model">
                          <InfoWindow
                            position={infoWindowPosition}
                            onCloseClick={() => setInfoWindowPosition(null)}
                          >
                            <div>
                              Latitude: {infoWindowPosition?.lat?.toFixed(6)}
                              <br />
                              Longitude: {infoWindowPosition?.lng?.toFixed(6)}
                            </div>
                          </InfoWindow>
                        </div>
                      )}
                    </GoogleMap>
                  ) : (
                    <GoogleMap
                      center={mobileUploadLatLngData?.[0]}
                      mapContainerStyle={containerStyle}
                      zoom={mapZoom}
                      onLoad={(mapRefCopy) => {
                        setMapRef(mapRefCopy);
                      }}
                      onZoomChanged={() => {
                        if (mapRef) {
                          setMapZoom(mapRef?.getZoom());
                        }
                      }}
                    >
                      <>
                        <Polyline
                          path={mobileUploadLatLngData}
                          options={{
                            strokeColor: 'blue',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          }}
                        />
                        <Marker position={mobUploadLiveMarker} />
                      </>
                      {editMarkerValue && (
                        <Marker
                          position={editMarkerValue}
                          draggable
                          icon={{
                            url: editMarkerIcon,
                          }}
                          onDragEnd={editMarker}
                        />
                      )}
                      {infoWindowPosition && (
                        <div className="info-model">
                          <InfoWindow
                            position={infoWindowPosition}
                            onCloseClick={() => setInfoWindowPosition(null)}
                          >
                            <div>
                              Latitude: {infoWindowPosition?.lat?.toFixed(6)}
                              <br />
                              Longitude: {infoWindowPosition?.lng?.toFixed(6)}
                            </div>
                          </InfoWindow>
                        </div>
                      )}
                    </GoogleMap>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none">
            <Slider
              step={SLIDER_MARK}
              defaultValue={0}
              value={mainSliderValue}
              onChange={handleSliderChange}
              max={videoDuration}
            />
          </div>
          <div className="sync-table-wrapper">
            {initialTableData && initialTableData?.length > 0 && (
              <>
                <Typography className="mb-12">
                  Synced Points ({sourceSyncDataList?.count})
                </Typography>
                <CommonTable
                  columns={initialTableCol}
                  data={initialTableData || null}
                  editTime={editTime}
                  editLatLng={editLatLng}
                  tableClassName="custom-table"
                  loadingData={sourceSyncDataListLoading}
                  rowKey={(record) => record?.syncDataId}
                />
              </>
            )}
          </div>
          <ModalComponents
            centered
            title="Update Points"
            visible={openModel}
            className="modal-components-design"
            maskClosable={false}
            onCancel={() => {
              if (videoTimeStampsEdit) {
                handleVideoEditDiscard();
              } else if (kmlLatLngEdit) {
                handleKmlLatLngEditDiscard();
              }
            }}
          >
            <Typography>
              {videoTimeStampsEdit
                ? 'The consecutive timestamps will be affected by changing this point. Are you sure?'
                : 'Are you sure you want to update the latitude and longitude?'}
            </Typography>
            <Form
              layout="vertical"
              className="custom-label"
              onFinish={onFinish}
              initialValues={initialValue}
            >
              {videoTimeStampsEdit ? (
                <Row gutter={[32, 32]}>
                  <Col xl={12}>
                    <Form.Item
                      label="Previous Timestamp (Seconds)"
                      className="custom-label"
                      name="previousTimeStamps"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xl={12}>
                    <Form.Item
                      label="Updated Timestamp (Seconds)"
                      className="custom-label"
                      name="updatedTimeStamps"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Form.Item
                  label="Timestamp (Seconds)"
                  className="custom-label"
                  name="videoStartTimeStamps"
                >
                  <Input disabled />
                </Form.Item>
              )}
              <Form.Item
                label="KML Time"
                className="custom-label"
                name="kmlTime"
              >
                <Input disabled />
              </Form.Item>

              {!editMarkerValue?.lat ? (
                <Row gutter={[32, 32]}>
                  <Col xl={12}>
                    <Form.Item
                      label="Latitude Coordinates"
                      className="custom-label"
                      name="latitudeCoordinates"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xl={12}>
                    <Form.Item
                      label="Longitude Coordinates"
                      className="custom-label"
                      name="longitudeCoordinates"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row gutter={[32, 32]}>
                    <Col xl={12}>
                      <Form.Item
                        label="Previous Latitude Coordinates"
                        className="custom-label"
                        name="previousLatitude"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        label="Updated Latitude Coordinates"
                        className="custom-label"
                        name="updateLatitude"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <Col xl={12}>
                      <Form.Item
                        label="Previous Longitude Coordinates"
                        className="custom-label"
                        name="previousLongitude"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        label="Updated Longitude Coordinates"
                        className="custom-label"
                        name="updateLongitude"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Form.Item shouldUpdate>
                {() => (
                  <Button htmlType="submit" size="large" type="primary" block>
                    Update
                  </Button>
                )}
              </Form.Item>
            </Form>
          </ModalComponents>
          <ModalComponents
            centered
            title="Update Points"
            className="modal-components-design"
            maskClosable={false}
            visible={openWithoutKmlModel}
          >
            <Typography>
              Are you sure you want to update the latitude and longitude?
            </Typography>
            <Form
              layout="vertical"
              className="custom-label"
              onFinish={onFinish}
              initialValues={initialValue}
            >
              <Form.Item
                label="Timestamp (Seconds)"
                className="custom-label"
                name="videoStartTimeStamps"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="KML Time"
                className="custom-label"
                name="kmlTime"
              >
                <Input />
              </Form.Item>
              <Row gutter={[32, 32]}>
                <Col sm={12} lg={12} xl={12}>
                  <Form.Item
                    label="Latitude Coordinates"
                    className="custom-label"
                    name="updateLatitude"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={12} xl={12}>
                  <Form.Item
                    label="Longitude Coordinates"
                    className="custom-label"
                    name="updateLongitude"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item shouldUpdate>
                {() => (
                  <Button htmlType="submit" size="large" type="primary" block>
                    Update
                  </Button>
                )}
              </Form.Item>
            </Form>
          </ModalComponents>
          <CommonConfirmationModal
            visible={openLatLngErrorModel}
            title="Missing Lat, Long Information"
            okText="Okay"
            onCancel={() => {
              setOpenLatLngErrorModel(false);
            }}
            onOk={() => {
              setOpenLatLngErrorModel(false);
            }}
            content={LatLngErrorText}
            cancelButtonProps={{
              className: 'd-none',
            }}
          />
          <CommonConfirmationModal
            visible={openAiModel}
            title="Video Processing"
            okText="With AI"
            cancelText="Without AI"
            content="How would you like to proceed?"
            footer={[
              <Button
                className="secondary-btn border-btn mr-8"
                key="without AI"
                onClick={handleWithOutAi}
              >
                Without AI
              </Button>,
              <>
                {continueWithAI ? (
                  <Button
                    key="With AI"
                    className="secondary-btn"
                    type="primary"
                    disabled={!continueWithAI}
                    onClick={handleWithAi}
                  >
                    With AI
                  </Button>
                ) : (
                  <Tooltip key="Okay" title="Coming Soon">
                    <Button
                      key="With AI"
                      className="secondary-btn"
                      type="primary"
                      disabled={!continueWithAI}
                      onClick={handleWithAi}
                    >
                      With AI
                    </Button>
                  </Tooltip>
                )}
              </>,
            ]}
          />
        </div>
      )}
    </>
  );
};

export default Synchronization;
