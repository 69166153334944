import {
  Col,
  Form,
  Input,
  Row,
  Modal,
  Progress,
  Button,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { debounce, isFunction } from 'lodash';
import VideoUploader from '../uploader/VideoUploader';
import KmlUploader from '../uploader/KmlUploader';
import { AppContext } from '../../AppContext';
import {
  fileUpload,
  formValidatorRules,
  getId,
  getVideoDuration,
} from '../../common/utils';
import CustomInput from '../customInput.js/CustomInput';
import CommonConfirmationModal from '../CommonConfirmationModal';
import LoaderComponent from '../LoaderComponent';
import {
  CHECK_VIDEO_NAME,
  GET_PROJECT_KML_SIGNED_URL,
  GET_PROJECT_VIDEO,
  GET_PROJECT_VIDEO_SIGNED_URL,
  GET_PROJECT_VIDEO_SOURCE_DATA_SIGNED_PUT_URL,
} from '../../modules/projects/graphql/Queries';
import {
  CREATE_PROJECT_VIDEO,
  UPDATE_PROJECT_VIDEO,
} from '../../modules/projects/graphql/Mutations';
import {
  ATTACH_KML_FILE,
  ATTACH_LATITUDE_LONGITUDE_FILE,
} from '../../common/constants';
import { InfoEmpty } from '../../assets/svg';
import LatLongUploader from '../uploader/LatLongUploader';

const initialValue = {
  videoName: '',
  startingChainage: { km: '', m: '' },
  endingChainage: { km: '', m: '' },
  uploadVideo: [],
  uploadKml: [],
  latLangFile: [],
};
const { required } = formValidatorRules;

const UploadVideoModel = ({
  addVideoModel,
  setAddVideoModel,
  refetch,
  isEdit,
  setIsEdit,
  editVideoId,
  status,
  setSyncedStatus,
  setGetProjectVideos,
  handleRefetch,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { chainageCustomInputValidation } = formValidatorRules;
  const [fileSelected, setFileSelected] = useState('attach_kml_file');
  const { projectId } = useParams();
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editData, setEditData] = useState(false);
  const [radioButtonKml, setRadioButtonKml] = useState(false);
  const [radioButtonLatLangFile, setRadioButtonLatLangFile] = useState(false);
  const [progress, setProgress] = useState({
    video: 0,
    kml: 0,
  });
  const [visible, setVisible] = useState(false);

  const [getProjectVideo] = useLazyQuery(GET_PROJECT_VIDEO, {
    fetchPolicy: 'no-cache',
    onCompleted(record) {
      setEditData(record?.getProjectVideo?.data);
      setEditLoading(false);
    },
  });

  const [getVideoSignedPutUrl] = useLazyQuery(GET_PROJECT_VIDEO_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getKmlSignedPutUrl] = useLazyQuery(GET_PROJECT_KML_SIGNED_URL, {
    fetchPolicy: 'network-only',
  });

  const [getProjectVideoSourceDataSignedPutUrl] = useLazyQuery(
    GET_PROJECT_VIDEO_SOURCE_DATA_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [createUpdateProjectVideo] = useMutation(
    editData ? UPDATE_PROJECT_VIDEO : CREATE_PROJECT_VIDEO,
    {
      onCompleted() {
        setSyncedStatus(false);
        setEditData(false);
        setIsEdit(false);
      },
    },
  );

  const [checkVideoName] = useLazyQuery(CHECK_VIDEO_NAME, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      form.setFields([
        {
          name: 'videoName',
          errors: [error?.message],
        },
      ]);
    },
  });

  const handleVideoProgress = (value) => {
    setProgress((prev) => ({ ...prev, video: value }));
  };

  const handleKmlProgress = (value) =>
    setProgress((prev) => ({ ...prev, kml: value }));

  const onFinish = async ({
    uploadVideo,
    uploadKml,
    videoName,
    startingChainage,
    endingChainage,
    direction,
    latLangFile,
  }) => {
    setLoading(true);
    let duration = 0;
    if (uploadVideo?.[0]?.duration) duration = uploadVideo?.[0]?.duration;
    if (uploadVideo?.[0]?.originFileObj) {
      duration = await getVideoDuration(uploadVideo?.[0]?.originFileObj);
    }
    const uid = editData?.uid ?? getId();
    const videoMeta = uploadVideo?.[0]?.type?.split('/')[1];
    const kmlMeta = uploadKml?.[0]?.type?.substring(
      uploadKml?.[0]?.type?.lastIndexOf('.') + 1,
    );
    const sourceMeta = latLangFile?.[0]?.type?.substring(
      latLangFile?.[0]?.type?.lastIndexOf('.') + 1,
    );
    try {
      const payload = {
        ...(!editData && {
          projectId,
          workspaceId: currentUser?.workspaceId,
        }),
        name: videoName,
        direction,
        videoMeta: {
          fileName: uploadVideo?.[0]?.name,
          size: uploadVideo?.[0]?.size,
          duration,
          contentType: videoMeta,
          extension: videoMeta,
        },
        kmlMeta: {
          fileName: uploadKml?.[0]?.name,
          size: uploadKml?.[0]?.size,
          contentType: kmlMeta,
          extension: kmlMeta,
        },
        videoKey: uploadVideo?.length ? undefined : '',
        kmlKey: uploadKml?.length ? undefined : '',
        uid,
        startingChainage: {
          kilometer: +startingChainage?.km,
          meter: +startingChainage?.m,
        },
        endingChainage:
          endingChainage?.km || endingChainage?.m
            ? {
                kilometer: endingChainage?.km ? +endingChainage?.km : 0,
                meter: +endingChainage?.m ? +endingChainage?.m : 0,
              }
            : null,
        sourceDataKey: latLangFile?.length ? undefined : null,
        sourceDataMeta: {
          fileName: latLangFile?.[0]?.name,
          size: latLangFile?.[0]?.size,
          contentType: sourceMeta,
          extension: sourceMeta,
        },
      };
      const promises = [];
      if (uploadVideo?.length && uploadVideo?.[0]?.originFileObj) {
        const videoFile = uploadVideo?.[0]?.originFileObj;
        const { data, error } = await getVideoSignedPutUrl({
          variables: {
            data: {
              fileName: videoFile?.name,
              uid,
            },
          },
        });
        if (!error && data) {
          payload.videoKey = data?.getProjectVideoSignedPutUrl?.fileName;
          promises?.push(
            fileUpload(
              data?.getProjectVideoSignedPutUrl?.signedUrl,
              videoFile,
              handleVideoProgress,
            ),
          );
        }
      }
      if (uploadKml?.length && uploadKml?.[0]?.originFileObj) {
        const kmlFile = uploadKml?.[0]?.originFileObj;
        const { data, error } = await getKmlSignedPutUrl({
          variables: {
            data: {
              fileName: kmlFile?.name,
              uid,
            },
          },
        });

        if (!error && data) {
          payload.kmlKey = data?.getProjectKmlSignedPutUrl?.fileName;
          promises?.push(
            fileUpload(
              data?.getProjectKmlSignedPutUrl?.signedUrl,
              kmlFile,
              handleKmlProgress,
            ),
          );
        }
      }
      if (latLangFile?.length && latLangFile?.[0]?.originFileObj) {
        const latLang = latLangFile?.[0]?.originFileObj;
        const { data, error } = await getProjectVideoSourceDataSignedPutUrl({
          variables: {
            data: {
              fileName: latLang?.name,
              uid,
            },
          },
        });
        if (!error && data) {
          payload.sourceDataKey =
            data?.getProjectVideoSourceDataSignedPutUrl?.fileName;
          promises?.push(
            fileUpload(
              data?.getProjectVideoSourceDataSignedPutUrl?.signedUrl,
              latLang,
              handleKmlProgress,
              data?.getProjectVideoSourceDataSignedPutUrl?.fileName,
            ),
          );
        }
      }
      if (promises?.length) await Promise?.all(promises);
      await createUpdateProjectVideo({
        variables: {
          data: payload,
          ...(editData && {
            where: {
              videoId: editData?.id,
            },
          }),
        },
      });
      setAddVideoModel(false);
      setRadioButtonLatLangFile(false);
      setRadioButtonKml(false);
    } catch (error) {
      //
    } finally {
      if (isFunction(refetch)) {
        setGetProjectVideos([]);
        handleRefetch(projectId);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit === true) {
      setEditLoading(true);
      getProjectVideo({
        variables: {
          where: {
            videoId: editVideoId,
          },
        },
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (editData) {
      if (editData?.sourceDataUrl) {
        setFileSelected(ATTACH_LATITUDE_LONGITUDE_FILE);
        setRadioButtonKml(true);
      }
      if (editData?.kmlUrl) {
        setFileSelected(ATTACH_KML_FILE);
        setRadioButtonLatLangFile(true);
      }
      form.setFieldsValue({
        videoName: editData?.name,
        direction: editData?.direction,
        startingChainage: {
          km: editData?.startingChainage?.kilometer,
          m: editData?.startingChainage?.meter,
        },
        endingChainage: {
          km: editData?.endingChainage?.kilometer,
          m: editData?.endingChainage?.meter,
        },
        uploadVideo: editData?.videoUrl
          ? [
              {
                uid: editData?.uid,
                name: editData?.videoMeta?.fileName,
                ...editData?.videoMeta,
              },
            ]
          : [],
        uploadKml: editData?.kmlUrl
          ? [
              {
                uid: editData?.uid,
                name: editData?.kmlMeta?.fileName,
                ...editData?.kmlMeta,
              },
            ]
          : [],
        latLangFile: editData?.sourceDataUrl
          ? [
              {
                uid: editData?.uid,
                name: editData?.sourceDataMeta?.fileName,
                ...editData?.sourceDataMeta,
              },
            ]
          : [],
      });
    }
  }, [editData]);

  const debounceCheckVideoName = useMemo(() => debounce(checkVideoName, 500), [
    checkVideoName,
  ]);

  return (
    <div>
      <Modal
        destroyOnClose
        className="modal-components-design"
        title={isEdit ? 'Edit Video' : 'Add New Video'}
        open={addVideoModel}
        onCancel={() => {
          if (loading) return;
          setVisible(true);
          setIsEdit(false);
        }}
        footer={null}
        centered
      >
        <CommonConfirmationModal
          visible={visible}
          onCancel={() => {
            setSyncedStatus(false);
            setVisible(false);
            setAddVideoModel(false);
          }}
          onOk={() => {
            setVisible(false);
          }}
          title="Unsaved Changes"
          content="If you close, you will lose any changes that you have made."
          okText="Keep Editing"
          cancelText="Discard"
        />
        <LoaderComponent spinning={editLoading && isEdit}>
          <Form
            name="videoModal"
            onFinish={onFinish}
            className="custom-label"
            form={form}
            layout="vertical"
            initialValues={initialValue}
            disabled={loading}
          >
            <Form.Item
              label="Video Name"
              name="videoName"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
                {
                  async validator(_, value) {
                    await debounceCheckVideoName({
                      variables: {
                        where: {
                          name: value,
                          id: editData?.id || undefined,
                          projectId,
                        },
                      },
                    });
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter video name here"
                showCount
                maxLength={250}
              />
            </Form.Item>
            <Form.Item
              label="Direction of Recording"
              name="direction"
              rules={[
                {
                  required,
                  message: 'Please select direction of recording',
                },
              ]}
            >
              <Select
                placeholder="Select recording direction"
                required
                disabled={status || loading}
                options={[
                  {
                    value: 'UPWARD',
                    label: 'Up (L.H.S)',
                  },
                  {
                    value: 'DOWNWARD',
                    label: 'Down (R.H.S)',
                  },
                ]}
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Starting Chainage(Km + m)"
                  name="startingChainage"
                  rules={[
                    { required, message: 'Please enter chainage value' },
                    chainageCustomInputValidation,
                  ]}
                >
                  <CustomInput status={status} disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Ending Chainage(Km + m)"
                  name="endingChainage"
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.km && value?.m && value?.km !== 0) {
                          throw new Error('Please enter chainage value');
                        }
                        if (value?.km && !value?.m && value?.m !== 0) {
                          throw new Error('Please enter chainage value');
                        }
                      },
                    },
                  ]}
                >
                  <CustomInput status={status} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Video"
              name="uploadVideo"
              getValueFromEvent={(value) => {
                if (Array.isArray(value)) return value;
                return value?.fileList;
              }}
              required
              rules={[
                {
                  async validator(_, value) {
                    if (!value?.length) {
                      throw new Error('Please select file');
                    }
                  },
                },
              ]}
              valuePropName="fileList"
            >
              <VideoUploader status={status} disabled={loading} />
            </Form.Item>
            {!!progress?.video && (
              <Progress percent={progress?.video} size="small" showInfo />
            )}
            <Form.Item
              label="File Attachment"
              name="fileAttachment"
              getValueFromEvent={(value) => {
                if (Array.isArray(value)) return value;
                return value?.fileList;
              }}
              className="fileAttachment"
              valuePropName="fileList"
            >
              <Radio.Group
                onChange={(e) => {
                  if (e.target.value === ATTACH_KML_FILE) {
                    form.resetFields(['latLangFile']);
                  }
                  if (e.target.value === ATTACH_LATITUDE_LONGITUDE_FILE) {
                    form.resetFields(['uploadKml']);
                  }
                  setFileSelected(e.target.value);
                }}
                value={fileSelected}
              >
                <Radio
                  value={ATTACH_KML_FILE}
                  disabled={radioButtonKml || loading || status}
                >
                  Attach KML file
                </Radio>
                <Radio
                  value={ATTACH_LATITUDE_LONGITUDE_FILE}
                  disabled={radioButtonLatLangFile || loading || status}
                >
                  Attach latitude, longitude file
                  <Tooltip
                    title="This refers to the raw data file that can be
downloaded from the mobile app"
                  >
                    <InfoEmpty className="ml-10" />
                  </Tooltip>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {fileSelected === 'attach_kml_file' ? (
              <Form.Item
                label="Associated KML file"
                name="uploadKml"
                className="mb-16"
                getValueFromEvent={(value) => {
                  if (Array.isArray(value)) return value;
                  return value?.fileList;
                }}
                valuePropName="fileList"
              >
                <KmlUploader status={status} disabled={loading} />
              </Form.Item>
            ) : (
              <Form.Item
                label="Associated LAT-LONG file"
                name="latLangFile"
                className="mb-16"
                getValueFromEvent={(value) => {
                  if (Array.isArray(value)) return value;
                  return value?.fileList;
                }}
                valuePropName="fileList"
              >
                <LatLongUploader
                  status={status}
                  disabled={loading}
                  uploadedFrom={editData && editData?.uploadedFrom}
                  sourceDataUrl={editData && editData?.sourceDataUrl}
                />
              </Form.Item>
            )}
            {!!progress?.kml && (
              <Progress percent={progress?.kml} size="small" showInfo />
            )}
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, getFieldsError }) => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                  disabled={
                    !isFieldsTouched(
                      [
                        'videoName',
                        'startingChainage',
                        'uploadVideo',
                        'direction',
                      ],
                      true,
                    ) ||
                    !!getFieldsError()?.filter(({ errors }) => errors?.length)
                      .length
                  }
                >
                  Save
                </Button>
              )}
            </Form.Item>
          </Form>
        </LoaderComponent>
      </Modal>
    </div>
  );
};

export default UploadVideoModel;
