import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import './auth.less';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useMutation } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { formValidatorRules } from '../../common/utils';
import { EyeCloseIcon, EyeOpenIcon, TerramaticLogo } from '../../assets/svg';
import googleIcon from '../../assets/svg/googleLogo.svg';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { BREAKPOINTS, ROUTES } from '../../common/constants';
import { GOOGLE_AUTH, SIGNUP } from './graphql/Mutations';
import { AppContext } from '../../AppContext';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const SignUp = () => {
  const history = useHistory();
  const location = useLocation();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [form] = Form?.useForm();
  const { required, email, password } = formValidatorRules;
  const { initializeAuth } = useContext(AppContext);
  const params = new URLSearchParams(location?.search);
  const invitationId = params?.get('id') || null;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const buttonDisable =
    !form.isFieldsTouched(true) ||
    !!form?.getFieldsError()?.filter(({ errors }) => errors?.length)?.length;

  const [signupMutate, { loading }] = useMutation(SIGNUP, {
    onError() {},
  });
  const [googleAuth, { loading: loader }] = useMutation(GOOGLE_AUTH, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace(ROUTES?.MAIN);
  }

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
        confirmPassword: values?.confirmPassword?.trim(),
        invitationId,
      };
      const response = await signupMutate({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        history?.replace(ROUTES?.SIGNUP_EMAIL_SENT, values?.email);
      }
    } catch (error) {
      if (error?.message) {
        message?.error(error?.message);
      } else {
        message?.error('Something went wrong');
      }
    }
  };

  const renderPasswordIcon = (visible) => {
    if (visible) {
      return <EyeOpenIcon />;
    }
    return <EyeCloseIcon />;
  };

  if (!firebase?.apps?.length) {
    firebase?.initializeApp(firebaseConfig);
  }

  const signInWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider?.setCustomParameters({
      prompt: 'select_account',
    });
    firebase
      ?.auth()
      ?.signInWithPopup(googleProvider)
      ?.then(async (result) => {
        const idToken = result?.user?.multiFactor?.user?.accessToken;
        const response = await googleAuth({
          variables: {
            data: {
              token: idToken,
              invitationId,
            },
          },
        });
        if (response?.data) {
          const accessToken = response?.data?.validateToken?.accessToken;
          const userData = response?.data?.validateToken?.data;
          const tokenRefresh = response?.data?.validateToken?.refreshToken;
          if (successCallback) {
            successCallback(accessToken, userData, tokenRefresh);
          }
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log('google-auth => ', error));
  };

  useEffect(() => {
    setIsButtonDisabled(true);
  }, [buttonDisable]);

  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <h1 className="mt-0 mb-12 title-text">Welcome</h1>
              <div className="sub-title mb-32">
                Join us in creating safer roads through comprehensive audits.
                Sign up now and make a difference.
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="Signup"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="vertical"
                  form={form}
                  className="full-width custom-label"
                >
                  <Form.Item
                    name="email"
                    label="Email ID"
                    rules={[
                      { required, message: 'Please enter email!' },
                      email,
                    ]}
                  >
                    <Input placeholder="Enter your email id here" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    className="custom-label"
                    label="Password"
                    rules={[
                      { required, message: 'Please enter password!' },
                      password,
                    ]}
                  >
                    <Input.Password
                      placeholder="Minimum 8 characters"
                      iconRender={(visible) => renderPasswordIcon(visible)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    rules={[
                      { required, message: 'Please enter password!' },
                      {
                        validator: (_, value) => {
                          const { getFieldValue } = form;

                          if (value && value !== getFieldValue('password')) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject(`The passwords don't match`);
                          }
                          return Promise?.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Minimum 8 characters"
                      iconRender={(visible) => renderPasswordIcon(visible)}
                    />
                  </Form.Item>

                  <Form.Item shouldUpdate className="full-width mb-0">
                    {() => (
                      <Button
                        type="primary"
                        size="large"
                        block
                        htmlType="submit"
                        loading={loading}
                        disabled={
                          !isButtonDisabled ||
                          !form.isFieldsTouched(true) ||
                          !!form
                            ?.getFieldsError()
                            ?.filter(({ errors }) => errors?.length)?.length
                        }
                        className="mt-8"
                      >
                        Sign-up
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
              <div className="or-text-div">
                <div className="hr-or-line right" />
                <div className="text-or">OR</div>
                <div className="hr-or-line left" />
              </div>
              <div className="full-width">
                <Button
                  className="full-width google-btn"
                  htmlType="submit"
                  loading={loader}
                  onClick={signInWithGoogle}
                >
                  <img
                    className="googleLogoIcon"
                    alt="google-icon"
                    src={googleIcon}
                  />
                  <b className="continueWithGoogle">Continue with Google</b>
                </Button>
              </div>
              <div className="bottom">
                <div className="bottom-user-text">
                  <span>Already a user? </span>
                  <Link to={isDesktop ? ROUTES?.LOGIN : ROUTES?.MOBILE_LOGIN}>
                    <b className="bottom-user-text-link ml-8">Login</b>
                  </Link>
                </div>
                <div className="byLoggingInContainer">
                  <p className="byLoggingIn mb-0">
                    By signing in to your account you agree to our
                  </p>
                  <p className="byLoggingIn m-0 terms-link">
                    <Link to={ROUTES?.SIGNUP}>Terms & Conditions</Link>
                    <span className="ml-4 mr-4">|</span>
                    <Link to={ROUTES?.SIGNUP}>Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="signup-side-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default SignUp;
