import { Button, Upload, message } from 'antd';
import React, { useState } from 'react';
import dummyImg from '../assets/svg/workspaceDummy.svg';
import { getBase64 } from '../common/utils';
import { ALL_IMAGE_UPLOAD } from '../common/constants';
import CommonConfirmationModal from './CommonConfirmationModal';

const CommonUploadFile = ({
  previewImage,
  setPreviewImage,
  currentFile,
  setCurrentFile,
  title,
  onChange,
  value,
  deletePopConfirmDescription,
  deletePopConfirmTitle,
  deleteStyles,
  setSaveDisabled,
  setDeleteFlag,
  deleteFlag,
  ...res
}) => {
  const [deleteImageModal, setDeleteImageModal] = useState(false);
  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setCurrentFile(info?.file);
      const preview = await getBase64(info?.fileList?.[0]?.originFileObj);
      setPreviewImage(preview);
      if (setSaveDisabled) {
        setSaveDisabled(true);
      }
    } else {
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
    onChange(info);
  };

  return (
    <div>
      <div className="workspace-agency-details">
        <div>
          {previewImage ? (
            <img src={previewImage} alt="app-icon" className="logo-img" />
          ) : (
            <img src={dummyImg} alt="camera-icon" />
          )}
        </div>
        <div>
          <span className="workspace-agency-logo">{title}</span>
          <p className="preferable-image-text">
            Preferable Image Size: 3inch x 3inch
          </p>
          <div>
            <Upload
              maxCount={1}
              onChange={onChangeImageUpload}
              beforeUpload={() => false}
              showUploadList={false}
              {...res}
            >
              {!previewImage ? (
                <div className="edit-delete-btn">
                  <Button size="small">Upload</Button>
                </div>
              ) : (
                <div className="edit-delete-btn">
                  <CommonConfirmationModal
                    visible={deleteImageModal}
                    onCancel={(e) => {
                      e?.stopPropagation();
                      setDeleteImageModal(false);
                    }}
                    onOk={(e) => {
                      setPreviewImage(null);
                      if (setSaveDisabled) {
                        setSaveDisabled(true);
                      }
                      if (setDeleteFlag) {
                        setDeleteFlag(!deleteFlag);
                      }
                      setDeleteImageModal(false);
                      e?.stopPropagation();
                    }}
                    title={deletePopConfirmTitle}
                    content={deletePopConfirmDescription}
                    okText="Delete"
                    cancelText="Discard"
                  />
                  <Button
                    className="secondary-btn border-btn"
                    size="small"
                    onClick={(e) => {
                      setDeleteImageModal(true);
                      e?.stopPropagation();
                    }}
                  >
                    Delete
                  </Button>
                  <Button className="secondary-btn" size="small">
                    Edit
                  </Button>
                </div>
              )}
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonUploadFile;
