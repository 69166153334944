import { Menu } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { MODULES, ROLES, ROLE_KEYS, ROUTES } from '../../../common/constants';
import {
  GroupMembers,
  ListVegneta,
  Settings,
  WorkSpacesIcon,
} from '../../../assets/svg';
import AccessRole from '../../../components/AccessRole';
import AccessRoleAdmin from '../../../components/AccessRoleAdmin';

function Sidebar({ location: { pathname }, history, items }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const menuItems = [
    getItem(MODULES?.PROJECTS, ROUTES?.PROJECTS, <ListVegneta />),
    AccessRole({ allowedRoles: ROLES?.OWNER }) &&
      getItem(MODULES?.MEMBERS, ROUTES?.MEMBERS, <GroupMembers />),
    AccessRoleAdmin({ allowedRoles: [ROLE_KEYS?.SUPER_ADMIN] }) &&
      getItem(
        MODULES?.WORKSPACES,
        ROUTES?.WORKSPACE_MANAGEMENT,
        <WorkSpacesIcon />,
      ),
    getItem(MODULES.SETTINGS, ROUTES?.SETTINGS, <Settings />, [
      getItem(MODULES?.PERSONAL, ROUTES?.PERSONAL),
      AccessRole({ allowedRoles: ROLES?.OWNER }) &&
        getItem(MODULES?.AGENCY, ROUTES?.AGENCY),
    ]),
  ];

  const onMenuSelect = (e) => {
    history?.push(e?.key);
  };

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        defaultSelectedKeys={[ROUTES?.MAIN]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={items || menuItems}
        className="side-bar-menu"
        inlineIndent="36"
      />
    </div>
  );
}

export default withRouter(Sidebar);
