import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useLoadScript,
} from '@react-google-maps/api';
import { Button, Col, Row, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { CONSOLIDATED_VIDEOS, GET_PROJECT_DETAILS } from './graphql/Queries';
import LoaderComponent from '../../components/LoaderComponent';
import {
  PROJECT_ROLES,
  ROUTES,
  containerStyle,
  libraries,
} from '../../common/constants';
import { getHoursMinutesAndSeconds } from '../../common/utils';
import { UPSERT_PROJECT_CONSOLIDATED_IMAGE } from './graphql/Mutation';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import { AppContext } from '../../AppContext';

const center = {
  lat: -3.745,
  lng: -38.523,
};
const ConsolidatedView = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [projectData, setProjectData] = useState();
  const { projectId } = useParams();
  const history = useHistory();
  const {
    state: { projectRole },
  } = useContext(AppContext);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [syncedVideoData, setSyncedVideoData] = useState(null);
  const [distance, setDistance] = useState(null);
  const [paths, setPaths] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [mapZoom, setMapZoom] = useState(12);
  const [latLongObj, setLatLongObj] = useState(null);

  const [consolidatedVideos, { loading }] = useLazyQuery(CONSOLIDATED_VIDEOS, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setDistance(res?.consolidatedVideos?.distanceCovered);
      setSyncedVideoData(res?.consolidatedVideos);
      const newArray = [];
      // eslint-disable-next-line array-callback-return
      res?.consolidatedVideos?.data?.map((elem) => {
        const inputArray = elem?.locations?.map((item) => {
          const parsedArray = JSON.parse(item);
          return { lat: parsedArray[1], lng: parsedArray[0] };
        });
        newArray.push(inputArray);
      });
      setPaths(newArray);
      if (newArray?.length > 0) {
        setLatLongObj(newArray?.[0]?.[0]);
      }
    },
    onError() {},
  });
  const [getProject, { loading: projectLoading }] = useLazyQuery(
    GET_PROJECT_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setProjectData(res?.getProject.data);
      },
      onError() {},
    },
  );

  const [upsertProjectConsolidatedImage] = useMutation(
    UPSERT_PROJECT_CONSOLIDATED_IMAGE,
    {
      onCompleted() {
        getProject({
          variables: {
            where: { projectId },
          },
        });
      },
    },
  );

  const centerMapFunc = async () => {
    // eslint-disable-next-line no-undef
    const userLocation = navigator?.geolocation;
    if (userLocation) {
      userLocation?.getCurrentPosition((data) => {
        setLatLongObj({
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        });
        return {
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        };
      });
    } else {
      return null;
    }

    return center;
  };

  const getHourMinSec = (seconds) => {
    const duration = getHoursMinutesAndSeconds(seconds);
    return `${duration?.h}:${duration?.m}:${duration?.s}`;
  };

  useEffect(() => {
    getProject({
      variables: {
        where: { projectId },
      },
    });
  }, []);

  useEffect(() => {
    consolidatedVideos({
      variables: {
        where: {
          projectId,
        },
      },
    });
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      centerMapFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleMouseMove = (e) => {
    setInfoWindowPosition({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() });
  };

  return (
    <>
      {loading ? (
        <LoaderComponent spinning={loading} setHeight="200" />
      ) : (
        <div className="consolidated-view">
          <Row gutter={[32, 32]} className="consolidated-view-map">
            <Col span={16}>
              <ProjectAccessRole
                allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                currentRole={projectRole}
              >
                <div className="d-flex flex-horizontal-reverse mb-8">
                  <Button
                    className="secondary-btn ml-16 -mt-40"
                    size="small"
                    onClick={() => {
                      if (syncedVideoData?.count === 0) {
                        message.error(
                          'Project does not contain any synced videos,cannot generate maps image!',
                        );
                      } else {
                        upsertProjectConsolidatedImage({
                          variables: {
                            data: {
                              projectId,
                              addToReport: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    Add to Report
                  </Button>
                  <Button
                    disabled={!projectData?.consolidatedImageUrl}
                    className="ml-16 -mt-40"
                    size="small"
                    onClick={() => {
                      upsertProjectConsolidatedImage({
                        variables: {
                          data: {
                            projectId,
                            addToReport: false,
                          },
                        },
                      });
                    }}
                    loading={projectLoading}
                  >
                    Remove from Report
                  </Button>
                </div>
              </ProjectAccessRole>

              {isLoaded && (
                <>
                  <GoogleMap
                    className="google-map-div"
                    mapContainerStyle={containerStyle}
                    center={latLongObj}
                    onLoad={(mapRefCopy) => {
                      setMapRef(mapRefCopy);
                    }}
                    onZoomChanged={() => {
                      if (mapRef) {
                        setMapZoom(mapRef?.getZoom());
                      }
                    }}
                    zoom={mapZoom}
                  >
                    {
                      // eslint-disable-next-line array-callback-return
                      paths &&
                        paths?.map((elem, ind) => (
                          <>
                            <Polyline
                              path={elem}
                              key={uniqueId('synced')}
                              options={{
                                strokeColor: '#777777',
                                strokeOpacity: 0.8,
                                strokeWeight: 4,
                              }}
                              onClick={handleMouseMove}
                            />
                            <Marker position={elem?.[0]} label={`${ind + 1}`} />
                          </>
                        ))
                    }
                    {infoWindowPosition?.lat && (
                      <div className="info-model">
                        <InfoWindow
                          position={infoWindowPosition}
                          onCloseClick={() => setInfoWindowPosition(null)}
                        >
                          <div>
                            Latitude: {infoWindowPosition?.lat?.toFixed(6)}
                            <br />
                            Longitude: {infoWindowPosition?.lng?.toFixed(6)}
                          </div>
                        </InfoWindow>
                      </div>
                    )}
                  </GoogleMap>
                  <div className="total-distance-covered">
                    {`Distance Covered: ${distance} KM`}
                  </div>
                </>
              )}
            </Col>
            <Col span={8} className="consolidated-view-content">
              <Row gutter={8}>
                <Col
                  span={12}
                  className="synced-video-count d-flex align-center"
                >
                  Synced Videos ({syncedVideoData?.count})
                </Col>
                <Col span={12} className="d-flex flex-horizontal-reverse">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      history?.push(
                        `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.SEE_ALL}`,
                      );
                    }}
                  >
                    See All Detections
                  </Button>
                </Col>
              </Row>

              {syncedVideoData?.data &&
                syncedVideoData?.data?.map((elem, index) => (
                  <>
                    <div
                      className="sync-videos py-16 px-24 mt-24"
                      key={uniqueId(elem?.id)}
                    >
                      <Row gutter={[32]}>
                        <Col span={16} className="sync-video-title">
                          {`${index + 1}. ${elem?.name}`}
                        </Col>
                        <Col
                          span={8}
                          className="d-flex flex-horizontal-reverse"
                        >
                          <button type="button" className="sync-video-duration">
                            {getHourMinSec(elem?.duration)}
                          </button>
                        </Col>
                      </Row>
                      <Row className="sync-video-distance">
                        Total Distance: {`${elem?.totalDistance} KM`}
                      </Row>
                      <Row
                        className="mt-16 sync-video-chainage-title"
                        gutter={8}
                      >
                        <Col span={12}>Starting Chainage (km + m)</Col>
                        <Col span={12}>Ending Chainage (km + m)</Col>
                      </Row>
                      <Row gutter={8} className="sync-video-chainage">
                        <Col span={12}>
                          {`${elem?.startingChainage?.kilometer}+${elem?.startingChainage?.meter}`}
                        </Col>
                        <Col span={12}>
                          {elem?.endingChainage
                            ? `${elem?.endingChainage?.kilometer}+${elem?.endingChainage?.meter}`
                            : '-'}
                        </Col>
                      </Row>
                    </div>
                  </>
                ))}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ConsolidatedView;
