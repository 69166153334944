import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { fileUpload, formValidatorRules } from '../../common/utils';
import CommonUploadFile from '../../components/CommonUploadFile';
import { AppContext } from '../../AppContext';
import {
  GET_WORKSPACE,
  GET_WORKSPACE_LOGO_SIGNED_URL,
} from './graphql/Queries';
import { UPDATE_WORKSPACE } from '../projects/graphql/Mutations';
import { BI0_LIMIT, ROLES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';

const initialValue = {
  projectLogo: '',
  projectName: '',
  WorkspaceName: '',
  bio: '',
};
const { TextArea } = Input;
const { Option } = Select;
const { number, email: emailRegEx } = formValidatorRules;

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select defaultValue="+91">
      <Option value="+91">+91</Option>
    </Select>
  </Form.Item>
);
const Agency = () => {
  const {
    state: { currentUser = {} },
    getCurrentUser,
  } = useContext(AppContext);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [agencyInformationFlag, setAgencyInformationFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form?.useForm();
  const [deleteFlag, setDeleteFlag] = useState(false);
  const { required, checkImageSizeAndDimensions } = formValidatorRules;
  const [saveDisabled, setSaveDisabled] = useState(false);
  const user = getCurrentUser() || null;

  const [workSpace] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      form?.setFieldsValue({
        projectLogo: null,
        agencyName: res?.workspace?.agency || null,
        WorkspaceName: res?.workspace?.name || null,
        bio: res?.workspace?.bio || null,
        email: res?.workspace?.contactDetails?.email || null,
        phoneNumber: res?.workspace?.contactDetails?.phoneNumber || null,
        fax: res?.workspace?.contactDetails?.fax || null,
        website: res?.workspace?.contactDetails?.website || null,
        addressLine1: res?.workspace?.contactDetails?.addressLine1 || null,
        addressLine2: res?.workspace?.contactDetails?.addressLine2 || null,
      });
      setPreviewImage(res?.workspace?.logoUrl);
      setLoading(false);
    },
    onError() {},
  });

  const [getWorkspaceLogoUrl] = useLazyQuery(GET_WORKSPACE_LOGO_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE, {
    onCompleted() {
      setDeleteFlag(false);
      setSaveDisabled(false);
      setLoading(false);
      setAgencyInformationFlag(false);
      form.resetFields(['projectLogo']);
    },
  });

  const handleUpdateWorkspace = (value) => {
    updateWorkspace({
      variables: {
        data: value,
        where: {
          workspaceId: currentUser?.workspaceId,
        },
      },
    });
  };

  useEffect(() => {
    if (user?.workspaceRole === ROLES?.OWNER) {
      workSpace();
    }
  }, []);

  const onFinish = async ({
    projectLogo,
    agencyName,
    WorkspaceName,
    bio,
    email,
    addressLine1,
    addressLine2,
    fax,
    phoneNumber,
    website,
  }) => {
    const payload = {
      agency: agencyName,
      name: WorkspaceName,
      bio,
      contactDetails: {
        email: email || null,
        addressLine2: addressLine1 || null,
        addressLine1: addressLine2 || null,
        fax: fax || null,
        phoneNumber: phoneNumber || null,
        website: website || null,
      },
    };

    setLoading(true);

    try {
      if (projectLogo?.[0]?.originFileObj) {
        const {
          data: {
            getWorkspaceLogoUploadSignedPutUrl: { fileName, signedUrl } = {},
          } = {},
        } = await getWorkspaceLogoUrl({
          variables: {
            data: {
              workspaceId: currentUser?.workspaceId,
              fileName: projectLogo?.[0]?.name,
            },
          },
        });
        await fileUpload(signedUrl, projectLogo?.[0]?.originFileObj);
        if (agencyInformationFlag) {
          payload.logoKey = fileName;
          handleUpdateWorkspace(payload);
        } else {
          handleUpdateWorkspace({ logoKey: fileName });
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (agencyInformationFlag) {
          if (deleteFlag) {
            payload.logoKey = null;
            handleUpdateWorkspace(payload);
          } else {
            handleUpdateWorkspace(payload);
          }
        } else {
          handleUpdateWorkspace({ logoKey: null });
        }
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="height-calc-60">
      <div className="content-top mb-32">
        <div className="content-top-heading">My Settings</div>
      </div>
      {loading ? (
        <LoaderComponent spinning={loading} setHeight="200" />
      ) : (
        <div className="modal-components-design height-100">
          <Form
            name="agencyDetails"
            initialValues={initialValue}
            className="custom-label setting height-100 d-flex justify-between flex-vertical"
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <div>
              <Form.Item
                className="project-logo"
                name="projectLogo"
                getValueFromEvent={(event) => event?.fileList}
                rules={[
                  { required, message: 'Please upload the file' },
                  checkImageSizeAndDimensions,
                ]}
              >
                <CommonUploadFile
                  title="Agency Logo"
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  currentFile={currentFile}
                  setCurrentFile={setCurrentFile}
                  deletePopConfirmTitle="Delete Logo"
                  deletePopConfirmDescription="Are you sure you want to delete agency logo?"
                  deleteStyles="popconfirm-buttons"
                  setSaveDisabled={setSaveDisabled}
                  setDeleteFlag={setDeleteFlag}
                  deleteFlag={deleteFlag}
                  setAgencyInformationFlag={setAgencyInformationFlag}
                />
              </Form.Item>
              <Row gutter={32}>
                <Col lg={8}>
                  <Form.Item
                    label="Agency Name"
                    name="agencyName"
                    rules={[
                      {
                        required,
                        message: 'Please enter agency name here!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter agency name here"
                      maxLength={50}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    label="Workspace Name"
                    name="WorkspaceName"
                    rules={[
                      {
                        required,
                        message: 'Please enter Workspace name here!',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Workspace name here"
                      maxLength={50}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item label="Email" name="email" rules={[emailRegEx]}>
                    <Input
                      placeholder="Enter your email here"
                      maxLength={64}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col lg={8}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[number]}
                  >
                    <Input
                      maxLength={10}
                      placeholder="Enter your phone number here"
                      addonBefore={prefixSelector}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item label="Website" name="website">
                    <Input
                      placeholder="Enter your website link here"
                      maxLength={25}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item label="Fax" name="fax" rules={[number]}>
                    <Input
                      placeholder="Enter your fax number"
                      maxLength={25}
                      onChange={() => {
                        setSaveDisabled(true);
                        setAgencyInformationFlag(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Address Line 1" name="addressLine1">
                <Input
                  showCount
                  placeholder="enter address here"
                  maxLength={50}
                  onChange={() => {
                    setSaveDisabled(true);
                    setAgencyInformationFlag(true);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="addressLine2"
                className="addressLine2"
              >
                <Input
                  showCount
                  placeholder="enter address here"
                  maxLength={50}
                  onChange={() => {
                    setSaveDisabled(true);
                    setAgencyInformationFlag(true);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Bio"
                name="bio"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value && value.length > BI0_LIMIT) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject(
                          `Bio should not exceed ${BI0_LIMIT} characters!`,
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {/* comment out temporarily,Will implement in future */}
                {/* <QuillEditor
                  onChange={() => {
                    setSaveDisabled(true);
                    setAgencyInformationFlag(true);
                  }}
                /> */}
                <TextArea
                  showCount
                  autoSize={{ minRows: 5 }}
                  maxLength={BI0_LIMIT}
                  onChange={() => {
                    setSaveDisabled(true);
                    setAgencyInformationFlag(true);
                  }}
                  placeholder="Enter bio description"
                />
              </Form.Item>
            </div>
            <div className="d-flex flex-horizontal-reverse mt-32">
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    disabled={
                      !saveDisabled ||
                      !!form
                        ?.getFieldsError()
                        ?.filter(({ errors }) => errors?.length)?.length
                    }
                  >
                    Save
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Agency;
