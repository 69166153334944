import {
  Button,
  Col,
  Form,
  Input,
  Row,
  TimePicker,
  Typography,
  Upload,
} from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  fileUpload,
  formValidatorRules,
  getDayJsTime,
} from '../../common/utils';
import { DownloadExcel, UploadCsvFileIcon } from '../../assets/svg';
import csvLogo from '../../assets/svg/csvLogo.svg';
import { ANALYSIS, ANALYSIS_CSV_SIGNED_PUT_URL } from './graphql/Queries';
import { CREATE_ANALYSIS, UPDATE_ANALYSIS } from './graphql/Mutations';
import TableComponent from '../../components/CommonTable';
import {
  ALL_CSV_UPLOAD,
  PROJECT_ROLES,
  ROAD_ANALYSIS,
  USER,
} from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import { AppContext } from '../../AppContext';

const sampleTrafficAnalysis = process.env.REACT_APP_SAMPLE_TRAFFIC_ANALYSIS;
const TrafficAnalysis = () => {
  const [form] = Form?.useForm();
  const { required, chainageValidation } = formValidatorRules;
  const { projectId } = useParams();
  const [graphsData, setGraphsData] = useState(null);
  const [trafficAnalysisData, setTrafficAnalysisData] = useState(null);
  const [trafficAnalysisUid, setTrafficAnalysisUid] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [csvUrl, setCsvUrl] = useState(null);
  const [csvMeta, setCsvMeta] = useState(null);
  const [deleteCsvModel, setDeleteCsvModel] = useState(false);
  const [chainageData, setChainageData] = useState(null);
  const [trafficDuration, setTrafficDuration] = useState(null);
  const [csvUploadError, setCsvUploadError] = useState(false);

  const {
    state: { projectRole },
  } = useContext(AppContext);

  // eslint-disable-next-line no-undef
  const createdBy = JSON?.parse(localStorage?.getItem(USER));
  const chainage = Form.useWatch(['Chainage'], form);
  const duration = Form.useWatch(['DurationOfTrafficAnalysis'], form);

  const [
    analysis,
    { refetch: analysisRefetch, loading: analysisLoading },
  ] = useLazyQuery(ANALYSIS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      let chainAgeValue = '';
      setChainageData(res?.analysis?.data?.chainage);
      if (res?.analysis?.data?.csvUrl) {
        setTrafficDuration(res?.analysis?.data?.duration);
      }

      if (res?.analysis?.data?.chainage) {
        const test1 = res?.analysis?.data?.chainage;
        chainAgeValue += `${Number((test1 - (test1 % 1000)) / 1000)}+${
          test1 % 1000
        }`;
        form.setFieldsValue({
          Chainage: chainAgeValue || '',
          DurationOfTrafficAnalysis:
            getDayJsTime(res?.analysis?.data?.duration) || '',
        });
      }

      setTrafficAnalysisUid(res?.analysis?.data?.uid);
      setCsvUrl(res?.analysis?.data?.csvUrl);
      setCsvMeta(res?.analysis?.data?.csvMeta);
      setDataId(res?.analysis?.data?.id);
      setGraphsData(res?.analysis?.data?.graphs);
      setTrafficAnalysisData(res?.analysis?.sourceData);
    },
    onError() {},
  });

  const [updateAnalysis, { loading: updateLoading }] = useMutation(
    UPDATE_ANALYSIS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        analysisRefetch();
      },
    },
  );

  const [createAnalysis, { loading: createAnalysisLoading }] = useMutation(
    CREATE_ANALYSIS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        analysisRefetch();
      },
    },
  );

  const [analysisCsvSignedPutUrl] = useLazyQuery(ANALYSIS_CSV_SIGNED_PUT_URL, {
    fetchPolicy: 'no-cache',
    onError() {},
  });

  const handleChange = async (value) => {
    if (value?.fileList?.[0]?.originFileObj) {
      const file = value?.fileList?.[0]?.originFileObj;
      const extension = file?.name?.split('.');
      const createNanoId = nanoid(8);
      const { data } = await analysisCsvSignedPutUrl({
        variables: {
          data: {
            fileName: file?.name,
            // eslint-disable-next-line no-unneeded-ternary
            uid: trafficAnalysisUid ? trafficAnalysisUid : createNanoId,
            type: ROAD_ANALYSIS?.TRAFFIC,
          },
        },
      });
      const csvKey = data?.analysisCsvSignedPutUrl?.fileName;
      await fileUpload(data?.analysisCsvSignedPutUrl?.signedUrl, file);

      // eslint-disable-next-line no-undef
      if (ALL_CSV_UPLOAD?.includes(...extension?.slice(1, 2))) {
        setCsvUploadError(false);
        try {
          if (dataId) {
            let timeInDuration = 0;
            timeInDuration += Number(+duration?.$H * 3600);
            timeInDuration += Number(+duration?.$m * 60);

            await updateAnalysis({
              variables: {
                where: {
                  type: ROAD_ANALYSIS?.TRAFFIC,
                  projectId,
                },
                data: {
                  csvKey,
                  csvMeta: {
                    extension: extension?.[extension?.length - 1],
                    fileName: file?.name,
                    contentType: file?.type,
                    size: file?.size,
                  },
                  duration: timeInDuration,
                },
              },
            });
          } else {
            let timeInDuration = 0;
            let chainAgeInMeter = 0;
            timeInDuration += Number(+duration?.$H * 3600);
            timeInDuration += Number(+duration?.$m * 60);

            // eslint-disable-next-line array-callback-return
            chainage?.split('+')?.map((elem, index) => {
              if (index === 0) {
                chainAgeInMeter += Number(+elem * 1000);
              } else {
                chainAgeInMeter += Number(elem);
              }
            });
            await createAnalysis({
              variables: {
                data: {
                  uid: createNanoId,
                  type: ROAD_ANALYSIS?.TRAFFIC,
                  projectId,
                  csvKey,
                  createdBy: createdBy?.id,
                  chainage: chainAgeInMeter,
                  duration: timeInDuration,
                  csvMeta: {
                    extension: extension?.[extension?.length - 1],
                    fileName: file?.name,
                    contentType: file?.type,
                    size: file?.size,
                  },
                },
              },
            });
          }
        } catch (errors) {
          return errors;
        }
      } else if (!ALL_CSV_UPLOAD?.includes(...extension?.slice(1, 2))) {
        setCsvUploadError(true);
      }
    }
  };

  useEffect(() => {
    analysis({
      variables: {
        where: {
          type: ROAD_ANALYSIS?.TRAFFIC,
          projectId,
        },
      },
    });
  }, []);

  const trafficAnalysisTableCol = [
    {
      title: 'Mode of Transport',
      dataIndex: 'Mode of Transport',
      key: 'Mode of Transport',
    },
    {
      title: 'Nos.',
      dataIndex: 'Nos.',
    },
    {
      title: 'Passenger Car Unit(PCU) Value',
      dataIndex: 'Passenger car unit (PCU) value',
      key: 'Passenger car unit (PCU) value',
    },
    {
      title: 'Total Traffic(PCU)',
      dataIndex: 'Total Traffic (PCU)',
      key: 'Total Traffic (PCU)',
    },
    {
      title: 'Peak Hour Traffic(PCU)',
      dataIndex: 'Peak hour Traffic (PCU)',
      key: 'Peak hour Traffic (PCU)',
    },
    {
      title: 'Average Speed(KMPH)',
      dataIndex: 'Average Speed (KMPH)',
      key: 'Average Speed (KMPH)',
    },
    {
      title: 'Average Speed(KMPH)',
      dataIndex: 'Average Speed (KMPH)',
      key: 'Average Speed (KMPH)',
    },
    {
      title: 'Level of service (LOS)',
      dataIndex: 'Level of service (LOS)',
      key: 'Level of service (LOS)',
    },
    {
      title: 'Average Traffic Volume (PCU/h)',
      dataIndex: 'Average Traffic Volume (PCU/h)',
      key: 'Average Traffic Volume (PCU/h)',
    },
  ];

  return (
    <>
      <Typography className="analysis-title mb-24 mt-38">
        Traffic Analysis
      </Typography>
      <div className="traffic-analysis mt-20">
        <Form
          name="traffic-analysis"
          className="custom-label"
          layout="vertical"
          form={form}
        >
          <Row gutter={32}>
            <Col span={8}>
              <div className="steps-block">
                <div className="steps-head">
                  <Typography className="step">Step 1</Typography>
                  <Typography className="description">
                    Please enter the chainage value & duration for which the
                    traffic analysis is being carried out.
                  </Typography>
                </div>
                <div className="steps-footer">
                  <div className="steps-block">
                    <div className="steps-head">
                      <Row gutter={[16, 16]}>
                        <Col sm={24} lg={24} xl={24} xxl={12}>
                          <Form.Item
                            label="Chainage"
                            name="Chainage"
                            rules={[
                              {
                                required,
                                message: 'Please Enter the chainage value',
                              },
                              chainageValidation,
                            ]}
                          >
                            <Input disabled={chainageData} />
                          </Form.Item>
                        </Col>
                        <Col
                          className="time-duration"
                          sm={24}
                          lg={24}
                          xl={24}
                          xxl={12}
                        >
                          <Form.Item
                            label="Duration of Traffic Analysis"
                            name="DurationOfTrafficAnalysis"
                            rules={[
                              {
                                required,
                                message: 'Please Select time duration',
                              },
                            ]}
                          >
                            <TimePicker
                              popupClassName="time-picker"
                              format="HH:mm"
                              size="large"
                              disabled={trafficDuration}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="steps-block">
                <div className="steps-head">
                  <Typography className="step">Step 2</Typography>
                  <Typography className="description">
                    Download excel file with existing format
                  </Typography>
                </div>
                <div className="steps-footer">
                  <Button
                    icon={<DownloadExcel />}
                    href={sampleTrafficAnalysis}
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        ?.getFieldsError()
                        ?.filter(({ errors }) => errors?.length)?.length
                    }
                  >
                    Download Excel Sheet
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="steps-block">
                <div className="steps-head">
                  <Typography className="step">Step 3</Typography>
                  <Typography className="description">
                    Upload CSV file with updated data
                  </Typography>
                </div>
                <div className="steps-footer">
                  <ProjectAccessRole
                    allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                    currentRole={projectRole}
                  >
                    <Upload
                      maxCount={1}
                      onChange={handleChange}
                      beforeUpload={() => false}
                      showUploadList={false}
                    >
                      {csvUrl ? (
                        <Row gutter={[12, 12]}>
                          <Col
                            onClick={(e) => {
                              e?.stopPropagation();
                            }}
                          >
                            <img src={csvLogo} alt="upload csv file" />
                          </Col>
                          <Col>
                            <Typography className="csv-file-name mb-6">
                              {csvMeta?.fileName}
                            </Typography>
                            <Button
                              className="secondary-btn border-btn"
                              size="small"
                              onClick={(e) => {
                                setDeleteCsvModel(true);
                                e?.stopPropagation();
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Form.Item shouldUpdate>
                          {() => (
                            <>
                              {csvUploadError && (
                                <p className="uploaded_csv_error">
                                  The uploaded file does not match with the CSV
                                  format. Please check the file and try again.
                                </p>
                              )}
                              <Button
                                type="dashed"
                                className="upload-csv"
                                icon={<UploadCsvFileIcon />}
                                disabled={
                                  !form.isFieldsTouched(true) ||
                                  !!form
                                    ?.getFieldsError()
                                    ?.filter(({ errors }) => errors?.length)
                                    ?.length
                                }
                                loading={createAnalysisLoading || updateLoading}
                              >
                                Upload CSV File
                              </Button>
                            </>
                          )}
                        </Form.Item>
                      )}
                    </Upload>
                  </ProjectAccessRole>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      {trafficAnalysisData?.length > 0 && (
        <TableComponent
          columns={trafficAnalysisTableCol}
          data={trafficAnalysisData}
          tableClassName="mt-24 custom-table-analysis"
          loadingData={analysisLoading || updateLoading}
          scroll={{ x: true }}
        />
      )}
      {(createAnalysisLoading || updateLoading) && (
        <LoaderComponent
          setHeight="400"
          spinning={createAnalysisLoading || updateLoading}
        />
      )}

      {graphsData &&
        graphsData?.map((elem) => (
          <div className="graph-container mt-32" key={elem.type}>
            <img alt="graph" src={elem?.graphUrl} />
          </div>
        ))}
      <CommonConfirmationModal
        visible={deleteCsvModel}
        title="Delete CSV"
        content="Are you sure you want to delete the file"
        cancelText="Discard"
        okText="Delete"
        onOk={() => {
          updateAnalysis({
            variables: {
              where: {
                type: ROAD_ANALYSIS?.TRAFFIC,
                projectId,
              },
              data: {
                csvKey: null,
                csvMeta: null,
              },
            },
          });
          setTrafficDuration(null);
          setDeleteCsvModel(false);
        }}
        onCancel={() => setDeleteCsvModel(false)}
      />
    </>
  );
};

export default TrafficAnalysis;
