import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './auth.less';
import Gleap from 'gleap';
import { useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { formValidatorRules } from '../../common/utils';
import { EyeCloseIcon, EyeOpenIcon, TerramaticLogo } from '../../assets/svg';
import googleIcon from '../../assets/svg/googleLogo.svg';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { AppContext } from '../../AppContext';
import { GOOGLE_AUTH, LOGIN, RESEND_VERIFY_EMAIL } from './graphql/Mutations';
import { ROUTES } from '../../common/constants';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const Login = () => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { required, email, password } = formValidatorRules;
  const buttonDisable =
    !form.isFieldsTouched(true) ||
    !!form?.getFieldsError()?.filter(({ errors }) => errors?.length)?.length;

  const [googleAuth, { loading: loader }] = useMutation(GOOGLE_AUTH, {
    onError() {},
  });

  const [loginMutate, { loading }] = useMutation(LOGIN, {
    onError() {},
  });

  const [resendVerifyLink] = useMutation(RESEND_VERIFY_EMAIL, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    history?.replace(ROUTES?.MAIN);
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };

      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.loginUser?.accessToken;
        const userData = response?.data?.loginUser?.data;
        const tokenRefresh = response?.data?.loginUser?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
          Gleap.identify(userData?.id, {
            name: userData?.fullName,
            email: userData?.email,
            createdAt: new Date(),
          });
        }
      } else if (response?.errors?.message === 'Email is not verified!') {
        setTimeout(() => {
          resendVerifyLink({
            variables: { data: { email: formValues?.email } },
          });
        }, 2000);
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  if (!firebase?.apps?.length) {
    firebase?.initializeApp(firebaseConfig);
  }

  const signInWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider?.setCustomParameters({
      prompt: 'select_account',
    });
    firebase
      ?.auth()
      ?.signInWithPopup(googleProvider)
      ?.then(async (result) => {
        const idToken = result?.user?.multiFactor?.user?.accessToken;
        const response = await googleAuth({
          variables: {
            data: { token: idToken },
          },
        });
        if (response?.data) {
          const accessToken = response?.data?.validateToken?.accessToken;
          const userData = response?.data?.validateToken?.data;
          const tokenRefresh = response?.data?.validateToken?.refreshToken;
          if (successCallback) {
            successCallback(accessToken, userData, tokenRefresh);
          }
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log('google-auth => ', error));
  };

  const renderPasswordIcon = (visible) => {
    if (visible) {
      return <EyeOpenIcon />;
    }
    return <EyeCloseIcon />;
  };

  useEffect(() => {
    setIsButtonDisabled(true);
  }, [buttonDisable]);

  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <h1 className="mt-0 mb-12 title-text">Welcome</h1>
              <div className="sub-title mb-32">
                Let's continue making roads safer.
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="Login"
                  form={form}
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  onFinish={onFinish}
                  layout="vertical"
                  className="full-width custom-label"
                >
                  <Form.Item
                    name="email"
                    label="Email-ID"
                    rules={[
                      { required, message: 'Enter your email id here' },
                      email,
                    ]}
                  >
                    <Input placeholder="Enter your email id here" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required, message: 'Minimum 8 character' },
                      password,
                    ]}
                  >
                    <Input.Password
                      placeholder="Minimum 8 characters"
                      iconRender={(visible) => renderPasswordIcon(visible)}
                    />
                  </Form.Item>
                  <div className="forget-password mb-40">
                    <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password?</Link>
                  </div>

                  <Form.Item shouldUpdate className="full-width mb-0">
                    {() => (
                      <Button
                        type="primary"
                        size="large"
                        block
                        htmlType="submit"
                        loading={loading}
                        disabled={
                          !isButtonDisabled ||
                          !form.isFieldsTouched(true) ||
                          !!form
                            ?.getFieldsError()
                            ?.filter(({ errors }) => errors?.length)?.length
                        }
                      >
                        Login
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
              <div className="or-text-div">
                <div className="hr-or-line right" />
                <div className="text-or">OR</div>
                <div className="hr-or-line left" />
              </div>
              <div className="full-width">
                <Button
                  className="full-width google-btn "
                  htmlType="submit"
                  loading={loader}
                  onClick={signInWithGoogle}
                >
                  <img className="googleLogoIcon" alt="" src={googleIcon} />
                  <b className="continueWithGoogle">Continue with Google</b>
                </Button>
              </div>
              <div className="bottom">
                <div className="bottom-user-text">
                  <span>Not a user? </span>
                  <Link to={ROUTES?.SIGNUP}>
                    <b className="bottom-user-text-link ml-8">Sign-up</b>
                  </Link>
                </div>
                <div className="byLoggingInContainer">
                  <p className="byLoggingIn mb-0">
                    By logging in to your account you agree to our
                  </p>
                  <p className="byLoggingIn m-0 terms-link">
                    <Link to={ROUTES?.LOGIN}>Terms & Conditions</Link>
                    <span className="ml-4 mr-4">|</span>
                    <Link to={ROUTES?.LOGIN}>Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="side-cover-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default Login;
