import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROLE_KEYS, ROUTES } from '../../common/constants';
import WorkspaceManagement from './WorkspaceManagement';
import AccessRoleAdmin from '../../components/AccessRoleAdmin';

const WorkspaceManagementWrapper = () => (
  <Switch>
    {AccessRoleAdmin({ allowedRoles: ROLE_KEYS?.SUPER_ADMIN }) && (
      <Route
        exact
        path={ROUTES?.WORKSPACE_MANAGEMENT}
        component={WorkspaceManagement}
      />
    )}
  </Switch>
);

export default WorkspaceManagementWrapper;
