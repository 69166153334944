import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_WORKSPACE_IS_ACTIVE_STATUS = gql`
  mutation UpdateWorkspaceIsActiveStatus(
    $data: UpdateWorkspaceIsActiveStatusInput!
    $where: WorkspaceUniqueInput
  ) {
    updateWorkspaceIsActiveStatus(data: $data, where: $where) {
      message
      data {
        id
        agency
        name
        features
        isActive
        distanceCovered
      }
    }
  }
`;
