import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
  InfoWindow,
} from '@react-google-maps/api';
import {
  Slider,
  Tabs,
  Button,
  Tour,
  Switch,
  Typography,
  message,
  Tooltip,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { nanoid } from 'nanoid';
import { PauseOutlined } from '@ant-design/icons';
import VideoPlayer from '../sync/Components/VideoPlayer';
import CommonTable from '../../components/CommonTable';
import {
  Comment,
  MagicPen,
  MagicPenBlue,
  MagicPenRed,
  MagicPenYellow,
  PlayVideo,
  Plus,
} from '../../assets/svg';
import DetectionMarker from '../../assets/svg/detection.svg';
import {
  DETECTION_AI_JOB,
  GET_DETECTIONS_LIST,
  GET_DETECTION_IMAGE_SIGNED_URL,
  GET_PROJECT_VIDEO,
  SYNC_DATA,
} from './graphql/Queries';
import {
  convertDataTimeIntoNormalFormat,
  fileUpload,
} from '../../common/utils';
import { AppContext } from '../../AppContext';
import {
  AI_PROCESS,
  AI_PROCESSING,
  DETECTION_IMAGE_NANOID,
  PROJECT_ROLES,
  ROUTES,
  SLIDER_MARK,
  UPLOADED_FROM_MOBILE,
  center,
  containerStyle,
  libraries,
  priorityOptions,
  riskLevelOptions,
} from '../../common/constants';
import CommentDrawer from './CommentDrawer';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import {
  CREATE_DETECTION_AI_JOB,
  CREATE_PROJECT_VIDEO_MOBILE_KML,
  TERMINATED_DETECTION_AI_JOB,
} from './graphql/Mutations';
import LoaderComponent from '../../components/LoaderComponent';
import AIProcessingStatus from '../../components/AIProcessingStatus';

const AddDetection = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const {
    dispatch,
    state: {
      projectRole,
      currentVideoChainageDetail,
      processedWithAI,
      videoChainage,
    },
    getCurrentUser,
  } = useContext(AppContext);

  const { workspaceFeatures } = getCurrentUser() || {};
  const continueWithAI = workspaceFeatures?.includes(AI_PROCESS);
  const { projectId, videoId } = useParams();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const [centerLocation, setCenterLocation] = useState(center);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [play, setPlay] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [detectionListCoords, setDetectionListCoords] = useState([]);
  const [videoAIProcessed, setVideoAIProcessed] = useState(false);
  const [addNewDetectionLoading, setAddNewDetectionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [liveMarker, setLiveMarker] = useState(null);
  const [seekTo, setSeekTo] = useState(null);
  const [videoChaniage, setVideoChainage] = useState(0);
  const [mainSliderValue, setMainSliderValue] = useState(0);
  const [videoDuration, setVideoDuration] = useState(null);
  const [initialTableData, setInitialTableData] = useState();
  const [tabChange, setTabChange] = useState(0);
  const [detectionImage, setDetectionImage] = useState();
  const [detectionsList, setDetectionsList] = useState(null);
  const [captureDetectionImage, setCaptureDetectionImage] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [commentsData, setCommentsData] = useState(null);
  const [latLngData, setLatLngData] = useState([]);
  const [mapZoom, setMapZoom] = useState(15);
  const [mapRef, setMapRef] = useState(null);
  const [closeTourVisible, setCloseTourVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const currentDate = new Date();
  const [imageAIProcessingModel, setImageAIProcessingModel] = useState(false);
  const [videoAIProcessingStatus, setVideoAIProcessingStatus] = useState(false);
  const [imageProcessingAI, setImageProcessingAI] = useState(false);
  const [aiJobData, setAiJobData] = useState(false);
  const [switchToAI, setSwitchToAI] = useState(false);
  const [imageJobData, setImageJobData] = useState(false);
  const [imageProcessingModel, setImageProcessingModel] = useState(false);
  const [terminateAIProcessModel, setTerminateAIProcessModel] = useState(false);
  const [detectionListCount, setDetectionListCount] = useState();
  const [imageNewDetectionCount, setImageNewDetectionCount] = useState(0);
  const [firstDetection, setFirstDetection] = useState();
  const [
    completedAIViewDetailsModel,
    setCompletedAIViewDetailsModel,
  ] = useState(false);
  const [
    videoProcessingUsingAIModel,
    setVideoProcessingUsingAIModel,
  ] = useState(false);
  const [aiProcessingDetailsModel, setAiProcessingDetailsModel] = useState(
    false,
  );

  const formattedDate = currentDate?.toLocaleString();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const newLocation = {
    ...history.location,
    state: undefined,
  };

  const [
    getProjectVideo,
    {
      data: {
        getProjectVideo: {
          data: {
            videoUrl = '',
            kmlUrl = '',
            aiProcessed = false,
            aiVideoUrl = false,
            uploadedFrom,
          } = {},
        } = {},
      } = {},
    },
  ] = useLazyQuery(GET_PROJECT_VIDEO, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        videoId,
      },
    },
  });

  const [createProjectVideoMobileKml] = useMutation(
    CREATE_PROJECT_VIDEO_MOBILE_KML,
    {
      onCompleted: () => {
        getProjectVideo({
          variables: {
            where: {
              videoId,
            },
          },
        });
      },
    },
  );

  const [getDetectionsList, { loading: detectionsLoading }] = useLazyQuery(
    GET_DETECTIONS_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const detectionsListCoordinates = [];
        // eslint-disable-next-line array-callback-return
        res?.detectionList?.data?.map((elem) => {
          if (elem?.elementMissing) {
            detectionsListCoordinates.push({
              lat: elem?.syncData?.location?.coordinates?.[1],
              lng: elem?.syncData?.location?.coordinates?.[0],
              detectionListElem: elem,
            });
          }
          if (elem?.mismatched > 0) {
            detectionsListCoordinates.push({
              lat: elem?.syncData?.location?.coordinates?.[1],
              lng: elem?.syncData?.location?.coordinates?.[0],
              detectionListElem: elem,
            });
          }
        });
        setDetectionListCoords(detectionsListCoordinates);
        setDetectionsList(res?.detectionList?.data);
        setDetectionListCount(Number(res?.detectionList?.count));
        if (state?.tourStart) {
          setOpen(true);
        }
      },
      onError() {},
    },
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (processedWithAI) {
      setShowModal(true);
      dispatch({
        type: 'PROCESSED_WITH_AI',
        data: false,
      });
    }
  }, [state]);

  useEffect(() => {
    getProjectVideo({
      variables: {
        where: {
          videoId,
        },
      },
    });
  }, [videoAIProcessingStatus, switchToAI, detectionListCount]);

  const [getDetectionImageSignedUrl] = useLazyQuery(
    GET_DETECTION_IMAGE_SIGNED_URL,
    {
      onError() {},
      fetchPolicy: 'network-only',
    },
  );
  const [detectionAIJobFunction] = useLazyQuery(DETECTION_AI_JOB, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setVideoAIProcessed(res?.aiJob);
      if (res?.aiJob?.type === 'VIDEO') {
        setVideoAIProcessingStatus(res?.aiJob?.status);
        setAiJobData(res?.aiJob);
      } else if (
        res?.aiJob?.type === 'IMAGE' &&
        res?.aiJob?.status === AI_PROCESSING?.COMPLETED
      ) {
        setImageProcessingAI(false);

        if (res?.aiJob?.metaData?.detectionCreated === 0) {
          message.error('No detection found in the selected frame');
        } else if (res?.aiJob?.metaData?.detectionCreated === 1) {
          setImageNewDetectionCount(res?.aiJob?.metaData?.detectionCreated);
          getDetectionsList({
            variables: {
              where: {
                projectId,
                videoId,
              },
            },
          });
          message.info('One detection found and added into the detection list');
        } else if (res?.aiJob?.metaData?.detectionCreated > 1) {
          setImageNewDetectionCount(res?.aiJob?.metaData?.detectionCreated);
          getDetectionsList({
            variables: {
              where: {
                projectId,
                videoId,
              },
            },
          });
          setImageJobData(res?.aiJob);
          setImageProcessingModel(true);
        }
      }
    },
    onError() {},
  });

  const { loading: syncDataLoading } = useQuery(SYNC_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        videoId,
      },
      filter: {
        search: null,
      },
    },
    onCompleted(res) {
      const array = [];
      setVideoChainage(res?.syncData?.data?.[0]?.chainagePoint);
      dispatch({
        type: 'SET_VIDEO_CHAINAGE_DETAIL',
        data: res?.syncData?.data?.[0],
      });
      // eslint-disable-next-line array-callback-return
      const latLngArray = res?.syncData?.data?.map((elem) => {
        array.push({
          id: elem?.id,
          chainagePoint: elem?.chainagePoint,
          chainage: elem?.chainage,
          lat: elem?.location?.coordinates?.[1],
          lng: elem?.location?.coordinates?.[0],
          kmlTimeStamp: elem?.kmlTimestamp,
          videoTimeStamp: elem?.videoTimestamp,
        });
        return {
          lat: elem?.location?.coordinates?.[1],
          lng: elem?.location?.coordinates?.[0],
        };
      });
      setLatLngData(latLngArray);
      setInitialTableData(array);
    },
    onError() {},
  });

  const [
    createDetectionAIJob,
    { loading: createDetectionAIJobLoading },
  ] = useMutation(CREATE_DETECTION_AI_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      if (imageProcessingAI) {
        detectionAIJobFunction({
          variables: {
            where: {
              instanceId: videoId,
              type: 'IMAGE',
            },
          },
        });
      } else {
        detectionAIJobFunction({
          variables: {
            where: {
              instanceId: videoId,
              type: 'VIDEO',
            },
          },
        });
      }
      setDetectionImage();
    },
  });

  const [terminateDetectionAIJob, { loading }] = useMutation(
    TERMINATED_DETECTION_AI_JOB,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        history?.push(
          `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES.ADD_DETECTION}`,
        );
      },
    },
  );

  useEffect(() => {
    if (initialTableData !== undefined && detectionsList) {
      const tableData = initialTableData;
      const firstDetectionVal = tableData?.find(
        (e) =>
          e?.chainagePoint === detectionsList?.[0]?.syncData?.chainagePoint,
      );
      setFirstDetection(firstDetectionVal);

      if (firstDetection) {
        setSeekTo(firstDetection?.videoTimeStamp);
        setVideoChainage(firstDetection?.chainagePoint);
        dispatch({
          type: 'SET_VIDEO_CHAINAGE_DETAIL',
          data: firstDetection,
        });
      }
    }
  }, [initialTableData, detectionsList]);

  useEffect(() => {
    getDetectionsList({
      variables: {
        where: {
          projectId,
          videoId,
        },
      },
    });
  }, [videoAIProcessingStatus]);

  useEffect(() => {
    detectionAIJobFunction({
      variables: {
        where: {
          instanceId: videoId,
          type: 'VIDEO',
        },
      },
    });
    if (imageProcessingAI) {
      detectionAIJobFunction({
        variables: {
          where: {
            instanceId: videoId,
            type: 'IMAGE',
          },
        },
      });
    }
  }, [aiProcessed]);

  useEffect(() => {
    let id;
    if (imageProcessingAI) {
      id = setInterval(() => {
        detectionAIJobFunction({
          variables: {
            where: {
              instanceId: videoId,
              type: 'IMAGE',
            },
          },
        });
      }, 5000);
    }
    return () => {
      clearInterval(id);
    };
  }, [imageProcessingAI]);

  useEffect(() => {
    let id;
    if (
      videoAIProcessingStatus !== AI_PROCESSING?.COMPLETED &&
      videoAIProcessingStatus !== AI_PROCESSING?.TERMINATED &&
      aiProcessed
    ) {
      id = setInterval(() => {
        detectionAIJobFunction({
          variables: {
            where: {
              instanceId: videoId,
              type: 'VIDEO',
            },
          },
        });
      }, 10000);
    }
    return () => {
      clearInterval(id);
    };
  }, [videoAIProcessingStatus, aiProcessed]);

  const handleOnSeek = (value) => {
    setMainSliderValue(value);
  };

  const scrollConfig = currentStep === 6 || currentStep === 5 ? { y: 220 } : {};

  const steps = [
    {
      title: 'Video File',
      description: 'You can see the video being played here.',
      target: () => ref1?.current,
    },
    {
      title: 'KML File',
      description:
        'If you have uploaded a KML file it can be viewed from here, if KML is not uploaded you would be able to see a map view with starting and ending points',
      target: () => ref2?.current,
    },
    {
      title: 'Slider',
      description:
        'You can play, pause and move the video frames from here. You can pause the video on a particular frame and it will be selected.',
      target: () => ref3?.current,
    },
    {
      title: 'Add Detection',
      description: 'You can add a new detections to this video from here.',
      target: () => ref4?.current,
    },
    {
      title: 'See All',
      description:
        'You will be able to see a combined overview of all the detections that are added to this video.',
      target: () => ref5?.current,
    },
    {
      title: 'Detection Table',
      description:
        'A list of all the detections that are added to this video will be displayed here.',
      target: () => ref6?.current,
    },
    {
      title: 'Synced Points Table',
      description: 'All the points that are synced will be displayed here.',
      target: () => ref6?.current,
    },
    {
      target: () =>
        history?.push({
          pathname: `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/${detectionsList?.[0]?.id}`,
          state: { tourStart: true },
        }),
    },
  ];

  const handleOnProgress = (value) => {
    const playedSeconds = Math?.round(value?.playedSeconds);
    const tableData = initialTableData;
    const latlng = tableData?.find((e) => e?.videoTimeStamp === playedSeconds);
    if (latlng?.chainagePoint) {
      setVideoChainage(latlng?.chainagePoint);
      dispatch({
        type: 'SET_VIDEO_CHAINAGE_DETAIL',
        data: latlng,
      });
    }
    if (value?.playedSeconds === 0 && firstDetection) {
      if (videoChainage) {
        setSeekTo(videoChainage?.videoTimeStamp);
        setMainSliderValue(videoChainage?.videoTimeStamp);
        setLiveMarker({
          lat: Number(videoChaniage?.lat),
          lng: Number(videoChaniage?.lng),
        });
        dispatch({
          type: 'SET_VIDEO_CHAINAGE',
          data: null,
        });
      } else {
        setSeekTo(firstDetection?.videoTimeStamp);
        setMainSliderValue(firstDetection?.videoTimeStamp);
        setLiveMarker({
          lat: Number(firstDetection?.lat),
          lng: Number(firstDetection?.lng),
        });
      }
    } else {
      setMainSliderValue(playedSeconds);
      setLiveMarker({ lat: Number(latlng?.lat), lng: Number(latlng?.lng) });
    }
  };

  const handleSliderChange = (val) => {
    setMainSliderValue(val);
    setSeekTo(val);
    const tableData = initialTableData;
    const latlng = tableData?.find((e) => e?.videoTimeStamp === val);
    if (latlng?.chainagePoint) {
      setVideoChainage(latlng?.chainagePoint);
      dispatch({
        type: 'SET_VIDEO_CHAINAGE_DETAIL',
        data: latlng,
      });
    }
    if (latlng?.lat && latlng?.lng) {
      setLiveMarker({ lat: Number(latlng?.lat), lng: Number(latlng?.lng) });
    }
  };

  const handleNewDetectionUpload = async () => {
    const base64String = detectionImage;
    const binaryString = atob(base64String.split(',')?.[1]);
    const arrayBuffer = new ArrayBuffer(binaryString?.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryString?.length; i++) {
      uint8Array[i] = binaryString?.charCodeAt(i);
    }

    // Create a Blob object from the binary data
    // eslint-disable-next-line no-undef
    const blob = new Blob([arrayBuffer], { type: 'image/png' });

    const imageNanoId = nanoid(8);

    const res = await getDetectionImageSignedUrl({
      variables: {
        data: {
          fileName: `detectionImage${imageNanoId}.jpeg`,
          uid: imageNanoId,
          type: 'SOURCE_IMAGE',
        },
      },
    });
    const response = await getDetectionImageSignedUrl({
      variables: {
        data: {
          fileName: `detectionImage${imageNanoId}.jpeg`,
          uid: imageNanoId,
          type: 'RECOMMENDATION_SOURCE_IMAGE',
        },
      },
    });
    await fileUpload(res?.data?.detectionImageUploadSignedUrl?.signedUrl, blob);
    await fileUpload(
      response?.data?.detectionImageUploadSignedUrl?.signedUrl,
      blob,
    );

    // eslint-disable-next-line no-undef
    localStorage.setItem(DETECTION_IMAGE_NANOID, imageNanoId);
    dispatch({
      type: 'DETECTION_SOURCE_IMAGE_DATA_URL',
      data: detectionImage,
    });
    dispatch({
      type: 'SET_VIDEO_DETECTION_SOURCE_IMAGE_OBJ',
      data: res?.data?.detectionImageUploadSignedUrl,
    });
    dispatch({
      type: 'SET_VIDEO_DETECTION_RECOMMENDATION_SOURCE_IMAGE_OBJ',
      data: response?.data?.detectionImageUploadSignedUrl,
    });
    setAddNewDetectionLoading(false);

    const fileName = res?.data?.detectionImageUploadSignedUrl?.fileName;
    const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);

    if (imageProcessingAI) {
      createDetectionAIJob({
        variables: {
          data: {
            type: 'IMAGE',
            instanceId: videoId,
            syncDataId: currentVideoChainageDetail?.id,
            sourceImage: {
              size: null,
              key: fileName,
              fileName: formattedDate,
              extension,
              contentType: 'image/jpeg',
            },
          },
        },
      });
    } else {
      history?.push(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}${ROUTES?.CREATE}`,
      );
    }
  };

  useEffect(() => {
    if (detectionImage !== undefined) {
      handleNewDetectionUpload();
    }
  }, [detectionImage]);

  const handleRowClick = (record) => {
    const detectionId = record?.id;
    const handleRow = () => {
      history?.push(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/${detectionId}`,
        {
          aiProcessed: record?.aiProcessed,
        },
      );
    };
    return { onClick: handleRow };
  };

  const detectionTableCol = [
    ...(continueWithAI
      ? [
          {
            title: ' ',
            dataIndex: 'aiProcessed',
            key: 'aiProcessed',
            render: (val) => (
              <div className="magic-blue">
                {val && continueWithAI && (
                  <Tooltip title="AI Processed">
                    <MagicPenBlue />
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      title: '#',
      dataIndex: 'aiProcessed',
      key: '#',
      render: (val, _, index) => <div className="magic-blue">{index + 1}</div>,
    },
    {
      title: 'Detection Type',
      key: 'detectionType',
      render: (val) => {
        if (val?.elementMissing) {
          return 'Issue';
        }
        if (val?.mismatched === '0') {
          return 'Information';
        }
        return 'Issue';
      },
    },
    {
      title: 'Chainage (km + m)',
      dataIndex: ['syncData', 'chainagePoint'],
      key: 'chainage',
      render: (val) => `${val}`,
    },
    {
      title: 'Category',
      key: 'category',
      render: (val) => {
        if (val?.category) {
          return val?.category?.name;
        }
        return val?.otherCategory;
      },
    },
    {
      title: 'Type',
      key: 'type',
      render: (val) => {
        if (val?.subCategory) {
          return val?.subCategory?.name;
        }
        return val?.otherSubCategory;
      },
    },
    {
      title: 'Mismatched',
      dataIndex: 'mismatched',
      key: 'mismatched',
    },
    {
      title: 'Added to Report',
      dataIndex: 'addToReport',
      key: 'addToReport',
      render: (val) => {
        if (val === true) {
          return 'Yes';
        }
        return 'No';
      },
    },
    {
      title: 'Risk Level',
      key: 'riskLevel',
      render: (val) => {
        const matchingRiskLevel = riskLevelOptions?.find(
          (item) => item?.value === val?.riskLevel,
        );
        return matchingRiskLevel?.label || '-';
      },
    },
    {
      title: 'Priority',
      key: 'priority',
      render: (val) => {
        const matchingPriority = priorityOptions?.find(
          (item) => item?.value === val?.priority,
        );
        return matchingPriority?.label || '-';
      },
    },
    {
      title: ' ',
      dataIndex: 'action',
      key: 'action',
      render: (_, value) => (
        <Comment
          className="pointer"
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            setOpenDrawer(true);
            setCommentsData(value);
          }}
        />
      ),
    },
  ];

  const rowClassName = (record, index) => {
    if (index < imageNewDetectionCount) {
      return 'highlighted-row pointer'; // CSS class for highlighted rows
    }
    return 'pointer'; // Return empty string for other rows
  };

  const items = [
    {
      key: 1,
      label: 'Detections',
      children: (
        <CommonTable
          onRow={handleRowClick}
          columns={detectionTableCol}
          data={detectionsList || null}
          tableClassName="custom-table"
          loadingData={detectionsLoading || syncDataLoading}
          scroll={scrollConfig}
          rowClassName={rowClassName}
        />
      ),
    },
  ];

  const handleCloseTour = () => {
    setOpen(false);
    setCloseTourVisible(true);
    history.push(newLocation);
    dispatch({
      type: 'SET_TOUR_CLOSE',
      data: true,
    });
  };

  const handleNextPrev = (e) => {
    if (e === 6) {
      setTabChange(1);
    } else {
      setTabChange(0);
    }
    if (currentStep < e) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleImageProcess = async () => {
    setImageProcessingAI(true);
    setImageAIProcessingModel(true);
    setCaptureDetectionImage(true);
  };

  const handleVideoProcessUsingAI = () => {
    setVideoProcessingUsingAIModel(true);
    setVideoAIProcessingStatus(AI_PROCESSING?.IN_PROGRESS);
    createDetectionAIJob({
      variables: {
        data: {
          type: 'VIDEO',
          instanceId: videoId,
        },
      },
    });
  };

  const handleStopAIProcessing = () => {
    setTerminateAIProcessModel(false);
    setVideoAIProcessingStatus(AI_PROCESSING?.TERMINATING);
    terminateDetectionAIJob({
      variables: {
        where: {
          instanceId: videoId,
          jobId: aiJobData?.id,
        },
      },
    });
  };

  const downloadKmlFromMobile = () => {
    createProjectVideoMobileKml({
      variables: {
        where: {
          videoId,
        },
      },
    });
  };

  const handleMouseMove = (e) => {
    const latLng = {
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    };

    setInfoWindowPosition(latLng);
  };

  const centerMapFunc = async () => {
    // eslint-disable-next-line no-undef
    const userLocation = navigator?.geolocation;
    if (userLocation) {
      userLocation?.getCurrentPosition((val) => {
        setCurrentPosition({
          lat: val?.coords?.latitude,
          lng: val?.coords?.longitude,
        });
        return {
          lat: val?.coords?.latitude,
          lng: val?.coords?.longitude,
        };
      });
    }
    return center;
  };

  useEffect(() => {
    setCenterLocation(currentPosition);
  }, [currentPosition]);

  useEffect(() => {
    if (isLoaded) {
      centerMapFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  if (loadError) return 'Error Loading Maps..!';
  return (
    <>
      {!isLoaded ? (
        <LoaderComponent spinning={!isLoaded} />
      ) : (
        <div className="sync-container">
          <Tour
            open={open}
            onClose={() => handleCloseTour()}
            steps={steps}
            current={currentStep}
            onChange={handleNextPrev}
          />
          <div className="d-flex flex-horizontal-reverse ">
            {videoAIProcessed === null ||
            videoAIProcessingStatus === AI_PROCESSING?.FAILED ? (
              <ProjectAccessRole
                allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                currentRole={projectRole}
              >
                <div className="d-flex align-center col-gap-16">
                  {uploadedFrom === UPLOADED_FROM_MOBILE && !kmlUrl && (
                    <Button
                      className="-mt-40"
                      size="small"
                      type="primary"
                      onClick={downloadKmlFromMobile}
                    >
                      Generate KML
                    </Button>
                  )}
                  {uploadedFrom === UPLOADED_FROM_MOBILE && kmlUrl && (
                    <Button
                      className="-mt-40"
                      size="small"
                      type="primary"
                      href={kmlUrl}
                    >
                      Download KML
                    </Button>
                  )}

                  {continueWithAI ? (
                    <Button
                      className="manually-ai-process -mt-40"
                      size="small"
                      onClick={handleVideoProcessUsingAI}
                      loading={createDetectionAIJobLoading}
                    >
                      <MagicPen className="mr-10" />
                      Process Video Using AI
                    </Button>
                  ) : (
                    <Tooltip title="Coming Soon">
                      <Button
                        className="manually-ai-process -mt-40"
                        size="small"
                        onClick={handleVideoProcessUsingAI}
                        loading={createDetectionAIJobLoading}
                        disabled
                      >
                        Process Video Using AI
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </ProjectAccessRole>
            ) : (
              <>
                {continueWithAI && (
                  <AIProcessingStatus
                    status={videoAIProcessingStatus}
                    onViewDetails={() => {
                      if (
                        videoAIProcessingStatus === AI_PROCESSING?.COMPLETED
                      ) {
                        setCompletedAIViewDetailsModel(true);
                      } else if (
                        videoAIProcessingStatus === AI_PROCESSING?.TERMINATED
                      ) {
                        setAiProcessingDetailsModel(true);
                      }
                    }}
                    loading={loading}
                    onStop={() => setTerminateAIProcessModel(true)}
                    completionPercentage={aiJobData?.completion}
                  />
                )}
              </>
            )}
          </div>
          <div className="d-flex main-wrapper">
            <div className="width-percent-50 video-wrapper text-center">
              <div>
                <div
                  className="fill-width d-flex justify-center flex-vertical video-player-container"
                  ref={ref1}
                >
                  <VideoPlayer
                    url={switchToAI ? aiVideoUrl : videoUrl}
                    handleOnSeek={handleOnSeek}
                    handleOnProgress={handleOnProgress}
                    timeToStart={seekTo}
                    setVideoDuration={setVideoDuration}
                    customSlider={false}
                    handleSlider={false}
                    setPlayVideo={setPlayVideo}
                    playVideo={playVideo}
                    setCaptureDetectionImage={setCaptureDetectionImage}
                    setDetectionImage={setDetectionImage}
                    captureDetectionImage={captureDetectionImage}
                    config={{
                      file: { attributes: { crossOrigin: 'anonymous' } },
                    }}
                    onEnded={() => setPlayVideo(false)}
                    setPlay={setPlay}
                  />
                  {continueWithAI && (
                    <ProjectAccessRole
                      allowedRoles={[
                        PROJECT_ROLES?.OWNER,
                        PROJECT_ROLES?.EDITOR,
                      ]}
                      currentRole={projectRole}
                    >
                      <div className="ai-processing-div">
                        {imageProcessingAI ? (
                          <div className="image-processing-status">
                            <div className="image-status-in-progress">
                              <MagicPenYellow /> Processing In Progress
                            </div>
                          </div>
                        ) : (
                          <div
                            className="magic-pen pointer"
                            onClick={handleImageProcess}
                          >
                            <MagicPen />
                          </div>
                        )}
                        {aiVideoUrl && (
                          <Switch onClick={(e) => setSwitchToAI(e)} />
                        )}
                      </div>
                    </ProjectAccessRole>
                  )}
                </div>
              </div>
            </div>
            <div className="width-percent-50 map-wrapper text-center">
              <div
                className="fill-width d-flex justify-center flex-vertical detection-map"
                ref={ref2}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={liveMarker || centerLocation}
                  onLoad={(mapRefCopy) => {
                    setMapRef(mapRefCopy);
                  }}
                  onZoomChanged={() => {
                    if (mapRef) {
                      setMapZoom(mapRef?.getZoom());
                    }
                  }}
                  zoom={mapZoom}
                >
                  {isLoaded && latLngData && (
                    <>
                      <Polyline
                        path={latLngData}
                        key={uniqueId('synced')}
                        options={{
                          strokeColor: 'blue',
                          strokeOpacity: 0.8,
                          strokeWeight: 4,
                        }}
                        onClick={(e) => handleMouseMove(e)}
                      />
                      <Marker position={liveMarker} />
                    </>
                  )}
                  {detectionListCoords?.length > 0 &&
                    // eslint-disable-next-line no-console
                    detectionListCoords?.map(
                      // eslint-disable-next-line no-console
                      (detection) => (
                        <Marker
                          key={uniqueId('detectionListCoords')}
                          position={detection}
                          icon={{
                            url: DetectionMarker,
                          }}
                        />
                      ),
                    )}
                  {infoWindowPosition && (
                    <div className="info-model">
                      <InfoWindow
                        position={infoWindowPosition}
                        onCloseClick={() => setInfoWindowPosition(null)}
                      >
                        <div>
                          Latitude: {infoWindowPosition?.lat?.toFixed(6)}
                          <br />
                          Longitude: {infoWindowPosition?.lng?.toFixed(6)}
                        </div>
                      </InfoWindow>
                    </div>
                  )}
                </GoogleMap>
              </div>
            </div>
          </div>
          <div className="detection-slider" ref={ref3}>
            {playVideo || play ? (
              <PauseOutlined
                className="pause-icon"
                onClick={() => setPlayVideo(!playVideo)}
              />
            ) : (
              <PlayVideo
                onClick={() => {
                  setPlayVideo(!playVideo);
                }}
                className="pointer play-icon"
              />
            )}
            <Slider
              className="slider"
              step={SLIDER_MARK}
              defaultValue={0}
              value={mainSliderValue}
              onChange={handleSliderChange}
              max={videoDuration}
              tooltip={{ formatter: () => videoChaniage }}
            />
          </div>
          <div className="sync-table-wrapper" ref={ref4}>
            <div className="button-wraper">
              {tabChange === 0 && (
                <>
                  <Button
                    className="pointer"
                    size="small"
                    ref={ref5}
                    onClick={() => {
                      history?.push(
                        `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.SEE_ALL}`,
                      );
                    }}
                  >
                    See All
                  </Button>
                  <ProjectAccessRole
                    allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                    currentRole={projectRole}
                  >
                    <Button
                      className="pointer"
                      size="small"
                      ref={ref4}
                      type="primary"
                      disabled={imageProcessingAI}
                      onClick={() => {
                        setCaptureDetectionImage(true);
                        setAddNewDetectionLoading(true);
                      }}
                      loading={addNewDetectionLoading}
                    >
                      <Plus className="mr-6" />
                      <span>Add</span>
                    </Button>
                  </ProjectAccessRole>
                </>
              )}
            </div>
            <div ref={ref6}>
              <Tabs
                className="mb-0"
                defaultActiveKey="1"
                type="card"
                size="large"
                items={items}
              />
            </div>
          </div>
          {openDrawer && (
            <CommentDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              detectionId={commentsData?.id}
            />
          )}
          {closeTourVisible && (
            <CommonConfirmationModal
              visible={closeTourVisible}
              onCancel={() => {
                setCloseTourVisible(false);
                setOpen(true);
              }}
              onOk={() => {
                setCloseTourVisible(false);
                setCurrentStep(null);
                setOpen(false);
                history.push(newLocation);
              }}
              title="Demo Project Tour"
              content="Are you sure you want to close the tour for demo project?"
              okText="Close"
              cancelText="Cancel"
            />
          )}
          <CommonConfirmationModal
            visible={imageAIProcessingModel}
            cancelButtonProps={{ className: 'd-none' }}
            onOk={() => {
              setImageAIProcessingModel(false);
            }}
            title="Image Processing Using AI"
            content="We have initiated image processing on the selected frame, and it may take some time. We kindly request your patience while we complete the processing."
            okText="Okay"
          />

          <CommonConfirmationModal
            visible={videoProcessingUsingAIModel || showModal}
            cancelButtonProps={{ className: 'd-none' }}
            onOk={() => {
              setVideoProcessingUsingAIModel(false);
              setShowModal(false);
            }}
            title="Video Processing Using AI"
            content="The video is currently undergoing processing by artificial intelligence, which may require some time. Kindly wait patiently."
            okText="Okay"
          />
          <CommonConfirmationModal
            visible={terminateAIProcessModel}
            onOk={() => {
              handleStopAIProcessing();
            }}
            onCancel={() => {
              setTerminateAIProcessModel(false);
            }}
            title="Terminate AI Processing"
            content="Are you sure you want to terminate AI processing for this video?"
            okText="Confirm"
            cancelText="Discard"
          />
          <CommonConfirmationModal
            visible={imageProcessingModel}
            onOk={() => {
              setImageProcessingModel(false);
            }}
            cancelButtonProps={{ className: 'd-none' }}
            okText="Okay"
            title={
              <div className="view-details-ai-process">
                <MagicPenBlue />
                <span className="ai-processing-title-completed ml-4">
                  AI Processing Details
                </span>
              </div>
            }
            contentBlock={
              <div className="ai-process-completed">
                <Typography className="description">
                  The AI processing has been completed successfully, and the
                  summary is provided below.
                </Typography>
                <Typography className="description mt-16">
                  Processing Initiated:
                  <span className="progress-processing-complete ml-4">
                    {convertDataTimeIntoNormalFormat(imageJobData?.createdAt)}
                  </span>
                </Typography>
                <Typography className="description mt-10">
                  Processing Completed:
                  <span className="progress-processing-complete ml-4">
                    {convertDataTimeIntoNormalFormat(imageJobData?.endTime)}
                  </span>
                </Typography>

                <Typography className="description mt-10">
                  Number of Detections Identified:
                  <span className="progress-processing-complete ml-4">
                    {imageJobData?.metaData?.detectionCreated}
                  </span>
                </Typography>
              </div>
            }
          />
          <CommonConfirmationModal
            visible={aiProcessingDetailsModel || completedAIViewDetailsModel}
            title={
              aiProcessingDetailsModel ? (
                <div className="view-details-ai-process">
                  <MagicPenRed />
                  <span className="ai-processing-title-terminated ml-4">
                    AI Processing Terminated
                  </span>
                </div>
              ) : (
                <div className="view-details-ai-process">
                  <MagicPenBlue />
                  <span className="ai-processing-title-completed ml-4">
                    AI Processing Details
                  </span>
                </div>
              )
            }
            onOk={() => {
              setAiProcessingDetailsModel(false);
              setCompletedAIViewDetailsModel(false);
            }}
            okText="Okay"
            cancelButtonProps={{ className: 'd-none' }}
            className="account-delete-modal"
            contentBlock={
              aiProcessingDetailsModel ? (
                <div className="ai-process-terminated">
                  <Typography className="description">
                    Video processing was interrupted midway; a portion of the
                    video had already been processed, and the relevant details
                    are provided below.
                  </Typography>
                  <Typography className="description mt-16">
                    Processing Initiated:
                    <span className="progress-processing ml-4">
                      {convertDataTimeIntoNormalFormat(aiJobData?.createdAt)}
                    </span>
                  </Typography>
                  <Typography className="description mt-10">
                    Processing Terminated:
                    <span className="progress-processing ml-4">
                      {convertDataTimeIntoNormalFormat(aiJobData?.endTime)}
                    </span>
                  </Typography>
                  <Typography className="description mt-16">
                    Video Processed:
                    <span className="progress-processing ml-4">
                      {`${Math.round(Number(aiJobData?.completion))}`}%
                    </span>
                  </Typography>
                  <Typography className="description mt-10">
                    Number of Frames Processed:
                    <span className="progress-processing ml-4">
                      {aiJobData?.metaData?.frameProcessed}
                    </span>
                  </Typography>
                  <Typography className="description mt-10">
                    Number of Detections Identified:
                    <span className="progress-processing ml-4">
                      {aiJobData?.metaData?.detectionCreated}
                    </span>
                  </Typography>
                </div>
              ) : (
                <div className="ai-process-completed">
                  <Typography className="description">
                    The AI processing has been completed successfully, and the
                    summary is provided below.
                  </Typography>
                  <Typography className="description mt-16">
                    Processing Initiated:
                    <span className="progress-processing-complete ml-4">
                      {convertDataTimeIntoNormalFormat(aiJobData?.createdAt)}
                    </span>
                  </Typography>
                  <Typography className="description mt-10">
                    Processing Completed:
                    <span className="progress-processing-complete ml-4">
                      {convertDataTimeIntoNormalFormat(aiJobData?.endTime)}
                    </span>
                  </Typography>

                  <Typography className="description mt-10">
                    Number of Detections Identified:
                    <span className="progress-processing-complete ml-4">
                      {aiJobData?.metaData?.detectionCreated}
                    </span>
                  </Typography>
                </div>
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default AddDetection;
