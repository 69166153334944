import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import CommonConfirmationModal from '../../../components/CommonConfirmationModal';
import {
  CREATE_DETECTION_PROPERTY,
  UPDATE_DETECTION_PROPERTY,
} from '../graphql/Mutations';
import { priorityOptions, riskLevelOptions } from '../../../common/constants';

const { Option } = Select;
const { TextArea } = Input;

const DetailModal = ({
  isEdit,
  setIsEdit,
  updateData,
  setUpdateData,
  visible,
  setVisible,
  setDetectionPropertyLoading,
}) => {
  const [form] = Form?.useForm();
  const riskLevelValue = Form?.useWatch(['riskLevel'], form);
  const priorityValue = Form?.useWatch(['priority'], form);
  const otherRecommendationValue = Form?.useWatch(
    ['otherRecommendation'],
    form,
  );
  const { detectionId } = useParams();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [otherRecommendation, setOtherRecommendation] = useState(['']);

  const [createDetectionProperty] = useMutation(CREATE_DETECTION_PROPERTY, {
    onCompleted() {
      setVisible(false);
      setDetectionPropertyLoading(true);
      setCreateLoading(false);
    },
    onError() {
      setCreateLoading(false);
    },
  });

  const [updateDetectionProperty] = useMutation(UPDATE_DETECTION_PROPERTY, {
    onCompleted() {
      setVisible(false);
      setDetectionPropertyLoading(true);
      setCreateLoading(false);
    },
    onError() {
      setCreateLoading(false);
    },
  });

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        property: 'NotApplicable',
      });
    }
  }, [visible]);

  useEffect(() => {
    if (updateData) {
      const validRecommendations = (updateData?.recommendations || [])
        .filter((item) => item?.otherRecommendation !== null)
        .map((item) => ({
          otherRecommendationId: item?.id,
          otherRecommendationText: item?.otherRecommendation,
        }));

      setOtherRecommendation([...validRecommendations, '']);

      form.setFieldsValue({
        property: 'NotApplicable',
        riskLevel: updateData?.riskLevel,
        priority: updateData?.priority,
        otherRecommendation: updateData?.recommendations
          ?.map(
            (item) =>
              item?.otherRecommendation !== null && item?.otherRecommendation,
          )
          .filter((item) => item),
      });
    }
  }, [isEdit, updateData]);

  const addOtherRecommendation = () => {
    const lastRecommendation =
      otherRecommendation[otherRecommendation.length - 1];
    if (lastRecommendation !== '') {
      setOtherRecommendation([...otherRecommendation, '']);
    }
  };

  const onFinish = (values) => {
    setCreateLoading(true);
    if (isEdit) {
      const otherRecommendations = otherRecommendation?.filter((item) => {
        if (item?.otherRecommendationText) {
          return values?.otherRecommendation?.includes(
            item?.otherRecommendationText,
          );
        }
        return values?.otherRecommendation?.includes(item);
      });
      updateDetectionProperty({
        variables: {
          where: {
            detectionPropertyId: updateData?.id,
          },
          data: {
            otherRecommendations,
            priority: values?.priority,
            riskLevel: values?.riskLevel,
          },
        },
      });
    } else {
      createDetectionProperty({
        variables: {
          data: {
            detectionId,
            riskLevel: values?.riskLevel,
            priority: values?.priority,
            otherRecommendations: values?.otherRecommendation,
          },
        },
      });
    }
  };

  useEffect(() => {
    const hasEmptyStringRecommendation = otherRecommendationValue?.some(
      (recommendation) => recommendation?.otherRecommendationText === '',
    );
    const hasEmptyRecommendation = otherRecommendationValue?.some(
      (recommendation) =>
        typeof recommendation === 'string' && recommendation?.trim() === '',
    );
    if (
      riskLevelValue &&
      priorityValue &&
      otherRecommendationValue?.length > 0 &&
      otherRecommendationValue[0] &&
      !hasEmptyStringRecommendation &&
      !hasEmptyRecommendation
    ) {
      setIsSaveButtonDisabled(false);
    } else {
      setIsSaveButtonDisabled(true);
    }
  }, [riskLevelValue, priorityValue, otherRecommendationValue]);

  return (
    <Modal
      destroyOnClose
      className="modal-components-design property-modal"
      title={isEdit ? 'Edit Details' : 'Add Details'}
      open={visible}
      onCancel={() => {
        setCancelVisible(true);
      }}
      footer={null}
      centered
    >
      <CommonConfirmationModal
        visible={cancelVisible}
        onCancel={() => {
          setVisible(false);
          setIsEdit(false);
          setUpdateData();
          setCancelVisible(false);
        }}
        onOk={() => {
          setCancelVisible(false);
        }}
        title="Unsaved Changes"
        content="If you close, you will lose any changes that you have made."
        okText="Keep Editing"
        cancelText="Discard"
      />
      <Form
        name="videoModal"
        onFinish={onFinish}
        className="custom-label"
        form={form}
        layout="vertical"
        validateTrigger
      >
        <div className="form-overflow">
          <Form.Item
            label="Property"
            name="property"
            rules={[
              {
                required: true,
                message: 'Please select property',
              },
            ]}
          >
            <Select placeholder="Select property" disabled>
              <Option key="NotApplicable" value="NotApplicable">
                Not Applicable
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Risk Level"
            name="riskLevel"
            rules={[
              {
                required: true,
                message: 'Please select risk level',
              },
            ]}
          >
            <Select
              placeholder="Select risk level"
              options={riskLevelOptions}
            />
          </Form.Item>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[
              {
                required: true,
                message: 'Please select priority',
              },
            ]}
          >
            <Select placeholder="Select priority" options={priorityOptions} />
          </Form.Item>
          <Form.Item
            label="Recommendations"
            name="otherRecommendation"
            rules={[
              {
                required: true,
                message: 'Please select atleast one checkbox',
              },
            ]}
          >
            <Checkbox.Group>
              {otherRecommendation.map((recommendation, index) => (
                <Checkbox
                  key={`${recommendation?.otherRecommendationId}-index`}
                  onClick={addOtherRecommendation}
                  value={
                    recommendation?.otherRecommendationText
                      ? recommendation?.otherRecommendationText
                      : recommendation
                  }
                >
                  <TextArea
                    value={recommendation?.otherRecommendationText}
                    onChange={(e) => {
                      const updatedRecommendations = [...otherRecommendation];
                      if (
                        updatedRecommendations?.[index]?.otherRecommendationText
                      ) {
                        updatedRecommendations[index].otherRecommendationText =
                          e?.target?.value;
                        setOtherRecommendation(updatedRecommendations);
                      } else {
                        updatedRecommendations[index] = {
                          otherRecommendationText: e?.target?.value,
                        };
                        setOtherRecommendation(updatedRecommendations);
                      }
                    }}
                    maxLength={200}
                    placeholder="Enter recommendation"
                    autoSize={{ minRows: 1, maxRows: 5 }}
                  />
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
        <Form.Item shouldUpdate>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={isSaveButtonDisabled}
            loading={createLoading}
            block
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DetailModal;
