import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      message
      data {
        fullName
        profileImageUrl
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation removeUser($where: RemoveUserInput!) {
    removeUser(where: $where) {
      message
    }
  }
`;
