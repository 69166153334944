import { Layout } from 'antd';
import React from 'react';

const { Header } = Layout;

const AppHeader = (props) => {
  const { children } = props;

  return (
    <div>
      <Header className="site-layout-sub-header-background app-header">
        {children}
      </Header>
    </div>
  );
};
export default AppHeader;
