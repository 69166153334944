import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Member from './Member';

const MemberWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.MEMBERS} component={Member} />
  </Switch>
);

export default MemberWrapper;
