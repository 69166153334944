import React, { useEffect, useState } from 'react';

const CommentTimeAgo = ({ timestamp, className }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const updateTimeAgo = () => {
      const currentTime = new Date();
      const editedTime = new Date(timestamp);

      const timeDifference = currentTime - editedTime;

      if (timeDifference < 60000) {
        setTimeAgo('now');
      } else if (timeDifference < 3600000) {
        const minutesAgo = Math.floor(timeDifference / 60000);
        setTimeAgo(`${minutesAgo} min ago`);
      } else if (timeDifference < 86400000) {
        const hoursAgo = Math.floor(timeDifference / 3600000);
        setTimeAgo(`${hoursAgo} h ago`);
      } else if (timeDifference < 2592000000) {
        const daysAgo = Math.floor(timeDifference / 86400000);
        setTimeAgo(`${daysAgo} d ago`);
      } else {
        const monthsAgo = Math.floor(timeDifference / 2592000000);
        setTimeAgo(`${monthsAgo} month ago`);
      }
    };

    updateTimeAgo();

    // Update the time ago text every minute to keep it up to date
    const interval = setInterval(updateTimeAgo, 60000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return <span className={className}>{timeAgo}</span>;
};

export default CommentTimeAgo;
