import { Button, Form, Input, Modal } from 'antd';
import React from 'react';

const DetectionCategoryOtherModal = ({
  visible,
  setVisible,
  setDetectionCategoryOther,
}) => {
  const [form] = Form?.useForm();
  const onFinish = (value) => {
    setDetectionCategoryOther(value?.category);
    setVisible(false);
  };
  return (
    <Modal
      destroyOnClose
      className="modal-components-design"
      title="Category - Other"
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
      centered
    >
      <Form
        name="videoModal"
        onFinish={onFinish}
        className="custom-label"
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Category"
          name="category"
          rules={[
            {
              required: true,
              message: 'Please enter category',
            },
          ]}
        >
          <Input placeholder="Enter category here" maxLength={100} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ isFieldsTouched, getFieldsError }) => (
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              block
              disabled={
                !isFieldsTouched(['category'], true) ||
                !!getFieldsError()?.filter(({ errors }) => errors?.length)
                  .length
              }
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DetectionCategoryOtherModal;
