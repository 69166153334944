import React from 'react';
import { Col, Input, Row } from 'antd';
import { PlusOutlined } from '../../assets/svg';

const CustomInput = ({ value, onChange, status, disabled }) => {
  const handleChange = (val, name) => {
    onChange({
      ...value,
      [name]: val?.target?.value,
    });
  };

  return (
    <div>
      <Row gutter={4}>
        <Col span={10}>
          <Input
            placeholder="km"
            name="km"
            value={value?.km}
            onChange={(val) => {
              handleChange(val, 'km');
            }}
            onKeyPress={(e) => {
              const charCode = e?.charCode;
              if (
                (charCode < 48 || charCode > 57) && // Digits
                charCode !== 8 // Backspace
              ) {
                e?.preventDefault();
              }
            }}
            disabled={status || disabled}
          />
        </Col>
        <div className="d-flex align-center">
          <PlusOutlined />
        </div>

        <Col span={10}>
          <Input
            placeholder="m"
            name="m"
            value={value?.m}
            onChange={(val) => {
              handleChange(val, 'm');
            }}
            onKeyPress={(e) => {
              const charCode = e?.charCode;
              if (
                (charCode < 48 || charCode > 57) && // Digits
                charCode !== 8 // Backspace
              ) {
                e?.preventDefault();
              }
            }}
            disabled={status || disabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomInput;
