import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation, Link, useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import {
  GET_PROJECT_NAME,
  GET_PROJECT_VIDEO_NAME,
} from '../app/graphql/Queries';
import { VIDEO_ID_CHECK } from '../common/constants';

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname?.split('/')?.filter((item) => item);
  const { projectId, videoId } = useParams();
  const [projectName, setProjectName] = useState();
  const [videoName, setVideoName] = useState();

  const [getProjectName, { loading: projectNameLoading }] = useLazyQuery(
    GET_PROJECT_NAME,
    {
      onCompleted(res) {
        setProjectName(res?.getProject?.data?.name);
      },
      onError() {},
    },
  );

  const [getVideoName, { loading: videoNameLoading }] = useLazyQuery(
    GET_PROJECT_VIDEO_NAME,
    {
      onCompleted(res) {
        setVideoName(res?.getProjectVideo?.data?.name);
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (projectId !== undefined) {
      getProjectName({
        variables: {
          where: {
            projectId,
          },
        },
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (videoId !== undefined && !VIDEO_ID_CHECK?.includes(videoId)) {
      getVideoName({
        variables: {
          where: {
            videoId,
          },
        },
      });
    }
  }, [videoId]);

  const isLoading = projectNameLoading || videoNameLoading;

  const path = pathnames?.map((elem, index) => {
    const routesTo = `/${pathnames?.slice(0, index + 1)?.join('/')}`;
    const isLast = index === pathnames.length - 1;

    if (isLast) {
      if (index === 1 && projectId) {
        const title = projectName || elem;
        return { title: title?.charAt?.(0)?.toUpperCase() + title?.slice(1) };
      }
      // Convert each word in the path to title case
      const title = elem
        ?.split('-')
        ?.map((word) => word?.charAt?.(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');

      return { title };
    }
    if (index === 1 && projectId) {
      const title = projectName || elem;
      return {
        title: <Link to={routesTo}>{title}</Link>,
      };
    }
    if (index === 2 && videoId) {
      const title = videoName || elem;
      return {
        title: title?.charAt?.(0)?.toUpperCase() + title?.slice(1),
      };
    }
    if (elem === 'detection-detail' || elem === 'edit' || elem === 'create') {
      return { title: elem?.charAt?.(0)?.toUpperCase() + elem?.slice(1) };
    }
    if (elem === 'add-detection') {
      const title = elem
        ?.split('-')
        ?.map((word) => word?.charAt?.(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');

      return {
        title: <Link to={routesTo}>{title}</Link>,
      };
    }
    return {
      title: (
        <Link to={routesTo}>
          {elem?.charAt?.(0)?.toUpperCase() + elem?.slice(1)}
        </Link>
      ),
    };
  });

  return (
    <div className="mb-12 mt-8 breadcrumb">
      {isLoading ? <div>Loading...</div> : <Breadcrumb items={path} />}
    </div>
  );
};

export default BreadCrumb;
