import {
  Button,
  Col,
  Dropdown,
  Input,
  Row,
  Space,
  Tour,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { InView } from 'react-intersection-observer';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AssignMembersModal from './AssignMembersModal';
import {
  AddUserIconGreen,
  EditPencil,
  GraphUp,
  MagicBlueOptionDisabled,
  MagicPenBlue,
  MagicPenBlueOptions,
  MagicPenCancelOptions,
  MagicPenGreen,
  MagicPenYellow,
  Plus,
  ReportsIcon,
  ReprocessAI,
  SyncDeleteIcon,
  SyncEditIcon,
  TaskList,
} from '../../assets/svg';
import AddNewProject from './AddNewProject';
import {
  PROJECT_ROLES,
  ROUTES,
  STATUS_SYNCED,
  AI_PROCESSING,
  AI_PROCESS,
  LIMIT,
  DIRECTION,
  STATUS_PENDING,
  STATUS_IN_PROGRESS,
} from '../../common/constants';
import UploadVideoModel from '../../components/videoModel/UploadVideoModel';
import { AppContext } from '../../AppContext';
import TableComponent from '../../components/CommonTable';
import { formatChainage, formatDateTime } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { GET_PROJECT_DETAILS, GET_PROJECT_VIDEOS } from './graphql/Queries';
import {
  CREATE_DETECTION_AI_JOB,
  DELETE_PROJECT_VIDEO,
  TERMINATED_DETECTION_AI_JOB,
} from './graphql/Mutations';
import ProjectAccessRole from '../../components/ProjectAccessRole';

const Action = ({
  aiJobStatus,
  videoId,
  status,
  setIsEdit,
  setEditVideoId,
  setAddVideoModel,
  projectRole,
  setSyncedStatus,
  aiJobId,
  handleDeleteVideo,
  handleRefetch,
}) => {
  const [visible, setVisible] = useState(false);
  const { projectId } = useParams();
  const [terminateAiProcessingModel, setTerminateAiProcessingModel] = useState(
    false,
  );
  const [reProcessAiModel, setReProcessAiModel] = useState(false);
  const [aiProcessedModel, setAiProcessedModel] = useState(false);
  const [beforeAiProcessingModel, setBeforeAiProcessingModel] = useState(false);
  const history = useHistory();
  const { getCurrentUser } = useContext(AppContext);
  const { workspaceFeatures } = getCurrentUser() || {};
  const continueWithAI = workspaceFeatures?.includes(AI_PROCESS);

  const [terminateDetectionAIJobInstance] = useMutation(
    TERMINATED_DETECTION_AI_JOB,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setTerminateAiProcessingModel(false);
        handleRefetch(projectId);
      },
    },
  );

  const [createDetectionAIJob] = useMutation(CREATE_DETECTION_AI_JOB, {
    fetchPolicy: 'network-only',
    onCompleted() {
      setReProcessAiModel(false);
      setAiProcessedModel(false);
      handleRefetch(projectId);
    },
  });

  const editVideo = async () => {
    if (status === STATUS_SYNCED) {
      setSyncedStatus(true);
    }
    setAddVideoModel(true);
    setIsEdit(true);
    setEditVideoId(videoId);
  };

  const deleteVideo = async () => {
    setVisible(true);
  };

  return (
    <>
      <Space>
        <ProjectAccessRole
          allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
          currentRole={projectRole}
        >
          <Space>
            <Tooltip title="Edit Video">
              <SyncEditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  editVideo();
                }}
                className="pointer"
              />
            </Tooltip>
            <Tooltip title="Delete Video">
              <SyncDeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  deleteVideo();
                }}
                className="pointer"
              />
            </Tooltip>
            {continueWithAI ? (
              <>
                {aiJobStatus === AI_PROCESSING?.COMPLETED ? (
                  <Tooltip title="Reprocess Video Using AI">
                    <ReprocessAI
                      onClick={(e) => {
                        e.stopPropagation();
                        setReProcessAiModel(true);
                      }}
                      className="pointer"
                    />
                  </Tooltip>
                ) : (
                  <>
                    {aiJobStatus === AI_PROCESSING?.IN_PROGRESS ? (
                      <Tooltip title="Terminate AI Processing">
                        <MagicPenCancelOptions
                          onClick={(e) => {
                            e.stopPropagation();
                            setTerminateAiProcessingModel(true);
                          }}
                          className="pointer"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Process With AI">
                        <MagicPenBlueOptions
                          onClick={(e) => {
                            e.stopPropagation();
                            if (status === STATUS_SYNCED) {
                              setAiProcessedModel(true);
                            } else {
                              setBeforeAiProcessingModel(true);
                            }
                          }}
                          className="pointer"
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            ) : (
              <Tooltip title="Coming Soon">
                <MagicBlueOptionDisabled className="pointer" />
              </Tooltip>
            )}
          </Space>
        </ProjectAccessRole>
      </Space>

      <CommonConfirmationModal
        visible={terminateAiProcessingModel || reProcessAiModel}
        onOk={() => {
          if (terminateAiProcessingModel) {
            terminateDetectionAIJobInstance({
              variables: {
                where: {
                  instanceId: videoId,
                  jobId: aiJobId,
                },
              },
            });
          } else if (reProcessAiModel) {
            createDetectionAIJob({
              variables: {
                data: {
                  type: 'VIDEO',
                  instanceId: videoId,
                },
              },
            });
          }
        }}
        title={
          terminateAiProcessingModel
            ? 'Terminate AI Processing'
            : 'Reprocess Video Using AI'
        }
        content={
          terminateAiProcessingModel
            ? 'Are you sure you want to terminate AI processing for this video?'
            : 'Processing the video again could result in the duplication of detections. Are you sure you want to reprocess the video?'
        }
        okText="Confirm"
        cancelText="Discard"
        onCancel={(e) => {
          e.preventDefault();
          setTerminateAiProcessingModel(false);
          setReProcessAiModel(false);
        }}
      />

      <CommonConfirmationModal
        visible={aiProcessedModel || beforeAiProcessingModel}
        onCancel={(e) => {
          e.preventDefault();
          setAiProcessedModel(false);
        }}
        onOk={(e) => {
          e.preventDefault();
          if (aiProcessedModel) {
            createDetectionAIJob({
              variables: {
                data: {
                  type: 'VIDEO',
                  instanceId: videoId,
                },
              },
            });
          } else if (beforeAiProcessingModel) {
            setBeforeAiProcessingModel(false);
            history?.push(
              `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.SYNCHRONIZATION}`,
            );
          }
        }}
        title={aiProcessedModel ? 'AI Video Processing' : 'Video Not Synced'}
        content={
          aiProcessedModel
            ? 'Are you sure you want to continue AI processing for this video?'
            : 'AI processing cannot be initiated as the video is not yet synced. To continue with the AI processing, The synchronization process must completed first.'
        }
        okText={beforeAiProcessingModel ? 'Okay' : 'Confirm'}
        cancelText="Discard"
        cancelButtonProps={{
          className: beforeAiProcessingModel ? 'd-none' : '',
        }}
      />

      <CommonConfirmationModal
        visible={visible}
        onCancel={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        onOk={async () => {
          try {
            setVisible(false);
            await handleDeleteVideo(projectId, videoId);
          } catch (error) {
            //
          }
        }}
        title="Delete Video"
        content="By deleting this video you will loose all data points. Are you sure you want to delete?"
        okText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

const InsideProject = () => {
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const { workspaceFeatures } = getCurrentUser() || {};
  const continueWithAI = workspaceFeatures?.includes(AI_PROCESS);
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const [loading, setLoading] = useState(false);
  const [getProjectVideos, setGetProjectVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [projectEditModel, setProjectEditModel] = useState(false);
  const [projectEdit, setProjectEdit] = useState(false);
  const [addVideoModel, setAddVideoModel] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [assignMembersModel, setAssignMembersModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editVideoId, setEditVideoId] = useState(false);
  const { projectId } = useParams();
  const [syncedStatus, setSyncedStatus] = useState(false);
  const searchInput = useRef(null);
  const [closeTourVisible, setCloseTourVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const newLocation = {
    ...history.location,
    state: undefined,
  };

  const {
    data: project,
    loading: projectLoading,
    refetch: projectRefetch,
  } = useQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        projectId,
      },
    },
    onCompleted(res) {
      if (state?.tourStart) {
        setOpen(true);
      }
      dispatch({
        type: 'SET_PROJECT_ROLE',
        data: res?.getProject?.data?.userRole,
      });
    },
  });

  const [deleteProjectVideo] = useMutation(DELETE_PROJECT_VIDEO);

  const [getProjectVideo, { loading: videosLoading, refetch }] = useLazyQuery(
    GET_PROJECT_VIDEOS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setLoading(false);
        setGetProjectVideos([
          ...getProjectVideos,
          ...res?.getProjectVideos?.data,
        ]);
        if (res?.getProjectVideos?.data?.length < LIMIT) {
          setHasMore(false);
        }
      },
      onError() {},
    },
  );

  const steps = [
    {
      title: 'Edit Project Details',
      description: 'You can edit the details of this project by clicking here.',
      target: () => ref1?.current,
    },
    {
      title: 'Assign Members',
      description:
        'You can assign new members and view existing members from here.',
      target: () => ref2?.current,
    },
    {
      title: 'Add New Video',
      description: 'You can add a new video to this project from here.',
      target: () => ref3?.current,
    },
    {
      title: 'Video Listing',
      description:
        'All the videos that are added to this project will be displayed here in a tabluar format.',
      target: () => ref4?.current,
    },
    {
      title: 'Add Analysis',
      description:
        'You can add traffic and accident analysis to this project from here.',
      target: () => ref5?.current,
    },
    {
      title: 'Consolidated View',
      description:
        'You can see a combined overview of all the detections from every video within this specific project.',
      target: () => ref6?.current,
    },
    {
      title: 'Reports',
      description: 'You can download project reports from here.',
      target: () => ref7?.current,
    },
    {
      target: () =>
        history?.push({
          pathname: `${ROUTES?.PROJECTS}/${projectId}/${getProjectVideos?.[0]?.id}${ROUTES?.ADD_DETECTION}`,
          state: { tourStart: true },
        }),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getProjectVideo({
      variables: {
        where: {
          projectId,
        },
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, []);

  const handleSearch = (selectedKeys, confirm) => {
    setLoading(true);
    setGetProjectVideos([]);
    const onSearchValue = selectedKeys?.trim();
    confirm();
    // eslint-disable-next-line no-unused-expressions
    selectedKeys?.length > 0
      ? refetch({ filter: { search: onSearchValue } })
      : refetch({
          filter: {
            search: '',
          },
        });

    return null;
  };

  const handleReset = (clearFilters, confirm) => {
    setGetProjectVideos([]);
    clearFilters();
    confirm();
    refetch({
      filter: {
        search: '',
      },
    });
  };

  const changeOnEditProject = async () => {
    projectRefetch();
  };

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        className="video-name-search"
        onKeyDown={(e) => e?.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder="Search name"
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e?.target?.value)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            className="secondary-btn border-btn"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <SearchOutlined />,
  });

  const handleModel = () => {
    setAddVideoModel(true);
    setIsEdit(false);
  };

  const handleCloseTour = () => {
    setOpen(false);
    setCloseTourVisible(true);
    history.push(newLocation);
    dispatch({
      type: 'SET_TOUR_CLOSE',
      data: true,
    });
  };

  const handleNextPrev = (e) => {
    if (currentStep < e) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const formattedCount = getProjectVideos?.length?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const handleRowClick = (record) => {
    const detectionId = record?.id;
    const status = record?.status;
    const handleRow = (clickedColumnIndex) => {
      if (clickedColumnIndex !== undefined) {
        if (status === STATUS_SYNCED) {
          history?.push(
            `${ROUTES?.PROJECTS}/${projectId}/${detectionId}${ROUTES?.ADD_DETECTION}`,
          );
        } else if (status === STATUS_PENDING) {
          message.info(`Video upload is currently ${status?.toLowerCase()}`);
        } else if (status === STATUS_IN_PROGRESS) {
          message.info(`Video upload is currently in progress`);
        } else {
          history?.push(
            `${ROUTES?.PROJECTS}/${projectId}/${detectionId}${ROUTES?.SYNCHRONIZATION}`,
          );
        }
      }
    };
    return {
      onClick: (event) => {
        const clickedColumnIndex = event.target.cellIndex;
        handleRow(clickedColumnIndex);
      },
    };
  };

  const handleScroll = () => {
    setLoading(true);
    getProjectVideo({
      variables: {
        where: {
          projectId,
        },
        filter: {
          skip: getProjectVideos?.length,
          limit: LIMIT,
        },
      },
    });
  };

  const handleRefetch = async (val) => {
    try {
      setGetProjectVideos([]);
      setHasMore(true);
      await getProjectVideo({
        variables: {
          where: {
            projectId: val,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    } catch (err) {
      return err;
    }
  };

  const handleDeleteVideo = async (projectUniqueId, videoUniqueId) => {
    try {
      const response = await deleteProjectVideo({
        variables: {
          where: {
            projectId: projectUniqueId,
            videoId: videoUniqueId,
          },
          filter: {
            skip: 0,
            limit: 10,
          },
        },
      });
      if (response?.data?.removeProjectVideo) {
        setHasMore(true);
        setGetProjectVideos([]);
        getProjectVideo({
          variables: {
            where: {
              projectId,
            },
            filter: {
              skip: 0,
              limit: LIMIT,
            },
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const columns = [
    ...(continueWithAI
      ? [
          {
            title: ' ',
            dataIndex: 'aiJobs',
            render: (_, val) => (
              <>
                {val?.aiProcessed && (
                  <Tooltip title="AI Processed">
                    <MagicPenBlue />
                  </Tooltip>
                )}
              </>
            ),
          },
        ]
      : []),
    {
      title: '#',
      dataIndex: 'id',
      render: (_, _1, index) => index + 1,
    },
    {
      title: `Video Name`,
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Starting - Ending Chainage (km+m)',
      render: (val) => {
        if (val?.endingChainage) {
          return `${formatChainage(val?.startingChainage)} - ${formatChainage(
            val?.endingChainage,
          )}`;
        }
        return `${formatChainage(val?.startingChainage)}`;
      },
    },
    {
      title: 'Recording Direction',
      dataIndex: 'direction',
      render: (val) => {
        if (val === DIRECTION?.UPWARD) {
          return 'Up (L.H.S)';
        }
        return 'Down (R.H.S)';
      },
    },
    {
      title: 'Uploaded On',
      dataIndex: 'createdAt',
      render: formatDateTime,
    },
    ...(continueWithAI
      ? [
          {
            title: 'Status',
            dataIndex: 'aiJobs',
            render: (_, val) => (
              <div className="video-processing-status">
                {val?.aiJobs?.[0]?.status === AI_PROCESSING?.COMPLETED ? (
                  <div className="status-completed">
                    <MagicPenGreen />
                    Completed
                  </div>
                ) : (
                  <>
                    {val?.aiJobs?.[0]?.status === AI_PROCESSING?.IN_PROGRESS ? (
                      <div className="status-in-progress">
                        <MagicPenYellow /> In Progress
                      </div>
                    ) : (
                      <>
                        {val?.aiJobs?.[0]?.status ===
                        AI_PROCESSING?.TERMINATED ? (
                          <div className="status-terminated">
                            {Math.round(Number(val?.aiJobs?.[0]?.completion))}%
                            processed
                          </div>
                        ) : (
                          <>
                            {val?.aiJobs?.[0]?.status ===
                            AI_PROCESSING?.TERMINATING ? (
                              <div className="status-in-progress">
                                <MagicPenYellow /> Terminating
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      title: 'Manage',
      dataIndex: 'action',
      render: (_, data) => (
        <Action
          videoId={data?.id}
          status={data?.status}
          setIsEdit={setIsEdit}
          setEditVideoId={setEditVideoId}
          setAddVideoModel={setAddVideoModel}
          projectRole={project?.getProject?.data?.userRole}
          setSyncedStatus={setSyncedStatus}
          aiJobStatus={data?.aiJobs?.[0]?.status}
          aiJobId={data?.aiJobs?.[0]?.id}
          handleDeleteVideo={handleDeleteVideo}
          handleRefetch={handleRefetch}
        />
      ),
    },
  ];

  return (
    <div className="inside-project-container">
      <Tour
        open={open}
        onClose={() => handleCloseTour()}
        steps={steps}
        current={currentStep}
        onChange={handleNextPrev}
      />
      <div className="container-div d-flex justify-between align-center">
        <Typography>Project Details</Typography>
        <ProjectAccessRole
          allowedRoles={[PROJECT_ROLES?.OWNER]}
          currentRole={project?.getProject?.data?.userRole}
        >
          <div className="d-flex flex-horizontal">
            <Button
              className="assign-members"
              size="small"
              onClick={() => {
                setAssignMembersModel(true);
              }}
              icon={<AddUserIconGreen />}
              ref={ref2}
            >
              Assign Members
            </Button>
            <Button
              className="secondary-btn border-btn ml-16"
              size="small"
              icon={<EditPencil />}
              onClick={() => {
                setProjectEditModel(true);
                setProjectEdit(true);
              }}
              ref={ref1}
            >
              Edit
            </Button>
          </div>
        </ProjectAccessRole>
      </div>
      {projectLoading ? (
        <LoaderComponent spinning={projectLoading} setHeight="200" />
      ) : (
        <>
          <Row className="project-details mt-24" gutter={[16, 16]}>
            <Col lg={12} xl={8}>
              <Typography>Project Name</Typography>
              <p>{project?.getProject?.data?.name}</p>
            </Col>
            <Col lg={12} xl={8}>
              <Typography>Start Point Location</Typography>
              <p>{project?.getProject?.data?.startAddress}</p>
            </Col>
            <Col lg={12} xl={8}>
              <Typography>End Point Location</Typography>
              <p>{project?.getProject?.data?.endAddress}</p>
            </Col>
            <Col lg={12} xl={8}>
              <Typography>Type of Road</Typography>
              <p>{project?.getProject?.data?.roadType?.name}</p>
            </Col>
            <Col lg={12} xl={8}>
              <Typography>Road Width (m)</Typography>
              <p>{project?.getProject?.data?.roadWidth}</p>
            </Col>
            <Col lg={12} xl={8}>
              <Typography>Project & Report Header</Typography>
              <p>{project?.getProject?.data?.reportHeaderName}</p>
            </Col>
          </Row>
          <Row className="project-details mt-16">
            <Col lg={24} xl={24}>
              <Typography>Description</Typography>
              <p>{project?.getProject?.data?.description}</p>
            </Col>
          </Row>
          <div className="container-div mt-32 d-flex justify-between align-center">
            <Typography>Videos ({formattedCount})</Typography>
            <div className="d-flex flex-horizontal">
              <Button
                size="small"
                type="link"
                icon={<GraphUp />}
                className="custom-link"
                ref={ref5}
              >
                <Dropdown
                  overlayClassName="project-card-dropdown"
                  menu={{
                    items: [
                      {
                        key: 'editTimeStamp',
                        label: 'Traffic Analysis',
                        onClick: () => {
                          history.push(
                            `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.TRAFFIC_ANALYSIS}`,
                          );
                        },
                      },
                      {
                        type: 'divider',
                      },
                      {
                        key: 'editLatLong',
                        label: 'Accident Analysis',
                        onClick: () => {
                          history.push(
                            `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.ACCIDENT_ANALYSIS}`,
                          );
                        },
                      },
                    ],
                  }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <div>Add Analysis</div>
                </Dropdown>
              </Button>
              <Button
                size="small"
                type="link"
                icon={<TaskList />}
                className="ml-16 custom-link"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.CONSOLIDATED_VIEW}`,
                  );
                }}
                ref={ref6}
              >
                Consolidated View
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ReportsIcon />}
                className="ml-16"
                onClick={() => {
                  history?.push(
                    `${ROUTES?.PROJECTS}/${projectId}${ROUTES?.REPORTS}`,
                  );
                }}
                ref={ref7}
              >
                Reports
              </Button>
              <ProjectAccessRole
                allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                currentRole={project?.getProject?.data?.userRole}
              >
                <Button
                  className="secondary-btn ml-16"
                  size="small"
                  onClick={handleModel}
                  icon={<Plus />}
                  ref={ref3}
                >
                  Add New Video
                </Button>
              </ProjectAccessRole>
            </div>
          </div>
          <div className="mt-16 mb-16 videos-list" ref={ref4}>
            <TableComponent
              onRow={handleRowClick}
              data={getProjectVideos}
              columns={columns}
              tableClassName="custom-table"
              scroll={{ x: '100%' }}
              loadingData={videosLoading}
              rowClassName="pointer"
            />
          </div>
        </>
      )}
      {addVideoModel && (
        <UploadVideoModel
          addVideoModel={addVideoModel}
          setAddVideoModel={setAddVideoModel}
          setUploadedVideo={setUploadedVideo}
          uploadedVideo={uploadedVideo}
          editVideoId={editVideoId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          refetch={refetch}
          status={syncedStatus}
          setSyncedStatus={setSyncedStatus}
          setGetProjectVideos={setGetProjectVideos}
          handleRefetch={handleRefetch}
        />
      )}
      {closeTourVisible && (
        <CommonConfirmationModal
          visible={closeTourVisible}
          onCancel={() => {
            setCloseTourVisible(false);
            setOpen(true);
          }}
          onOk={() => {
            setCloseTourVisible(false);
            setOpen(false);
          }}
          title="Demo Project Tour"
          content="Are you sure you want to close the tour for demo project?"
          okText="Close"
          cancelText="Cancel"
        />
      )}
      {projectEditModel && (
        <AddNewProject
          editProjectData={project}
          projectEdit={projectEdit}
          setProjectEdit={setProjectEdit}
          visible={projectEditModel}
          setVisible={setProjectEditModel}
          onSubmit={() => {
            changeOnEditProject();
          }}
          projectRefetch={projectRefetch}
        />
      )}
      {assignMembersModel && (
        <AssignMembersModal
          visible={assignMembersModel}
          setVisible={setAssignMembersModel}
          projectId={projectId}
        />
      )}
      {!videosLoading && !loading && (
        <InView
          as="div"
          onChange={(inView) => (inView && hasMore ? handleScroll() : null)}
        />
      )}
    </div>
  );
};

export default InsideProject;
