import React from 'react';

const ProjectAccessRole = ({ children, allowedRoles, currentRole }) => {
  if (allowedRoles?.includes(currentRole)) {
    return <div>{children}</div>;
  }
  return null;
};

export default ProjectAccessRole;
