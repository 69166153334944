import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { VERIFY_EMAIL } from './graphql/Mutations';
import { ROUTES } from '../../common/constants';

const VerifyEmail = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const uid = params?.get('uid');

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    onError() {},
  });

  const handleVerifyEmail = async () => {
    try {
      const response = await verifyEmail({
        variables: { data: { token, uid } },
      });
      if (response?.data) {
        history?.push(ROUTES?.LOGIN);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);
  return null;
};

export default VerifyEmail;
