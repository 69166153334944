import React from 'react';
import { Typography, Button } from 'antd';
import { MagicPenGreen, MagicPenGrey, MagicPenYellow } from '../assets/svg';
import { AI_PROCESSING } from '../common/constants';

const AIProcessingStatus = ({
  status,
  completionPercentage,
  onViewDetails,
  onStop,
  loading,
}) => {
  const getStatusDetails = () => {
    switch (status) {
      case AI_PROCESSING?.TERMINATING:
        return {
          icon: <MagicPenYellow className="mr-10" />,
          text: 'Terminating',
          buttons: null,
        };

      case AI_PROCESSING?.COMPLETED:
        return {
          icon: <MagicPenGreen className="mr-10" />,
          text: 'Completed',
          buttons: (
            <Button className="view-details-ai-process" onClick={onViewDetails}>
              View Details
            </Button>
          ),
        };

      case AI_PROCESSING?.IN_PROGRESS:
        return {
          icon: <MagicPenYellow className="mr-10" />,
          text: 'In progress',
          buttons: (
            <Button
              className="stop-ai-process"
              loading={loading}
              onClick={onStop}
            >
              Stop
            </Button>
          ),
        };

      case AI_PROCESSING?.TERMINATED:
        return {
          icon: <MagicPenGrey className="mr-10" />,
          text: `${Math.round(Number(completionPercentage))}% Completed`,
          buttons: (
            <Button className="view-details-ai-process" onClick={onViewDetails}>
              View Details
            </Button>
          ),
        };

      default:
        return null;
    }
  };
  const statusDetails = getStatusDetails();
  if (!statusDetails) {
    return null;
  }
  const { icon, text, buttons } = statusDetails;

  return (
    <div className="-mt-40 ai-processing-status">
      <div className={`d-flex processing-${status?.toLowerCase()}`}>
        {icon}
        <Typography>{`AI Processing Status: ${text}`}</Typography>
      </div>
      {buttons && (
        <>
          <div className="separate" />
          {buttons}
        </>
      )}
    </div>
  );
};

export default AIProcessingStatus;
