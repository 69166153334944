import React from 'react';
import { Modal } from 'antd';

const CommonConfirmationModal = ({
  className,
  visible,
  title,
  content,
  onCancel,
  onOk,
  okText,
  cancelText,
  cancelButtonProps,
  contentBlock,
  footer,
}) => (
  <Modal
    className={`discard-modal ${className}`}
    centered
    width={450}
    open={visible}
    title={title}
    onCancel={onCancel}
    onOk={onOk}
    cancelText={cancelText}
    cancelButtonProps={cancelButtonProps}
    okText={okText}
    maskClosable={false}
    closable={false}
    keyboard={false}
    footer={footer}
  >
    {content ? <p>{content}</p> : <div>{contentBlock}</div>}
  </Modal>
);

export default CommonConfirmationModal;
