import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { formValidatorRules } from '../../common/utils';
import { UPDATE_PASSWORD } from '../../modules/auth/graphql/Mutations';
import { EyeCloseIcon, EyeOpenIcon } from '../../assets/svg';
import { CODE } from '../../common/constants';

const ChangePassword = ({ isModalOpen, setIsModalOpen }) => {
  const { required, password } = formValidatorRules;
  const [form] = Form?.useForm();

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD);

  const renderPasswordIcon = (visible) => {
    if (visible) {
      return <EyeOpenIcon />;
    }
    return <EyeCloseIcon />;
  };
  const onFinish = async ({ newpassword, currentpassword }) => {
    try {
      await updatePassword({
        variables: {
          data: {
            
            oldPassword: currentpassword,
            newPassword: newpassword,
          },
        },
      });
      setIsModalOpen(false);
    } catch (error) {
      const code = get(error, 'graphQLErrors.0.extensions.code');
      if (code === CODE?.PASSWORD_INCORRECT) {
        form.setFields([
          { name: 'currentpassword', value: '', errors: [error?.message] },
        ]);
      }
    }
  };

  return (
    <Modal
      className="change-password"
      title="Change Password"
      open={isModalOpen}
      onCancel={() => {
        form?.resetFields();
        setIsModalOpen(false);
      }}
      footer
      centered
    >
      <div className="content">
        <div className="details">
          <Form
            name="reset-password"
            initialValues={{
              currentpassword: '',
              newpassword: '',
              confirmPassword: '',
            }}
            onFinish={onFinish}
            layout="vertical"
            form={form}
            className="full-width custom-label"
          >
            <Form.Item
              name="currentpassword"
              label="Current Password"
              rules={[
                { ...required, message: 'Enter current password' },
                password,
              ]}
            >
              <Input.Password
                placeholder="Enter current password"
                iconRender={(visible) => renderPasswordIcon(visible)}
              />
            </Form.Item>
            <Form.Item
              name="newpassword"
              label="New Password"
              rules={[
                { ...required, message: 'Minimum 8 character' },
                password,
              ]}
            >
              <Input.Password
                placeholder="Enter new password"
                iconRender={(visible) => renderPasswordIcon(visible)}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required, message: 'Please enter confirm password!' },
                {
                  validator: (_, value) => {
                    const { getFieldValue } = form;
                    if (value && value !== getFieldValue('newpassword')) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Password do not match');
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                placeholder="Enter confirm password"
                iconRender={(visible) => renderPasswordIcon(visible)}
              />
            </Form.Item>
            <Form.Item shouldUpdate className="full-width mb-0 submit-button">
              {() => (
                <Button
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      ?.getFieldsError()
                      ?.filter(({ errors }) => errors?.length)?.length
                  }
                >
                  Submit
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
