import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tour,
  Typography,
  Upload,
  message,
} from 'antd';
import { InView } from 'react-intersection-observer';
import { debounce } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Back,
  ChatEmpty,
  InfoEmpty,
  MagicPenBlue,
  Plus,
} from '../../assets/svg';
import {
  chainageConversion,
  fileUpload,
  formValidatorRules,
} from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import DetectionCategoryOtherModal from './modal/DetectionCategoryOtherModal';
import DetectionTypeOtherModal from './modal/DetectionTypeOtherModal';
import PropertyModal from './modal/PropertyModal';
import {
  DETECTION_CATEGORY,
  DETECTION_SUB_CATEGORY,
  GET_DETECTION,
  GET_DETECTION_IMAGE_SIGNED_URL,
  GET_DETECTION_PROPERTIES_LIST,
} from './graphql/Queries';
import {
  CREATE_DETECTION,
  DELETE_DETECTION,
  DELETE_DETECTION_PROPERTY,
  UPDATE_DETECTION,
  UPDATE_DETECTION_PROPERTY,
} from './graphql/Mutations';
import {
  ALL_IMAGE_UPLOAD,
  DETECTION_IMAGE_NANOID,
  IMAGE_TYPE,
  LIMIT,
  PROJECT_ROLES,
  ROUTES,
  priorityOptions,
  riskLevelOptions,
} from '../../common/constants';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import ViewPropertyModal from './modal/ViewPropertyModal';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import AnnotationImageModal from './modal/AnnotationImageModal';
import DetailModal from './modal/DetailModal';
import ViewDetailModal from './modal/ViewDetailModal';
import CommentDrawer from './CommentDrawer';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import DetectionPropertyListDemoImage from '../../assets/svg/detectionPropertyListDemoImg.svg';

const { required, chainageValidation } = formValidatorRules;
const { TextArea } = Input;
const { Option } = Select;

const DetectionDetail = () => {
  const {
    state: {
      currentVideoDetectionImageDataUrl,
      currentVideoDetectionSourceImageObj,
      currentVideoChainageDetail,
      projectRole,
      currentVideoDetectionRecommendationSourceImageObj,
    },
    dispatch,
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const debounceScrollType = useRef(null);
  // eslint-disable-next-line no-undef
  const detectionImageNanoId = localStorage.getItem(DETECTION_IMAGE_NANOID);
  const { videoId, projectId, detectionId } = useParams();
  const [hasMore, setHasMore] = useState(true);
  const [addToReportObj, setAddToReportObj] = useState({
    sourceImageAddedToReport: true,
    annotateImageAddedToReport: true,
    replaceImageAddedToReport: true,
    recommendationAnnotatedImageAddedToReport: true,
    recommendationSourceImageAddedToReport: false,
    recommendationReplacedImageAddedToReport: true,
  });
  const [createDetectionLoading, setCreateDetectionLoading] = useState(false);
  const [detectionPropertyLoading, setDetectionPropertyLoading] = useState(
    false,
  );
  const [imageType, setImageType] = useState();
  const [replaceImageType, setReplaceImageType] = useState();
  const [
    annotationImageModalVisible,
    setAnnotationImageModalVisible,
  ] = useState(false);
  const [categoryOtherVisible, setCategoryOtherVisible] = useState(false);
  const [typeOtherVisible, setTypeOtherVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [viewDetailModalVisible, setViewDetailModalVisible] = useState(false);
  const [propertyModalVisible, setPropertyModalVisible] = useState(false);
  const [typeSearchFlag, setTypeSearchFlag] = useState(false);
  const [typeSkip, setTypeSkip] = useState(0);
  const [isEndType, setIsEndType] = useState(false);
  const [typeLoading, setTypeLoading] = useState(false);
  const [typeSearchValue, setTypeSearchValue] = useState('');
  const [viewPropertyModalVisible, setViewPropertyModalVisible] = useState(
    false,
  );
  const [scrollFlag, setScrollFlag] = useState(false);
  const [detectionDetails, setDetectionDetails] = useState();
  const [detectionMissing, setDetectionMissing] = useState(false);
  const [detectionLoading, setDetectionLoading] = useState(false);
  const [viewPropertyModalData, setViewPropertyModalData] = useState('');
  const [categoryProperties, setCategoryProperties] = useState('');
  const [typeProperties, setTypeProperties] = useState('');
  const [detectionUid, setDetectionUid] = useState('');
  const [detectionCategories, setDetectionCategories] = useState('');
  const [detectionCategoryOther, setDetectionCategoryOther] = useState('');
  const [detectionTypeOther, setDetectionTypeOther] = useState('');
  const [detectionType, setDetectionType] = useState('');
  const [detectionCreated, setDetectionCreated] = useState(false);
  const [detectionAddToReport, setDetectionAddToReport] = useState(false);
  const [detectionPropertyList, setDetectionPropertyList] = useState('');
  const [detectionPropertyUpdate, setDetectionPropertyUpdate] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [
    deleteDetectionConfirmation,
    setDeleteDetectionConfirmation,
  ] = useState(false);
  const [
    deleteDetectionPropertyConfirmation,
    setDeleteDetectionPropertyConfirmation,
  ] = useState(false);
  const [
    replaceDetectionImageVisible,
    setReplaceDetectionImageVisible,
  ] = useState(false);
  const [deleteDetectionPropertyId, setDeleteDetectionPropertyId] = useState(
    '',
  );
  const [detectionImage, setDetectionImage] = useState('');
  const [
    detectionImageRecommendation,
    setDetectionImageRecommendation,
  ] = useState('');
  const [detectionAnnotatedImage, setDetectionAnnotateImage] = useState('');
  const [
    recommendationAnnotatedImage,
    setRecommendationAnnotatedImage,
  ] = useState('');

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString();
  const [closeTourVisible, setCloseTourVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const newLocation = {
    ...history.location,
    state: undefined,
  };

  const [createDetection] = useMutation(CREATE_DETECTION, {
    onCompleted(res) {
      setDetectionCreated(true);
      setDetectionLoading(true);
      setCreateDetectionLoading(false);
      dispatch({
        type: 'SET_VIDEO_CHAINAGE_DETAIL',
        data: null,
      });
      dispatch({
        type: 'DETECTION_SOURCE_IMAGE_DATA_URL',
        data: null,
      });
      dispatch({
        type: 'SET_VIDEO_DETECTION_SOURCE_IMAGE_OBJ',
        data: null,
      });
      // eslint-disable-next-line no-undef
      localStorage.removeItem(DETECTION_IMAGE_NANOID);
      history?.replace(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/${res?.createDetection?.data?.id}`,
      );
    },
    onError() {
      setCreateDetectionLoading(false);
    },
  });

  const [getDetectionCategory] = useLazyQuery(DETECTION_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDetectionCategories(res?.categories?.data);
    },
  });

  const [getDetectionType] = useLazyQuery(DETECTION_SUB_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDetectionType(res?.subCategories?.data);
      if (res?.subCategories?.data?.length < 50) {
        setIsEndType(true);
      }
      if (typeSearchFlag) {
        setDetectionType(res?.subCategories?.data);
      } else {
        setDetectionType([...detectionType, ...res?.subCategories?.data]);
      }
      setTypeLoading(false);
    },
    onError() {},
  });

  const [getDetectionPropertyList] = useLazyQuery(
    GET_DETECTION_PROPERTIES_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (scrollFlag) {
          setDetectionPropertyList([
            ...detectionPropertyList,
            ...res?.detectionProperties?.data,
          ]);
          if (res?.detectionProperties?.data?.length < LIMIT) {
            setHasMore(false);
          }
        } else {
          setDetectionPropertyList([...res?.detectionProperties?.data]);
        }
        setScrollFlag(false);
        setDetectionPropertyLoading(false);
        if (state?.tourStart) {
          setOpen(true);
        }
      },
      onError() {
        setDetectionPropertyLoading(false);
      },
    },
  );

  const imageUrlToBase64 = async (imageUrl, setImageState) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageState(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error converting image to base64:', error);
    }
  };

  const [getDetection] = useLazyQuery(GET_DETECTION, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDetectionDetails(res?.detection?.data);
      let tempObj = {};
      if (res?.detection?.data?.replacedImage) {
        tempObj = {
          ...tempObj,
          replaceImageAddedToReport:
            res?.detection?.data?.replacedImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.replacedImage?.url,
          setDetectionImage,
        );
      } else {
        tempObj = {
          ...tempObj,
          sourceImageAddedToReport:
            res?.detection?.data?.sourceImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.sourceImage?.url,
          setDetectionImage,
        );
      }
      if (res?.detection?.data?.recommendationReplacedImage) {
        tempObj = {
          ...tempObj,
          recommendationReplacedImageAddedToReport:
            res?.detection?.data?.recommendationReplacedImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.recommendationReplacedImage?.url,
          setDetectionImageRecommendation,
        );
      } else {
        tempObj = {
          ...tempObj,
          recommendationSourceImageAddedToReport:
            res?.detection?.data?.recommendationSourceImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.recommendationSourceImage?.url,
          setDetectionImageRecommendation,
        );
      }

      if (res?.detection?.data?.annotatedImage?.url) {
        tempObj = {
          ...tempObj,
          annotateImageAddedToReport:
            res?.detection?.data?.annotatedImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.annotatedImage?.url,
          setDetectionAnnotateImage,
        );
      }
      if (res?.detection?.data?.recommendationAnnotatedImage?.url) {
        tempObj = {
          ...tempObj,
          recommendationAnnotatedImageAddedToReport:
            res?.detection?.data?.recommendationAnnotatedImage?.addToReport,
        };
        imageUrlToBase64(
          res?.detection?.data?.recommendationAnnotatedImage?.url,
          setRecommendationAnnotatedImage,
        );
      }
      if (!res?.detection?.data?.otherCategory) {
        getDetectionType({
          variables: {
            filter: {
              skip: 0,
              limit: 50,
              search: '',
              categoryKey: res?.detection?.data?.category?.key,
            },
          },
        });
      } else {
        setDetectionCategoryOther(res?.detection?.data?.otherCategory);
        setDetectionTypeOther(res?.detection?.data?.otherSubCategory);
      }
      getDetectionPropertyList({
        variables: {
          where: {
            detectionId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
      setDetectionMissing(res?.detection?.data?.elementMissing);
      setDetectionUid(res?.detection?.data?.uid);
      setDetectionAddToReport(res?.detection?.data?.addToReport);
      setCategoryProperties(res?.detection?.data?.category?.key);
      setTypeProperties(res?.detection?.data?.subCategory?.key);
      form?.setFieldsValue({
        id: res?.detection?.data?.id,
        chainage: res?.detection?.data?.syncData?.chainagePoint,
        category: res?.detection?.data?.category?.key
          ? res?.detection?.data?.category?.key
          : res?.detection?.data?.otherCategory,
        type: res?.detection?.data?.subCategory?.key
          ? res?.detection?.data?.subCategory?.key
          : res?.detection?.data?.otherSubCategory,
        safetyConcern: res?.detection?.data?.safetyConcern,
        missingElement: res?.detection?.data?.elementMissing,
        endingChainage: res?.detection?.data?.endingChainage
          ? `${res?.detection?.data?.endingChainage?.kilometer}+${res?.detection?.data?.endingChainage?.meter}`
          : null,
      });
      setDetectionLoading(false);
      setAddToReportObj({
        ...addToReportObj,
        ...tempObj,
      });
    },
    onError() {
      setDetectionLoading(false);
    },
  });

  const [updateDetection] = useMutation(UPDATE_DETECTION, {
    onCompleted() {
      setDetectionCreated(true);
      getDetection({
        variables: {
          where: {
            detectionId,
          },
        },
      });
    },
  });

  const [updateDetectionProperty] = useMutation(UPDATE_DETECTION_PROPERTY, {
    onCompleted() {
      getDetectionPropertyList({
        variables: {
          where: {
            detectionId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    },
  });

  const [deleteDetection] = useMutation(DELETE_DETECTION, {
    onCompleted() {
      setDeleteDetectionConfirmation(false);
      history?.push(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}`,
      );
    },
  });

  const [deleteDetectionProperty] = useMutation(DELETE_DETECTION_PROPERTY, {
    onCompleted() {
      setDeleteDetectionPropertyConfirmation(false);
      getDetectionPropertyList({
        variables: {
          where: {
            detectionId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    },
  });

  const [getDetectionImageSignedUrl] = useLazyQuery(
    GET_DETECTION_IMAGE_SIGNED_URL,
    {
      onError() {},
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (detectionId) {
      setDetectionLoading(true);
      setDetectionCreated(true);
      getDetection({
        variables: {
          where: {
            detectionId,
          },
        },
      });
    }
  }, [detectionId]);

  useEffect(() => {
    if (detectionPropertyLoading) {
      getDetectionPropertyList({
        variables: {
          where: {
            detectionId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, [detectionPropertyLoading]);

  useEffect(() => {
    dispatch({
      type: 'SET_VIDEO_CHAINAGE',
      data: currentVideoChainageDetail,
    });
    if (currentVideoChainageDetail) {
      form.setFieldsValue({
        chainage: chainageConversion(currentVideoChainageDetail?.chainagePoint),
      });
    }

    getDetectionCategory({
      variables: {
        filter: {
          skip: 0,
          limit: 50,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (detectionCategoryOther !== '') {
      form.setFieldsValue({
        category: detectionCategoryOther,
      });
    }
  }, [detectionCategoryOther]);

  useEffect(() => {
    if (detectionTypeOther !== '') {
      form.setFieldsValue({
        type: detectionTypeOther,
      });
    }
  }, [detectionTypeOther]);

  const handleSafetyConcernUpdate = (e) => {
    updateDetection({
      variables: {
        where: {
          detectionId,
        },
        data: {
          safetyConcern: e?.target?.value,
        },
      },
    });
  };

  const handleSafetyConcernDebounce = debounce(handleSafetyConcernUpdate, 500);

  const onFinish = (values) => {
    const endingChainage = values?.endingChainage?.split('+');
    const fileName = currentVideoDetectionSourceImageObj?.fileName;
    const recommendationFileName =
      currentVideoDetectionRecommendationSourceImageObj?.fileName;
    const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
    const detectionData = {
      videoId,
      syncDataId: currentVideoChainageDetail?.id,
      safetyConcern: values?.safetyConcern,
      elementMissing: values?.missingElement,
      sourceImage: {
        key: fileName,
        fileName: formattedDate,
        extension,
        contentType: 'image/jpeg',
        addToReport: addToReportObj?.sourceImageAddedToReport,
      },
      recommendationSourceImage: {
        key: recommendationFileName,
        fileName: formattedDate,
        extension,
        contentType: 'image/jpeg',
        addToReport: addToReportObj?.recommendationSourceImageAddedToReport,
      },
      categoryKey: detectionCategoryOther ? null : values?.category,
      subCategoryKey: detectionTypeOther ? null : values?.type,
      otherCategory: detectionCategoryOther,
      otherSubCategory: detectionTypeOther,
      uid: detectionImageNanoId,
      endingChainage: endingChainage
        ? {
            kilometer: parseInt(endingChainage[0], 10),
            meter: parseInt(endingChainage[1], 10),
          }
        : null,
    };
    createDetection({ variables: { data: { ...detectionData } } });
  };

  const handleSubmitDetection = () => {
    delete detectionDetails?.annotatedImage?.url;
    delete detectionDetails?.sourceImage?.url;
    delete detectionDetails?.replacedImage?.url;
    delete detectionDetails?.recommendationSourceImage?.url;
    delete detectionDetails?.recommendationAnnotatedImage?.url;
    delete detectionDetails?.recommendationReplacedImage?.url;

    updateDetection({
      variables: {
        where: {
          detectionId,
        },
        data: {
          // eslint-disable-next-line no-nested-ternary
          ...(detectionAnnotatedImage
            ? {
                annotatedImage: {
                  ...detectionDetails?.annotatedImage,
                  addToReport: addToReportObj?.annotateImageAddedToReport,
                },
              }
            : detectionDetails?.replacedImage
            ? {
                replacedImage: {
                  ...detectionDetails?.replacedImage,
                  addToReport: addToReportObj?.replaceImageAddedToReport,
                },
              }
            : {
                sourceImage: {
                  ...detectionDetails?.sourceImage,
                  addToReport: addToReportObj?.sourceImageAddedToReport,
                },
              }),
          // eslint-disable-next-line no-nested-ternary
          ...(recommendationAnnotatedImage
            ? {
                recommendationAnnotatedImage: {
                  ...detectionDetails?.recommendationAnnotatedImage,
                  addToReport:
                    addToReportObj?.recommendationAnnotatedImageAddedToReport,
                },
              }
            : detectionDetails?.recommendationReplacedImage
            ? {
                recommendationReplacedImage: {
                  ...detectionDetails?.recommendationReplacedImage,
                  addToReport:
                    addToReportObj?.recommendationReplacedImageAddedToReport,
                },
              }
            : {
                recommendationSourceImage: {
                  ...detectionDetails?.recommendationSourceImage,
                  addToReport:
                    addToReportObj?.recommendationSourceImageAddedToReport,
                },
              }),
        },
      },
    });
    history?.replace(
      `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}`,
    );
    message?.success('All changes saved successfully');
  };

  const scrollConfig =
    currentStep === 4 || currentStep === 3
      ? { y: 150, x: '100%' }
      : { x: '100%' };

  const steps = [
    {
      title: 'Detection Details',
      description:
        'You can add detection details and delete detection from here.',
      target: () => ref1?.current,
    },
    {
      title: 'Detection Image',
      description: 'Selected frame will appear here from the video.',
      target: () => ref2?.current,
    },
    {
      title: 'Annotate Image',
      description:
        'You can annotate the image in order to elaborate the detection from here.',
      target: () => ref3?.current,
    },
    {
      title: 'Replace Image',
      description:
        'You can even replace the selected image to an image from your device from here.',
      target: () => ref4?.current,
    },
    {
      title: 'Add Property',
      description: 'You can add new properties to this detection from here.',
      target: () => ref5?.current,
    },
    {
      title: 'Property Table',
      description: 'A list of all the properties added will be displayed here.',
      target: () => ref6?.current,
    },
    {
      title: 'Add Comment',
      description: 'You can add comments to a detection from here.',
      target: () => ref7?.current,
    },
  ];

  const handleDetectionReport = (value) => {
    setDetectionLoading(true);
    updateDetection({
      variables: {
        where: {
          detectionId,
        },
        data: {
          addToReport: value,
        },
      },
    });
  };

  const handleDetectionPropertyReport = (values, value) => {
    const recommendationIds = [];
    const updateOtherRecommendations = [];

    value?.recommendations?.forEach((item) => {
      if (item?.recommendation) {
        recommendationIds?.push(item?.recommendation?.id);
      } else {
        updateOtherRecommendations?.push({
          otherRecommendationId: item?.id,
          otherRecommendationText: item?.otherRecommendation,
        });
      }
    });

    setDetectionPropertyLoading(true);
    updateDetectionProperty({
      variables: {
        where: {
          detectionPropertyId: value?.id,
        },
        data: {
          addToReport: values,
          recommendationIds,
          otherRecommendations: updateOtherRecommendations,
        },
      },
    });
  };

  const handleDeleteDetection = () => {
    deleteDetection({
      variables: {
        where: {
          detectionId,
        },
      },
    });
  };

  const handleDeleteDetectionProperty = () => {
    setHasMore(true);
    setDetectionPropertyList([]);
    deleteDetectionProperty({
      variables: {
        where: {
          detectionPropertyId: deleteDetectionPropertyId,
        },
      },
    });
  };

  const propertyColumns = [
    {
      title: '#',
      dataIndex: '#',
      render: (val, _, index) => index + 1,
    },
    {
      title: `Property`,
      dataIndex: 'property',
      render: (_, value) => {
        if (value?.property === null) {
          if (value?.otherProperty) {
            return (
              <div className="property-name">
                <span className="text">{value?.otherProperty}</span>
              </div>
            );
          }
          return 'Not Applicable';
        }
        return (
          <div className="property-name">
            <span className="text">{value?.property?.name}</span>
          </div>
        );
      },
    },
    {
      title: 'Matched',
      dataIndex: 'matched',
      render: (_, value) => {
        if (value?.matched === null) {
          return '-';
        }
        return value?.matched === true ? 'Yes' : 'No';
      },
    },
    {
      title: 'Recommendation',
      dataIndex: 'recommendations',
      render: (_, value) => {
        const recommendations = value?.recommendations?.[0];
        const recommendationsCount = value?.recommendations?.length;
        if (recommendations) {
          if (recommendations?.recommendation) {
            return (
              <div className="recommendations">
                <span className="description">
                  {recommendations?.recommendation?.description}
                </span>
                {recommendationsCount > 1 && (
                  <span className="description-count">
                    + {recommendationsCount - 1}
                  </span>
                )}
              </div>
            );
          }
          return (
            <div className="recommendations">
              <span className="description">
                {recommendations?.otherRecommendation}
              </span>
              {recommendationsCount > 1 && (
                <span className="description-count">
                  + {recommendationsCount - 1}
                </span>
              )}
            </div>
          );
        }
        return '-';
      },
    },
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      render: (_, value) => (
        <img
          className="detection-property-list-img"
          width={24}
          height={24}
          src={
            value?.imageUrl ? value?.imageUrl : DetectionPropertyListDemoImage
          }
          alt="detection-property-img"
        />
      ),
    },
    {
      title: 'Risk Level',
      dataIndex: 'riskLevel',
      render: (_, value) => {
        const matchingRiskLevel = riskLevelOptions?.find(
          (item) => item?.value === value?.riskLevel,
        );
        return matchingRiskLevel?.label;
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      render: (_, value) => {
        const matchingPriority = priorityOptions?.find(
          (item) => item?.value === value?.priority,
        );
        return matchingPriority?.label;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, value) => {
        const menuItems = [
          {
            key: 'viewDetails',
            label: 'View Details',
            onClick: () => {
              if (detectionMissing) {
                setViewDetailModalVisible(true);
              } else {
                setViewPropertyModalVisible(true);
              }
              setViewPropertyModalData(value);
            },
          },
        ];

        if (
          projectRole === PROJECT_ROLES?.OWNER ||
          projectRole === PROJECT_ROLES?.EDITOR
        ) {
          menuItems.push(
            { type: 'divider' },
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => {
                if (detectionMissing) {
                  setDetailModalVisible(true);
                } else {
                  setPropertyModalVisible(true);
                }
                setViewPropertyModalData(value);
                setDetectionPropertyUpdate(true);
              },
            },
            { type: 'divider' },
          );

          if (value?.addToReport === true) {
            menuItems.push({
              key: 'removeFromReport',
              label: 'Remove from Report',
              onClick: () => {
                handleDetectionPropertyReport(false, value);
                setHasMore(true);
                setDetectionPropertyList([]);
              },
            });
          } else {
            menuItems.push({
              key: 'addToReport',
              label: 'Add to Report',
              onClick: () => {
                handleDetectionPropertyReport(true, value);
                setHasMore(true);
                setDetectionPropertyList([]);
              },
            });
          }

          menuItems.push(
            { type: 'divider' },
            {
              key: 'delete',
              label: 'Delete',
              onClick: () => {
                setDeleteDetectionPropertyId(value?.id);
                setDeleteDetectionPropertyConfirmation(true);
              },
              className: 'error-color',
            },
          );
        }

        return (
          <Dropdown
            overlayClassName="property-actions"
            menu={{
              items: menuItems,
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Space
              className="pointer d-flex align-center gap-4 actions"
              size="small"
            >
              More Actions
            </Space>
          </Dropdown>
        );
      },
    },
  ];

  const handleSavedImage = async (value) => {
    setDetectionLoading(true);
    const base64String = value?.imageBase64;
    const binaryString = atob(base64String.split(',')?.[1]);
    const arrayBuffer = new ArrayBuffer(binaryString?.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryString?.length; i++) {
      uint8Array[i] = binaryString?.charCodeAt(i);
    }

    // eslint-disable-next-line no-undef
    const blob = new Blob([arrayBuffer], { type: 'image/png' });

    const res = await getDetectionImageSignedUrl({
      variables: {
        data: {
          fileName: `${imageType}${value?.name}.png`,
          uid: detectionUid,
          type: imageType,
        },
      },
    });
    await fileUpload(res?.data?.detectionImageUploadSignedUrl?.signedUrl, blob);

    if (res?.data) {
      setAnnotationImageModalVisible(false);
      const fileName = res?.data?.detectionImageUploadSignedUrl?.fileName;
      const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);

      updateDetection({
        variables: {
          where: {
            detectionId,
          },
          data:
            imageType === IMAGE_TYPE?.ANNOTATED_IMAGE
              ? {
                  annotatedImage: {
                    key: fileName,
                    fileName: `${imageType}${value?.name}`,
                    extension,
                    contentType: value?.mimeType,
                    addToReport: addToReportObj?.annotateImageAddedToReport,
                  },
                }
              : {
                  recommendationAnnotatedImage: {
                    key: fileName,
                    fileName: `${imageType}${value?.name}`,
                    extension,
                    contentType: value?.mimeType,
                    addToReport:
                      addToReportObj?.recommendationAnnotatedImageAddedToReport,
                  },
                },
        },
      });
    } else {
      setDetectionLoading(false);
    }
  };

  const handleAnnotatedImageReplace = () => {
    // eslint-disable-next-line no-undef
    const fileInput = document?.getElementById('replace-image-btn');
    if (fileInput) {
      fileInput?.click();
    }
    setReplaceDetectionImageVisible(false);
  };

  const handleReplaceImageUpload = async (value) => {
    const name = value?.fileList?.[0]?.name;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (!ALL_IMAGE_UPLOAD?.includes(ext)) {
      message.error(`${value?.file?.name} file is not image file.`);
    } else {
      setDetectionLoading(true);
      const res = await getDetectionImageSignedUrl({
        variables: {
          data: {
            fileName: `${replaceImageType}-${value?.fileList?.[0]?.name}`,
            uid: detectionUid,
            type: replaceImageType,
          },
        },
      });
      await fileUpload(
        res?.data?.detectionImageUploadSignedUrl?.signedUrl,
        value?.fileList?.[0]?.originFileObj,
      );

      if (res?.data) {
        const fileName = res?.data?.detectionImageUploadSignedUrl?.fileName;
        const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
        setDetectionAnnotateImage(null);
        setRecommendationAnnotatedImage(null);
        updateDetection({
          variables: {
            where: {
              detectionId,
            },
            data:
              replaceImageType === IMAGE_TYPE?.REPLACED_IMAGE
                ? {
                    replacedImage: {
                      key: fileName,
                      fileName: `${replaceImageType}${value?.name}`,
                      extension,
                      contentType: value?.mimeType,
                      addToReport: addToReportObj?.replaceImageAddedToReport,
                    },
                    annotatedImage: null,
                  }
                : {
                    recommendationReplacedImage: {
                      key: fileName,
                      fileName: `${replaceImageType}${value?.name}`,
                      extension,
                      contentType: value?.mimeType,
                      addToReport:
                        addToReportObj?.recommendationReplacedImageAddedToReport,
                    },
                    recommendationAnnotatedImage: null,
                  },
          },
        });
      } else {
        setDetectionLoading(false);
      }
    }
  };

  const handleCloseTour = (e) => {
    if (e === 6) {
      setOpen(false);
      history.push(newLocation);
      dispatch({
        type: 'SET_TOUR_CLOSE',
        data: true,
      });
    } else {
      setOpen(false);
      setCloseTourVisible(true);
      history.push(newLocation);
      dispatch({
        type: 'SET_TOUR_CLOSE',
        data: true,
      });
    }
  };

  const handleNextPrev = (e) => {
    if (currentStep < e) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = () => {
    if (detectionDetails?.replacedImage) {
      setAddToReportObj({
        ...addToReportObj,
        replaceImageAddedToReport: !addToReportObj.replaceImageAddedToReport,
      });
    } else {
      setAddToReportObj({
        ...addToReportObj,
        sourceImageAddedToReport: !addToReportObj.sourceImageAddedToReport,
      });
    }
    if (detectionDetails?.annotatedImage?.url) {
      setAddToReportObj({
        ...addToReportObj,
        annotateImageAddedToReport: !addToReportObj.annotateImageAddedToReport,
      });
    }
  };

  const handleChangeRecommendationCheckbox = () => {
    if (detectionDetails?.recommendationReplacedImage) {
      setAddToReportObj({
        ...addToReportObj,
        recommendationReplacedImageAddedToReport: !addToReportObj.recommendationReplacedImageAddedToReport,
      });
    } else {
      setAddToReportObj({
        ...addToReportObj,
        recommendationSourceImageAddedToReport: !addToReportObj.recommendationSourceImageAddedToReport,
      });
    }
    if (detectionDetails?.recommendationAnnotatedImage) {
      setAddToReportObj({
        ...addToReportObj,
        recommendationAnnotatedImageAddedToReport: !addToReportObj.recommendationAnnotatedImageAddedToReport,
      });
    }
  };

  const handleScroll = () => {
    setScrollFlag(true);
    getDetectionPropertyList({
      variables: {
        where: {
          detectionId,
        },
        filter: {
          skip: detectionPropertyList?.length,
          limit: LIMIT,
        },
      },
    });
  };

  const detectionCategoriesSearch = debounce((input) => {
    getDetectionCategory({
      variables: {
        filter: {
          skip: 0,
          limit: 50,
          search: input?.trim()?.toLowerCase(),
        },
      },
    });
  }, 500);

  const categoriesTypeSearch = debounce((input) => {
    setTypeSearchFlag(true);
    setTypeSearchValue(input);
    setTypeSkip(0);
    getDetectionType({
      variables: {
        filter: {
          skip: 0,
          limit: 50,
          search: input?.trim()?.toLowerCase(),
          categoryKey: categoryProperties,
        },
      },
    });
  }, 500);

  const categoriesTypeScroll = (event) => {
    setTypeSearchFlag(false);
    if (debounceScrollType.current) {
      debounceScrollType.current.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    debounceScrollType.current = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
      const newSkip = typeSkip + 50;
      setTypeSkip(newSkip);
      if (scrolledToBottom && !isEndType) {
        setTypeLoading(true);
        getDetectionType({
          variables: {
            filter: {
              skip: newSkip,
              limit: 50,
              search: typeSearchValue,
              categoryKey: categoryProperties,
            },
          },
        });
      }
    }, 500);

    debounceScrollType.current();
  };

  return (
    <div className="add-new-detection">
      {open && (
        <Tour
          open={open}
          onClose={(e) => handleCloseTour(e)}
          steps={steps}
          current={currentStep}
          onChange={handleNextPrev}
        />
      )}
      <LoaderComponent spinning={detectionLoading}>
        <Row>
          <Col span={6} className="sidebar">
            <div className="sidebar-sticky">
              <div
                className="add-detection-back-button pointer"
                // eslint-disable-next-line no-console
                onClick={() => {
                  history.goBack();
                }}
              >
                <Back className="mr-12" />
                Back
              </div>
              <div ref={ref2}>
                <p className="add-detection-img-title">image</p>
                <img
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    detectionId
                      ? // eslint-disable-next-line no-unneeded-ternary
                        detectionAnnotatedImage
                        ? detectionAnnotatedImage
                        : detectionImage
                      : currentVideoDetectionImageDataUrl
                  }
                  alt="annotate-img"
                  className="annotate-image"
                />

                <Checkbox
                  className="mb-16 mt-8"
                  checked={
                    (detectionDetails?.annotatedImage?.url &&
                      addToReportObj?.annotateImageAddedToReport) ||
                    (!detectionDetails?.annotatedImage?.url &&
                      detectionDetails?.replacedImage &&
                      addToReportObj?.replaceImageAddedToReport) ||
                    (!detectionAnnotatedImage &&
                      !detectionDetails?.replacedImage &&
                      addToReportObj?.sourceImageAddedToReport)
                  }
                  onChange={() => {
                    handleChange();
                  }}
                >
                  Include safety concern image in the report
                </Checkbox>

                <ProjectAccessRole
                  allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                  currentRole={projectRole}
                >
                  <div className="d-flex justify-between annotate-button">
                    <Upload
                      id="replace-image-btn"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={handleReplaceImageUpload}
                      maxCount={1}
                      accept="image/png, image/jpeg"
                    >
                      <Button
                        size="small"
                        className="secondary-btn border-btn"
                        disabled={!detectionCreated}
                        onClick={(e) => {
                          e?.stopPropagation();
                          setReplaceDetectionImageVisible(true);
                          setReplaceImageType(IMAGE_TYPE?.REPLACED_IMAGE);
                        }}
                        ref={ref4}
                      >
                        Replace Image
                      </Button>
                    </Upload>
                    <Button
                      size="small"
                      type="primary"
                      disabled={!detectionCreated}
                      onClick={() => {
                        setAnnotationImageModalVisible(true);
                        setImageType(IMAGE_TYPE?.ANNOTATED_IMAGE);
                      }}
                      ref={ref3}
                    >
                      {detectionAnnotatedImage
                        ? 'Edit Annotation'
                        : 'Annotate Image'}
                    </Button>
                  </div>
                </ProjectAccessRole>
                <div className="dashed-separator" />
                <p className="add-detection-img-title">Recommendation Image</p>
                <img
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    detectionId
                      ? // eslint-disable-next-line no-unneeded-ternary
                        recommendationAnnotatedImage
                        ? recommendationAnnotatedImage
                        : detectionImageRecommendation
                      : currentVideoDetectionImageDataUrl
                  }
                  alt="annotate-img"
                  className="annotate-image"
                />

                <Checkbox
                  className="mb-16 mt-8"
                  checked={
                    (detectionDetails?.recommendationAnnotatedImage?.url &&
                      addToReportObj?.recommendationAnnotatedImageAddedToReport) ||
                    (!detectionDetails?.recommendationAnnotatedImage?.url &&
                      detectionDetails?.recommendationReplacedImage &&
                      addToReportObj?.recommendationReplacedImageAddedToReport) ||
                    (!recommendationAnnotatedImage &&
                      !detectionDetails?.recommendationReplacedImage &&
                      addToReportObj?.recommendationSourceImageAddedToReport)
                  }
                  onChange={() => {
                    handleChangeRecommendationCheckbox();
                  }}
                >
                  Include recommendation image in the report
                </Checkbox>
                <ProjectAccessRole
                  allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
                  currentRole={projectRole}
                >
                  <div className="d-flex justify-between annotate-button">
                    <Upload
                      id="replace-image-btn"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={handleReplaceImageUpload}
                      maxCount={1}
                      accept="image/png, image/jpeg"
                    >
                      <Button
                        size="small"
                        className="secondary-btn border-btn"
                        disabled={!detectionCreated}
                        onClick={(e) => {
                          e?.stopPropagation();
                          setReplaceDetectionImageVisible(true);
                          setReplaceImageType(
                            IMAGE_TYPE?.RECOMMENDATION_REPLACED_IMAGE,
                          );
                        }}
                        ref={ref4}
                      >
                        Replace Image
                      </Button>
                    </Upload>
                    <Button
                      size="small"
                      type="primary"
                      disabled={!detectionCreated}
                      onClick={() => {
                        setAnnotationImageModalVisible(true);
                        setImageType(
                          IMAGE_TYPE?.RECOMMENDATION_ANNOTATED_IMAGE,
                        );
                      }}
                      ref={ref3}
                    >
                      {recommendationAnnotatedImage
                        ? 'Edit Annotation'
                        : 'Annotate Image'}
                    </Button>
                  </div>
                </ProjectAccessRole>
                {state?.aiProcessed && (
                  <>
                    <div className="dashed-separator" />
                    <div className="ai-powered-info">
                      <div>
                        <MagicPenBlue className="mr-6" />
                        <span className="ai-powered-title">AI Powered</span>
                      </div>
                      <div className="mt-2 ai-powered-description">
                        While our AI is efficient, we recommend against relying
                        solely on it. Please ensure that you make any necessary
                        adjustments.
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col span={18}>
            <div className="form-section">
              <div className="header">
                <span className="title">Detection</span>
                <Button
                  size="small"
                  className="secondary-btn border-btn"
                  onClick={() => setOpenDrawer(true)}
                  disabled={!detectionCreated}
                  ref={ref7}
                >
                  <ChatEmpty className="mr-6" />
                  Add Comment
                </Button>
              </div>
              <div className="form modal-components-design">
                <div ref={ref1}>
                  <Form
                    name="property-form"
                    onFinish={onFinish}
                    className="custom-label add-new-project-form"
                    form={form}
                    layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col xl={8} lg={12}>
                        <Form.Item label="ID" name="id">
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col xl={8} lg={12}>
                        <Form.Item
                          label="Starting Chainage (km+m)"
                          name="chainage"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={12} xl={8}>
                        <Form.Item
                          label="Ending Chainage (km+m)"
                          name="endingChainage"
                          rules={[chainageValidation]}
                        >
                          <Input
                            placeholder="please enter the ending chainage"
                            disabled={detectionCreated}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12} xl={8}>
                        <Form.Item
                          label="Category"
                          name="category"
                          rules={[
                            {
                              required,
                            },
                          ]}
                          tooltip={{
                            title:
                              'Lists of titles according to IRC SP 088 2019',
                            icon: <InfoEmpty />,
                          }}
                        >
                          <Select
                            disabled={detectionCreated}
                            placeholder="Select category"
                            showSearch
                            filterOption={false}
                            onSearch={detectionCategoriesSearch}
                            onChange={(value) => {
                              setCategoryProperties(value);
                              if (value === 'OTHER') {
                                setCategoryOtherVisible(true);
                                setDetectionType([]);
                                form.resetFields(['type']);
                              } else {
                                setCategoryOtherVisible(false);
                                setDetectionType([]);
                                form.resetFields(['type']);
                                getDetectionType({
                                  variables: {
                                    filter: {
                                      skip: 0,
                                      limit: 50,
                                      categoryKey: value,
                                    },
                                  },
                                });
                              }
                            }}
                          >
                            {detectionCategories?.length > 0 && (
                              <>
                                {detectionCategories?.map((item) => (
                                  <Option key={item?.id} value={item?.key}>
                                    {item?.name}
                                  </Option>
                                ))}
                              </>
                            )}
                            <Option key="other" value="OTHER">
                              Other
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12} xl={8}>
                        <Form.Item
                          label="Type"
                          name="type"
                          rules={[required]}
                          tooltip={{
                            title:
                              'This is an element under a defined category (i.e for category “Sign Board”, type is “STOP”)',
                            icon: <InfoEmpty />,
                          }}
                        >
                          <Select
                            disabled={detectionCreated}
                            showSearch
                            filterOption={false}
                            onSearch={categoriesTypeSearch}
                            onPopupScroll={categoriesTypeScroll}
                            loading={typeLoading}
                            placeholder="Select type"
                            onChange={(value) => {
                              setTypeProperties(value);
                              if (value === 'OTHER') {
                                setTypeOtherVisible(true);
                              } else {
                                setTypeOtherVisible(false);
                                setDetectionTypeOther('');
                              }
                            }}
                          >
                            {detectionType?.length > 0 && (
                              <>
                                {detectionType?.map((item) => (
                                  <Option key={item?.id} value={item?.key}>
                                    {item?.name}
                                  </Option>
                                ))}
                              </>
                            )}
                            <Option key="other" value="OTHER">
                              Other
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="Safety Concern" name="safetyConcern">
                          <TextArea
                            maxLength={350}
                            rows={4}
                            placeholder="Enter safety concern here"
                            onChange={
                              detectionCreated
                                ? handleSafetyConcernDebounce
                                : undefined
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="missingElement"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={detectionCreated}>
                            Mark this element as missing
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item className="text-right">
                          <ProjectAccessRole
                            allowedRoles={[
                              PROJECT_ROLES?.OWNER,
                              PROJECT_ROLES?.EDITOR,
                            ]}
                            currentRole={projectRole}
                          >
                            {detectionCreated ? (
                              <Button
                                className="mb-16"
                                type="link"
                                onClick={() => {
                                  setDeleteDetectionConfirmation(true);
                                }}
                              >
                                Delete Detection
                              </Button>
                            ) : (
                              <Button
                                className="secondary-btn mb-16"
                                size="small"
                                type="primary"
                                htmlType="submit"
                                loading={createDetectionLoading}
                              >
                                Save Detection
                              </Button>
                            )}
                          </ProjectAccessRole>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="border-dashed" />
                <div className="property-section" ref={ref6}>
                  <div className="container-div mt-24 d-flex justify-between align-center">
                    {detectionMissing ? (
                      <Typography>Details</Typography>
                    ) : (
                      <Typography>Properties</Typography>
                    )}
                    <div className="d-flex flex-horizontal">
                      <ProjectAccessRole
                        allowedRoles={[
                          PROJECT_ROLES?.OWNER,
                          PROJECT_ROLES?.EDITOR,
                        ]}
                        currentRole={projectRole}
                      >
                        {detectionMissing ? (
                          <Button
                            size="small"
                            type="primary"
                            className="secondary-btn ml-16"
                            onClick={() => setDetailModalVisible(true)}
                            disabled={
                              !detectionCreated ||
                              detectionPropertyList?.length === 1
                            }
                          >
                            <Plus /> Add Details
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            type="primary"
                            className="secondary-btn ml-16"
                            onClick={() => setPropertyModalVisible(true)}
                            disabled={!detectionCreated}
                            ref={ref5}
                          >
                            <Plus /> Add Property
                          </Button>
                        )}
                      </ProjectAccessRole>
                    </div>
                  </div>
                  <div className="mt-16 mb-16 detection-details-scroll">
                    <TableComponent
                      data={detectionPropertyList}
                      columns={propertyColumns}
                      tableClassName="custom-table"
                      loading={detectionPropertyLoading}
                      scroll={scrollConfig}
                    />
                    {!detectionPropertyLoading && hasMore && detectionId && (
                      <InView
                        onChange={(inView) => {
                          if (inView) {
                            handleScroll();
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="text-right mb-32">
                    <ProjectAccessRole
                      allowedRoles={[
                        PROJECT_ROLES?.OWNER,
                        PROJECT_ROLES?.EDITOR,
                      ]}
                      currentRole={projectRole}
                    >
                      <div className="d-flex justify-between">
                        <Button
                          size="large"
                          className="ml-auto"
                          type="primary"
                          onClick={() => {
                            handleSubmitDetection();
                          }}
                          disabled={!detectionCreated}
                        >
                          Save and Continue
                        </Button>
                        {detectionAddToReport ? (
                          <Button
                            size="large"
                            className="ml-auto"
                            onClick={() => handleDetectionReport(false)}
                            disabled={!detectionCreated}
                          >
                            Remove from Report
                          </Button>
                        ) : (
                          <Button
                            size="large"
                            type="primary"
                            className="ml-auto"
                            onClick={() => handleDetectionReport(true)}
                            disabled={!detectionCreated}
                          >
                            Add To Report
                          </Button>
                        )}
                      </div>
                    </ProjectAccessRole>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </LoaderComponent>
      {openDrawer && (
        <CommentDrawer
          detectionId={detectionId}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {annotationImageModalVisible && (
        <AnnotationImageModal
          visible={annotationImageModalVisible}
          setVisible={setAnnotationImageModalVisible}
          imageSource={
            imageType === IMAGE_TYPE?.ANNOTATED_IMAGE
              ? detectionImage
              : detectionImageRecommendation
          }
          annotatedImage={
            imageType === IMAGE_TYPE?.ANNOTATED_IMAGE
              ? detectionAnnotatedImage
              : recommendationAnnotatedImage
          }
          handleSavedImage={handleSavedImage}
        />
      )}
      {categoryOtherVisible && (
        <DetectionCategoryOtherModal
          visible={categoryOtherVisible}
          setVisible={setCategoryOtherVisible}
          setDetectionCategoryOther={setDetectionCategoryOther}
        />
      )}
      {typeOtherVisible && (
        <DetectionTypeOtherModal
          visible={typeOtherVisible}
          setVisible={() => {
            setTypeOtherVisible(false);
            form.setFieldValue('type', null);
          }}
          setDetectionTypeOther={setDetectionTypeOther}
        />
      )}
      {propertyModalVisible && (
        <PropertyModal
          isEdit={detectionPropertyUpdate}
          setIsEdit={setDetectionPropertyUpdate}
          updateData={viewPropertyModalData}
          setUpdateData={setViewPropertyModalData}
          visible={propertyModalVisible}
          setVisible={setPropertyModalVisible}
          categoryProperties={categoryProperties}
          typeProperties={typeProperties}
          setDetectionPropertyLoading={setDetectionPropertyLoading}
          detectionCategoryOther={detectionCategoryOther}
          detectionTypeOther={detectionTypeOther}
          setHasMore={setHasMore}
          setDetectionPropertyList={setDetectionPropertyList}
        />
      )}
      {viewPropertyModalVisible && (
        <ViewPropertyModal
          data={viewPropertyModalData}
          setData={setViewPropertyModalData}
          visible={viewPropertyModalVisible}
          setVisible={setViewPropertyModalVisible}
        />
      )}
      {viewDetailModalVisible && (
        <ViewDetailModal
          data={viewPropertyModalData}
          setData={setViewPropertyModalData}
          visible={viewDetailModalVisible}
          setVisible={setViewDetailModalVisible}
        />
      )}
      {detailModalVisible && (
        <DetailModal
          isEdit={detectionPropertyUpdate}
          setIsEdit={setDetectionPropertyUpdate}
          updateData={viewPropertyModalData}
          setUpdateData={setViewPropertyModalData}
          visible={detailModalVisible}
          setVisible={setDetailModalVisible}
          categoryProperties={categoryProperties}
          typeProperties={typeProperties}
          setDetectionPropertyLoading={setDetectionPropertyLoading}
        />
      )}
      {deleteDetectionConfirmation && (
        <CommonConfirmationModal
          visible={deleteDetectionConfirmation}
          onCancel={() => {
            setDeleteDetectionConfirmation(false);
          }}
          onOk={() => {
            handleDeleteDetection();
          }}
          title="Delete Detection"
          content="Are you sure you want to delete this detection?"
          okText="Delete"
          cancelText="Discard"
        />
      )}
      {deleteDetectionPropertyConfirmation && (
        <CommonConfirmationModal
          visible={deleteDetectionPropertyConfirmation}
          onCancel={() => {
            setDeleteDetectionPropertyConfirmation(false);
            setDeleteDetectionPropertyId();
          }}
          onOk={() => {
            handleDeleteDetectionProperty();
          }}
          title="Delete Property"
          content="Are you sure you want to delete this property?"
          okText="Delete"
          cancelText="Cancel"
        />
      )}
      {replaceDetectionImageVisible && (
        <CommonConfirmationModal
          visible={replaceDetectionImageVisible}
          onCancel={() => {
            setReplaceDetectionImageVisible(false);
          }}
          onOk={() => {
            handleAnnotatedImageReplace();
          }}
          title="Replace Image"
          content="You will lose all your previous changes if you replace this image. Are you sure you want to replace the image?"
          okText="Replace"
          cancelText="Discard"
        />
      )}
      {closeTourVisible && (
        <CommonConfirmationModal
          visible={closeTourVisible}
          onCancel={() => {
            setCloseTourVisible(false);
            setOpen(true);
          }}
          onOk={() => {
            setCloseTourVisible(false);
            setCurrentStep(null);
            setOpen(false);
            history.push(newLocation);
          }}
          title="Demo Project Tour"
          content="Are you sure you want to close the tour for demo project?"
          okText="Close"
          cancelText="Cancel"
        />
      )}
    </div>
  );
};

export default DetectionDetail;
