import React from 'react';
import { Button, Form, Input } from 'antd';
import './auth.less';
import { useMutation } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { formValidatorRules } from '../../common/utils';
import { NavArrowLeft, TerramaticLogo } from '../../assets/svg';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { ROUTES } from '../../common/constants';
import { FORGET_PASSWORD } from './graphql/Mutations';

const ForgetPassword = () => {
  const { required, email } = formValidatorRules;
  const history = useHistory();
  const [forgetPasswordMutating, { loading }] = useMutation(FORGET_PASSWORD, {
    onError() {},
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      const res = await forgetPasswordMutating({
        variables: { data: { ...formValues } },
      });

      if (res?.data) {
        history?.push(ROUTES?.FORGET_EMAIL_SENT, values?.email);
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <Link to={ROUTES?.LOGIN}>
                <div className="forget-back">
                  <NavArrowLeft className="navArrowLeftIcon" />
                  <div className="back-text">Back</div>
                </div>
              </Link>

              <h1 className="mt-0 mb-12 title-text">Forgot Password</h1>
              <div className="sub-title mb-32">
                Reset your password and regain access to Terramatic.
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="Forget-password"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="vertical"
                  className="full-width custom-label"
                >
                  <Form.Item
                    name="email"
                    label="Email-ID"
                    rules={[
                      { required, message: 'Please enter email!' },
                      email,
                    ]}
                  >
                    <Input placeholder="Enter your email id here" />
                  </Form.Item>

                  <Form.Item className="full-width mb-0">
                    <Button
                      type="primary"
                      size="large"
                      block
                      htmlType="submit"
                      loading={loading}
                    >
                      Send Link
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="signup-side-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default ForgetPassword;
