import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import CommonConfirmationModal from '../../../components/CommonConfirmationModal';
import { priorityOptions, riskLevelOptions } from '../../../common/constants';
import {
  DETECTION_PROPERTIES,
  DETECTION_PROPERTIES_RECOMMENDATIONS,
  GET_DETECTION_PROPERTY_IMAGE_UPLOAD_SIGNED_PUT_URL,
  GET_PROPERTY_PREDEFINED_VALUES,
} from '../graphql/Queries';
import {
  CREATE_DETECTION_PROPERTY,
  UPDATE_DETECTION_PROPERTY,
} from '../graphql/Mutations';
import ImageUploader from '../../../components/uploader/ImageUploader';
import { fileUpload } from '../../../common/utils';

const { Option } = Select;
const { TextArea } = Input;

const PropertyModal = ({
  isEdit,
  setIsEdit,
  updateData,
  setUpdateData,
  visible,
  setVisible,
  categoryProperties,
  typeProperties,
  setDetectionPropertyLoading,
  detectionCategoryOther,
  detectionTypeOther,
  setHasMore,
  setDetectionPropertyList,
}) => {
  const [form] = Form?.useForm();
  const propertyValue = Form?.useWatch(['property'], form);
  const matchedValue = Form?.useWatch(['matched'], form);
  const riskLevelValue = Form?.useWatch(['riskLevel'], form);
  const priorityValue = Form?.useWatch(['priority'], form);
  const propertyOtherValue = Form?.useWatch(['propertyOther'], form);
  const recommendationValue = Form?.useWatch(['recommendation'], form);
  const otherRecommendationValue = Form?.useWatch(
    ['otherRecommendation'],
    form,
  );
  const uploadImage = Form?.useWatch(['uploadImage'], form);
  const { detectionId } = useParams();
  const [matchedValueYesNo, setMatchedValueYesNo] = useState('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [standardValue, setStandardValue] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [detectionPropertiesOptions, setDetectionPropertiesOptions] = useState(
    '',
  );
  const [propertyOtherInputVisible, setPropertyOtherInputVisible] = useState(
    false,
  );
  const [
    detectionPropertiesRecommendations,
    setDetectionPropertiesRecommendations,
  ] = useState('');
  const [otherRecommendation, setOtherRecommendation] = useState(['']);

  const [getDetectionPropertyImageUploadSignedPutUrl] = useLazyQuery(
    GET_DETECTION_PROPERTY_IMAGE_UPLOAD_SIGNED_PUT_URL,
  );

  const [createDetectionProperty] = useMutation(CREATE_DETECTION_PROPERTY, {
    onCompleted() {
      setVisible(false);
      setDetectionPropertyLoading(true);
      setCreateLoading(false);
    },
    onError() {
      setCreateLoading(false);
    },
  });

  const [getDetectionProperties] = useLazyQuery(DETECTION_PROPERTIES, {
    onCompleted(res) {
      setDetectionPropertiesOptions(res?.properties?.data);
    },
  });

  const [getDetectionPropertiesRecommendation] = useLazyQuery(
    DETECTION_PROPERTIES_RECOMMENDATIONS,
    {
      onCompleted(res) {
        setDetectionPropertiesRecommendations(res?.recommendations?.data);
      },
      onError() {},
    },
  );

  const [getPropertyAutofillData] = useLazyQuery(
    GET_PROPERTY_PREDEFINED_VALUES,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (res?.getPropertyAutofillData?.data?.standardValue) {
          setStandardValue(res?.getPropertyAutofillData?.data?.standardValue);
        }
        if (res?.getPropertyAutofillData?.data?.riskLevel) {
          form.setFieldValue(
            'riskLevel',
            res?.getPropertyAutofillData?.data?.riskLevel,
          );
        }
        if (res?.getPropertyAutofillData?.data?.priority) {
          form.setFieldValue(
            'priority',
            res?.getPropertyAutofillData?.data?.priority,
          );
        }
      },
      onError() {},
    },
  );

  const [updateDetectionProperty] = useMutation(UPDATE_DETECTION_PROPERTY, {
    onCompleted() {
      setDetectionPropertyLoading(true);
      setCreateLoading(false);
      setVisible(false);
      if (setHasMore && setDetectionPropertyList) {
        setHasMore(true);
        setDetectionPropertyList([]);
      }
    },
    onError() {
      setCreateLoading(false);
    },
  });

  useEffect(() => {
    if ((visible && !detectionCategoryOther) || !detectionTypeOther) {
      getDetectionProperties({
        variables: {
          filter: {
            skip: 0,
            limit: 50,
            categoryKey: categoryProperties,
            subCategoryKey: typeProperties,
          },
        },
      });
    }
  }, [visible]);

  useEffect(() => {
    if (updateData) {
      if (updateData?.property?.id) {
        getDetectionPropertiesRecommendation({
          variables: {
            filter: {
              skip: 0,
              limit: 10,
              categoryKey: categoryProperties,
              subCategoryKey: typeProperties,
              propertyId: updateData?.property?.id,
            },
          },
        });
      }
      const validRecommendations = (updateData?.recommendations || [])
        .filter((item) => item?.otherRecommendation !== null)
        .map((item) => ({
          otherRecommendationId: item?.id,
          otherRecommendationText: item?.otherRecommendation,
        }));

      setOtherRecommendation([...validRecommendations, '']);
      if (updateData?.otherProperty?.length > 0) {
        setPropertyOtherInputVisible(true);
      }
      setMatchedValueYesNo(updateData?.matched === true ? 'yes' : 'no');
      form.setFieldsValue({
        property: updateData?.otherProperty
          ? 'other'
          : updateData?.property?.id,
        propertyOther: updateData?.otherProperty,
        matched: updateData?.matched === true ? 'yes' : 'no',
        riskLevel: updateData?.riskLevel,
        priority: updateData?.priority,
        recommendation: updateData?.recommendations
          ?.map(
            (item) => item?.recommendationId !== null && item?.recommendationId,
          )
          .filter((item) => item),
        otherRecommendation: updateData?.recommendations
          ?.map(
            (item) =>
              item?.otherRecommendation !== null && item?.otherRecommendation,
          )
          .filter((item) => item),
        uploadImage: updateData?.imageUrl
          ? [
              {
                name: updateData?.imageMeta?.fileName,
                preview: updateData?.imageUrl,
                ...updateData?.imageMeta,
              },
            ]
          : [],
      });
    }
  }, [isEdit, updateData]);

  const addOtherRecommendation = () => {
    const lastRecommendation =
      otherRecommendation[otherRecommendation.length - 1];
    if (lastRecommendation !== '') {
      setOtherRecommendation([...otherRecommendation, '']);
    }
  };

  const updateDetectionPropertyFunctions = (payload) => {
    updateDetectionProperty({
      variables: {
        where: {
          detectionPropertyId: updateData?.id,
        },
        data: payload,
      },
    });
  };

  const onFinish = async (values) => {
    setCreateLoading(true);
    let res;

    if (values?.uploadImage?.[0]?.originFileObj) {
      res = await getDetectionPropertyImageUploadSignedPutUrl({
        variables: {
          data: {
            fileName: values?.uploadImage?.[0]?.name,
            detectionId,
          },
        },
      });
      await fileUpload(
        res?.data?.getDetectionPropertyImageUploadSignedPutUrl?.signedUrl,
        values?.uploadImage?.[0]?.originFileObj,
      );
    }
    if (isEdit) {
      const otherRecommendations = otherRecommendation?.filter((item) => {
        if (item?.otherRecommendationText) {
          return values?.otherRecommendation?.includes(
            item?.otherRecommendationText,
          );
        }
        return values?.otherRecommendation?.includes(item);
      });
      const payload = {
        propertyId: values?.propertyOther ? null : values?.property,
        otherProperty: values?.propertyOther,
        matched: values?.matched === 'yes',
        recommendationIds: values?.recommendation,
        otherRecommendations,
        priority: values?.matched === 'yes' ? null : values?.priority,
        riskLevel: values?.matched === 'yes' ? null : values?.riskLevel,
        imageKey:
          res?.data?.getDetectionPropertyImageUploadSignedPutUrl?.fileName ||
          null,
        imageMeta: {
          fileName: values?.uploadImage?.[0]?.name || null,
          size: values?.uploadImage?.[0]?.size || null,
          extension: values?.uploadImage?.[0]?.type?.split('/')?.[1] || null,
          contentType: values?.uploadImage?.[0]?.type?.split('/')?.[1] || null,
        },
      };
      if (res) {
        updateDetectionPropertyFunctions(payload);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (uploadImage?.[0]?.fileName) {
          delete payload?.imageKey;
          delete payload?.imageMeta;
          updateDetectionPropertyFunctions(payload);
        } else {
          payload.imageKey = null;
          payload.imageMeta = null;
          updateDetectionPropertyFunctions(payload);
        }
      }

      setIsEdit(false);
      setUpdateData(null);
    } else {
      createDetectionProperty({
        variables: {
          data: {
            detectionId,
            propertyId: values?.propertyOther ? null : values?.property,
            otherProperty: values?.propertyOther,
            matched: values?.matched === 'yes',
            riskLevel: values?.riskLevel,
            priority: values?.priority,
            recommendationIds: values?.recommendation,
            otherRecommendations: values?.otherRecommendation,
            imageKey:
              res?.data?.getDetectionPropertyImageUploadSignedPutUrl
                ?.fileName || null,
            imageMeta: values?.uploadImage?.[0]?.name
              ? {
                  fileName: values?.uploadImage?.[0]?.name,
                  size: values?.uploadImage?.[0]?.size,
                  extension: values?.uploadImage?.[0]?.type?.split('/')?.[1],
                  contentType: values?.uploadImage?.[0]?.type?.split('/')?.[1],
                }
              : null,
          },
        },
      });
    }
  };

  useEffect(() => {
    const hasEmptyStringRecommendation = otherRecommendationValue?.some(
      (recommendation) => recommendation?.otherRecommendationText === '',
    );
    const hasEmptyRecommendation = otherRecommendationValue?.some(
      (recommendation) =>
        typeof recommendation === 'string' && recommendation?.trim() === '',
    );

    if (propertyValue && matchedValue === 'yes') {
      setIsSaveButtonDisabled(false);
    } else if (
      propertyValue &&
      matchedValue &&
      riskLevelValue &&
      priorityValue &&
      (recommendationValue?.length > 0 ||
        (otherRecommendationValue?.length > 0 &&
          otherRecommendationValue[0] &&
          !hasEmptyStringRecommendation &&
          !hasEmptyRecommendation))
    ) {
      if (
        propertyValue === 'other' &&
        (propertyOtherValue === undefined || propertyOtherValue?.length === 0)
      ) {
        setIsSaveButtonDisabled(true);
      } else {
        setIsSaveButtonDisabled(false);
      }
    } else {
      setIsSaveButtonDisabled(true);
    }
  }, [
    propertyValue,
    matchedValue,
    riskLevelValue,
    priorityValue,
    recommendationValue,
    otherRecommendationValue,
    propertyOtherValue,
  ]);

  const propertyValueSearch = debounce((input) => {
    getDetectionProperties({
      variables: {
        filter: {
          skip: 0,
          limit: 50,
          search: input?.trim()?.toLowerCase(),
          categoryKey: categoryProperties,
          subCategoryKey: typeProperties,
        },
      },
    });
  });

  useEffect(() => {
    if (detectionCategoryOther && detectionTypeOther) {
      form.setFieldsValue({
        property: 'other',
        matched: 'no',
      });
    }
  }, [detectionCategoryOther, detectionTypeOther]);

  return (
    <Modal
      destroyOnClose
      className="modal-components-design property-modal"
      title={isEdit ? 'Edit Property' : 'Add New Property'}
      open={visible}
      onCancel={() => {
        setCancelVisible(true);
      }}
      footer={null}
      centered
    >
      <CommonConfirmationModal
        visible={cancelVisible}
        onCancel={() => {
          setVisible(false);
          setIsEdit(false);
          setUpdateData(null);
          setCancelVisible(false);
        }}
        onOk={() => {
          setCancelVisible(false);
        }}
        title="Unsaved Changes"
        content="If you close, you will lose any changes that you have made."
        okText="Keep Editing"
        cancelText="Discard"
      />
      <Form
        name="videoModal"
        onFinish={onFinish}
        className="custom-label"
        form={form}
        layout="vertical"
        validateTrigger
      >
        <div className="form-overflow">
          <Form.Item
            label="Property"
            name="property"
            rules={[
              {
                required: true,
                message: 'Please select property',
              },
            ]}
          >
            <Select
              disabled={
                detectionCategoryOther?.length > 0 &&
                detectionTypeOther?.length > 0
              }
              showSearch={!propertyOtherInputVisible}
              filterOption={false}
              onSearch={propertyValueSearch}
              placeholder="Select property"
              onChange={(value, values) => {
                if (value === 'other') {
                  setDetectionPropertiesRecommendations('');
                  setPropertyOtherInputVisible(true);
                } else {
                  setPropertyOtherInputVisible(false);
                  getDetectionPropertiesRecommendation({
                    variables: {
                      filter: {
                        skip: 0,
                        limit: 10,
                        categoryKey: categoryProperties,
                        subCategoryKey: typeProperties,
                        propertyId: value,
                      },
                    },
                  });
                  getPropertyAutofillData({
                    variables: {
                      where: {
                        categoryKey: categoryProperties,
                        subCategoryKey: typeProperties,
                        propertyKey: values?.key,
                      },
                    },
                  });
                }
                form.setFieldValue('matched', 'no');
              }}
            >
              {detectionPropertiesOptions?.length > 0 && (
                <>
                  {detectionPropertiesOptions?.map((item) => (
                    <Option key={item?.key} value={item?.id}>
                      {item?.name}
                    </Option>
                  ))}
                </>
              )}
              <Option key="other" value="other">
                Other
              </Option>
            </Select>
          </Form.Item>
          {standardValue && (
            <p className="standard-value">Standard Value: {standardValue}</p>
          )}
          {((detectionCategoryOther?.length > 0 &&
            detectionTypeOther?.length > 0) ||
            propertyOtherInputVisible) && (
            <Form.Item
              className="other-property-input"
              name="propertyOther"
              rules={[
                {
                  required: true,
                  message: 'Please enter other property',
                },
              ]}
            >
              <Input placeholder="Enter property here" maxLength={100} />
            </Form.Item>
          )}
          <Form.Item
            label="Matched"
            name="matched"
            rules={[
              {
                required: true,
                message: 'Please select matched',
              },
            ]}
          >
            <Select
              placeholder="Select if the property matches"
              options={[
                {
                  value: 'no',
                  label: 'No',
                },
                {
                  value: 'yes',
                  label: 'Yes',
                },
              ]}
              onChange={(value) => {
                setMatchedValueYesNo(value);
              }}
              disabled={
                (detectionCategoryOther?.length > 0 &&
                  detectionTypeOther?.length > 0) ||
                propertyOtherInputVisible
              }
            />
          </Form.Item>
          {matchedValueYesNo !== 'yes' && (
            <>
              <Form.Item
                label="Risk Level"
                name="riskLevel"
                rules={[
                  {
                    required: true,
                    message: 'Please select risk level',
                  },
                ]}
              >
                <Select
                  placeholder="Select risk level"
                  options={riskLevelOptions}
                />
              </Form.Item>
              <Form.Item
                label="Priority"
                name="priority"
                rules={[
                  {
                    required: true,
                    message: 'Please select priority',
                  },
                ]}
              >
                <Select
                  placeholder="Select priority"
                  options={priorityOptions}
                />
              </Form.Item>
              <Form.Item
                label="Image"
                name="uploadImage"
                getValueFromEvent={(value) => {
                  if (Array.isArray(value)) return value;
                  return value?.fileList;
                }}
                valuePropName="fileList"
              >
                <ImageUploader />
              </Form.Item>
              {detectionPropertiesRecommendations?.length > 0 ? (
                <>
                  <Form.Item
                    label="Recommendations"
                    name="recommendation"
                    className="recommendation-required"
                  >
                    <Checkbox.Group>
                      {detectionPropertiesRecommendations?.map((item) => (
                        <Checkbox key={item?.id} value={item?.id}>
                          <TextArea
                            disabled
                            value={item?.description}
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            maxLength={200}
                          />
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    name="otherRecommendation"
                    className="other-recommendation"
                  >
                    <Checkbox.Group>
                      {otherRecommendation.map((recommendation, index) => (
                        <Checkbox
                          key={`${recommendation?.otherRecommendationId}-index`}
                          onClick={() => {
                            if (recommendation?.otherRecommendationText) {
                              addOtherRecommendation();
                            }
                          }}
                          value={
                            recommendation?.otherRecommendationText
                              ? recommendation?.otherRecommendationText
                              : recommendation
                          }
                        >
                          <TextArea
                            value={recommendation?.otherRecommendationText}
                            onChange={(e) => {
                              const updatedRecommendations = [
                                ...otherRecommendation,
                              ];
                              if (
                                updatedRecommendations?.[index]
                                  ?.otherRecommendationText
                              ) {
                                updatedRecommendations[
                                  index
                                ].otherRecommendationText = e?.target?.value;
                                setOtherRecommendation(updatedRecommendations);
                              } else {
                                updatedRecommendations[index] = {
                                  otherRecommendationText: e?.target?.value,
                                };
                                setOtherRecommendation(updatedRecommendations);
                              }
                            }}
                            maxLength={200}
                            placeholder="Enter recommendation"
                            autoSize={{ minRows: 1, maxRows: 5 }}
                          />
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label="Recommendations"
                  name="otherRecommendation"
                  className="recommendation-required"
                >
                  <Checkbox.Group>
                    {otherRecommendation.map((recommendation, index) => (
                      <Checkbox
                        key={`${recommendation?.otherRecommendationId}-index`}
                        onClick={() => {
                          if (recommendation?.otherRecommendationText) {
                            addOtherRecommendation();
                          }
                        }}
                        value={
                          recommendation?.otherRecommendationText
                            ? recommendation?.otherRecommendationText
                            : recommendation
                        }
                      >
                        <TextArea
                          value={recommendation?.otherRecommendationText}
                          onChange={(e) => {
                            const updatedRecommendations = [
                              ...otherRecommendation,
                            ];
                            if (
                              updatedRecommendations?.[index]
                                ?.otherRecommendationText
                            ) {
                              updatedRecommendations[
                                index
                              ].otherRecommendationText = e?.target?.value;
                              setOtherRecommendation(updatedRecommendations);
                            } else {
                              updatedRecommendations[index] = {
                                otherRecommendationText: e?.target?.value,
                              };
                              setOtherRecommendation(updatedRecommendations);
                            }
                          }}
                          maxLength={200}
                          placeholder="Enter recommendation"
                          autoSize={{ minRows: 1, maxRows: 5 }}
                        />
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              )}
            </>
          )}
        </div>
        <Form.Item shouldUpdate>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={isSaveButtonDisabled}
            loading={createLoading}
            block
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PropertyModal;
