import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Button, Form, Select } from 'antd';
import { debounce } from 'lodash';
import ModalComponents from '../../components/CommonModel';
import { DefaultMemberImage, DeleteIcon } from '../../assets/svg';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { WORKSPACE_MEMBERS, PROJECT_MEMBERS } from './graphql/Queries';
import { formValidatorRules } from '../../common/utils';
import { ROLES } from '../../common/constants';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import {
  ASSIGN_MEMBERS_TO_PROJECT,
  REMOVE_MEMBER_FROM_PROJECT,
  UPDATE_MEMBER_TO_PROJECT,
} from './graphql/Mutations';

const { Option } = Select;

const AssignMembersModal = ({ visible, setVisible, projectId }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);
  const { required } = formValidatorRules;
  const [members, setMembers] = useState();
  const [removeMember, setRemoveMember] = useState();
  const [workSpaceMembersList, setWorkSpaceMembersList] = useState([]);
  const [workspaceLoading, setWorkspaceLoading] = useState(false);
  const [deleteMember, setDeleteMember] = useState(false);

  const [getWorkspaceMembers] = useLazyQuery(WORKSPACE_MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setWorkSpaceMembersList(res?.getWorkspaceMembers?.data);
      setWorkspaceLoading(false);
    },
    onError() {
      setWorkspaceLoading(false);
    },
  });

  const [projectMembers] = useLazyQuery(PROJECT_MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted(record) {
      setMembers(record?.getProjectMembers?.data);
      setLoading(false);
    },
    onError() {},
  });

  const [assignProjectMember] = useMutation(ASSIGN_MEMBERS_TO_PROJECT, {
    onCompleted() {
      setLoading(true);
      form.resetFields();
      projectMembers({
        variables: {
          where: {
            projectId,
          },
        },
      });
    },
    onError() {},
  });

  const [removeProjectMember] = useMutation(REMOVE_MEMBER_FROM_PROJECT, {
    onCompleted() {
      setRemoveMember();
      setDeleteMember(false);
      setLoading(true);
      projectMembers({
        variables: {
          where: {
            projectId,
          },
        },
      });
    },
    onError() {},
  });

  const [updateProjectMember] = useMutation(UPDATE_MEMBER_TO_PROJECT, {
    onCompleted() {
      setLoading(true);
      projectMembers({
        variables: {
          where: {
            projectId,
          },
        },
      });
    },
    onError() {},
  });

  useEffect(() => {
    projectMembers({
      variables: {
        where: {
          projectId,
        },
      },
    });
    getWorkspaceMembers({
      variables: {
        where: {
          workspaceId: currentUser?.workspaceId,
        },
        filter: {
          search: '',
        },
      },
    });
  }, []);

  const handleSubmit = (values) => {
    assignProjectMember({
      variables: {
        where: {
          projectId,
          workspaceId: currentUser?.workspaceId,
        },
        data: {
          userIds: values?.email,
          role: values?.role,
        },
      },
    });
  };

  const handleClearSearch = () => {
    setWorkspaceLoading(true);
    getWorkspaceMembers({
      variables: {
        where: {
          workspaceId: currentUser?.workspaceId,
        },
        filter: {
          search: '',
        },
      },
    });
  };

  const handleMemberSearch = (event) => {
    const trimSearch = event?.trim();
    setWorkspaceLoading(true);
    getWorkspaceMembers({
      variables: {
        where: {
          workspaceId: currentUser?.workspaceId,
        },
        filter: {
          search: trimSearch,
        },
      },
    });
  };

  const handleSearchMemberDebounce = debounce(handleMemberSearch, 500);

  const handleMemberRoleChange = (value, item) => {
    updateProjectMember({
      variables: {
        where: {
          projectId,
          projectMemberId: item?.id,
        },
        data: {
          role: value,
        },
      },
    });
  };

  const handleMemberDelete = () => {
    removeProjectMember({
      variables: {
        where: {
          projectMemberId: removeMember,
          projectId,
        },
      },
    });
  };

  return (
    <ModalComponents
      width={600}
      centered
      onCancel={() => setVisible(false)}
      visible={visible}
      className="modal-components-design assign-member-modal"
      title="Assign"
    >
      <div className="assign-by-email">
        <Form
          form={form}
          initialValues={{ role: 'EDITOR' }}
          name="assignProjectMember"
          onFinish={handleSubmit}
        >
          <Form.Item name="email" rules={[required]}>
            <Select
              allowClear
              filterOption={false}
              placeholder="Enter email here"
              mode="multiple"
              defaultOpen={false}
              onSearch={handleSearchMemberDebounce}
              onClear={handleClearSearch}
              onBlur={handleClearSearch}
              loading={workspaceLoading}
            >
              {workSpaceMembersList?.length > 0 && (
                <>
                  {workSpaceMembersList?.map((member) => (
                    <Option key={member?.user?.id} value={member?.user?.id}>
                      {member?.user?.email}
                    </Option>
                  ))}
                </>
              )}
            </Select>
          </Form.Item>
          <Form.Item name="role">
            <Select
              options={[
                {
                  value: 'EDITOR',
                  label: 'Editor',
                },
                {
                  value: 'VIEWER',
                  label: 'Viewer',
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button size="small" type="primary" htmlType="submit">
              Assign
            </Button>
          </Form.Item>
        </Form>
        <p className="assign-member-text">
          *Only workspace members can be assigned to the project
        </p>
      </div>
      <hr className="dashed" />
      <LoaderComponent spinning={loading} setHeight={800}>
        <div className="members-listing">
          <p>Shared with</p>
          <div className="list-scroll">
            {members?.map((item) => (
              <div className="list" key={item?.id}>
                <div className="user">
                  <Avatar
                    className={
                      item?.user?.profileImageUrl ? '' : 'default-image'
                    }
                    src={
                      item?.user?.profileImageUrl ? (
                        item?.user?.profileImageUrl
                      ) : (
                        <DefaultMemberImage />
                      )
                    }
                    size={40}
                  />
                  <span className="email">{item?.user?.email}</span>
                </div>
                <div className="d-flex align-center">
                  {item?.role === ROLES?.OWNER ? (
                    <span className="roles owner">Owner</span>
                  ) : (
                    <Select
                      className="roles"
                      defaultValue={item?.role}
                      onChange={(e) => {
                        handleMemberRoleChange(e, item);
                      }}
                      options={[
                        {
                          value: 'EDITOR',
                          label: 'Editor',
                        },
                        {
                          value: 'VIEWER',
                          label: 'Viewer',
                        },
                      ]}
                    />
                  )}
                  {item?.role !== ROLES?.OWNER && (
                    <div
                      className="delete-icon"
                      onClick={() => {
                        setDeleteMember(true);
                        setRemoveMember(item?.id);
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <CommonConfirmationModal
          visible={deleteMember}
          onCancel={() => {
            setRemoveMember();
            setDeleteMember(false);
          }}
          onOk={handleMemberDelete}
          title="Delete Member"
          content="Are you sure to delete this member from the project?"
          okText="Delete"
          cancelText="Discard"
        />
      </LoaderComponent>
    </ModalComponents>
  );
};

export default AssignMembersModal;
