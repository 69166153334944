import { Button } from 'antd';
import React from 'react';
import { MobileOpenAppIcon } from '../assets/svg';

const MobileLoginSignup = ({ signup }) => (
  <>
    <div className="header-open">
      <div className="logo-text">
        <MobileOpenAppIcon />
        <div className="text">
          <span>Terramatic</span>
          <p>Open in the Terramatic app</p>
        </div>
      </div>
      <Button size="small" type="primary">
        OPEN
      </Button>
    </div>
    <div className="mobile-dashboard">
      {signup ? (
        <>
          <h3>Signed Up Successfully!</h3>
          <p>
            Your sign-up was successful! To access 'Terramatic', just log in at{' '}
            <a href={process.env.REACT_APP_WEB_URL}>www.terramatic.ai</a> on
            your desktop or use the mobile app.
          </p>
          <Button type="primary">Download App</Button>
        </>
      ) : (
        <>
          <h3>Login</h3>
          <p>
            To log in, you can either download the mobile app or use the web app
            on your desktop.
          </p>
          <Button type="primary">Download App</Button>
        </>
      )}
    </div>
  </>
);

export default MobileLoginSignup;
