import { Button, Typography, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { AddVideoIcon } from '../../assets/svg';
import videoCircle from '../../assets/svg/videoCircle.svg';
import { getVideoDuration, secondsToHms } from '../../common/utils';
import { ALL_VIDEO_UPLOAD } from '../../common/constants';

const VideoUploader = ({ fileList, id, status, disabled, ...rest }) => {
  const [fileInfo, setFileInfo] = useState(null);

  useEffect(() => {
    (async () => {
      if (fileList?.length) {
        const fileObj = fileList?.[0];
        const name = fileObj?.name;
        const size = `${((fileObj?.size ?? 0) / 1000)?.toFixed(1)} KB`;
        const ext = name?.substring(name?.lastIndexOf('.') + 1)?.toLowerCase();

        if (!ALL_VIDEO_UPLOAD?.includes(ext)) {
          message.error(
            `Unsupported Video Format. Please upload a video in ${ALL_VIDEO_UPLOAD} format`,
          );
        } else {
          const duration =
            fileObj?.duration ||
            (await getVideoDuration(fileObj?.originFileObj));
          setFileInfo({
            name,
            size,
            duration,
          });
        }
      } else {
        setFileInfo(null);
      }
    })();
  }, [fileList]);

  const handleReplace = () => {
    // eslint-disable-next-line no-undef
    const fileInput = document?.getElementById(id);
    if (fileInput) {
      fileInput?.click();
    }
  };

  return (
    <Upload
      showUploadList={false}
      className="upload-video"
      accept="video/mp4"
      fileList={fileList}
      beforeUpload={() => false}
      maxCount={1}
      id={id}
      {...rest}
    >
      {fileInfo ? (
        <div
          className="upload-video-preview d-flex align-center"
          onClick={(e) => {
            e?.stopPropagation();
          }}
        >
          <div className="upload-video-preview-img d-flex justify-center align-center">
            <img src={videoCircle} alt="error in video upload" />
          </div>
          <div>
            <Typography className="upload-video-preview-name">
              {fileInfo?.name}
            </Typography>
            <Typography className="upload-video-preview-time">
              {`${secondsToHms(fileInfo?.duration)} ${fileInfo?.size}`}
            </Typography>
            <Typography className="upload-video-preview-time">
              <Button
                onClick={handleReplace}
                size="small"
                disabled={status || disabled}
              >
                Replace
              </Button>
            </Typography>
          </div>
        </div>
      ) : (
        <Button icon={<AddVideoIcon />} className="dashed-button-upload">
          Select Video
        </Button>
      )}
    </Upload>
  );
};

export default VideoUploader;
