import React, { useContext } from 'react';
import { AppContext } from '../AppContext';

const AccessRoleAdmin = ({ children, allowedRoles }) => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser() || {};
  if (allowedRoles?.includes(user?.role)) {
    return <div>{children}</div>;
  }
  return null;
};

export default AccessRoleAdmin;
