import { Avatar, Button, Drawer, Form, Input, Spin } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import LoaderComponent from '../../components/LoaderComponent';
import { AppContext } from '../../AppContext';
import { CREATE_DETECTION_COMMENTS } from './graphql/Mutations';
import { DETECTION_COMMENTS } from './graphql/Queries';
import { LIMIT, ROUTES } from '../../common/constants';
import { getInitials } from '../../common/utils';
import CommentTimeAgo from '../../components/CommentTImeAgo';

const { TextArea } = Input;

const CommentDrawer = ({
  openDrawer,
  setOpenDrawer,
  detectionId,
  comingFrom,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const commentText = Form?.useWatch(['newComment'], form);
  const [commentsData, setCommentsData] = useState([]);
  const [addedNewComment, setAddedNewComment] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const email = currentUser?.email?.toUpperCase();
  const name = currentUser?.fullName?.split(' ');
  const firstName = name && name?.[0]?.toUpperCase();
  const lastName = name && name?.[1]?.toUpperCase();

  const [detectionComments, { refetch: fetchComments }] = useLazyQuery(
    DETECTION_COMMENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (addedNewComment) {
          setCommentsData(res?.detectionComments?.data);
        } else {
          setCommentsData([...commentsData, ...res?.detectionComments?.data]);
        }
        if (res?.detectionComments?.data?.length < LIMIT) {
          setHasMore(false);
        }
        setLoading(false);
        setScrollLoading(false);
      },
      onError() {
        setLoading(false);
        setScrollLoading(false);
      },
    },
  );
  const [createDetectionComments] = useMutation(CREATE_DETECTION_COMMENTS, {
    fetchPolicy: 'network-only',
    onCompleted() {
      setAddedNewComment(true);
      fetchComments();
    },
  });

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await createDetectionComments({
        variables: {
          data: {
            detectionId,
            comment: values?.newComment,
          },
        },
      });
      form.resetFields();
    } catch (error) {
      return error;
    }
  };

  const handleScroll = () => {
    setScrollLoading(true);
    detectionComments({
      variables: {
        where: {
          detectionId,
        },
        filter: {
          skip: commentsData?.length,
          limit: LIMIT,
        },
      },
    });
  };

  useEffect(() => {
    if (detectionId) {
      setLoading(true);
      detectionComments({
        variables: {
          where: {
            detectionId,
          },
          filter: {
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, [detectionId]);

  return (
    <Drawer
      className="comment-drawer"
      title="Comments"
      placement="right"
      onClose={onCloseDrawer}
      open={openDrawer}
    >
      <p className="detection-id">
        Detection ID:
        <b className="ml-6 detection-id-sn">{detectionId}</b>
      </p>

      {comingFrom !== ROUTES?.SEE_ALL && (
        <>
          <div className="comment-box">
            <div>
              {currentUser?.profileImageUrl ? (
                <Avatar src={currentUser?.profileImageUrl} size={48} />
              ) : (
                <Avatar className="user-avatar" alt="Avatar" size={48}>
                  {getInitials(firstName, lastName) || getInitials(email)}
                </Avatar>
              )}
            </div>
            <div className="comment-form">
              <Form form={form} onFinish={onFinish}>
                <Form.Item name="newComment" className="text-box">
                  <TextArea
                    rows={5}
                    placeholder="Add your comment here"
                    maxLength={500}
                  />
                </Form.Item>
                <Form.Item className="text-left m-0">
                  <Button
                    className="secondary-btn"
                    size="small"
                    type="primary"
                    htmlType="submit"
                    disabled={
                      commentText?.length === 0 ||
                      commentText?.length === undefined
                    }
                  >
                    Add Comment
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="border-dashed" />
        </>
      )}

      {loading ? (
        <>
          <LoaderComponent spinning={loading} />
        </>
      ) : (
        <>
          <div className="comment-overflow">
            {commentsData?.length > 0 ? (
              <>
                {commentsData?.map((elem) => {
                  const creatorName = elem?.creator?.fullName?.split(' ');
                  const creatorFirstName =
                    creatorName && creatorName?.[0]?.toUpperCase();
                  const creatorLastName =
                    creatorName && creatorName?.[1]?.toUpperCase();

                  return (
                    <div className="comment-list" key={elem?.id}>
                      <div className="comment">
                        <div>
                          {elem?.creator?.profileImageUrl ? (
                            <Avatar
                              src={elem?.creator?.profileImageUrl}
                              size={40}
                            />
                          ) : (
                            <Avatar
                              className="user-avatar"
                              alt="Avatar"
                              size={40}
                            >
                              {getInitials(creatorFirstName, creatorLastName) ||
                                getInitials(
                                  elem?.creator?.email?.toUpperCase(),
                                )}
                            </Avatar>
                          )}
                        </div>
                        <div className="comment-form">
                          <div className="d-flex align-baseline">
                            <p className="comment-form-sub-name mb-0 mt-0">
                              {elem?.creator?.fullName
                                ? elem?.creator?.fullName
                                : elem?.creator?.email}
                            </p>
                            <CommentTimeAgo
                              className="ml-8 comment-time"
                              timestamp={elem?.createdAt}
                            />
                          </div>
                          <p className="comment-form-comment">
                            {elem?.comment}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <InView
                  as="div"
                  onChange={(inView) =>
                    inView && hasMore ? handleScroll() : null
                  }
                />
                {scrollLoading && !loading ? <Spin /> : null}
              </>
            ) : (
              <>
                <div className="no-comment">No comments found</div>
              </>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default React.memo(CommentDrawer);
