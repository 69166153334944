import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_WORKSPACE_MEMBERS = gql`
  query workspaceMembers($filter: WorkspaceMemberFilter) {
    workspaceMembers(filter: $filter) {
      count
      data {
        email
        fullName
        status
        userId
        id
        workspaceId
        role
      }
    }
  }
`;
