import React from 'react';
import './auth.less';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { ROUTES } from '../../common/constants';
import { TerramaticLogo } from '../../assets/svg';

const ChangePassword = () => {
  const history = useHistory();

  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <h1 className="mt-0 mb-12 title-text">Password Changed</h1>
              <div className="sub-title mb-32">
                Your password has been changed successfully
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Button
                  type="primary"
                  size="large"
                  block
                  htmlType="submit"
                  onClick={() => history?.push(ROUTES?.LOGIN)}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="side-cover-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default ChangePassword;
