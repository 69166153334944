import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES, REFRESH_TOKEN } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN_MUTATION } from './graphql/Mutations';

const RefreshToken = () => {
  const history = useHistory();
  const {
    location: { state },
  } = history;

  const { initializeAuth } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const refreshToken = localStorage?.getItem(REFRESH_TOKEN);

  const [refreshTokenMutation, { loading }] = useMutation(
    REFRESH_TOKEN_MUTATION,
    {
      variables: {
        data: {
          refreshToken,
        },
      },
      fetchPolicy: 'network-only',
      onCompleted(res) {
        initializeAuth(res?.refreshToken?.accessToken);
        if (state?.from) {
          history?.replace(state?.from);
        } else {
          history?.goBack();
        }
      },
      onError() {
        history?.replace(ROUTES?.LOGOUT);
      },
    },
  );

  useEffect(() => {
    refreshTokenMutation();
  }, []);

  return <LoaderComponent spinning={loading} />;
};

export default RefreshToken;
