import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROJECT_VIDEO = gql`
  query getProjectVideo($where: VideoUniqueInput!) {
    getProjectVideo(where: $where) {
      data {
        workspace {
          id
        }
        project {
          id
        }
        id
        uid
        name
        videoUrl
        aiVideoUrl
        kmlUrl
        startingChainage
        endingChainage
        videoMeta
        kmlMeta
        createdAt
        updatedAt
        status
        uploadedFrom
        thumbnailUrl
        aiProcessed
        sourceDataUrl
        sourceDataMeta
        direction
      }
    }
  }
`;

export const CHECK_VIDEO_NAME = gql`
  query checkVideoName($where: VideoNameCheckInput!) {
    checkVideoName(where: $where) {
      message
    }
  }
`;

export const ROAD_TYPES = gql`
  query roadTypes {
    roadTypes {
      data {
        id
        key
        name
        width
      }
    }
  }
`;

export const CHECK_PROJECT_NAME = gql`
  query checkProjectName($where: ProjectNameCheckInput!) {
    checkProjectName(where: $where) {
      message
    }
  }
`;

export const GET_PROJECT_VIDEO_SIGNED_URL = gql`
  query getProjectVideoSignedPutUrl($data: GetProjectSignedPutUrlCommonInput!) {
    getProjectVideoSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const GET_PROJECT_KML_SIGNED_URL = gql`
  query getProjectKmlSignedPutUrl($data: GetProjectSignedPutUrlCommonInput!) {
    getProjectKmlSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const GET_PROJECT_VIDEO_SOURCE_DATA_SIGNED_PUT_URL = gql`
  query GetProjectVideoSourceDataSignedPutUrl(
    $data: GetProjectSignedPutUrlCommonInput!
  ) {
    getProjectVideoSourceDataSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_DETECTION_PROPERTY_IMAGE_UPLOAD_SIGNED_PUT_URL = gql`
  query GetDetectionPropertyImageUploadSignedPutUrl(
    $data: GetDetectionPropertyImageUploadSignedPutUrlInput!
  ) {
    getDetectionPropertyImageUploadSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query getProfileImageUploadSignedPutUrl(
    $data: GetProfileImageUploadSignedPutUrlInput!
  ) {
    getProfileImageUploadSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const GET_PROJECT_VIDEOS = gql`
  query getProjectVideos($where: ProjectUniqueInput!, $filter: VideosFilter) {
    getProjectVideos(where: $where, filter: $filter) {
      data {
        id
        uid
        name
        videoUrl
        kmlUrl
        startingChainage
        endingChainage
        videoMeta
        kmlMeta
        createdAt
        updatedAt
        status
        aiVideoUrl
        aiProcessed
        direction
        aiJobs {
          id
          type
          status
          instanceId
          completion
          metaData
          createdBy
          endTime
          createdAt
          updatedAt
        }
      }
      count
    }
  }
`;

export const SYNC_DATA = gql`
  query syncData($where: VideoUniqueInput!, $filter: SourceSyncDataFilter) {
    syncData(where: $where, filter: $filter) {
      data {
        id
        chainage
        kmlTimestamp
        videoTimestamp
        location
        createdAt
        updatedAt
        chainagePoint
      }
      count
    }
  }
`;

export const SOURCE_SYNC_DATA_LIST = gql`
  query sourceSyncDataList(
    $where: VideoUniqueInput!
    $filter: SourceSyncDataFilter
  ) {
    sourceSyncDataList(where: $where, filter: $filter) {
      data {
        id
        video {
          id
          uid
          name
          videoUrl
          kmlUrl
          startingChainage
          endingChainage
          videoMeta
          kmlMeta
          createdAt
          updatedAt
        }
        kmlTimestamp
        videoTimestamp
        location
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query getProject($where: ProjectUniqueInput!) {
    getProject(where: $where) {
      data {
        id
        createdAt
        name
        logoUrl
        startAddress
        endAddress
        startPoint
        endPoint
        roadType {
          id
          key
          name
        }
        roadWidth
        description
        uid
        userRole
        reportHeaderName
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects($where: WorkspaceIdInput!, $filter: ProjectsFilter) {
    getProjects(where: $where, filter: $filter) {
      count
      data {
        isDemo
        startAddress
        id
        name
        logoUrl
        creator {
          id
          email
          fullName
          profileImageUrl
          role
          isActive
          lastActiveOn
          uid
          verifiedOn
          createdAt
          updatedAt
          workspaceId
          workspaceRole
        }
        workspace {
          id
          agency
          name
          createdBy {
            id
            email
            fullName
            profileImageUrl
            role
            isActive
            lastActiveOn
            uid
            verifiedOn
            createdAt
            updatedAt
            workspaceId
            workspaceRole
          }
          logoUrl
          uid
          createdAt
          updatedAt
        }
        endAddress
        startPoint
        endPoint
        roadType {
          id
          name
          key
        }
        roadWidth
        description
        uid
        userRole
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SIGNED_URL_PROJECT_LOGO = gql`
  query getProjectLogoSignedPutUrl($data: GetProjectSignedPutUrlCommonInput!) {
    getProjectLogoSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_SIGNED_URL_WORKSPACE_LOGO = gql`
  query getWorkspaceLogoUploadSignedPutUrl(
    $data: GetWorkspaceLogoUploadSignedPutUrlInput!
  ) {
    getWorkspaceLogoUploadSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const WORKSPACE_MEMBERS = gql`
  query getWorkspaceMembers(
    $where: WorkspaceUniqueInput!
    $filter: GetWorkspaceMembersFilter
  ) {
    getWorkspaceMembers(where: $where, filter: $filter) {
      data {
        user {
          id
          email
          fullName
          profileImageUrl
        }
      }
    }
  }
`;

export const GET_WORKSPACE_DETAIL = gql`
  query workspace {
    workspace {
      createdBy {
        id
        email
        fullName
        profileImageUrl
        role
        isActive
        lastActiveOn
        uid
        verifiedOn
        createdAt
        updatedAt
        workspaceId
        workspaceRole
      }
      agency
      name
      logoUrl
      uid
      createdAt
      updatedAt
      bio
      features
      isActive
      distanceCovered
      contactDetails
    }
  }
`;

export const PROJECT_MEMBERS = gql`
  query getProjectMembers($where: ProjectUniqueInput!) {
    getProjectMembers(where: $where) {
      data {
        id
        workspace {
          id
        }
        project {
          id
        }
        user {
          id
          fullName
          email
          profileImageUrl
        }
        creator {
          id
        }
        role
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_DETECTIONS_LIST = gql`
  query detectionList($where: DetectionsWhereInput!) {
    detectionList(where: $where) {
      count
      data {
        id
        otherCategory
        otherSubCategory
        mismatched
        elementMissing
        aiProcessed
        aiJobId
        category {
          id
          name
          key
        }
        subCategory {
          id
          name
          key
          categoryKey
        }
        syncData {
          id
          chainage
          chainagePoint
          location
        }
        riskLevel
        priority
        addToReport
        createdAt
        detectionProperties {
          id
          matched
          riskLevel
          priority
        }
        videoId
      }
    }
  }
`;

export const GET_DETECTIONS_SEE_ALL = gql`
  query detections(
    $where: DetectionsWhereInput!
    $filter: DetectionFilter
    $sort: [DetectionSort]
  ) {
    detections(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        otherCategory
        otherSubCategory
        mismatched
        videoId
        category {
          id
          name
          key
        }
        subCategory {
          id
          name
          key
          categoryKey
        }
        syncData {
          id
          chainage
          kmlTimestamp
          videoTimestamp
          location
          createdAt
          updatedAt
          videoId
          chainagePoint
        }
        addToReport
        createdAt
        riskLevel
        priority
        detectionProperties {
          id
          detectionId
          propertyId
          otherProperty
          matched
          riskLevel
          priority
          recommendations {
            id
            otherRecommendation
            recommendationId
            recommendation {
              id
              description
            }
          }
          property {
            id
            name
            key
            categoryKey
            subCategoryKey
          }
        }
        videoId
      }
    }
  }
`;

export const GET_DETECTION = gql`
  query detection($where: DetectionUniqueInput) {
    detection(where: $where) {
      data {
        id
        videoId
        otherCategory
        safetyConcern
        addToReport
        otherSubCategory
        mismatched
        category {
          id
          name
          key
        }
        subCategory {
          id
          name
          key
          categoryKey
        }
        syncData {
          id
          chainage
          chainagePoint
        }
        replacedImage
        sourceImage
        annotatedImage
        uid
        elementMissing
        recommendationSourceImage
        recommendationReplacedImage
        recommendationAnnotatedImage
        endingChainage
      }
    }
  }
`;

export const DETECTION_CATEGORY = gql`
  query categories($filter: CategoriesFilter) {
    categories(filter: $filter) {
      data {
        id
        name
        key
      }
      count
    }
  }
`;

export const DETECTION_SUB_CATEGORY = gql`
  query subCategories($filter: SubCategoriesFilter) {
    subCategories(filter: $filter) {
      data {
        id
        name
        key
        categoryKey
      }
      count
    }
  }
`;

export const DETECTION_PROPERTIES = gql`
  query properties($filter: PropertiesFilter!) {
    properties(filter: $filter) {
      data {
        id
        name
        key
        categoryKey
        subCategoryKey
      }
      count
    }
  }
`;

export const DETECTION_PROPERTIES_RECOMMENDATIONS = gql`
  query Recommendations($filter: RecommendationsFilter!) {
    recommendations(filter: $filter) {
      data {
        id
        description
        categoryKey
        subCategoryKey
        propertyId
      }
      count
    }
  }
`;

export const GET_DETECTION_IMAGE_SIGNED_URL = gql`
  query DetectionImageUploadSignedUrl($data: DetectionImageUploadSignedInput!) {
    detectionImageUploadSignedUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_DETECTION_PROPERTIES_LIST = gql`
  query detectionProperties(
    $where: DetectionUniqueInput!
    $filter: DetectionPropertiesFilter
  ) {
    detectionProperties(where: $where, filter: $filter) {
      data {
        id
        detectionId
        propertyId
        otherProperty
        matched
        riskLevel
        priority
        imageUrl
        imageMeta
        recommendations {
          id
          otherRecommendation
          recommendationId
          recommendation {
            id
            description
          }
        }
        property {
          id
          name
          key
          categoryKey
          subCategoryKey
        }
        addToReport
      }
      count
    }
  }
`;

export const DETECTION_COMMENTS = gql`
  query detectionComments(
    $filter: DetectionCommentFilter!
    $where: DetectionUniqueInput!
  ) {
    detectionComments(filter: $filter, where: $where) {
      data {
        id
        comment
        detectionId
        createdAt
        creator {
          id
          email
          fullName
          profileImageUrl
        }
      }
      count
    }
  }
`;

export const ANALYSIS_CSV_SIGNED_PUT_URL = gql`
  query analysisCsvSignedPutUrl($data: GetAnalysisCsvSignedPutUrlInput!) {
    analysisCsvSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const ANALYSIS = gql`
  query Analysis($where: AnalysisUniqueInput!) {
    analysis(where: $where) {
      data {
        id
        projectId
        createdBy
        uid
        chainage
        duration
        type
        csvUrl
        csvMeta
        graphs
        createdAt
        updatedAt
      }
      sourceData
    }
  }
`;

export const GET_REPORT = gql`
  query reports($where: ProjectUniqueInput!, $filter: ReportsFilter) {
    reports(where: $where, filter: $filter) {
      count
      data {
        id
        projectId
        createdBy
        error
        status
        name
        downloadUrl
        createdAt
        aiProcessed
      }
    }
  }
`;

export const GET_PROPERTY_PREDEFINED_VALUES = gql`
  query getPropertyAutofillData($where: PropertyWhereInput!) {
    getPropertyAutofillData(where: $where) {
      data {
        id
        riskLevel
        priority
        standardValue
      }
    }
  }
`;
export const DETECTION_AI_JOB = gql`
  query aiJob($where: AIJobUniqueInput!) {
    aiJob(where: $where) {
      id
      type
      status
      instanceId
      completion
      metaData
      createdBy
      endTime
      createdAt
      updatedAt
    }
  }
`;

export const CHECK_PROJECT_HAS_DETECTIONS = gql`
  query CheckProjectHasAIDetections($where: ProjectUniqueInput) {
    checkProjectHasAIDetections(where: $where) {
      hasAIDetections
    }
  }
`;
