import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  Autocomplete,
  useLoadScript,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { nanoid } from 'nanoid';
import { debounce } from 'lodash';
import pinIcon from '../../assets/svg/pin.svg';
import altPinIcon from '../../assets/svg/pinAlt.svg';
import ModalComponents from '../../components/CommonModel';
import { InfoEmpty, NavArrowLeft } from '../../assets/svg';
import { fileUpload, formValidatorRules } from '../../common/utils';
import CommonUploadFile from '../../components/CommonUploadFile';
import { AppContext } from '../../AppContext';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import {
  CHECK_PROJECT_NAME,
  GET_SIGNED_URL_PROJECT_LOGO,
  ROAD_TYPES,
} from './graphql/Queries';
import { CREATE_PROJECT, EDIT_PROJECT } from './graphql/Mutations';
import LoaderComponent from '../../components/LoaderComponent';
import { center, containerStyle, libraries } from '../../common/constants';

const { Option } = Select;

const getLatLng = (place) => {
  if (!place) return;
  return {
    lat: place?.geometry?.location?.lat(),
    lng: place?.geometry?.location?.lng(),
  };
};

const SelectPlace = ({ onChange, placeholder, value }) => {
  const ref = useRef();
  const [selectedPlace, setSelectedPlace] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event?.target?.value);
  };

  useEffect(() => {
    if (value?.formatted_address) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (ref.current && selectedPlace !== '') {
      setInputValue(selectedPlace);
    }
  }, [selectedPlace]);

  return (
    <Autocomplete
      onLoad={(setRef) => {
        ref.current = setRef;
      }}
      onPlaceChanged={() => {
        if (ref.current) {
          const place = ref?.current?.getPlace();
          onChange(place);
          setSelectedPlace(place?.formatted_address);
        }
      }}
    >
      <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      />
    </Autocomplete>
  );
};

const startMarker = {
  icon: {
    url: pinIcon,
  },
};
const endMarker = {
  icon: {
    url: altPinIcon,
  },
};

const { required, checkImageSizeAndDimensions } = formValidatorRules;

const AddNewProject = ({
  visible,
  setVisible,
  onSubmit,
  projectEdit,
  setProjectEdit,
  editProjectData,
  projectRefetch,
}) => {
  const { projectId } = useParams();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [discardModal, setDiscardModal] = useState(false);
  const [form] = Form?.useForm();
  const [previewImage, setPreviewImage] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const [editProjectUid, setEditProjectUid] = useState(null);
  const [startPositionValue, setStartPositionValue] = useState(null);
  const [endPositionValue, setEndPositionValue] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [directions, setDirections] = useState(null);
  const { getCurrentUser } = useContext(AppContext);
  const { workspaceId } = getCurrentUser() || null;
  const isReportHeaderNameChanged = useRef(false);
  let startId;
  let endId;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const initialValue = {
    name: '',
    startPoint: '',
    endPoint: '',
    roadType: 'Select road type',
  };

  const { data: roadTypesData } = useQuery(ROAD_TYPES);

  const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL_PROJECT_LOGO, {
    onError() {},
    fetchPolicy: 'no-cache',
  });

  const [createProject, { loading: createProjectLoading }] = useMutation(
    CREATE_PROJECT,
    {
      onError() {},
    },
  );

  const [editProject, { loading: editProjectLoading }] = useMutation(
    EDIT_PROJECT,
    {
      onError() {},
    },
  );

  const [checkProjectName] = useLazyQuery(CHECK_PROJECT_NAME, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      form?.setFields([
        {
          name: 'projectName',
          errors: [error?.message],
        },
      ]);
    },
  });

  const handleMapClick = (event) => {
    const { latLng } = event;
    const { lat, lng } = latLng.toJSON();

    if (!startPosition) {
      setStartPosition({ lat, lng });
    } else if (!endPosition) {
      setEndPosition({ lat, lng });
    }
  };

  const handleStartPosDrag = (event) => {
    const { latLng } = event;
    const { lat, lng } = latLng.toJSON();
    clearTimeout(startId);
    startId = setTimeout(() => {
      startId = setStartPosition({ lat, lng });
    }, 1000);
  };

  const handleEndPosDrag = (event) => {
    const { latLng } = event;
    const { lat, lng } = latLng?.toJSON();
    clearTimeout(endId);
    endId = setTimeout(() => {
      setEndPosition({ lat, lng });
    }, 1000);
  };

  const resetFormValues = () => {
    form?.resetFields();
    setPreviewImage(null);
    setStartPosition(null);
    setEndPosition(null);
    setVisible(false);
    onSubmit();
  };

  const centerMapFunc = async () => {
    // eslint-disable-next-line no-undef
    const userLocation = navigator?.geolocation;
    if (userLocation) {
      userLocation?.getCurrentPosition((data) => {
        setCurrentPosition({
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        });
        return {
          lat: data?.coords?.latitude,
          lng: data?.coords?.longitude,
        };
      });
    }

    return center;
  };

  const onFinish = async (values) => {
    setLoadingCreate(true);

    try {
      let logoKey = null;
      const uid = editProjectUid || nanoid(8);
      if (currentFile) {
        const res = await getSignedUrl({
          variables: {
            data: {
              fileName: currentFile?.name,
              uid,
            },
          },
        });

        await fileUpload(
          res?.data?.getProjectLogoSignedPutUrl?.signedUrl,
          currentFile,
        );
        logoKey = res?.data?.getProjectLogoSignedPutUrl?.fileName;
      }

      const projectData = {
        description: values?.description,
        endAddress: values?.endPoint?.formatted_address || values?.endPoint,
        endPoint: {
          lat: endPosition?.lat,
          lng: endPosition?.lng,
        },
        name: values?.projectName,
        reportHeaderName: values?.reportHeaderName,
        logoKey,
        startAddress:
          values?.startPoint?.formatted_address || values?.startPoint,
        roadWidth: Number(values?.roadWidth),
        startPoint: {
          lat: startPosition?.lat,
          lng: startPosition?.lng,
        },
        roadTypeKey: values?.roadType,
        uid,
      };

      if (projectEdit) {
        await editProject({
          variables: {
            data: projectData,
            where: {
              projectId,
            },
          },
        });
        projectRefetch();
      } else {
        await createProject({
          variables: {
            data: {
              ...projectData,
              workspaceId,
            },
          },
        });
      }

      resetFormValues();
    } catch (err) {
      return err;
    }
    setLoadingCreate(false);
  };

  useEffect(() => {
    if (isLoaded && startPosition && endPosition) {
      const directionsOptions = {
        origin: startPosition,
        destination: endPosition,
        travelMode: 'DRIVING',
      };
      // eslint-disable-next-line no-undef
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(directionsOptions, (result, status) => {
        // eslint-disable-next-line no-undef
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      });
    }
  }, [isLoaded, startPosition, endPosition]);

  useEffect(() => {
    setMapCenter(currentPosition);
  }, [currentPosition]);

  useEffect(() => {
    if (editProjectData) {
      const logoUrl = editProjectData?.getProject?.data?.logoUrl;
      setEditProjectUid(editProjectData?.getProject?.data?.uid);
      setPreviewImage(logoUrl);
      setStartPositionValue(editProjectData?.getProject?.data?.startAddress);
      setEndPositionValue(editProjectData?.getProject?.data?.endAddress);
      setStartPosition({
        lng: editProjectData?.getProject?.data?.startPoint?.coordinates?.[0],
        lat: editProjectData?.getProject?.data?.startPoint?.coordinates?.[1],
      });
      setEndPosition({
        lng: editProjectData?.getProject?.data?.endPoint?.coordinates?.[0],
        lat: editProjectData?.getProject?.data?.endPoint?.coordinates?.[1],
      });
      form?.setFieldsValue({
        projectName: editProjectData?.getProject?.data?.name,
        startPoint: editProjectData?.getProject?.data?.startAddress,
        endPoint: editProjectData?.getProject?.data?.endAddress,
        roadType: editProjectData?.getProject?.data?.roadType?.key,
        roadWidth: editProjectData?.getProject?.data?.roadWidth,
        description: editProjectData?.getProject?.data?.description,
        reportHeaderName: editProjectData?.getProject?.data?.reportHeaderName,
      });
    }
  }, [editProjectData]);

  useEffect(() => {
    if (isLoaded) {
      centerMapFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const debounceCheckVideoName = useMemo(
    () => debounce(checkProjectName, 500),
    [],
  );

  const handleStartClick = () => {
    setMapCenter({ ...startPosition });
  };

  const handleEndClick = () => {
    setMapCenter({ ...endPosition });
  };

  return (
    <ModalComponents
      closable={false}
      onCancel={() => setDiscardModal(true)}
      visible={visible}
      className="add-new-project-modal modal-components-design "
      width="100vw"
      title={
        <>
          <CommonConfirmationModal
            visible={discardModal}
            onCancel={() => {
              setVisible(false);
              if (projectEdit) setProjectEdit(false);
              setDiscardModal(false);
              setStartPosition(null);
              setEndPosition(null);
              form?.resetFields();
            }}
            onOk={() => setDiscardModal(false)}
            title="Discard Changes"
            content="If you exit the current window, all your changes will be lost!"
            okText="Keep Editing"
            cancelText="Discard"
          />
          <div
            className="d-flex align-center pointer"
            onClick={() => setDiscardModal(true)}
          >
            <NavArrowLeft /> {projectEdit ? 'Edit Project' : 'Add New Project'}
          </div>
        </>
      }
    >
      <div className="project-input-fields">
        {!isLoaded ? (
          <LoaderComponent spinning={!isLoaded} />
        ) : (
          <Row gutter={32}>
            <Col sm={14} md={14} lg={14} xl={14} xxl={14}>
              <div className="map-img">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={mapCenter}
                  zoom={10}
                  onClick={handleMapClick}
                >
                  {currentPosition && <Marker position={currentPosition} />}
                  {startPosition && (
                    <Marker
                      options={startMarker}
                      position={startPosition}
                      draggable
                      onDrag={handleStartPosDrag}
                    />
                  )}
                  {endPosition && (
                    <Marker
                      options={endMarker}
                      position={endPosition}
                      draggable
                      onDrag={handleEndPosDrag}
                    />
                  )}
                  {directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{ suppressMarkers: true }}
                    />
                  )}
                </GoogleMap>
              </div>
            </Col>
            <Col sm={10} md={10} lg={10} xl={10} xxl={10}>
              <div className="project-input-fields">
                <p className="workspace-title">Project Details</p>
                <div>
                  <Spin spinning={false}>
                    <Form
                      name="addNewProject"
                      initialValues={initialValue}
                      className="custom-label add-new-project-form"
                      form={form}
                      layout="vertical"
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="projectLogo"
                        getValueFromEvent={(event) => event?.fileList}
                        rules={[
                          { required, message: 'Please Upload the file' },
                          checkImageSizeAndDimensions,
                        ]}
                      >
                        <CommonUploadFile
                          title="Project-Logo"
                          previewImage={previewImage}
                          setPreviewImage={setPreviewImage}
                          currentFile={currentFile}
                          setCurrentFile={setCurrentFile}
                          deletePopConfirmDescription="Are you sure you want to delete project Logo?"
                          deletePopConfirmTitle="Delete Profile Logo"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[
                          {
                            required,
                            message: 'Please enter project name here!',
                          },
                        ]}
                        tooltip={{
                          title:
                            'This name will be only used for the entire Terramatic Platform (Mobile app and Web app)',
                          icon: <InfoEmpty />,
                        }}
                      >
                        <Input
                          type="text"
                          placeholder="Enter project name here"
                          maxLength={80}
                          showCount
                          onChange={(e) => {
                            debounceCheckVideoName({
                              variables: {
                                where: {
                                  name: e?.target?.value,
                                  id: null,
                                  workspaceId,
                                },
                              },
                            });
                            if (!isReportHeaderNameChanged.current)
                              form.setFieldValue(
                                'reportHeaderName',
                                e.target.value,
                              );
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Start Point Location"
                        name="startPoint"
                        className="project-address"
                        tooltip={{
                          title: 'Start point marker',
                          className: 'pointer',
                          icon: (
                            <img
                              src={pinIcon}
                              alt="Start Point"
                              className="start-end-icon"
                              onClick={handleStartClick}
                              height={20}
                              width={20}
                            />
                          ),
                        }}
                      >
                        <SelectPlace
                          value={startPositionValue}
                          onChange={(place) => {
                            const coords = getLatLng(place);
                            const { lat, lng } = coords;
                            if (lat !== undefined && lng !== undefined) {
                              setStartPosition(coords);
                              setMapCenter(coords);
                            }
                          }}
                          placeholder="Enter start point location here "
                        />
                      </Form.Item>
                      {startPosition?.lat ? (
                        <span className="lat-lng">{`${startPosition?.lat}, ${startPosition?.lng}`}</span>
                      ) : null}
                      <Form.Item
                        label="End Point Location"
                        name="endPoint"
                        className="project-address"
                        tooltip={{
                          title: 'End point marker',
                          className: 'pointer',
                          icon: (
                            <img
                              src={altPinIcon}
                              alt="End Point"
                              className="start-end-icon"
                              onClick={handleEndClick}
                              height={20}
                              width={20}
                            />
                          ),
                        }}
                      >
                        <SelectPlace
                          value={endPositionValue}
                          onChange={(place) => {
                            const coords = getLatLng(place);
                            const { lat, lng } = coords;
                            if (lat !== undefined && lng !== undefined) {
                              setEndPosition(coords);
                              setMapCenter(coords);
                            }
                          }}
                          placeholder="Enter end point location here"
                        />
                      </Form.Item>
                      {endPosition?.lat ? (
                        <span className="lat-lng">{`${endPosition?.lat}, ${endPosition?.lng}`}</span>
                      ) : null}

                      <Row gutter={24} className="road-details">
                        <Col span={12}>
                          <Form.Item
                            label="Type of Road"
                            name="roadType"
                            disabled
                            rules={[
                              {
                                required,
                                message: 'Please select type of road',
                              },
                            ]}
                          >
                            <Select
                              width="100%"
                              onChange={(value, { width }) => {
                                form?.setFieldValue('roadWidth', width);
                                form?.setFields([
                                  {
                                    name: 'roadWidth',
                                    touched: true,
                                  },
                                  {
                                    name: 'projectLogo',
                                    touched: true,
                                  },
                                ]);
                              }}
                            >
                              {roadTypesData?.roadTypes?.data?.map((item) => (
                                <Option
                                  key={item?.key}
                                  value={item?.key}
                                  width={item?.width}
                                >
                                  {item?.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Road Width(m)"
                            name="roadWidth"
                            rules={[
                              {
                                required,
                              },
                            ]}
                            tooltip={{
                              title:
                                'The width of the undivided carriageway without shoulder',
                              icon: <InfoEmpty />,
                            }}
                          >
                            <Input
                              placeholder="Road Width"
                              type="text"
                              onKeyPress={(e) => {
                                const charCode = e?.which
                                  ? e?.which
                                  : e?.keyCode;
                                const inputValue = e?.target?.value;
                                if (
                                  (charCode < 48 || charCode > 57) && // Digits
                                  charCode !== 8 && // Backspace
                                  (charCode !== 46 || inputValue?.includes('.')) // Decimal point
                                ) {
                                  e?.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label="Description"
                        name="description"
                        className="project-address"
                        tooltip={{
                          title:
                            'This data will be added to the final report under the project description section',
                          icon: <InfoEmpty />,
                        }}
                      >
                        <TextArea
                          maxLength={350}
                          showCount
                          autoSize={{ minRows: 2 }}
                          placeholder="Enter project description here"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Project & Report Header "
                        name="reportHeaderName"
                        className="project-address report-header"
                        tooltip={{
                          title:
                            'This Name will be reflected in the final Report  (1. In Report Title 2. In Page Header)',
                          icon: <InfoEmpty />,
                        }}
                      >
                        <Input
                          maxLength={350}
                          showCount
                          placeholder="Enter project Title to be displayed in report header"
                          onChange={(e) => {
                            if (!e.target.value) {
                              isReportHeaderNameChanged.current = false;
                            } else {
                              isReportHeaderNameChanged.current = true;
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        shouldUpdate
                        className="full-width add-new-project-save-btn workspace-details-btn"
                      >
                        {() => (
                          <Button
                            type="primary"
                            size="large"
                            block
                            htmlType="submit"
                            loading={
                              createProjectLoading ||
                              editProjectLoading ||
                              loadingCreate
                            }
                            disabled={
                              projectEdit
                                ? false
                                : loadingCreate ||
                                  !form?.isFieldsTouched(['projectName']) ||
                                  !form?.isFieldsTouched(['roadType']) ||
                                  !!form
                                    ?.getFieldsError()
                                    ?.filter(({ errors }) => errors?.length)
                                    ?.length
                            }
                          >
                            {projectEdit ? 'Save' : 'Create new project'}
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                  </Spin>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </ModalComponents>
  );
};

export default AddNewProject;
