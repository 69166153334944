import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      accessToken
      data {
        id
        email
        fullName
        profileImageUrl
        role
        isActive
        lastActiveOn
        uid
        verifiedOn
        createdAt
        updatedAt
        workspaceId
        workspaceRole
        workspaceFeatures
      }
      refreshToken
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation signUp($data: SignUpInput!) {
    signUp(data: $data) {
      message
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendEmailVerificationLink(
    $data: ResendEmailVerificationLinkInput
  ) {
    resendEmailVerificationLink(data: $data) {
      message
    }
  }
`;
export const VERIFY_EMAIL = gql`
  mutation emailVerification($data: EmailVerificationInput!) {
    emailVerification(data: $data) {
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser {
      message
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      accessToken
      user {
        id
        email
        fullName
        profileImageUrl
        role
        verifiedOn
        createdAt
        updatedAt
        workspaceId
        workspaceRole
      }
    }
  }
`;

export const GOOGLE_AUTH = gql`
  mutation validateToken($data: ValidateTokenInput!) {
    validateToken(data: $data) {
      accessToken
      message
      refreshToken
      data {
        email
        fullName
        id
        isActive
        lastActiveOn
        profileImageUrl
        uid
        role
        workspaceId
        workspaceRole
      }
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImageUrl
        name
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      message
    }
  }
`;

export const CREATE_DETECTION_AI_JOB = gql`
  mutation createDetectionAIJob($data: CreateDetectionAIJobInput!) {
    createDetectionAIJob(data: $data) {
      message
      data {
        id
        type
        status
        instanceId
        completion
        meteData
        createdBy
        createdAt
        updatedAt
      }
    }
  }
`;
