import {
  Input,
  Tabs,
  Menu,
  Space,
  Dropdown,
  Radio,
  Checkbox,
  Button,
  Divider,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InView } from 'react-intersection-observer';
import { debounce } from 'lodash';
import TableComponent from '../../components/CommonTable';
import {
  SeeAllMoreAction,
  Comment,
  FilterIcon,
  SortingIcon,
} from '../../assets/svg';

import {
  DETECTION_CATEGORY,
  DETECTION_SUB_CATEGORY,
  GET_DETECTIONS_SEE_ALL,
} from './graphql/Queries';
import CommentDrawer from './CommentDrawer';
import {
  LIMIT,
  PROJECT_ROLES,
  ROUTES,
  priorityOptions,
  riskLevelOptions,
} from '../../common/constants';
import CommonConfirmationModal from '../../components/CommonConfirmationModal';
import { DELETE_DETECTION, UPDATE_DETECTION } from './graphql/Mutations';
import ProjectAccessRole from '../../components/ProjectAccessRole';
import { AppContext } from '../../AppContext';

const { Search } = Input;
const SeeAll = () => {
  const {
    state: { projectRole },
  } = useContext(AppContext);
  const { projectId } = useParams();
  const history = useHistory();
  const debounceScrollType = useRef(null);
  const [detectionsListReport, setDetectionsListReport] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [commentsData, setCommentsData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [detectionTypeFilter, setDetectionTypeFilter] = useState('ALL');
  const [selectedFiltersKeys, setSelectedFiltersKeys] = useState({
    chainage: 'null',
    riskLevel: 'null',
    priority: 'null',
  });
  const [multipleFilter, setMultipleFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [typeSkip, setTypeSkip] = useState(0);
  const [isEndType, setIsEndType] = useState(false);
  const [detectionCategories, setDetectionCategories] = useState([]);
  const [tabChange, setTabChange] = useState(0);
  const [deleteDetectionId, setDeleteDetectionId] = useState(null);
  const [
    deleteDetectionConfirmation,
    setDeleteDetectionConfirmation,
  ] = useState(false);
  const [detectionTypes, setDetectionTypes] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isAnyFilterActive =
    detectionTypeFilter !== 'ALL' ||
    multipleFilter.length > 0 ||
    categoryFilter !== null ||
    typeFilter !== null ||
    searchValue !== null;

  const [getDetectionsSeeAll] = useLazyQuery(GET_DETECTIONS_SEE_ALL, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      setLoading(false);
      setDetectionsListReport([
        ...detectionsListReport,
        ...res?.detections?.data,
      ]);
      if (res?.detections?.data?.length < LIMIT) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setTableLoading(false);
    },
    onError() {
      setLoading(false);
      setTableLoading(false);
    },
  });

  const [getDetectionCategory] = useLazyQuery(DETECTION_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDetectionCategories(res?.categories?.data);
    },
    onError() {},
  });

  const [getDetectionType] = useLazyQuery(DETECTION_SUB_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.subCategories?.data?.length < LIMIT) {
        setIsEndType(true);
      }
      setDetectionTypes([...detectionTypes, ...res?.subCategories?.data]);
    },
    onError() {},
  });

  const [updateDetection] = useMutation(UPDATE_DETECTION, {
    onCompleted() {
      setDetectionsListReport([]);
      getDetectionsSeeAll({
        variables: {
          where: {
            projectId,
          },
          filter: {
            addedToReport: tabChange === 0,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
      setDeleteDetectionId(null);
    },
  });

  const [deleteDetection] = useMutation(DELETE_DETECTION, {
    onCompleted() {
      setDeleteDetectionConfirmation(false);
      setDetectionsListReport([]);
      getDetectionsSeeAll({
        variables: {
          where: {
            projectId,
          },
          filter: {
            addedToReport: tabChange === 0,
            skip: 0,
            limit: LIMIT,
          },
        },
      });
      setDeleteDetectionId(null);
    },
  });

  useEffect(() => {
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: true,
          skip: 0,
          limit: LIMIT,
        },
      },
    });
    getDetectionCategory({
      variables: {
        filter: {
          skip: 0,
          limit: 50,
        },
      },
    });
    getDetectionType({
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    setDetectionsListReport([]);
    setHasMore(false);
    setTableLoading(true);
    setMultipleFilter([]);
    setSelectedFiltersKeys({
      chainage: 'null',
      riskLevel: 'null',
      priority: 'null',
    });
    setCategoryFilter(null);
    setTypeFilter(null);
    setDetectionTypeFilter('ALL');
    setSearchValue(null);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, [tabChange]);

  const handleDeleteDetection = () => {
    setTableLoading(true);
    deleteDetection({
      variables: {
        where: {
          detectionId: deleteDetectionId,
        },
      },
    });
  };

  const handleDetectionTypeFilter = (e) => {
    setTableLoading(true);
    setDetectionTypeFilter(e?.target?.value);
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(e?.target?.value !== 'ALL' && {
            detectionType: e?.target?.value,
          }),
          categories: null,
          subCategories: null,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: multipleFilter,
      },
    });
  };

  const handleChainageFilter = (selectedKey, confirm) => {
    confirm();
    setTableLoading(true);
    const updatedMultipleFilter = multipleFilter.filter(
      (filter) => filter.sortOn !== 'chainage',
    );

    updatedMultipleFilter.push({
      sortBy: selectedKey,
      sortOn: 'chainage',
    });

    setMultipleFilter(updatedMultipleFilter);
    setSelectedFiltersKeys((prevKeys) => ({
      ...prevKeys,
      chainage: selectedKey,
    }));
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(detectionTypeFilter !== 'ALL' && {
            detectionType: detectionTypeFilter,
          }),
          categories: categoryFilter,
          subCategories: typeFilter,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: updatedMultipleFilter,
      },
    });
  };

  const handleCategoryFilter = (reset) => {
    setTableLoading(true);
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(detectionTypeFilter !== 'ALL' && {
            detectionType: detectionTypeFilter,
          }),
          categories: reset ? null : categoryFilter,
          subCategories: typeFilter,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: multipleFilter,
      },
    });
  };

  const handleTypeFilter = (reset) => {
    setTableLoading(true);
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(detectionTypeFilter !== 'ALL' && {
            detectionType: detectionTypeFilter,
          }),
          categories: categoryFilter,
          subCategories: reset ? null : typeFilter,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: multipleFilter,
      },
    });
  };

  const handleRiskLevelFilter = (selectedKey, confirm) => {
    confirm();
    setTableLoading(true);
    const updatedMultipleFilter = multipleFilter.filter(
      (filter) => filter.sortOn !== 'riskLevel',
    );

    updatedMultipleFilter.push({
      sortBy: selectedKey,
      sortOn: 'riskLevel',
    });

    setMultipleFilter(updatedMultipleFilter);
    setSelectedFiltersKeys((prevKeys) => ({
      ...prevKeys,
      riskLevel: selectedKey,
    }));
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(detectionTypeFilter !== 'ALL' && {
            detectionType: detectionTypeFilter,
          }),
          categories: categoryFilter,
          subCategories: typeFilter,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: updatedMultipleFilter,
      },
    });
  };

  const handlePriorityFilter = (selectedKey, confirm) => {
    confirm();
    setTableLoading(true);
    const updatedMultipleFilter = multipleFilter.filter(
      (filter) => filter.sortOn !== 'priority',
    );

    updatedMultipleFilter.push({
      sortBy: selectedKey,
      sortOn: 'priority',
    });

    setMultipleFilter(updatedMultipleFilter);
    setSelectedFiltersKeys((prevKeys) => ({
      ...prevKeys,
      priority: selectedKey,
    }));
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          ...(detectionTypeFilter !== 'ALL' && {
            detectionType: detectionTypeFilter,
          }),
          categories: categoryFilter,
          subCategories: typeFilter,
          skip: 0,
          limit: LIMIT,
          search: searchValue,
        },
        sort: updatedMultipleFilter,
      },
    });
  };

  const handleDetectionReport = (value) => {
    setTableLoading(true);
    updateDetection({
      variables: {
        where: {
          detectionId: deleteDetectionId,
        },
        data: {
          addToReport: value,
        },
      },
    });
  };

  const handleCategoriesTypeScroll = (event) => {
    if (debounceScrollType.current) {
      debounceScrollType.current.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    debounceScrollType.current = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 10;
      const newSkip = typeSkip + 10;
      setTypeSkip(newSkip);
      if (scrolledToBottom && !isEndType) {
        getDetectionType({
          variables: {
            filter: {
              skip: newSkip,
              limit: LIMIT,
            },
          },
        });
      }
    }, 500);

    debounceScrollType.current();
  };

  const detectionTableCol = [
    {
      title: '#',
      dataIndex: '#',
      render: (val, _, index) => index + 1,
    },
    {
      title: 'Detection Type',
      key: 'detectionType',
      filterDropdown: ({ confirm }) => (
        <div className="detection-type-filter">
          <Radio.Group
            onChange={(e) => {
              handleDetectionTypeFilter(e);
              confirm();
            }}
            value={detectionTypeFilter}
          >
            <Space direction="vertical" size="0">
              <Radio key="ALL" value="ALL">
                All
              </Radio>
              <Radio key="ISSUE" value="ISSUE">
                Issue
              </Radio>
              <Radio key="INFORMATION" value="INFORMATION">
                Information
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      render: (val) => {
        if (val?.elementMissing) {
          return 'Issue';
        }
        if (val?.mismatched === '0') {
          return 'Information';
        }
        return 'Issue';
      },
    },
    {
      title: 'Chainage (km + m)',
      dataIndex: ['syncData', 'chainagePoint'],
      filterDropdown: ({ confirm }) => (
        <Menu
          className="see-all-menu-filter"
          selectedKeys={selectedFiltersKeys?.chainage}
        >
          <Menu.Item
            key="ASC"
            onClick={() => handleChainageFilter('ASC', confirm)}
          >
            Ascending
          </Menu.Item>
          <Menu.Divider key="divider" />
          <Menu.Item
            key="DESC"
            onClick={() => handleChainageFilter('DESC', confirm)}
          >
            Descending
          </Menu.Item>
        </Menu>
      ),
      filterIcon: () => <SortingIcon />,
      render: (val) => `${val}` || '-',
    },
    {
      title: 'Category',
      key: 'category',
      render: (val) => {
        if (val?.category) {
          return val?.category?.name;
        }
        return val?.otherCategory;
      },
      filterIcon: () => <FilterIcon />,
      filterDropdown: ({ confirm }) => (
        <>
          <div className="d-flex flex-vertical checkbox-filter-wrapper">
            <Checkbox.Group
              onChange={(e) => {
                setCategoryFilter(e);
              }}
              value={categoryFilter}
            >
              <Space direction="vertical" size={0}>
                {detectionCategories?.map((item, index) => (
                  <div key={item?.id}>
                    <Checkbox key={item?.id} value={item?.key}>
                      <div className="checkbox-text">{item?.name}</div>
                    </Checkbox>
                    {index !== detectionCategories.length - 1 && <Divider />}
                  </div>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          <div className="checkbox-filter-wrapper-btn-wrapper">
            <Button
              size="small"
              type="text"
              onClick={() => {
                setCategoryFilter(null);
                handleCategoryFilter(true);
                confirm();
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              className="mr-5"
              onClick={() => {
                handleCategoryFilter(false);
                confirm();
              }}
            >
              Ok
            </Button>
          </div>
        </>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      render: (val) => {
        if (val?.subCategory) {
          return val?.subCategory?.name;
        }
        return val?.otherSubCategory;
      },
      filterIcon: () => <FilterIcon />,
      filterDropdown: ({ confirm }) => (
        <>
          <div
            className="d-flex flex-vertical checkbox-filter-wrapper"
            onScroll={handleCategoriesTypeScroll}
          >
            <Checkbox.Group
              onChange={(e) => {
                setTypeFilter(e);
              }}
              value={typeFilter}
            >
              <Space direction="vertical" size="0">
                {detectionTypes?.map((item, index) => (
                  <div key={item?.id}>
                    <Checkbox key={item?.id} value={item?.key}>
                      <div className="checkbox-text">{item?.name}</div>
                    </Checkbox>
                    {index !== detectionTypes.length - 1 && <Divider />}
                  </div>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          <div className="checkbox-filter-wrapper-btn-wrapper">
            <Button
              size="small"
              type="text"
              onClick={() => {
                setTypeFilter(null);
                handleTypeFilter(true);
                confirm();
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              className="mr-5"
              onClick={() => {
                handleTypeFilter(false);
                confirm();
              }}
            >
              Ok
            </Button>
          </div>
        </>
      ),
    },
    {
      title: 'Mismatched',
      dataIndex: 'mismatched',
      render: (val) => {
        if (val === '0') {
          return 'Not Applicable';
        }
        return val || '-';
      },
    },
    {
      title: 'Risk Level',
      filterDropdown: ({ confirm }) => (
        <Menu
          className="see-all-menu-filter"
          selectedKeys={selectedFiltersKeys?.riskLevel}
        >
          <Menu.Item
            key="DESC"
            onClick={() => handleRiskLevelFilter('DESC', confirm)}
          >
            Highest to lowest
          </Menu.Item>
          <Menu.Divider key="divide" />
          <Menu.Item
            key="ASC"
            onClick={() => handleRiskLevelFilter('ASC', confirm)}
          >
            Lowest to highest
          </Menu.Item>
        </Menu>
      ),
      filterIcon: () => <SortingIcon />,
      render: (val) => {
        const matchingRiskLevel = riskLevelOptions?.find(
          (item) => item?.value === val?.riskLevel,
        );
        return matchingRiskLevel?.label || '-';
      },
    },
    {
      title: 'Priority',
      filterDropdown: ({ confirm }) => (
        <Menu
          className="see-all-menu-filter"
          selectedKeys={selectedFiltersKeys?.priority}
        >
          <Menu.Item
            key="DESC"
            onClick={() => handlePriorityFilter('DESC', confirm)}
          >
            Highest to lowest
          </Menu.Item>
          <Menu.Divider key="divide" />
          <Menu.Item
            key="ASC"
            onClick={() => handlePriorityFilter('ASC', confirm)}
          >
            Lowest to highest
          </Menu.Item>
        </Menu>
      ),
      filterIcon: () => <SortingIcon />,
      render: (val) => {
        const matchingPriority = priorityOptions?.find(
          (item) => item?.value === val?.priority,
        );
        return matchingPriority?.label || '-';
      },
    },
    {
      title: ' ',
      dataIndex: 'action',
      render: (_, data) => (
        <Space>
          <Comment
            className="pointer"
            onClick={(e) => {
              e.stopPropagation();
              setCommentsData(data);
              setOpenDrawer(true);
            }}
          />
          <ProjectAccessRole
            allowedRoles={[PROJECT_ROLES?.OWNER, PROJECT_ROLES?.EDITOR]}
            currentRole={projectRole}
          >
            <Dropdown
              overlayClassName="project-card-dropdown"
              menu={{
                items: [
                  {
                    key: 'reportAction',
                    label:
                      tabChange === 0 ? 'Remove from Report' : 'Add to Report',
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      if (tabChange === 0) {
                        handleDetectionReport(false);
                      } else {
                        handleDetectionReport(true);
                      }
                    },
                  },
                  {
                    type: 'divider',
                  },
                  {
                    key: 'delete',
                    label: 'Delete',
                    className: 'error-color',
                    onClick: ({ domEvent }) => {
                      domEvent?.stopPropagation();
                      setDeleteDetectionConfirmation(true);
                    },
                  },
                ],
              }}
              trigger={['click']}
              placement="bottomRight"
              onClick={(e) => e.stopPropagation()}
            >
              <SeeAllMoreAction
                onClick={() => {
                  setDeleteDetectionId(data?.id);
                }}
                className="pointer"
              />
            </Dropdown>
          </ProjectAccessRole>
        </Space>
      ),
    },
  ];

  const handleOnSearch = (value) => {
    setDetectionTypeFilter('ALL');
    setMultipleFilter([]);
    setCategoryFilter(null);
    setTypeFilter(null);
    setTableLoading(true);
    setSearchValue(value);
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          skip: 0,
          limit: LIMIT,
          search: value,
        },
      },
    });
  };

  const handleResetFilter = () => {
    setTableLoading(true);
    setSelectedFiltersKeys({
      chainage: 'null',
      riskLevel: 'null',
      priority: 'null',
    });
    setDetectionTypeFilter('ALL');
    setMultipleFilter([]);
    setCategoryFilter(null);
    setTypeFilter(null);
    setDetectionsListReport([]);
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  };

  const handleScroll = () => {
    getDetectionsSeeAll({
      variables: {
        where: {
          projectId,
        },
        filter: {
          addedToReport: tabChange === 0,
          skip: detectionsListReport.length,
          limit: LIMIT,
        },
      },
    });
  };

  const handleRowClick = (record) => {
    const videoId = record?.videoId;
    const id = record?.id;
    const handleRow = () => {
      history?.push(
        `${ROUTES?.PROJECTS}/${projectId}/${videoId}${ROUTES?.ADD_DETECTION}${ROUTES?.EDIT}/${id}`,
      );
    };

    return { onClick: handleRow };
  };

  return (
    <div className="detection mt-20">
      <div className="content-top d-flex flex-horizontal justify-between align-center">
        <div className="content-top-heading">Detections</div>
        <div className="content-top-input d-flex flex-horizontal align-center">
          <div className="searchBox">
            <Search
              onSearch={handleOnSearch}
              placeholder="Search"
              allowClear
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="sync-table-wrapper">
        {isAnyFilterActive && (
          <Button
            className="secondary-btn border-btn reset-filters"
            size="small"
            onClick={handleResetFilter}
          >
            Reset Filter
          </Button>
        )}
        <Tabs
          className="mb-0"
          defaultActiveKey="1"
          type="card"
          size="large"
          onChange={(e) => {
            setTabChange(e);
          }}
          items={['Added to Report', 'Removed from Report']?.map(
            (title, id) => ({
              label: title,
              key: id,
              children: (
                <TableComponent
                  onRow={handleRowClick}
                  columns={detectionTableCol}
                  data={detectionsListReport || null}
                  tableClassName="custom-table"
                  scroll={{ x: 1000 }}
                  loading={tableLoading}
                  rowKey={(record) => record?.id}
                />
              ),
            }),
          )}
        />
      </div>
      {openDrawer && (
        <CommentDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          detectionId={commentsData?.id}
          comingFrom={ROUTES?.SEE_ALL}
        />
      )}
      {deleteDetectionConfirmation && (
        <CommonConfirmationModal
          visible={deleteDetectionConfirmation}
          onCancel={() => {
            setDeleteDetectionConfirmation(false);
          }}
          onOk={() => {
            handleDeleteDetection();
          }}
          title="Delete Detection"
          content="Are you sure you want to delete this detection?"
          okText="Delete"
          cancelText="Discard"
        />
      )}
      {!loading && !tableLoading && (
        <InView
          as="div"
          onChange={(inView) => (inView && hasMore ? handleScroll() : null)}
        />
      )}
    </div>
  );
};

export default SeeAll;
