/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Button, Typography, Upload, message } from 'antd';
import { UploadImageIcon } from '../../assets/svg';
import { ALL_IMAGE_UPLOAD } from '../../common/constants';
import { getBase64 } from '../../common/utils';

function isKMLFileEmpty(file) {
  const name = file?.name;
  const ext = name?.substring(name?.lastIndexOf('.') + 1).toLowerCase();
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error('Invalid file input. Please select a valid file.'));
      return;
    }
    if (!ALL_IMAGE_UPLOAD?.includes(ext)) {
      reject(new Error('Invalid file input. Please select a valid image.'));
      return;
    }

    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = () => {
      resolve(false);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
}

const ImageUploader = ({ fileList, onChange, id, ...rest }) => {
  const [fileInfo, setFileInfo] = useState(null);
  const [preview, setPreview] = useState();

  const handleOnChange = (value) => {
    isKMLFileEmpty(value?.file)
      ?.then(async (res) => {
        if (!res) {
          const previewImage = await getBase64(
            value?.fileList?.[0]?.originFileObj,
          );
          setPreview(previewImage);
          onChange(value);
        }
        // eslint-disable-next-line no-console
      })
      .catch((err) => {
        const errorMessage = err?.message || 'An unknown error occurred';
        message.error(errorMessage);
      });
  };

  useEffect(() => {
    (async () => {
      if (fileList?.length) {
        const fileObj = fileList?.[0];
        const name = fileObj?.name;
        const size = `${((fileObj?.size ?? 0) / 1000)?.toFixed(1)} KB`;
        const previewImage = fileList?.[0]?.preview;
        if (previewImage) {
          setPreview(previewImage);
        }
        setFileInfo({
          name,
          size,
        });
      } else {
        setFileInfo(null);
      }
    })();
  }, [fileList]);

  const handleReplace = () => {
    // eslint-disable-next-line no-undef
    const fileInput = document?.getElementById(id);
    if (fileInput) {
      fileInput?.click();
    }
  };

  return (
    <>
      <Upload
        beforeUpload={() => false}
        fileList={fileList}
        className="upload-video"
        showUploadList={false}
        accept="image/jpg/jpeg/png"
        onChange={handleOnChange}
        maxCount={1}
        id={id}
        {...rest}
      >
        {fileInfo ? (
          <div
            className="upload-video-preview d-flex align-center"
            onClick={(e) => {
              e?.stopPropagation();
            }}
          >
            <div className="upload-video-preview-img d-flex justify-center align-center">
              <img src={preview} alt="error in video upload" />
            </div>
            <div>
              <Typography className="upload-video-preview-name">
                {fileInfo?.name}
              </Typography>
              <Typography className="upload-video-preview-time">
                {fileInfo?.size}
              </Typography>
              <div className="kmlActionsButtons d-flex ">
                <Typography className="upload-video-preview-time">
                  <Button size="small" onClick={() => handleReplace()}>
                    Replace
                  </Button>
                </Typography>
                <Typography className="upload-video-preview-time ml-8">
                  <Button
                    size="small"
                    onClick={(e) => {
                      e?.stopPropagation();
                      onChange([]);
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <Button icon={<UploadImageIcon />} className="dashed-button-upload">
            Upload Image
          </Button>
        )}
      </Upload>
    </>
  );
};

export default ImageUploader;
