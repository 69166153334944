import React, { useEffect, useState } from 'react';
import { Switch, Input } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InView } from 'react-intersection-observer';
import TableComponent from '../../components/CommonTable';
import { WORKSPACES } from './graphql/Queries';
import { UPDATE_WORKSPACE_IS_ACTIVE_STATUS } from './graphql/Mutations';
import { LIMIT } from '../../common/constants';

const { Search } = Input;

const WorkspaceManagement = () => {
  const [workspacesData, setWorkspacesData] = useState([]);
  const [workSpacesCount, setWorkSpacesCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [workspaces, { loading: workspacesDataLoading }] = useLazyQuery(
    WORKSPACES,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setWorkSpacesCount(res?.workspaces?.count);
        setLoading(false);
        if (res?.workspaces?.data?.length < LIMIT) {
          setHasMore(false);
        }
        setWorkspacesData([...workspacesData, ...res?.workspaces?.data]);
      },
      onError() {},
    },
  );

  const [updateWorkspaceActive] = useMutation(
    UPDATE_WORKSPACE_IS_ACTIVE_STATUS,
    {
      fetchPolicy: 'no-cache',
      onCompleted() {
        workspaces({
          variables: {
            filter: {
              skip: 0,
              limit: LIMIT,
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    setLoading(true);
    workspaces({
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, []);

  const onSearch = async (value) => {
    setWorkspacesData([]);
    const onSearchValue = value.trim();
    workspaces({
      variables: {
        filter: {
          skip: 0,
          search: onSearchValue,
        },
      },
    });
  };

  const handleWorkspaceActiveChange = async (record, e) => {
    setHasMore(true);
    setWorkspacesData([]);
    await updateWorkspaceActive({
      variables: {
        where: {
          workspaceId: record?.id,
        },
        data: {
          isActive: e,
        },
      },
    });
  };

  const handleScroll = () => {
    setLoading(true);
    workspaces({
      variables: {
        filter: {
          skip: workspacesData?.length,
          limit: LIMIT,
        },
      },
    });
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (rule, value, index) => index + 1,
    },
    {
      title: 'Workspace Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Agency Name',
      dataIndex: 'agency',
      key: 'agency',
    },
    {
      title: 'Owner Email',
      dataIndex: ['createdBy', 'email'],
      key: 'name',
    },

    {
      title: 'Distance Covered (km)',
      dataIndex: 'distanceCovered',
      key: 'distanceCovered',
      render: (_, record) => <span>{record?.distanceCovered}</span>,
    },
    {
      title: 'Active',
      key: 'active',
      render: (_, record) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked={record?.isActive}
          onClick={(e) => handleWorkspaceActiveChange(record, e)}
        />
      ),
    },
  ];
  return (
    <div>
      <div className="content-top d-flex flex-horizontal justify-between align-center">
        <div className="content-top-heading">
          Workspaces {workSpacesCount && `(${workSpacesCount})`}
        </div>
        <div className="content-top-input d-flex flex-horizontal align-center">
          <div className="searchBox d-flex flex-horizontal justify-start align-center">
            <Search
              placeholder="Search"
              allowClear
              onSearch={onSearch}
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="mt-40">
        <TableComponent
          data={workspacesData}
          columns={columns}
          tableClassName="custom-table"
          loading={workspacesDataLoading}
          scroll={{ x: 1000 }}
        />
      </div>
      {!loading && !workspacesDataLoading && (
        <InView
          as="div"
          onChange={(inView) => (inView && hasMore ? handleScroll() : null)}
        />
      )}
    </div>
  );
};

export default WorkspaceManagement;
