import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CURRENT_USER = gql`
  query me {
    me {
      id
      email
      fullName
      profileImageUrl
      role
      isActive
      lastActiveOn
      uid
      verifiedOn
      workspaceId
      workspaceRole
      workspaceFeatures
      workspaceIsActive
    }
  }
`;
