import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseIconModel } from '../assets/svg';

const ModalComponents = (props) => {
  const {
    children,
    closeModal,
    closable = true,
    content,
    footer,
    height,
    maxHeight,
    title,
    width,
    onOk,
    className,
    bordered,
    visible = true,
    ...restProps
  } = props;
  const [open, setOpen] = useState(visible);

  useEffect(() => {
    if (visible) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [visible]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      destroyOnClose
      className={className}
      title={title}
      open={open}
      onCancel={closable && handleCancel}
      closable={closable}
      onOk={onOk}
      width={width}
      height={height}
      maxHeight={maxHeight}
      bordered={bordered}
      footerPadding={!!footer}
      footer={null}
      closeIcon={<CloseIconModel />}
      {...restProps}
    >
      {open ? children : null}
    </Modal>
  );
};

export default ModalComponents;
