import { FileImageOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';
import StopRoadSign from '../../../assets/images/detection/14.01-stop-road-sign.png';
import GiveWayRoadSign from '../../../assets/images/detection/14.02-give-way-road-sign.png';
import GiveWayToBusesExitingTheBusBayRoadSign from '../../../assets/images/detection/14.03-give-way-to-buses-exiting-the-bus-bay-road-sign.png';
import BullockCartsProhibitedRoadSign from '../../../assets/images/detection/14.04-bullock-carts-prohibited-road-sign.png';
import BullockAndHandCartsProhibitedRoadSign from '../../../assets/images/detection/14.05-bullock-and-hand-carts-prohibited-road-sign.png';
import HandCartsProhibitedRoadSign from '../../../assets/images/detection/14.06-hand-carts-prohibited-road-sign.png';
import TongasProhibitedRoadSign from '../../../assets/images/detection/14.07-tongas-prohibited-road-sign.png';
import HorseRidingProhibitedRoadSign from '../../../assets/images/detection/14.08-horse-riding-prohibited-road-sign.png';
import AutoRickshawProhibitedRoadSign from '../../../assets/images/detection/14.09-auto-rickshaw-prohibited-road-sign.png';
import BusesProhibitedRoadSign from '../../../assets/images/detection/14.10-buses-prohibited-road-sign.png';
import CarsProhibitedRoadSign from '../../../assets/images/detection/14.11-cars-prohibited-road-sign.png';
import TrucksProhibitedRoadSign from '../../../assets/images/detection/14.12-trucks-prohibited-road-sign.png';
import TractorProhibitedRoadSign from '../../../assets/images/detection/14.13-tractor-prohibited-road-sign.png';
import ConstructionVehicleProhibitedRoadSign from '../../../assets/images/detection/14.14-construction-vehicle-prohibited-road-sign.png';
import ArticulatedVehiclesMovementProhibitedRoadSign from '../../../assets/images/detection/14.15-articulated-vehicles-movement-prohibited-road-sign.png';
import TwoWheelerProhibitedRoadSign from '../../../assets/images/detection/14.16-two-wheeler-prohibited-road-sign.png';
import CyclesProhibitedRoadSign from '../../../assets/images/detection/14.17-cycles-prohibited-road-sign.png';
import PedestrianProhibitedRoadSign from '../../../assets/images/detection/14.18-pedestrian-prohibited-road-sign.png';
import HornProhibitedRoadSign from '../../../assets/images/detection/14.19-horn-prohibited-road-sign.png';
import NoEntryRoadSign from '../../../assets/images/detection/14.20-no-entry-road-sign.png';
import OneWayRoadSign from '../../../assets/images/detection/14.21-one-way-road-sign.png';
import LeftTurnProhibitedRoadSign from '../../../assets/images/detection/14.22-left-turn-prohibited-road-sign.png';
import RightTurnProhibitedRoadSign from '../../../assets/images/detection/14.23-right-turn-prohibited-road-sign.png';
import OvertakingProhibitedRoadSign from '../../../assets/images/detection/14.24-overtaking-prohibited-road-sign.png';
import UTurnProhibitedRoadSign from '../../../assets/images/detection/14.25-u-turn-prohibited-road-sign.png';
import RightTurnUTurnProhibitedRoadSign from '../../../assets/images/detection/14.26-right-turn-&-u-turn-prohibited-road-sign.png';
import FreeLeftTurnProhibitedAtSignalRoadSign from '../../../assets/images/detection/14.27-free-left-turn-prohibited-at-signal-road-sign.png';
import PriorityToVehiclesFromTheOppositeDirectionRoadSign from '../../../assets/images/detection/14.28-priority-to-vehicles-from-the-opposite-direction-road-sign.png';
import NoStandingRoadSign from '../../../assets/images/detection/14.29-no-standing-road-sign.png';
import NoStoppingRoadSign from '../../../assets/images/detection/14.30-no-stopping-road-sign.png';
import NoParkingRoadSign from '../../../assets/images/detection/14.31-no-parking-road-sign.png';
import ParkingNotAllowedOnFootpathRoadSign from '../../../assets/images/detection/14.32-parking-not-allowed-on-footpath-road-sign.png';
import ParkingNotAllowedOnHalfOfFootPathRoadSign from '../../../assets/images/detection/14.33-parking-not-allowed-on-half-of-foot-path-road-sign.png';
import AxleLoadLimitRoadSign from '../../../assets/images/detection/14.34-axle-load-limit-road-sign.png';
import HeightLimitRoadSign from '../../../assets/images/detection/14.35-height-limit-road-sign.png';
import LengthLimitRoadSign from '../../../assets/images/detection/14.36-length-limit-road-sign.png';
import LoadLimitRoadSign from '../../../assets/images/detection/14.37-load-limit-road-sign.png';
import WidthLimitRoadSign from '../../../assets/images/detection/14.38-width-limit-road-sign.png';
import MaximumSpeedLimitRoadSign from '../../../assets/images/detection/14.39-maximum-speed-limit-road-sign.png';
import MaximumSpeedLimitVehicleTypeRoadSign from '../../../assets/images/detection/14.40-maximum-speed-limit-(vehicle-type)-road-sign.png';
import StopForPoliceCheckRoadSign from '../../../assets/images/detection/14.41-stop-for-police-check-road-sign.png';
import RestrictionEndsRoadSign from '../../../assets/images/detection/14.42-restriction-ends-road-sign.png';
import CompulsoryAheadRoadSign from '../../../assets/images/detection/14.43-compulsory-ahead-road-sign.png';
import CompulsoryAheadOrRightTurnRoadSign from '../../../assets/images/detection/14.44-compulsory-ahead-or-right-turn-road-sign.png';
import CompulsoryAheadOrLeftTurnRoadSign from '../../../assets/images/detection/14.45-compulsory-ahead-or-left-turn-road-sign.png';
import CompulsoryTurnRightRoadSign from '../../../assets/images/detection/14.46-compulsory-turn-right-road-sign.png';
import CompulsoryTurnLeftRoadSign from '../../../assets/images/detection/14.47-compulsory-turn-left-road-sign.png';
import PriorityToVehiclesFromTheRightRoadSign from '../../../assets/images/detection/14.47a-priority-to-vehicles-from-the-right-road-sign.png';
import CompulsoryTurnLeftInAdvanceOfJunctionRoadSign from '../../../assets/images/detection/14.48-compulsory-turn-left-(in-advance-of-junction)-road-sign.png';
import CompulsoryKeepLeftInAdvanceOfJunctionRoadSign from '../../../assets/images/detection/14.49-compulsory-keep-left-(in-advance-of-junction)-road-sign.png';
import CompulsoryKeepLeftRoadSign from '../../../assets/images/detection/14.50-compulsory-keep-left-road-sign.png';
import CompulsoryKeepRightRoadSign from '../../../assets/images/detection/14.51-compulsory-keep-right-road-sign.png';
import PassEitherSideRoadSign from '../../../assets/images/detection/14.52-pass-either-side-road-sign.png';
import MinimumSpeedLimitRoadSign from '../../../assets/images/detection/14.53-minimum-speed-limit-road-sign.png';
import CompulsoryCycleTrackcycleOnlyRoadSign from '../../../assets/images/detection/14.54-compulsory-cycle-trackcycle-only-road-sign.png';
import CompulsoryCyclistAndPedestrianRouteRoadSign from '../../../assets/images/detection/14.55-compulsory-cyclist-and-pedestrian-route-road-sign.png';
import PedestrianOnlyRoadSign from '../../../assets/images/detection/14.56-pedestrian-only-road-sign.png';
import CompulsorySnowChainRoadSign from '../../../assets/images/detection/14.57-compulsory-snow-chain-road-sign.png';
import LeftHendCurveRoadSign from '../../../assets/images/detection/15.01-left-hend-curve-road-sign.png';
import LeftSideRoadOnLeftCurveRoadSign from '../../../assets/images/detection/15.01a-left-side-road-on-left-curve-road-sign.png';
import RightSideRoadOnLeftCurveRoadSign from '../../../assets/images/detection/15.01b-right-side-road-on-left-curve-road-sign.png';
import CrossRoadOnLeftCurveRoadSign from '../../../assets/images/detection/15.01c-cross-road-on-left-curve-road-sign.png';
import StaggeredIntersectionOnLeftCurveRoadSign from '../../../assets/images/detection/15.01d-staggered-intersection-on-left-curve-road-sign.png';
import RightHendCurveRoadSign from '../../../assets/images/detection/15.02-right-hend-curve-road-sign.png';
import LeftSideRoadOnRightCurveRoadSign from '../../../assets/images/detection/15.02a-left-side-road-on-right-curve-road-sign.png';
import RightSideRoadOnRightCurveRoadSign from '../../../assets/images/detection/15.02b-right-side-road-on-right-curve-road-sign.png';
import CrossRoadOnRightCurveRoadSign from '../../../assets/images/detection/15.02c-cross-road-on-right-curve-road-sign.png';
import StaggeredIntersectionOnRightCurveRoadSign from '../../../assets/images/detection/15.02d-staggered-intersection-on-right-curve-road-sign.png';
import RightHairpinBandRoadSign from '../../../assets/images/detection/15.03-right-hairpin-band-road-sign.png';
import LeftHairpinBandRoadSign from '../../../assets/images/detection/15.04-left-hairpin-band-road-sign.png';
import RightReverseBendRoadSign from '../../../assets/images/detection/15.05-right-reverse-bend-road-sign.png';
import LeftReverseBendRoadSign from '../../../assets/images/detection/15.06-left-reverse-bend-road-sign.png';
import SeriesOfBandsRoadSign from '../../../assets/images/detection/15.07-series-of-bands-road-sign.png';
import DegreeLoopRoadSign from '../../../assets/images/detection/15.08-degree-loop-road-sign.png';
import SideRoadRightRoadSign from '../../../assets/images/detection/15.09-side-road-right-road-sign.png';
import SideRoadRightLeftRoadSign from '../../../assets/images/detection/15.10-side-road-right-left-road-sign.png';
import YIntersectionLeftMinorRoadRoadSign from '../../../assets/images/detection/15.11-y-intersection-(left-minor-road)-road-sign.png';
import YIntersectionRightMinorRoadRoadSign from '../../../assets/images/detection/15.12-y-intersection-(right-minor-road)-road-sign.png';
import YIntersectionRoadSign from '../../../assets/images/detection/15.13-y-intersection-road-sign.png';
import CrossRoadRoadSign from '../../../assets/images/detection/15.14-cross-road-road-sign.png';
import RoundaboutRoadSign from '../../../assets/images/detection/15.15-roundabout-road-sign.png';
import TrafficSignalsRoadSign from '../../../assets/images/detection/15.16-traffic-signals-road-sign.png';
import TIntersectionRoadSign from '../../../assets/images/detection/15.17-t-intersection-road-sign.png';
import TIntersectionMajorRoadAheadRoadSign from '../../../assets/images/detection/15.18-t-intersection-major-road-ahead-road-sign.png';
import MajorRoadAheadRoadSign from '../../../assets/images/detection/15.19-major-road-ahead-road-sign.png';
import StaggeredIntersectionRoadSign from '../../../assets/images/detection/15.20-staggered-intersection-road-sign.png';
import MergingTrafficAheadFromLeftRoadSign from '../../../assets/images/detection/15.22-merging-traffic-ahead-(from-left)-road-sign.png';
import MergingTrafficAheadFromRightRoadSign from '../../../assets/images/detection/15.22a-merging-traffic-ahead-(from-right)-road-sign.png';
import NarrowRoadAheadRoadSign from '../../../assets/images/detection/15.23-narrow-road-ahead-road-sign.png';
import RoadWidensRoadSign from '../../../assets/images/detection/15.24-road-widens-road-sign.png';
import NarrowBridgeAheadRoadSign from '../../../assets/images/detection/15.25-narrow-bridge-ahead-road-sign.png';
import SteepAscentRoadSign from '../../../assets/images/detection/15.26-steep-ascent-road-sign.png';
import SteepDescentRoadSign from '../../../assets/images/detection/15.27-steep-descent-road-sign.png';
import ReducedCarriagewayLeftLanesReducedRoadSign from '../../../assets/images/detection/15.28-reduced-carriageway-(left-lane(s)-reduced)-road-sign.png';
import ReducedCarriagewayRightLanesReducedRoadSign from '../../../assets/images/detection/15.29-reduced-carriageway-(right-lane(s)-reduced)-road-sign.png';
import StartOfDualCarriagewayRoadSign from '../../../assets/images/detection/15.30-start-of-dual-carriageway-road-sign.png';
import EndOfDualCarriagewayRoadSign from '../../../assets/images/detection/15.31-end-of-dual-carriageway-road-sign.png';
import GapInMedianRoadSign from '../../../assets/images/detection/15.32-gap-in-median-road-sign.png';
import PedestrianCrossingRoadSign from '../../../assets/images/detection/15.33-pedestrian-crossing-road-sign.png';
import SchoolAheadRoadSign from '../../../assets/images/detection/15.34-school-ahead-road-sign.png';
import BuiltUpAreaRoadSign from '../../../assets/images/detection/15.35-built-up-area-road-sign.png';
import TwoWayOperationRoadSign from '../../../assets/images/detection/15.36-two-way-operation-road-sign.png';
import TwoWayTrafficOnCrossRoadAheadWarningRoadSign from '../../../assets/images/detection/15.37-two-way-traffic-on-cross-road-ahead-warning-road-sign.png';
import LaneClosedTwoLaneCarriagewayRoadSign from '../../../assets/images/detection/15.38-lane-closed-(two-lane-carriageway)-road-sign.png';
import LaneClosedThreeLaneCarriagewayRoadSign from '../../../assets/images/detection/15.39-lane-closed-(three-lane-carriageway)-road-sign.png';
import LaneClosedFourLaneCarriagewayRoadSign from '../../../assets/images/detection/15.40-lane-closed-(four-lane-carriageway)-road-sign.png';
import TrafficDiversionOnDualCarriagewayRoadSign from '../../../assets/images/detection/15.41-traffic-diversion-on-dual-carriageway-road-sign.png';
import MenAtWorkRoadSign from '../../../assets/images/detection/15.42-men-at-work-road-sign.png';
import DangerWarningRoadSign from '../../../assets/images/detection/15.43-danger-warning-road-sign.png';
import DifferentlyAbledPersonAheadRoadSign from '../../../assets/images/detection/15.44-differently-abled-person-ahead-road-sign.png';
import DeafPersonsLikelyOnRoadAheadRoadSign from '../../../assets/images/detection/15.45a-deaf-persons-likely-on-road-ahead-road-sign.png';
import BlindPersonsLikelyOnRoadAheadRoadSign from '../../../assets/images/detection/15.45b-blind-persons-likely-on-road-ahead-road-sign.png';
import CycleCrossingRoadSign from '../../../assets/images/detection/15.46-cycle-crossing-road-sign.png';
import CycleRouteAheadRoadSign from '../../../assets/images/detection/15.47-cycle-route-ahead-road-sign.png';
import DangerousDipRoadSign from '../../../assets/images/detection/15.48-dangerous-dip-road-sign.png';
import SpeedBreakerRoadSign from '../../../assets/images/detection/15.49-speed-breaker-road-sign.png';
import RumbleStripRoadSign from '../../../assets/images/detection/15.50-rumble-strip-road-sign.png';
import RoughRoadRoadSign from '../../../assets/images/detection/15.51-rough-road-road-sign.png';
import DangerousDitchRoadSign from '../../../assets/images/detection/15.52-dangerous-ditch-road-sign.png';
import LooseGravelRoadSign from '../../../assets/images/detection/15.53-loose-gravel-road-sign.png';
import SlipperyRoadRoadSign from '../../../assets/images/detection/15.54-slippery-road-road-sign.png';
import SlipperyRoadBecauseOfIceRoadSign from '../../../assets/images/detection/15.55-slippery-road-because-of-ice-road-sign.png';
import OpeningOrSwingBridgeRoadSign from '../../../assets/images/detection/15.56-opening-or-swing-bridge-road-sign.png';
import OverheadCablesRoadSign from '../../../assets/images/detection/15.57-overhead-cables-road-sign.png';
import PlaygroundAheadRoadSign from '../../../assets/images/detection/15.58-playground-ahead-road-sign.png';
import QuaySideOnRiverBankRoadSign from '../../../assets/images/detection/15.59-quay-side-on-river-bank-road-sign.png';
import BarrierRoadSign from '../../../assets/images/detection/15.60-barrier-road-sign.png';
import SuddenSideWindsRoadSign from '../../../assets/images/detection/15.61-sudden-side-winds-road-sign.png';
import TunnelAheadRoadSign from '../../../assets/images/detection/15.62-tunnel-ahead-road-sign.png';
import FerryRoadSign from '../../../assets/images/detection/15.63-ferry-road-sign.png';
import TramsCrossingRoadSign from '../../../assets/images/detection/15.64-trams-crossing-road-sign.png';
import FallingRocksRoadSign from '../../../assets/images/detection/15.65-falling-rocks-road-sign.png';
import CattleCrossingRoadSign from '../../../assets/images/detection/15.66-cattle-crossing-road-sign.png';
import WildAnimalRoadSign from '../../../assets/images/detection/15.67-wild-animal-road-sign.png';
import QueuesLikelyAheadRoadSign from '../../../assets/images/detection/15.68-queues-likely-ahead-road-sign.png';
import LowFlyingAircraftRoadSign from '../../../assets/images/detection/15.69-low-flying-aircraft-road-sign.png';
import UnguardedRailwayCrossingRoadSign from '../../../assets/images/detection/15.70-unguarded-railway-crossing-road-sign.png';
import UnguardedRailwayCrossingAt50100MplainrollingAt3060MRoadSign from '../../../assets/images/detection/15.70a-unguarded-railway-crossing-[at-50-100-m-(plainrolling),-at-30-60-m-(hill)]-road-sign.png';
import GuardedRailwayCrossingRoadSign from '../../../assets/images/detection/15.71-guarded-railway-crossing-road-sign.png';
import GuardedRailwayCrossingAt50100MPlainrollingAt3060MhillRoadSign from '../../../assets/images/detection/15.71a-guarded-railway-crossing-[at-50-100-m-(plainrolling),-at-30-60-m-(hill)]-road-sign.png';
import CrashProneAreaAheadRoadSign from '../../../assets/images/detection/15.72-crash-prone-area-ahead-road-sign.png';
import UTurnAheadRoadSign from '../../../assets/images/detection/15.73-u-turn-ahead-road-sign.png';
import SingleChevronNormalRoadSign from '../../../assets/images/detection/15.74-single-chevron-(normal)-road-sign.png';
import DoubleChevronRoadSign from '../../../assets/images/detection/15.76-double-chevron-road-sign.png';
import TripleChevronRoadSign from '../../../assets/images/detection/15.77-triple-chevron-road-sign.png';
import ObjectHazardLeftRoadSign from '../../../assets/images/detection/15.78-object-hazard-(left)-road-sign.png';
import ObjectHazardRightRoadSign from '../../../assets/images/detection/15.79-object-hazard-(right)-road-sign.png';
import TwoWayHazardMarkerRoadSign from '../../../assets/images/detection/15.80-two-way-hazard-marker-road-sign.png';
import EatingPlaceRoadSign from '../../../assets/images/detection/17.01-eating-place-road-sign.png';
import LightRefreshmentRoadSign from '../../../assets/images/detection/17.02-light-refreshment-road-sign.png';
import RestingPlaceRoadSign from '../../../assets/images/detection/17.03-resting-place-road-sign.png';
import FirstAidPostRoadSign from '../../../assets/images/detection/17.04-first-aid-post-road-sign.png';
import ToiletRoadSign from '../../../assets/images/detection/17.05-toilet-road-sign.png';
import FillingStationRoadSign from '../../../assets/images/detection/17.06-filling-station-road-sign.png';
import HospitalRoadSign from '../../../assets/images/detection/17.07-hospital-road-sign.png';
import EmergencySosFacilityRoadSign from '../../../assets/images/detection/17.08-emergency-sos-facility-road-sign.png';
import PedestrianSubwayRoadSign from '../../../assets/images/detection/17.10-pedestrian-subway-road-sign.png';
import FootOverBridgeRoadSign from '../../../assets/images/detection/17.11-foot-over-bridge-road-sign.png';
import ChairLiftRoadSign from '../../../assets/images/detection/17.12-chair-lift-road-sign.png';
import PoliceStationRoadSign from '../../../assets/images/detection/17.13-police-station-road-sign.png';
import RepairFacilityRoadSign from '../../../assets/images/detection/17.14-repair-facility-road-sign.png';
import RailwayStationmetroStationmonorailStationRoadSign from '../../../assets/images/detection/17.15-railway-stationmetro-stationmonorail-station-road-sign.png';
import IndustrialAreaRoadSign from '../../../assets/images/detection/17.16-industrial-area-road-sign.png';
import CycleRickshawStandRoadSign from '../../../assets/images/detection/17.17-cycle-rickshaw-stand-road-sign.png';
import TaxiStandRoadSign from '../../../assets/images/detection/17.18-taxi-stand-road-sign.png';
import AutoRickshawStandRoadSign from '../../../assets/images/detection/17.19-auto-rickshaw-stand-road-sign.png';
import ShareTaxiShareAutoStandRoadSign from '../../../assets/images/detection/17.20-share-taxi-share-auto-stand-road-sign.png';
import HomeZoneRoadSign from '../../../assets/images/detection/17.21-home-zone-road-sign.png';
import CampSiteRoadSign from '../../../assets/images/detection/17.22-camp-site-road-sign.png';
import AirportRoadSign from '../../../assets/images/detection/17.23-airport-road-sign.png';
import GolfCourseRoadSign from '../../../assets/images/detection/17.24-golf-course-road-sign.png';
import NationalHeritageRoadSign from '../../../assets/images/detection/17.25-national-heritage-road-sign.png';
import NoThroughRoadRoadSign from '../../../assets/images/detection/17.26-no-through-road-road-sign.png';
import NoThroughSideRoadRoadSign from '../../../assets/images/detection/17.27-no-through-side-road-road-sign.png';
import TollRoadAheadRoadSign from '../../../assets/images/detection/17.28-toll-road-ahead-road-sign.png';
import GuideSignOnTollLanePortalRoadSign from '../../../assets/images/detection/17.29-guide-sign-on-toll-lane-portal-road-sign.png';
import CountryBorderRoadSign from '../../../assets/images/detection/17.30-country-border-road-sign.png';
import EntryRampForExpresswayRoadSign from '../../../assets/images/detection/17.31-entry-ramp-for-expressway-road-sign.png';
import ExitRampForExpresswayRoadSign from '../../../assets/images/detection/17.32-exit-ramp-for-expressway-road-sign.png';
import ExpresswaySymbolRoadSign from '../../../assets/images/detection/17.33-expressway-symbol-road-sign.png';
import EndOfExpresswayRoadSign from '../../../assets/images/detection/17.34-end-of-expressway-road-sign.png';
import BusStopRoadSign from '../../../assets/images/detection/17.35-bus-stop-road-sign.png';
import BusLaneRoadSign from '../../../assets/images/detection/17.36-bus-lane-road-sign.png';
import ContraFlowBusLaneRoadSign from '../../../assets/images/detection/17.37-contra-flow-bus-lane-road-sign.png';
import CycleLaneRoadSign from '../../../assets/images/detection/17.38-cycle-lane-road-sign.png';
import ContraFlowCycleLaneRoadSign from '../../../assets/images/detection/17.39-contra-flow-cycle-lane-road-sign.png';
import HolidayChaletsRoadSign from '../../../assets/images/detection/17.40-holiday-chalets-road-sign.png';
import EmergencyExitRoadSign from '../../../assets/images/detection/17.41-emergency-exit-road-sign.png';
import PedestrianCrossingInformatorySignRoadSign from '../../../assets/images/detection/17.47-pedestrian-crossing-informatory-sign-road-sign.png';
import ElectricVehicleChargingStationInformatorySignRoadSign from '../../../assets/images/detection/17.49-electric-vehicle-charging-station-informatory-sign-road-sign.png';

import CommonConfirmationModal from '../../../components/CommonConfirmationModal';

const AnnotationImageModal = ({
  visible,
  setVisible,
  imageSource,
  annotatedImage,
  handleSavedImage,
  closeImgEditor,
}) => {
  const [resetToOldImage, setResetToOldImage] = useState(false);
  const [resetConfirmationVisible, setResetConfirmationVisible] = useState(
    false,
  );
  const [modalClose, setModalClose] = useState(false);
  const currentDate = new Date();
  const dateString = currentDate.toLocaleString();

  return (
    <Modal
      className="image-editor-modal"
      width={1500}
      open={visible}
      footer={null}
      destroyOnClose
      onCancel={() => {
        setModalClose(true);
        setResetToOldImage(false);
      }}
      centered
    >
      <FilerobotImageEditor
        source={
          // eslint-disable-next-line no-nested-ternary
          resetToOldImage
            ? imageSource
            : // eslint-disable-next-line no-unneeded-ternary
            annotatedImage
            ? annotatedImage
            : imageSource
        }
        onSave={handleSavedImage}
        onClose={closeImgEditor}
        Text={{ text: 'Terramatic' }}
        tabsIds={[TABS.WATERMARK, TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
        defaultTabId="Watermark"
        AutoRickshawProhibited
        defaultSavedImageName={`terramatic-${dateString}`}
        avoidChangesNotSavedAlertOnLeave
        defaultToolId={TOOLS.TEXT}
        Watermark={{
          position: 'left',
          activePositions: 'top-row',
          defaultText: 'Filerobot.....',
          gallery: [
            StopRoadSign,
            GiveWayRoadSign,
            GiveWayToBusesExitingTheBusBayRoadSign,
            BullockCartsProhibitedRoadSign,
            BullockAndHandCartsProhibitedRoadSign,
            HandCartsProhibitedRoadSign,
            TongasProhibitedRoadSign,
            HorseRidingProhibitedRoadSign,
            AutoRickshawProhibitedRoadSign,
            BusesProhibitedRoadSign,
            CarsProhibitedRoadSign,
            TrucksProhibitedRoadSign,
            TractorProhibitedRoadSign,
            ConstructionVehicleProhibitedRoadSign,
            ArticulatedVehiclesMovementProhibitedRoadSign,
            TwoWheelerProhibitedRoadSign,
            CyclesProhibitedRoadSign,
            PedestrianProhibitedRoadSign,
            HornProhibitedRoadSign,
            NoEntryRoadSign,
            OneWayRoadSign,
            LeftTurnProhibitedRoadSign,
            RightTurnProhibitedRoadSign,
            OvertakingProhibitedRoadSign,
            UTurnProhibitedRoadSign,
            RightTurnUTurnProhibitedRoadSign,
            FreeLeftTurnProhibitedAtSignalRoadSign,
            PriorityToVehiclesFromTheOppositeDirectionRoadSign,
            NoStandingRoadSign,
            NoStoppingRoadSign,
            NoParkingRoadSign,
            ParkingNotAllowedOnFootpathRoadSign,
            ParkingNotAllowedOnHalfOfFootPathRoadSign,
            AxleLoadLimitRoadSign,
            HeightLimitRoadSign,
            LengthLimitRoadSign,
            LoadLimitRoadSign,
            WidthLimitRoadSign,
            MaximumSpeedLimitRoadSign,
            MaximumSpeedLimitVehicleTypeRoadSign,
            StopForPoliceCheckRoadSign,
            RestrictionEndsRoadSign,
            CompulsoryAheadRoadSign,
            CompulsoryAheadOrRightTurnRoadSign,
            CompulsoryAheadOrLeftTurnRoadSign,
            CompulsoryTurnRightRoadSign,
            CompulsoryTurnLeftRoadSign,
            PriorityToVehiclesFromTheRightRoadSign,
            CompulsoryTurnLeftInAdvanceOfJunctionRoadSign,
            CompulsoryKeepLeftInAdvanceOfJunctionRoadSign,
            CompulsoryKeepLeftRoadSign,
            CompulsoryKeepRightRoadSign,
            PassEitherSideRoadSign,
            MinimumSpeedLimitRoadSign,
            CompulsoryCycleTrackcycleOnlyRoadSign,
            CompulsoryCyclistAndPedestrianRouteRoadSign,
            PedestrianOnlyRoadSign,
            CompulsorySnowChainRoadSign,
            LeftHendCurveRoadSign,
            LeftSideRoadOnLeftCurveRoadSign,
            RightSideRoadOnLeftCurveRoadSign,
            CrossRoadOnLeftCurveRoadSign,
            StaggeredIntersectionOnLeftCurveRoadSign,
            RightHendCurveRoadSign,
            LeftSideRoadOnRightCurveRoadSign,
            RightSideRoadOnRightCurveRoadSign,
            CrossRoadOnRightCurveRoadSign,
            StaggeredIntersectionOnRightCurveRoadSign,
            RightHairpinBandRoadSign,
            LeftHairpinBandRoadSign,
            RightReverseBendRoadSign,
            LeftReverseBendRoadSign,
            SeriesOfBandsRoadSign,
            DegreeLoopRoadSign,
            SideRoadRightRoadSign,
            SideRoadRightLeftRoadSign,
            YIntersectionLeftMinorRoadRoadSign,
            YIntersectionRightMinorRoadRoadSign,
            YIntersectionRoadSign,
            CrossRoadRoadSign,
            RoundaboutRoadSign,
            TrafficSignalsRoadSign,
            TIntersectionRoadSign,
            TIntersectionMajorRoadAheadRoadSign,
            MajorRoadAheadRoadSign,
            StaggeredIntersectionRoadSign,
            MergingTrafficAheadFromLeftRoadSign,
            MergingTrafficAheadFromRightRoadSign,
            NarrowRoadAheadRoadSign,
            RoadWidensRoadSign,
            NarrowBridgeAheadRoadSign,
            SteepAscentRoadSign,
            SteepDescentRoadSign,
            ReducedCarriagewayLeftLanesReducedRoadSign,
            ReducedCarriagewayRightLanesReducedRoadSign,
            StartOfDualCarriagewayRoadSign,
            EndOfDualCarriagewayRoadSign,
            GapInMedianRoadSign,
            PedestrianCrossingRoadSign,
            SchoolAheadRoadSign,
            BuiltUpAreaRoadSign,
            TwoWayOperationRoadSign,
            TwoWayTrafficOnCrossRoadAheadWarningRoadSign,
            LaneClosedTwoLaneCarriagewayRoadSign,
            LaneClosedThreeLaneCarriagewayRoadSign,
            LaneClosedFourLaneCarriagewayRoadSign,
            TrafficDiversionOnDualCarriagewayRoadSign,
            MenAtWorkRoadSign,
            DangerWarningRoadSign,
            DifferentlyAbledPersonAheadRoadSign,
            DeafPersonsLikelyOnRoadAheadRoadSign,
            BlindPersonsLikelyOnRoadAheadRoadSign,
            CycleCrossingRoadSign,
            CycleRouteAheadRoadSign,
            DangerousDipRoadSign,
            SpeedBreakerRoadSign,
            RumbleStripRoadSign,
            RoughRoadRoadSign,
            DangerousDitchRoadSign,
            LooseGravelRoadSign,
            SlipperyRoadRoadSign,
            SlipperyRoadBecauseOfIceRoadSign,
            OpeningOrSwingBridgeRoadSign,
            OverheadCablesRoadSign,
            PlaygroundAheadRoadSign,
            QuaySideOnRiverBankRoadSign,
            BarrierRoadSign,
            SuddenSideWindsRoadSign,
            TunnelAheadRoadSign,
            FerryRoadSign,
            TramsCrossingRoadSign,
            FallingRocksRoadSign,
            CattleCrossingRoadSign,
            WildAnimalRoadSign,
            QueuesLikelyAheadRoadSign,
            LowFlyingAircraftRoadSign,
            UnguardedRailwayCrossingRoadSign,
            UnguardedRailwayCrossingAt50100MplainrollingAt3060MRoadSign,
            GuardedRailwayCrossingRoadSign,
            GuardedRailwayCrossingAt50100MPlainrollingAt3060MhillRoadSign,
            CrashProneAreaAheadRoadSign,
            UTurnAheadRoadSign,
            SingleChevronNormalRoadSign,
            DoubleChevronRoadSign,
            TripleChevronRoadSign,
            ObjectHazardLeftRoadSign,
            ObjectHazardRightRoadSign,
            TwoWayHazardMarkerRoadSign,
            EatingPlaceRoadSign,
            LightRefreshmentRoadSign,
            RestingPlaceRoadSign,
            FirstAidPostRoadSign,
            ToiletRoadSign,
            FillingStationRoadSign,
            HospitalRoadSign,
            EmergencySosFacilityRoadSign,
            PedestrianSubwayRoadSign,
            FootOverBridgeRoadSign,
            ChairLiftRoadSign,
            PoliceStationRoadSign,
            RepairFacilityRoadSign,
            RailwayStationmetroStationmonorailStationRoadSign,
            IndustrialAreaRoadSign,
            CycleRickshawStandRoadSign,
            TaxiStandRoadSign,
            AutoRickshawStandRoadSign,
            ShareTaxiShareAutoStandRoadSign,
            HomeZoneRoadSign,
            CampSiteRoadSign,
            AirportRoadSign,
            GolfCourseRoadSign,
            NationalHeritageRoadSign,
            NoThroughRoadRoadSign,
            NoThroughSideRoadRoadSign,
            TollRoadAheadRoadSign,
            GuideSignOnTollLanePortalRoadSign,
            CountryBorderRoadSign,
            EntryRampForExpresswayRoadSign,
            ExitRampForExpresswayRoadSign,
            ExpresswaySymbolRoadSign,
            EndOfExpresswayRoadSign,
            BusStopRoadSign,
            BusLaneRoadSign,
            ContraFlowBusLaneRoadSign,
            CycleLaneRoadSign,
            ContraFlowCycleLaneRoadSign,
            HolidayChaletsRoadSign,
            EmergencyExitRoadSign,
            PedestrianCrossingInformatorySignRoadSign,
            ElectricVehicleChargingStationInformatorySignRoadSign,
          ],
          imageScalingRatio: 0.09,
        }}
      />
      <Tooltip title="Reset Image">
        <FileImageOutlined
          className="reset-image"
          onClick={() => {
            setResetConfirmationVisible(true);
          }}
        />
      </Tooltip>
      {resetConfirmationVisible && (
        <CommonConfirmationModal
          visible={resetConfirmationVisible}
          onCancel={() => {
            setResetConfirmationVisible(false);
          }}
          onOk={() => {
            setResetToOldImage(true);
            setResetConfirmationVisible(false);
          }}
          title="All changes will be lost!"
          content="Are you sure you would want to reset the image to default?"
          okText="Continue"
          cancelText="Discard"
        />
      )}
      {modalClose && (
        <CommonConfirmationModal
          visible={modalClose}
          onCancel={() => {
            setModalClose(false);
            setVisible(false);
          }}
          onOk={() => {
            setModalClose(false);
          }}
          title="Unsaved Changes"
          content="If you close, you will lose any changes that you have made."
          okText="Keep editing"
          cancelText="Discard"
        />
      )}
    </Modal>
  );
};

export default AnnotationImageModal;
