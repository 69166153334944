import { isNaN } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { NavArrowLeft, TerramaticLogo } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { RESEND_VERIFY_EMAIL } from './graphql/Mutations';

const SignUpEmailSent = () => {
  // eslint-disable-next-line no-undef
  let storedMinutes = parseInt(localStorage.getItem('storedTimeMinutes'), 10);
  // eslint-disable-next-line no-undef
  let storedSeconds = parseInt(localStorage.getItem('storedTimeSeconds'), 10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [minutes, setMinutes] = useState(storedMinutes || 5);
  const [seconds, setSeconds] = useState(storedSeconds || 0);
  const [form] = Form?.useForm();
  const location = useLocation();
  const email = location?.state;
  const history = useHistory();
  const [resendVerifyLink, { loading }] = useMutation(RESEND_VERIFY_EMAIL, {
    onError() {},
  });

  const padZero = (num) => (num < 10 ? `0${num}` : num); // Function to add leading zero

  const onFinish = async () => {
    try {
      const formValues = {
        email: email?.trim()?.toLowerCase(),
      };
      await resendVerifyLink({
        variables: { data: { ...formValues } },
      });
      // eslint-disable-next-line no-undef
      localStorage.removeItem('storedTimeMinutes');
      // eslint-disable-next-line no-undef
      localStorage.removeItem('storedTimeSeconds');
      history?.replace(ROUTES?.LOGIN);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (isNaN(storedMinutes) || isNaN(storedSeconds)) {
      storedMinutes = 5;
      storedSeconds = 0;
      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeMinutes', storedMinutes.toString());
      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeSeconds', storedSeconds.toString());
    }

    const interval = setInterval(() => {
      if (storedMinutes === 0 && storedSeconds === 0) {
        clearInterval(interval);
        setIsButtonDisabled(false);
      } else if (storedSeconds === 0) {
        // eslint-disable-next-line no-plusplus
        storedMinutes--;
        storedSeconds = 59;
      } else {
        // eslint-disable-next-line no-plusplus
        storedSeconds--;
      }

      setMinutes(storedMinutes);
      setSeconds(storedSeconds);

      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeMinutes', storedMinutes.toString());
      // eslint-disable-next-line no-undef
      localStorage.setItem('storedTimeSeconds', storedSeconds.toString());
    }, 1000);

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <Link to={ROUTES?.LOGIN}>
                {isButtonDisabled ? (
                  <div
                    className="forget-back"
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      localStorage.removeItem('storedTimeMinutes');
                      // eslint-disable-next-line no-undef
                      localStorage.removeItem('storedTimeSeconds');
                    }}
                  >
                    <NavArrowLeft className="navArrowLeftIcon" />
                    <div className="back-text">Back</div>
                  </div>
                ) : null}
              </Link>

              <h1 className="mt-0 mb-12 title-text">
                {isButtonDisabled ? 'Email Sent' : 'Resent Verification'}
              </h1>
              <div className="sub-title mb-16">
                {isButtonDisabled
                  ? `We have sent an email to "${email}" for verification.`
                  : `Verification will be sent on "${email}".`}
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="Forget-password"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="vertical"
                  form={form}
                  className="full-width custom-label"
                >
                  <Form.Item>
                    <Button
                      size="large"
                      block
                      htmlType="submit"
                      loading={loading}
                      className={isButtonDisabled ? 'pointer-none' : ''}
                    >
                      {isButtonDisabled
                        ? `Resend in ${minutes}:${padZero(seconds)}` // Use padZero function for formatting
                        : 'Resend Link'}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="signup-side-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default SignUpEmailSent;
