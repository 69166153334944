import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_WORKSPACE_LOGO_SIGNED_URL = gql`
  query GetWorkspaceLogoUploadSignedPutUrl(
    $data: GetWorkspaceLogoUploadSignedPutUrlInput!
  ) {
    getWorkspaceLogoUploadSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;

export const GET_PROFILE_IMAGE_URL_LOGO = gql`
  query GetProfileImageUploadSignedPutUrl(
    $data: GetProfileImageUploadSignedPutUrlInput!
  ) {
    getProfileImageUploadSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_WORKSPACE = gql`
  query Workspace {
    workspace {
      logoUrl
      name
      agency
      id
      bio
      contactDetails
    }
  }
`;
