import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORKSPACES = gql`
  query Data($filter: WorkspacesFilter) {
    workspaces(filter: $filter) {
      count
      data {
        distanceCovered
        createdAt
        isActive
        id
        agency
        name
        logoUrl
        uid
        updatedAt
        bio
        features
        createdBy {
          id
          email
          fullName
          profileImageUrl
          role
          isActive
          lastActiveOn
          uid
          verifiedOn
          createdAt
          updatedAt
          workspaceId
          workspaceRole
          workspaceFeatures
          workspaceIsActive
        }
      }
    }
  }
`;
