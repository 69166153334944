import { Button, Divider, Select } from 'antd';
import React from 'react';

const Components = () => (
  <div>
    <div>
      <Divider orientation="primary btn" plain />
      <Button size="large" type="primary">
        Primary large Button
      </Button>
      <Button type="primary">Primary defult Button</Button>{' '}
      <Button size="small" type="primary">
        Primary small Button
      </Button>
    </div>
    <div>
      <Divider orientation="default btn" plain />
      <Button size="large">default large Button</Button>
      <Button>default Button</Button>
      <Button size="small">default small Button</Button>
    </div>
    <div>
      <Divider orientation="secondary btn" plain />
      <Button size="large" className="secondary-btn">
        secondary large Button
      </Button>
      <Button className="secondary-btn">secondary Button</Button>
      <Button className="secondary-btn" size="small">
        secondary small Button
      </Button>
    </div>
    <div>
      <Divider orientation="secondary border btn" plain />
      <Button size="large" className="secondary-btn border-btn">
        secondary border large Button
      </Button>
      <Button className="secondary-btn border-btn">
        secondary border Button
      </Button>
      <Button className="secondary-btn border-btn" size="small">
        secondary border small Button
      </Button>
    </div>
    <div>
      <Divider orientation="primary secondary disable btn" plain />
      <Button size="large" type="primary" disabled>
        Primary disable Button
      </Button>
      <Button size="large" disabled>
        disable Button
      </Button>
      <Button className="secondary-btn" disabled>
        secondary disable Button
      </Button>
      <Button size="small" className="secondary-btn border-btn" disabled>
        secondary border disable Button
      </Button>
    </div>
    <div>
      <Divider orientation="dash btn" plain />
      <Button size="large" type="dashed">
        Dashed Button
      </Button>
      <Button type="dashed">Primary Button</Button>
      <Button size="small" type="dashed">
        Primary Button
      </Button>
    </div>
    <div>
      <Button size="small" type="link">
        Link Button
      </Button>
    </div>

    <div>
      <Divider orientation="dash btn" plain />
      <Select
        placeholder="Select Value"
        style={{ width: '150px' }}
        options={[
          {
            label: 'Manager',
            value: 'Manager',
          },
          {
            label: 'Engineer',
            value: 'Engineer',
          },
        ]}
      />
      <Select
        style={{ width: '150px' }}
        status="error"
        defaultValue="Manager"
        options={[
          {
            label: 'Manager',
            value: 'Manager',
          },
          {
            label: 'Engineer',
            value: 'Engineer',
          },
        ]}
      />
      <Select
        disabled
        style={{ width: '150px' }}
        status="error"
        defaultValue="Manager"
        options={[
          {
            label: 'Manager',
            value: 'Manager',
          },
          {
            label: 'Engineer',
            value: 'Engineer',
          },
        ]}
      />
    </div>
  </div>
);

export default Components;
