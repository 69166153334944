import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DELETE_PROJECT_VIDEO = gql`
  mutation removeProjectVideo($where: RemoveVideoUniqueInput!) {
    removeProjectVideo(where: $where) {
      message
    }
  }
`;

export const CREATE_PROJECT_VIDEO = gql`
  mutation createProjectVideo($data: CreateProjectVideoInput!) {
    createProjectVideo(data: $data) {
      message
    }
  }
`;

export const UPDATE_PROJECT_VIDEO = gql`
  mutation updateProjectVideo(
    $where: VideoUniqueInput!
    $data: UpdateProjectVideoInput!
  ) {
    updateProjectVideo(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace(
    $data: UpdateWorkspaceInput!
    $where: WorkspaceUniqueInput
  ) {
    updateWorkspace(data: $data, where: $where) {
      message
    }
  }
`;

export const CREATE_SYNC_DATA = gql`
  mutation createSyncData($data: VideoUniqueInput!) {
    createSyncData(data: $data) {
      message
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($data: CreateProjectInput!) {
    createProject(data: $data) {
      message
    }
  }
`;

export const EDIT_PROJECT = gql`
  mutation updateProject(
    $where: ProjectUniqueInput!
    $data: UpdateProjectInput!
  ) {
    updateProject(where: $where, data: $data) {
      message
      data {
        id
        name
        logoUrl
        workspace {
          id
        }
        startAddress
        endAddress
        startPoint
        endPoint
        roadType {
          id
          key
          name
        }
        roadWidth
        description
        uid
        createdAt
        updatedAt
        lastUpdatedAt
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation removeProject($where: RemoveProjectUniqueInput!) {
    removeProject(where: $where) {
      message
    }
  }
`;

export const ASSIGN_MEMBERS_TO_PROJECT = gql`
  mutation assignMembersToProject(
    $where: AssignMembersToProjectIdsInput!
    $data: AssignMembersToProjectInput!
  ) {
    assignMembersToProject(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_MEMBER_TO_PROJECT = gql`
  mutation updateProjectMember(
    $where: UpdateProjectMemberUniqueInput!
    $data: UpdateProjectMemberInput!
  ) {
    updateProjectMember(where: $where, data: $data) {
      message
    }
  }
`;

export const REMOVE_MEMBER_FROM_PROJECT = gql`
  mutation removeProjectMember($where: RemoveProjectMemberUniqueInput!) {
    removeProjectMember(where: $where) {
      message
    }
  }
`;

export const UPDATE_SOURCE_SYNC_LOCATION = gql`
  mutation updateSourceSyncLocation(
    $where: SourceSyncDataUniqueInput!
    $data: UpdateSourceSyncDataInput!
  ) {
    updateSourceSyncLocation(where: $where, data: $data) {
      message
      data {
        videoTimestamp
        id
        kmlTimestamp
        location
        createdAt
        updatedAt
        video {
          id
        }
      }
    }
  }
`;

export const UPDATE_SOURCE_SYNC_VIDEO_TIMESTAMP = gql`
  mutation UpdateSourceSyncVideoTimestamp(
    $where: VideoUniqueInput!
    $data: [UpdateSourceSyncVideoTimestampInput!]!
  ) {
    updateSourceSyncVideoTimestamp(where: $where, data: $data) {
      data {
        id
        createdAt
        kmlTimestamp
        location
      }
      message
    }
  }
`;

export const CREATE_DETECTION = gql`
  mutation createDetection($data: CreatedDetectionInput!) {
    createDetection(data: $data) {
      data {
        id
        videoId
        otherCategory
        safetyConcern
        otherSubCategory
        mismatched
        category {
          id
          name
          key
        }
        subCategory {
          id
          name
          key
          categoryKey
        }
        syncData {
          id
          chainage
          kmlTimestamp
          videoTimestamp
          location
          createdAt
          updatedAt
        }
        replacedImage
        sourceImage
        annotatedImage
        uid
        recommendationAnnotatedImage
        recommendationReplacedImage
        recommendationSourceImage
      }
      message
    }
  }
`;

export const CREATE_DETECTION_PROPERTY = gql`
  mutation createDetectionProperty($data: CreatedDetectionPropertyInput!) {
    createDetectionProperty(data: $data) {
      data {
        id
        detectionId
        propertyId
        otherProperty
        matched
        riskLevel
        priority
        recommendations {
          id
          otherRecommendation
          recommendationId
          recommendation {
            id
            description
          }
        }
        property {
          id
          name
          key
          categoryKey
          subCategoryKey
        }
      }
      message
    }
  }
`;

export const DELETE_DETECTION = gql`
  mutation deleteDetection($where: DetectionUniqueInput!) {
    deleteDetection(where: $where) {
      message
    }
  }
`;

export const DELETE_DETECTION_PROPERTY = gql`
  mutation DeleteDetectionProperty($where: DetectionPropertyUniqueInput!) {
    deleteDetectionProperty(where: $where) {
      message
    }
  }
`;

export const UPDATE_DETECTION = gql`
  mutation updateDetection(
    $where: DetectionUniqueInput!
    $data: UpdateDetection!
  ) {
    updateDetection(where: $where, data: $data) {
      data {
        id
        videoId
        otherCategory
        safetyConcern
        otherSubCategory
        mismatched
        category {
          id
          name
          key
        }
        subCategory {
          id
          name
          key
          categoryKey
        }
        syncData {
          id
          chainage
          kmlTimestamp
          videoTimestamp
          location
          createdAt
          updatedAt
          videoId
          chainagePoint
        }
        replacedImage
        sourceImage
        annotatedImage
        uid
        elementMissing
        addToReport
      }
      message
    }
  }
`;

export const UPDATE_DETECTION_PROPERTY = gql`
  mutation updateDetectionProperty(
    $where: DetectionPropertyUniqueInput!
    $data: UpdateDetectionPropertyInput!
  ) {
    updateDetectionProperty(where: $where, data: $data) {
      data {
        id
        detectionId
        propertyId
        otherProperty
        matched
        riskLevel
        priority
        addToReport
      }
      message
    }
  }
`;

export const CREATE_DETECTION_COMMENTS = gql`
  mutation createDetectionComment($data: CreatedDetectionCommentInput!) {
    createDetectionComment(data: $data) {
      data {
        id
        comment
        detectionId
        createdAt
        creator {
          id
          email
          fullName
          profileImageUrl
          role
          isActive
          lastActiveOn
          uid
          verifiedOn
          createdAt
          updatedAt
          workspaceId
          workspaceRole
        }
      }
      message
    }
  }
`;

export const CREATE_ANALYSIS = gql`
  mutation createAnalysis($data: CreateAnalysisInput!) {
    createAnalysis(data: $data) {
      sourceData
      message
      data {
        id
        projectId
        createdBy
        uid
        chainage
        duration
        type
        csvUrl
        csvMeta
        graphs
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_ANALYSIS = gql`
  mutation updateAnalysis(
    $where: AnalysisUniqueInput!
    $data: UpdateAnalysisInput!
  ) {
    updateAnalysis(where: $where, data: $data) {
      sourceData
      data {
        id
        projectId
        createdBy
        uid
        chainage
        duration
        type
        csvUrl
        csvMeta
        graphs
        createdAt
        updatedAt
      }
      message
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation createReport($data: ProjectUniqueInput!) {
    createReport(data: $data) {
      message
    }
  }
`;

export const CREATE_DETECTION_AI_JOB = gql`
  mutation createAIJob($data: CreateAIJobInput!) {
    createAIJob(data: $data) {
      message
      data {
        id
        type
        status
        instanceId
        completion
        metaData
        createdBy
        endTime
        createdAt
        updatedAt
      }
    }
  }
`;
export const TERMINATED_DETECTION_AI_JOB = gql`
  mutation terminateAIJob($where: TerminateAIJobUniqueInput!) {
    terminateAIJob(where: $where) {
      message
      data {
        id
        type
        status
        instanceId
        completion
        metaData
        createdBy
        endTime
        createdAt
        updatedAt
      }
    }
  }
`;
export const CREATE_PROJECT_VIDEO_MOBILE_KML = gql`
  mutation createProjectVideoMobileKml($where: VideoUniqueInput!) {
    createProjectVideoMobileKml(where: $where) {
      message
    }
  }
`;
