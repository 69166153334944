import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROLE_KEYS, ROUTES } from '../common/constants';
import Error404 from '../Error404';
import MemberWrapper from '../modules/members';
import Personal from '../modules/settings/Personal';
import Agency from '../modules/settings/Agency';
import Project from '../modules/projects/Project';
import ProjectWrapper from '../modules/projects';
import WorkspaceManagementWrapper from '../modules/workspaceManagement';
import AccessRoleAdmin from '../components/AccessRoleAdmin';

const ContentRoutes = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.MAIN}
      render={() => <Redirect to={ROUTES?.PROJECTS} />}
    />
    <Route exact path={ROUTES?.PROJECTS} component={Project} />
    <Route
      path={`${ROUTES?.PROJECTS}/:projectId/:videoId`}
      component={ProjectWrapper}
    />
    <Route path={`${ROUTES?.PROJECTS}/:projectId`} component={ProjectWrapper} />
    <Route path={ROUTES?.MEMBERS} component={MemberWrapper} />
    <Route path={ROUTES?.PERSONAL} component={Personal} />
    <Route path={ROUTES?.AGENCY} component={Agency} />
    <AccessRoleAdmin allowedRoles={ROLE_KEYS?.SUPER_ADMIN}>
      <Route
        path={ROUTES?.WORKSPACE_MANAGEMENT}
        component={WorkspaceManagementWrapper}
      />
    </AccessRoleAdmin>
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ContentRoutes;
