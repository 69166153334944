import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { priorityOptions, riskLevelOptions } from '../../../common/constants';

const { TextArea } = Input;

const ViewDetailModal = ({ visible, setVisible, data, setData }) => {
  const [form] = Form?.useForm();

  useEffect(() => {
    if (data) {
      const matchingRiskLevel = riskLevelOptions?.find(
        (item) => item?.value === data?.riskLevel,
      );
      const matchingPriority = priorityOptions?.find(
        (item) => item?.value === data?.priority,
      );
      form.setFieldsValue({
        property: 'NotApplicable',
        riskLevel: matchingRiskLevel?.label,
        priority: matchingPriority?.label,
        recommendation: data?.recommendations
          ?.map(
            (item) =>
              item?.otherRecommendation !== null && item?.otherRecommendation,
          )
          .filter((item) => item),
      });
    }
  }, [data]);

  return (
    <Modal
      destroyOnClose
      className="modal-components-design property-modal"
      title="View Details"
      open={visible}
      onCancel={() => {
        setVisible(false);
        setData();
      }}
      footer={null}
      centered
    >
      <Form
        name="videoModal"
        className="custom-label"
        form={form}
        layout="vertical"
      >
        <div className="form-overflow">
          <Form.Item label="Property" name="property">
            <Select placeholder="Select property" disabled />
          </Form.Item>
          <Form.Item label="Risk Level" name="riskLevel">
            <Select disabled />
          </Form.Item>
          <Form.Item label="Priority" name="priority">
            <Select disabled />
          </Form.Item>
          <Form.Item label="Recommendations" name="recommendation">
            {data?.recommendations?.map((item, index) => (
              <TextArea
                key={`${item?.id}-index`}
                className={index !== 0 && 'mt-8'}
                disabled
                placeholder="Enter recommendation"
                autoSize={{ minRows: 1, maxRows: 5 }}
              />
            ))}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewDetailModal;
