import React from 'react';
import { Button, Form, Input } from 'antd';
import './auth.less';
import { useMutation } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { formValidatorRules } from '../../common/utils';
import {
  EyeCloseIcon,
  EyeOpenIcon,
  NavArrowLeft,
  TerramaticLogo,
} from '../../assets/svg';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { ROUTES } from '../../common/constants';
import { RESET_PASSWORD } from './graphql/Mutations';

const UpdatePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { required, password } = formValidatorRules;
  const [form] = Form?.useForm();
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  const uid = params?.get('uid');

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const renderPasswordIcon = (visible) => {
    if (visible) {
      return <EyeOpenIcon />;
    }
    return <EyeCloseIcon />;
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        uid,
        token,
        password: values?.password?.trim(),
        confirmPassword: values?.confirmPassword?.trim(),
      };
      const response = await resetPassword({
        variables: { data: { ...formValues } },
      });
      if (response) {
        history?.replace(ROUTES?.PASSWORD_CHANGED);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <Link to={ROUTES?.LOGIN}>
                <div className="forget-back">
                  <NavArrowLeft className="navArrowLeftIcon" />
                  <div className="back-text">Back</div>
                </div>
              </Link>

              <h1 className="mt-0 mb-12 title-text">Change Password</h1>
              <div className="sub-title mb-32">
                Reset your password and regain access to Terramatic.
              </div>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="reset-password"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="vertical"
                  form={form}
                  className="full-width custom-label"
                >
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      { required, message: 'Minimum 8 character' },
                      password,
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter new password"
                      iconRender={(visible) => renderPasswordIcon(visible)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[
                      { required, message: 'Please enter confirm password!' },
                      {
                        validator: (_, value) => {
                          const { getFieldValue } = form;

                          if (value && value !== getFieldValue('password')) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject('Password do not match');
                          }
                          return Promise?.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter confirm password"
                      iconRender={(visible) => renderPasswordIcon(visible)}
                    />
                  </Form.Item>

                  <Form.Item shouldUpdate className="full-width mb-0">
                    {() => (
                      <Button
                        type="primary"
                        size="large"
                        block
                        htmlType="submit"
                        loading={loading}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            ?.getFieldsError()
                            ?.filter(({ errors }) => errors?.length)?.length
                        }
                      >
                        Change Password
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="signup-side-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default UpdatePassword;
