import React from 'react';
import { Button, Form, Input } from 'antd';
import './auth.less';
import { useMutation } from '@apollo/client';
import { formValidatorRules } from '../../common/utils';
import { TerramaticLogo } from '../../assets/svg';
import roadImageLogo from '../../assets/images/road-image-logo.png';
import { RESEND_VERIFY_EMAIL } from './graphql/Mutations';

const ResentEmailVerify = () => {
  const { required, email } = formValidatorRules;
  const [resendVerifyLink, { loading }] = useMutation(RESEND_VERIFY_EMAIL, {
    onError() {},
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await resendVerifyLink({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="container">
      <div className="content-container">
        <div className="content-container-title">
          <div className="content-inner">
            <div>
              <h1 className="mt-0 mb-12 title-text">
                Resent Email Verification Link
              </h1>
            </div>
            <div className="content">
              <div className="details">
                <Form
                  name="Forget-password"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  layout="vertical"
                  className="full-width custom-label"
                >
                  <Form.Item
                    name="email"
                    label="Email-ID"
                    rules={[
                      { required, message: 'Please enter email!' },
                      email,
                    ]}
                  >
                    <Input placeholder="Enter your email id here" />
                  </Form.Item>

                  <Form.Item className="full-width mb-0">
                    <Button
                      type="primary"
                      size="large"
                      block
                      htmlType="submit"
                      loading={loading}
                    >
                      Resent Verification Link
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-cover">
        <img
          className="side-cover-image"
          alt="signup-side-img"
          src={roadImageLogo}
        />
        <TerramaticLogo className="logo" />
      </div>
    </div>
  );
};

export default ResentEmailVerify;
