import { gql } from '@apollo/client';

export const GET_PROJECT_NAME = gql`
  query getProject($where: ProjectUniqueInput!) {
    getProject(where: $where) {
      data {
        name
      }
    }
  }
`;

export const GET_PROJECT_VIDEO_NAME = gql`
  query getProjectVideo($where: VideoUniqueInput!) {
    getProjectVideo(where: $where) {
      data {
        name
      }
    }
  }
`;
