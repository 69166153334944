import React, { useContext } from 'react';
import { Avatar, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import ModalComponents from '../../../components/CommonModel';
import LoaderComponent from '../../../components/LoaderComponent';
import { ROUTES } from '../../../common/constants';
import { AppContext } from '../../../AppContext';
import { DefaultProfileImage } from '../../../assets/svg';

const UserProfileModal = ({ visible, setVisible }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const history = useHistory();

  const handleSignOut = () => {
    history?.push(ROUTES?.LOGOUT);
    setVisible(false);
  };

  return (
    <ModalComponents
      width={470}
      onCancel={() => setVisible(false)}
      closable={false}
      className="modal-components-design profile-modal"
      visible={visible}
      centered
    >
      <LoaderComponent spinning={false}>
        <div className="user">
          {currentUser?.profileImageUrl ? (
            <Avatar src={currentUser?.profileImageUrl} size={80} />
          ) : (
            <DefaultProfileImage />
          )}
          <h2 className="name">{currentUser?.fullName}</h2>
          <p className="email">{currentUser?.email}</p>

          <Button
            onClick={() => {
              setVisible(false);
              history?.push(ROUTES?.PERSONAL);
            }}
            className="secondary-btn border-btn"
            size="small"
          >
            View or edit profile
          </Button>
        </div>
        <hr />
        <span className="signout" onClick={() => handleSignOut()}>
          Sign Out
        </span>
      </LoaderComponent>
    </ModalComponents>
  );
};

export default UserProfileModal;
