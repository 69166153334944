import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { ROUTES } from '../../common/constants';
import CommonConfirmationModal from '../CommonConfirmationModal';
import { DELETE_USER } from '../../modules/settings/graphql/Mutations';
import { AppContext } from '../../AppContext';

const DeleteAccountModel = ({ visible = false, setVisible }) => {
  const { dispatch } = useContext(AppContext);
  const [form] = Form?.useForm();
  const navigate = useHistory();

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted() {
      setVisible(false);
      dispatch({ type: 'LOGOUT' });
      navigate?.push(ROUTES?.LOGIN);
    },
  });

  const onFinish = async ({ email }) => {
    try {
      await deleteUser({
        variables: {
          where: {
            email,
          },
        },
      });
    } catch (error) {
      return error;
    }
  };

  const handleConfirm = () => {
    form?.submit();
  };

  return (
    <CommonConfirmationModal
      visible={visible}
      onOk={handleConfirm}
      onCancel={() => {
        setVisible(false);
      }}
      title="Confirm Deletion"
      className="account-delete-modal"
      contentBlock={
        <div className="delete-account-modal">
          <Typography className="delete-account-description">
            Please take a moment to review what will happen when your account is
            deleted as this action is permanent and not reversible.
          </Typography>
          <Typography className="delete-account-title mt-8 mb-8">
            Workspace:
          </Typography>
          <Typography className="delete-account-description">
            You will be immediately removed from and lose access to your
            workspace.
          </Typography>
          <Divider className="mt-16 mb-16" />
          <Typography className="delete-account-title">
            This action is permanent, please confirm your understanding by
            entering your Email ID
          </Typography>
          <Form
            form={form}
            name="deleteAccount"
            initialValues={{}}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email ID"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      }
      okText="Confirm & Delete"
      cancelText="Cancel"
    />
  );
};

export default DeleteAccountModel;
