import { message } from 'antd';
import moment from 'moment';
import { filter } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import client from '../apollo';
import api from './api';
import { DATE_TIME_FORMET, defaultDateFormat, REGEX } from './constants';
import { GET_SIGNED_URL } from '../modules/projects/graphql/Queries';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid email address');
      }
      return Promise?.resolve();
    },
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.PASSWORD?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Password must be at least 8 characters long and contain at least 1 letter and 1 number.',
        );
      }
      return Promise?.resolve();
    },
  }),
  confirmPassword: () => ({
    // eslint-disable-next-line
    validator(rule) {},
  }),

  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid number');
      }
      return Promise?.resolve();
    },
  }),
  chainageCustomInputValidation: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (
        (!value?.km && value?.m && value?.km !== 0) ||
        (value?.km && !value?.m && value?.m !== 0)
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter chainage value');
      }
      if (value?.m > 999) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Please input a meter value within the range of 0 to 999',
        );
      }
      return Promise.resolve();
    },
  }),
  chainageValidation: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.CHAINAGE?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter a valid chainage value');
      }
      return Promise?.resolve();
    },
  }),
  checkEmailArray: () => ({
    validator(rule, value) {
      if (!value?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.resolve();
      }
      const data = filter(value, (item) => {
        if (REGEX?.EMAIL?.test(item?.toString())) {
          return true;
        }
        return false;
      });

      return data.length === value.length
        ? Promise?.resolve()
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise?.reject('The input is not valid E-mail');
    },
  }),
  beforeUpload: () => ({
    validator(_, fileList) {
      if (fileList?.[0]?.size > 5 * 2024 * 2024) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Size should be smaller than 5mb');
      }
      return Promise?.resolve('Success');
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const convertDataTimeIntoNormalFormat = (val) => {
  const date = new Date(val);

  // Define month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${
    monthNames[date.getMonth()]
  }-${date.getFullYear()} | ${formatAMPM(date)}`;

  function formatAMPM() {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes.toString().padStart(2, '0');
    return `${hours}:${minutes}${ampm}`;
  }
  return formattedDate;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress, name) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr.setRequestHeader(
        'Content-Disposition',
        `attachment; filename="${name}"`,
      );
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });

      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    message?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.variables?.data?.fileName;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;
  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type,
    },
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64Image = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const maxSizeInBytes = 5 * 1024 * 1024; // Maximum file size (in this case, 5MB)
      const maxWidth = 800; // Maximum width of the image
      const maxHeight = 600;
      // eslint-disable-next-line no-undef
      const img = new Image();
      img.src = e?.target?.result;
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          message.error('Image dimensions exceed the maximum allowed limit');
          return false;
        }
        // Check file size
        if (file.size > maxSizeInBytes) {
          message.error('File size exceeds the maximum allowed limit');
          return false;
        }
        resolve(reader?.result);
      };
    };
    reader.onerror = (error) => reject(error);
  });

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math?.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
  if (!isJpgOrPng) {
    message?.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message?.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const getId = () => uuidv4().substring(0, 8);

export function secondsToHms(seconds) {
  const h = Math?.floor(seconds / 3600);
  const m = Math?.floor((seconds % 3600) / 60);
  const s = Math?.floor(seconds % 60);
  const hDisplay = h > 0 ? `${(h < 10 ? '0' : '') + h}:` : '';
  const mDisplay = m > 0 ? `${(m < 10 ? '0' : '') + m}:` : '00:';
  const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
  return hDisplay + mDisplay + sDisplay;
}

export const checkLatLngExists = (data, currentLatLng) => {
  const regex = new RegExp(`\\b(${currentLatLng})\\b`);
  const result = data?.match(regex) == null;
  return result;
};

export const getVideoDuration = async (file) => {
  try {
    // eslint-disable-next-line no-undef
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    await new Promise((resolve) => {
      video.addEventListener('loadedmetadata', () => {
        resolve();
      });
    });
    const durationInSeconds = video.duration;
    const roundedDuration = Math.floor(durationInSeconds);
    return roundedDuration;
  } catch (error) {
    return error;
  }
};

export const formatChainage = (val) => `${val?.kilometer} + ${val?.meter}`;

export const chainageConversion = (val) => {
  const chainageSplitter = val?.split('+');
  const isNegative = val?.includes('-');

  let kilometers = Number(chainageSplitter[0]);
  let meters = Number(chainageSplitter[1]);

  if (isNegative) {
    kilometers = Math.abs(kilometers);
    meters = Math.abs(meters);
  }

  const kilometer =
    (isNegative ? '-' : '') + kilometers?.toString().padStart(2, '0');
  const meter = (isNegative ? '-' : '') + meters?.toString().padStart(3, '0');

  return `${kilometer} + ${meter}`;
};

export const formatDateTime = (value) =>
  moment(value)?.format(DATE_TIME_FORMET);
// Common Model//

export const getInitials = (...values) =>
  values
    ?.filter(Boolean)
    ?.map((item) => item?.toString()?.[0]?.toUpperCase())
    ?.join('');

export const getHoursMinutesAndSeconds = (totalSeconds) => {
  const sec = Math.floor(totalSeconds);
  const totalMinutes = Math.floor(sec / 60);

  const seconds = sec % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes, s: seconds };
};

export const getDurationText = (totalSeconds) => {
  const { h, m } = getHoursMinutesAndSeconds(totalSeconds);

  const hours = `${h}`.padStart(2, 0);
  const minutes = `${m}`.padStart(2, 0);
  return `${hours}:${minutes}`;
};

export const getDayJsTime = (totalSeconds) => {
  const totalMinutes = totalSeconds / 60;

  // Calculate hours and minutes from total minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Create a dayjs object with the desired time
  const selectedTime = dayjs().hour(hours).minute(minutes);
  return selectedTime;
};

export const getFileExtension = (filename) => {
  const parts = filename.split('.');
  const extension = parts[parts.length - 1];
  return extension;
};
