import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { priorityOptions, riskLevelOptions } from '../../../common/constants';
import DetectionPropertyListDemoImage from '../../../assets/svg/detectionPropertyListDemoImg.svg';

const { TextArea } = Input;

const ViewPropertyModal = ({ visible, setVisible, data, setData }) => {
  const [form] = Form?.useForm();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (data) {
      const matchingRiskLevel = riskLevelOptions?.find(
        (item) => item?.value === data?.riskLevel,
      );
      const matchingPriority = priorityOptions?.find(
        (item) => item?.value === data?.priority,
      );
      setPreview(data?.imageUrl);
      form.setFieldsValue({
        property: data?.otherProperty ? 'other' : data?.property?.name,
        propertyOther: data?.otherProperty,
        matched: data?.matched === true ? 'Yes' : 'No',
        riskLevel: matchingRiskLevel?.label,
        priority: matchingPriority?.label,
        uploadImage: data?.imageUrl,
        recommendation: data?.recommendations
          ?.map((item) => {
            if (item?.recommendation !== null) {
              return item?.recommendation?.description;
            }
            return item?.otherRecommendation;
          })
          .filter((item) => item),
      });
    }
  }, [data]);

  return (
    <Modal
      destroyOnClose
      className="modal-components-design property-modal"
      title="View Property"
      open={visible}
      onCancel={() => {
        setVisible(false);
        setData();
      }}
      footer={null}
      centered
    >
      <Form
        name="videoModal"
        className="custom-label"
        form={form}
        layout="vertical"
      >
        <div className="form-overflow">
          <Form.Item label="Property" name="property">
            <Select disabled />
          </Form.Item>
          {data?.otherProperty && (
            <Form.Item className="other-property-input" name="propertyOther">
              <Input disabled />
            </Form.Item>
          )}
          <Form.Item label="Matched" name="matched">
            <Select disabled />
          </Form.Item>
          <Form.Item
            label="Image"
            name="uploadImage"
            getValueFromEvent={(value) => {
              if (Array.isArray(value)) return value;
              return value?.fileList;
            }}
            valuePropName="fileList"
          >
            <img
              className="view-details-img"
              src={preview || DetectionPropertyListDemoImage}
              alt="view-details-img"
            />
          </Form.Item>
          {data?.matched !== true && (
            <>
              <Form.Item label="Risk Level" name="riskLevel">
                <Select disabled />
              </Form.Item>
              <Form.Item label="Priority" name="priority">
                <Select disabled />
              </Form.Item>
              <Form.Item label="Recommendations" name="recommendation">
                {data?.recommendations?.map((item, index) => {
                  if (item?.recommendation) {
                    return (
                      <TextArea
                        key={`${item?.recommendation?.id}-index`}
                        value={item?.recommendation?.description}
                        className={index !== 0 && 'mt-8'}
                        disabled
                        autoSize={{ minRows: 1, maxRows: 5 }}
                      />
                    );
                  }
                  return (
                    <TextArea
                      key={`${item?.id}-index`}
                      value={item?.otherRecommendation}
                      className={index !== 0 && 'mt-8'}
                      disabled
                      autoSize={{ minRows: 1, maxRows: 5 }}
                    />
                  );
                })}
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPropertyModal;
