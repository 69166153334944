import React, { useEffect, useState } from 'react';
import { Button, Typography, Upload, message } from 'antd';
import { AttachSquare } from '../../assets/svg';
import videoCircle from '../../assets/svg/AddedKML.svg';
import CommonConfirmationModal from '../CommonConfirmationModal';
import { LAT_LANG_UPLOAD, UPLOADED_FROM_MOBILE } from '../../common/constants';
import { getFileExtension } from '../../common/utils';

function isKMLFileEmpty(file) {
  const name = file?.name;
  const ext = name?.substring(name?.lastIndexOf('.') + 1).toLowerCase();
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error('Invalid file input. Please select a valid file.'));
      return;
    }
    if (!LAT_LANG_UPLOAD?.includes(ext)) {
      reject(
        new Error(
          'Invalid file input. Please select a valid lat-long-json file.',
        ),
      );
      return;
    }

    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = () => {
      resolve(false);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
}

const LatLongUploader = ({
  fileList,
  onChange,
  uploadKml,
  id,
  mergingSlider,
  updateKmlModel,
  setUpdateKmlModel,
  status,
  text,
  uploadedFrom,
  sourceDataUrl,
  disabled,
  ...rest
}) => {
  const [fileInfo, setFileInfo] = useState(null);

  const handleOnChange = (value) => {
    const format = getFileExtension(value?.fileList?.[0]?.name);
    isKMLFileEmpty(value?.file)
      ?.then((res) => {
        if (!res) {
          onChange(value, { format });
        }
      })
      .catch((err) => {
        const errorMessage = err?.message || 'An unknown error occurred';
        message.error(errorMessage);
      });
  };

  useEffect(() => {
    (async () => {
      if (fileList?.length) {
        const fileObj = fileList?.[0];
        const name = fileObj?.name;
        const size = `${((fileObj?.size ?? 0) / 1000)?.toFixed(1)} KB`;
        setFileInfo({
          name,
          size,
        });
      } else {
        setFileInfo(null);
      }
    })();
  }, [fileList]);

  const handleReplace = () => {
    // eslint-disable-next-line no-undef
    const fileInput = document?.getElementById(id);
    if (fileInput) {
      fileInput?.click();
    }
    if (status !== false) {
      setUpdateKmlModel(false);
    }
  };

  return (
    <>
      <Upload
        beforeUpload={() => false}
        fileList={fileList}
        className="upload-video"
        showUploadList={false}
        accept=".kml"
        onChange={handleOnChange}
        maxCount={1}
        id={id}
        {...rest}
      >
        {fileInfo && !uploadKml ? (
          <div
            className="upload-video-preview d-flex align-center"
            onClick={(e) => {
              e?.stopPropagation();
            }}
          >
            <div className="upload-video-preview-img d-flex justify-center align-center">
              <img src={videoCircle} alt="error in video upload" />
            </div>
            <div>
              <Typography className="upload-video-preview-name">
                {fileInfo?.name}
              </Typography>
              <Typography className="upload-video-preview-time">
                {fileInfo?.size}
              </Typography>
              <div className="kmlActionsButtons d-flex ">
                <Typography className="upload-video-preview-time">
                  <Button
                    size="small"
                    onClick={() => handleReplace(status)}
                    disabled={status || disabled}
                  >
                    Replace
                  </Button>
                </Typography>
                <Typography className="upload-video-preview-time ml-8">
                  <Button
                    size="small"
                    onClick={(e) => {
                      e?.stopPropagation();
                      onChange([]);
                    }}
                    danger
                    disabled={status || disabled}
                  >
                    Remove
                  </Button>
                </Typography>
                {uploadedFrom === UPLOADED_FROM_MOBILE && sourceDataUrl && (
                  <Typography className="upload-video-preview-time ml-8">
                    <Button
                      className="secondary-btn border-btn"
                      size="small"
                      href={sourceDataUrl}
                    >
                      Download
                    </Button>
                  </Typography>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {!uploadKml ? (
              <Button icon={<AttachSquare />} className="dashed-button-upload">
                Attach Latitude, Longitude file
              </Button>
            ) : (
              <Typography
                className={mergingSlider ? 'd-none' : 'kml-upload'}
                onClick={(e) => {
                  e?.stopPropagation();
                  setUpdateKmlModel(true);
                }}
              >
                Upload lat-long file
              </Typography>
            )}
          </>
        )}
      </Upload>
      <CommonConfirmationModal
        visible={updateKmlModel}
        onCancel={() => {
          setUpdateKmlModel(false);
        }}
        onOk={() => {
          handleReplace();
        }}
        title="Warning"
        content="Uploading Lat-Long will reset all the existing synced point. Are you sure you want to proceed?"
        cancelText="Discard"
        okText="Proceed"
      />
    </>
  );
};

export default LatLongUploader;
